"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.jumioSuccess = exports.jumioInit = void 0;
var asyncActions_1 = require("./asyncActions");
var creditApplicationService_1 = require("../services/creditApplicationService");
var navigationActions_1 = require("./navigationActions");
var inversify_container_1 = require("../inversify.container");
var inversify_types_1 = require("../inversify.types");
var getDispatchNavigation = function () {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
};
var jumioInit = function (creditRequestId, baseUrl) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var config;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(asyncActions_1.asyncBegin());
                    return [4 /*yield*/, creditApplicationService_1.jumioRedirectUrl(creditRequestId, baseUrl)];
                case 1:
                    config = _a.sent();
                    dispatch(asyncActions_1.asyncSuccess());
                    if (config.outcome === 'failure' && config.navigateTo) {
                        dispatch(getDispatchNavigation()(config.navigateTo));
                    }
                    return [2 /*return*/, config];
            }
        });
    }); };
};
exports.jumioInit = jumioInit;
var sleep = function (milliseconds) { return new Promise(function (res) { return setTimeout(res, milliseconds); }); };
var jumioSuccess = function (transactionReference, customerInternalReference, creditApplicationId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var i, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(asyncActions_1.asyncBegin());
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < 10)) return [3 /*break*/, 5];
                    return [4 /*yield*/, creditApplicationService_1.registerJumioSuccess(transactionReference, customerInternalReference, creditApplicationId)];
                case 2:
                    result = _a.sent();
                    if (result && result.outcome !== 'failure') {
                        dispatch(getDispatchNavigation()(navigationActions_1.ID_SUCCESS));
                        dispatch(asyncActions_1.asyncSuccess());
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, sleep(i * 500)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    i++;
                    return [3 /*break*/, 1];
                case 5:
                    dispatch(getDispatchNavigation()(navigationActions_1.ERROR_PAGE));
                    dispatch(asyncActions_1.asyncSuccess());
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.jumioSuccess = jumioSuccess;
