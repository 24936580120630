"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_utilities_1 = require("deko-utilities");
var deko_common_components_1 = require("deko-common-components");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var react_redux_1 = require("react-redux");
var Page_1 = require("../../components/Page");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var redux_1 = require("redux");
var navigationActions_1 = require("../../actions/navigationActions");
var navigationActions_2 = require("../../../common/actions/navigationActions");
var documentConfig_1 = require("../../components/documents/documentConfig");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var isApplicationOfType_1 = require("../../../common/utilities/isApplicationOfType");
require("../../styles/pages/DocumentsPage.scss");
var LenderComponentProvider_1 = require("../../components/LenderComponentProvider");
var Modal_1 = require("../../../common/components/Modal");
var Video_1 = require("../../components/Video");
var DocumentsPage = /** @class */ (function (_super) {
    __extends(DocumentsPage, _super);
    function DocumentsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            disabled: deko_utilities_1.maybe(isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, _this.props.application), false),
            modalOpen: false,
        };
        _this.getContent = _this.getContent.bind(_this);
        _this.onSignButtonClick = _this.onSignButtonClick.bind(_this);
        _this.enableReviewDocs = _this.enableReviewDocs.bind(_this);
        _this.renderPage = _this.renderPage.bind(_this);
        _this.renderOnlinePage = _this.renderOnlinePage.bind(_this);
        _this.renderInstorePreVideoPage = _this.renderInstorePreVideoPage.bind(_this);
        _this.renderInstorePreScriptPage = _this.renderInstorePreScriptPage.bind(_this);
        _this.goToRetailerInStoreScriptPage = _this.goToRetailerInStoreScriptPage.bind(_this);
        _this.showReturnDeviceToRetailerModal = _this.showReturnDeviceToRetailerModal.bind(_this);
        _this.hideReturnDeviceToRetailerModal = _this.hideReturnDeviceToRetailerModal.bind(_this);
        return _this;
    }
    DocumentsPage.prototype.componentDidMount = function () {
        window.parent.postMessage({
            message: 'APPLICATION_STATUS_UPDATE',
            contents: {
                status: this.props.application.status,
                reference: this.props.application.unique_reference
            }
        }, '*');
    };
    DocumentsPage.prototype.render = function () {
        return React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: false, fallbackAction: navigationActions_2.PAY_DEPOSIT },
            React.createElement(Page_1.default, { mainCols: 7, className: 'documents-page' },
                React.createElement("div", null, this.renderPage())));
    };
    DocumentsPage.prototype.renderPage = function () {
        var isInstoreApplication = isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, this.props.application);
        var branding = this.props.branding;
        var showInstoreVideo = branding.instore_information && branding.instore_information.should_show_instore_video;
        if (isInstoreApplication && showInstoreVideo) {
            return this.renderInstorePreVideoPage(branding.instore_information);
        }
        else if (isInstoreApplication) {
            return this.renderInstorePreScriptPage();
        }
        else {
            return this.renderOnlinePage();
        }
    };
    DocumentsPage.prototype.getInstoreVideoContent = function (documentList, latePaymentDocumentReference, withdrawDocumentReference) {
        return (React.createElement("div", null,
            this.getContent(),
            React.createElement("p", null,
                "The retailer will ask you to read a ",
                documentList,
                " and review an example credit agreement before signing. You can take the ",
                documentList,
                " away and consider them further if you wish. Documents are provided by ",
                this.props.lender.name,
                ". Consequences on any late payments are clearly shown in ",
                React.createElement("b", null, latePaymentDocumentReference),
                ". Details of how you can withdraw as set out in ",
                React.createElement("b", null, withdrawDocumentReference),
                ".")));
    };
    DocumentsPage.prototype.renderInstorePreVideoPage = function (info) {
        var _this = this;
        var video = String(info.video);
        var withdrawDocumentReference = info.document_references.withdrawal;
        var latePaymentDocumentReference = info.document_references.late_payment;
        if (!withdrawDocumentReference || !latePaymentDocumentReference) {
            throw new Error('Missing document reference configuration');
        }
        var documentList = this.docsList();
        return React.createElement(React.Fragment, null,
            React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                var PreDocumentsContentWrapper = _a.PreDocumentsContentWrapper;
                return (React.createElement(PreDocumentsContentWrapper, { content: _this.getInstoreVideoContent(documentList, latePaymentDocumentReference, withdrawDocumentReference) }));
            }),
            React.createElement("div", { className: 'instore-video' },
                React.createElement("p", null,
                    "Before we move on to the documentation, please ",
                    React.createElement("strong", null, "watch this short video"),
                    " detailing your purchase."),
                React.createElement(Video_1.default, { className: 'instore-video', videoId: video, onVideoEnd: this.enableReviewDocs })),
            React.createElement(deko_common_components_1.Button, { className: 'continue-to-docs', handleClick: this.onSignButtonClick, disabled: this.state.disabled }, "Continue"));
    };
    DocumentsPage.prototype.renderInstorePreScriptPage = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                var PreDocumentsContentWrapper = _a.PreDocumentsContentWrapper;
                return (React.createElement(PreDocumentsContentWrapper, { content: _this.getContent() }));
            }),
            React.createElement(deko_common_components_1.Button, { className: 'continue-to-docs', handleClick: this.showReturnDeviceToRetailerModal }, "Continue"),
            this.state.modalOpen && React.createElement(Modal_1.default, { className: 'instore-submit-modal', onClose: this.hideReturnDeviceToRetailerModal },
                React.createElement("h1", null, "Please return the device"),
                React.createElement("p", null,
                    "Thank you, ",
                    this.props.application.consumer.forename,
                    ", the retailer will give you more information about your finance product now, in order to finalise your purchase."),
                React.createElement("p", null,
                    React.createElement("strong", null,
                        "Please confirm that you are a representative of ",
                        this.props.branding.name,
                        " and that you are ready to verify this purchase.")),
                React.createElement(deko_common_components_1.Button, { className: 'continue-to-docs', handleClick: this.goToRetailerInStoreScriptPage }, "Confirm")));
    };
    DocumentsPage.prototype.renderOnlinePage = function () {
        return React.createElement(React.Fragment, null,
            this.getContent(),
            React.createElement(deko_common_components_1.Button, { className: 'continue-to-docs', handleClick: this.onSignButtonClick, disabled: this.state.disabled }, "Continue"));
    };
    DocumentsPage.prototype.getContent = function () {
        var _this = this;
        if (!this.props.application.finance) {
            throw new Error('Finance must be set');
        }
        var term = this.props.application.finance.offer.loan_term;
        var name = this.props.application.consumer.forename;
        var lender = this.props.lender;
        var weUseElectronicSignatures = React.createElement("p", null, "We use electronic signatures to confirm your approval of the credit agreement; it's more secure and saves paper!");
        switch (this.props.application.status) {
            case IApplication_1.AppStatusEnum.ACCEPT:
                return (React.createElement("div", null,
                    React.createElement("h1", null, "Your application has been approved"),
                    React.createElement("div", null, this.renderOfferTile(this.props.application.finance.offer)),
                    React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                        var AcceptContent = _a.AcceptContent;
                        return (React.createElement(AcceptContent, { term: term, lenderName: lender.name, lenderContactNumber: lender.contact_information.phone, lenderDescription: _this.props.branding.lender_description }));
                    }),
                    React.createElement("p", null,
                        "To finalise your purchase you'll need to review and sign your documents on the next page. To electronically sign the documents check the boxes at the end of each. The documents are as follows:\u00A0",
                        this.docsList(),
                        "."),
                    weUseElectronicSignatures));
            case IApplication_1.AppStatusEnum.REFER:
                return (React.createElement("div", null,
                    React.createElement("h1", null,
                        "Nearly there ",
                        name),
                    React.createElement("div", null, this.renderOfferTile(this.props.application.finance.offer)),
                    React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                        var ReferContent = _a.ReferContent;
                        return (React.createElement(ReferContent, { lender: _this.props.application.lender }));
                    }),
                    React.createElement("p", null,
                        "Once the manual checks have been completed,",
                        ' ',
                        React.createElement("strong", null, "you'll be notified regarding the outcome of your application by email."),
                        ' ',
                        "Please follow the instructions in the email to finalise your purchase. The checks usually take less than one hour, however applications received out of working hours may take longer."),
                    React.createElement("p", null,
                        "In the meantime, you can review and sign your documents on the next page. To electronically sign the documents check the boxes at the end of each. The documents are as follows:\u00A0",
                        this.docsList(),
                        "."),
                    weUseElectronicSignatures));
        }
    };
    DocumentsPage.prototype.renderOfferTile = function (offer) {
        var loan_term = offer.loan_term, monthly_instalment = offer.monthly_instalment, apr = offer.apr, annual_interest_rate = offer.annual_interest_rate, total_payable = offer.total_payable;
        return (React.createElement("div", { className: 'offer-tile' },
            React.createElement("div", { className: 'repayment' },
                React.createElement("div", { className: 'label' }, "Repayment term:"),
                React.createElement("div", { className: 'price' },
                    React.createElement("span", null,
                        "\u00A3",
                        deko_utilities_1.currency.formatCurrency(monthly_instalment)),
                    ' ',
                    "/month"),
                React.createElement("div", { className: 'term' },
                    "for ",
                    loan_term,
                    " months")),
            React.createElement("div", { className: 'amounts' },
                React.createElement("div", { className: 'box' },
                    React.createElement("div", { className: 'label' }, "Interest rate (fixed):"),
                    React.createElement("div", { className: 'interest_rate' },
                        annual_interest_rate,
                        "%"),
                    React.createElement("div", { className: 'label' }, "APR:"),
                    React.createElement("div", { className: 'apr' },
                        apr,
                        "%")),
                React.createElement("div", { className: 'box' },
                    React.createElement("div", { className: 'label' }, "Total amount payable (inc deposit):"),
                    React.createElement("div", { className: 'label' }, "\u00A0"),
                    React.createElement("div", { className: 'amount_payable' },
                        "\u00A3",
                        deko_utilities_1.currency.formatCurrency(total_payable))))));
    };
    DocumentsPage.prototype.docsList = function () {
        var documents = this.props.documents;
        var list = [];
        documents.map(function (document, i) {
            var label = document.title;
            list.push(React.createElement("strong", { key: i }, label));
        });
        return list.reduce(function (x, y, i) {
            if (i === list.length - 1) {
                return [x, ' and ', y];
            }
            if (x) {
                return [x, ', ', y];
            }
            return [y];
        }, false);
    };
    DocumentsPage.prototype.enableReviewDocs = function () {
        if (this.state.disabled) {
            this.setState({
                disabled: false
            });
        }
    };
    DocumentsPage.prototype.onSignButtonClick = function () {
        this.props.actions.dispatchNavigation(navigationActions_2.DOCUMENTS_SIGN_PAGE);
    };
    DocumentsPage.prototype.goToRetailerInStoreScriptPage = function () {
        this.props.actions.dispatchNavigation(navigationActions_2.DOCUMENTS_INSTRUCTION_PAGE);
    };
    DocumentsPage.prototype.showReturnDeviceToRetailerModal = function () {
        this.setState({ modalOpen: true });
    };
    DocumentsPage.prototype.hideReturnDeviceToRetailerModal = function () {
        this.setState({ modalOpen: false });
    };
    return DocumentsPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application,
    branding: state.branding,
    documents: documentConfig_1.getDocumentConfig(state.application),
    lender: state.application.lender,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_1.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT, IApplication_1.AppStatusEnum.REFER])(connected);
