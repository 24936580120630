"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Main = function (_a) {
    var children = _a.children, className = _a.className;
    if (className === undefined) {
        className = '';
    }
    return (React.createElement("main", { className: className }, children));
};
exports.default = Main;
