"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DekoTelephoneAlternativeFinanceContent = function (_a) {
    var renderTable = _a.renderTable;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'bold' }, "Please read out the following to the customer:"),
        React.createElement("p", null, "Based on the details you have provided our lenders can\u2019t offer you your first choice for finance. However, they may still be able to help. They have reviewed your finance application and there is an alternative for you to consider based on your credit profile."),
        React.createElement("div", { className: 'margin-bottom-large' }, renderTable()),
        React.createElement("p", null, "You have received an email instructing you how to continue your application, where you can either accept or decline the new offer proposed to you.")));
};
exports.default = DekoTelephoneAlternativeFinanceContent;
