"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var moment = require("moment");
var deko_common_components_1 = require("deko-common-components");
var Col_1 = require("../../../common/components/template/Col");
var Row_1 = require("../../../common/components/template/Row");
var EmbeddedDocument_1 = require("../../../common/components/EmbeddedDocument");
var Scroll4Progress_1 = require("../../../common/components/Scroll4Progress");
var Modal_1 = require("../../../common/components/Modal");
var ProgressDiv_1 = require("../../../common/components/form/ProgressDiv");
var DocumentNavigationButtons_1 = require("./DocumentNavigationButtons");
var Loader_1 = require("../../../common/components/Loader");
require("../../styles/components/documents/DocumentView.scss");
require("../../styles/components/documents/DocumentsContent.scss");
require("../../styles/components/InstoreSubmitModal.scss");
require("../../styles/components/documents/MagiDocumentView.scss");
var FormSection_1 = require("../../../common/components/form/FormSection");
require("../../styles/components/Loader.scss");
var creditApplicationActions_1 = require("../../../common/actions/creditApplicationActions");
var arrowIcon = require('../../../common/assets/arrow.svg');
var DocumentView = /** @class */ (function (_super) {
    __extends(DocumentView, _super);
    function DocumentView(props) {
        var _this = _super.call(this, props) || this;
        _this.documentLoaded = function () {
            _this.setState({
                finishedLoading: true
            });
        };
        _this.renderPrefixMessage = function () {
            var doc = _this.props.currentDocument;
            if (typeof doc.renderPrefixMessage === 'undefined') {
                return null;
            }
            return (React.createElement(Row_1.default, { className: 'disclaimer-message' },
                React.createElement(Col_1.default, null,
                    React.createElement("p", null, doc.renderPrefixMessage(_this.props.lender_dd_display_name)))));
        };
        _this.renderPrintOption = function () {
            if (!_this.props.showPrintOption) {
                return null;
            }
            var doc = _this.props.currentDocument;
            return (React.createElement("div", { className: 'download-container' },
                React.createElement("a", { className: 'download font-extra-small hidden-xs', href: doc.pdf_link, target: '_blank' }, "Download or Print")));
        };
        _this.getSignCheckbox = function () {
            var doc = _this.props.currentDocument;
            var wrapperClass = ['sign-container'];
            if (doc.agreed) {
                wrapperClass.push('agreed');
            }
            else {
                wrapperClass.push(_this.state.canAgree ? 'mobile-can-agree' : 'mobile-cannot-agree');
            }
            var signedTime;
            if (doc.agreed) {
                var date = doc.signed_time;
                signedTime = (React.createElement("div", { className: 'signed-time' },
                    React.createElement("span", { className: 'bold' }, "Signed: "),
                    React.createElement("span", null, moment(date).format('Do MMMM YYYY'))));
            }
            return (React.createElement("div", { className: wrapperClass.join(' ') },
                React.createElement("div", { className: 'read-scroll' },
                    React.createElement(deko_common_components_1.Image, { src: arrowIcon, className: 'arrow-icon' }),
                    React.createElement("span", { className: 'scroll-text' }, "Read and scroll through the document and then click to sign.")),
                React.createElement("div", { className: 'sign' },
                    React.createElement("div", { className: 'checkbox-wrapper' },
                        React.createElement("input", { name: 'agree', id: 'agree', type: 'checkbox', checked: doc.agreed || false, onChange: _this.handleSignClick }),
                        React.createElement("div", { className: 'agree-signed' },
                            React.createElement("label", { htmlFor: 'agree' },
                                React.createElement("span", { className: 'doc-checkbox' }),
                                React.createElement("span", null, doc.checkboxText)),
                            signedTime))),
                React.createElement("div", { className: 'button-group' },
                    React.createElement(DocumentNavigationButtons_1.default, { currentDocument: _this.props.currentDocument, handleSetCurrentDocument: _this.props.handleSetCurrentDocument, documents: _this.props.documents, closeModal: _this.unsetCurrentDocument, submit: _this.props.submit, allDocsSigned: _this.props.allDocsSigned }))));
        };
        _this.handleScrollToEnd = function () {
            if (!_this.state.finishedLoading || _this.state.canAgree) {
                return;
            }
            _this.setState({
                maxProgress: 100,
                progress: 100,
                canAgree: true
            });
        };
        _this.handleScrollProgress = function (currentProgress) {
            if (!_this.state.finishedLoading) {
                return;
            }
            if (currentProgress > _this.state.maxProgress) {
                _this.setState({
                    maxProgress: currentProgress,
                    progress: currentProgress,
                    canAgree: _this.state.canAgree
                });
            }
        };
        _this.handleSignClick = function (event) {
            var currentDocument = _this.props.currentDocument;
            if (currentDocument.agreed && event.target.value !== 'on') {
                return;
            }
            _this.props.actions.signDocument(_this.props.currentDocument.key);
        };
        _this.unsetCurrentDocument = function () {
            _this.props.handleSetCurrentDocument(undefined);
        };
        _this.state = {
            progress: 0,
            maxProgress: 0,
            canAgree: false,
            finishedLoading: false,
        };
        return _this;
    }
    DocumentView.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.currentDocument.html_link !== prevProps.currentDocument.html_link) {
            this.setState({
                progress: 0,
                maxProgress: 0,
                canAgree: false,
                finishedLoading: false
            });
        }
    };
    DocumentView.prototype.render = function () {
        var doc = this.props.currentDocument;
        var footer = this.state.finishedLoading ? (React.createElement(FormSection_1.default, { label: 'Please check the box in order to sign your document:' },
            React.createElement(ProgressDiv_1.default, { wrapperClass: 'sign-desktop', progressPercent: this.state.progress, progressWrapperClass: 'show-xs' }, this.getSignCheckbox()))) : null;
        return (React.createElement(Modal_1.default, { className: 'document-view', onClose: this.unsetCurrentDocument },
            React.createElement(Scroll4Progress_1.default, { className: 'document-content main', onScrollProgress: this.handleScrollProgress, onScrollToEnd: this.handleScrollToEnd },
                this.renderPrefixMessage(),
                React.createElement(Row_1.default, null,
                    React.createElement(Col_1.default, null,
                        this.renderPrintOption(),
                        React.createElement(EmbeddedDocument_1.default, { src: doc.html_link, onFinishedLoading: this.documentLoaded, loader: React.createElement(Loader_1.default, { className: 'doc-loader' }) },
                            React.createElement("div", null, doc.extraStatements))))),
            React.createElement("div", { className: 'footer' }, footer)));
    };
    return DocumentView;
}(React.Component));
var mapStateToProps = function (state) { return ({
    showPrintOption: state.branding.print_enabled
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        signDocument: creditApplicationActions_1.signDocument,
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(DocumentView);
