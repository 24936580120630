"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("redux");
var react_router_redux_1 = require("react-router-redux");
var applicationReducer_1 = require("../../common/reducers/applicationReducer");
var brandingReducer_1 = require("../../common/reducers/brandingReducer");
var asyncReducer_1 = require("../../common/reducers/asyncReducer");
var tooltipReducer_1 = require("../../common/reducers/tooltipReducer");
var redemptionReducer_1 = require("../../common/reducers/redemptionReducer");
var documentsReducer_1 = require("../../common/reducers/documentsReducer");
var depositReducer_1 = require("../../common/reducers/depositReducer");
var errorsReducer_1 = require("../../common/reducers/errorsReducer");
var tokenReducer_1 = require("../../common/reducers/tokenReducer");
var idUploadReducer_1 = require("../../common/reducers/idUploadReducer");
var alternativeFinanceReducer_1 = require("../../common/reducers/alternativeFinanceReducer");
var morpheusExperimentReducer_1 = require("../../common/reducers/morpheusExperimentReducer");
var routing = react_router_redux_1.routerReducer;
var rootReducer = redux_1.combineReducers({
    routing: routing,
    application: applicationReducer_1.default,
    branding: brandingReducer_1.default,
    async: asyncReducer_1.default,
    tooltip: tooltipReducer_1.default,
    deposit: depositReducer_1.default,
    documents: documentsReducer_1.default,
    errors: errorsReducer_1.default,
    redemption: redemptionReducer_1.default,
    token: tokenReducer_1.default,
    idUpload: idUploadReducer_1.default,
    alternativeFinance: alternativeFinanceReducer_1.default,
    morpheusExperiments: morpheusExperimentReducer_1.default
});
exports.default = rootReducer;
