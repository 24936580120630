"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("../../styles/components/BasePullOutButton.scss");
var PullOutButton = /** @class */ (function (_super) {
    __extends(PullOutButton, _super);
    function PullOutButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PullOutButton.prototype.render = function () {
        var className = ['pull-out-button'];
        className.push(this.props.arrowDirection);
        return (React.createElement("div", { className: className.join(' '), onClick: this.props.handleClick }));
    };
    return PullOutButton;
}(React.Component));
exports.default = PullOutButton;
