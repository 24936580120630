"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TYPES = {
    CreditApplicationApi: Symbol('CreditApplicationApi'),
    MorpheusApi: Symbol('MorpheusApi'),
    CapturePlusApi: Symbol('CapturePlusApi'),
    RedemptionApi: Symbol('RedemptionApi'),
    ThreatMetrixApi: Symbol('ThreatMetrixApi'),
    dispatchNavigation: Symbol('dispatchNavigation'),
    dispatchExternalNavigation: Symbol('dispatchExternalNavigation'),
    WillIQualifyApi: Symbol('WillIQualifyApi'),
    FormScriptsApi: Symbol('FormScriptsApi'),
    DynamicFormScripts: Symbol('DynamicFormScripts'),
};
exports.default = TYPES;
