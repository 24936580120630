"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
require("../../../common/styles/components/ClickToExpand.scss");
var ClickToExpand = /** @class */ (function (_super) {
    __extends(ClickToExpand, _super);
    function ClickToExpand(props) {
        var _this = _super.call(this, props) || this;
        _this.handleItemClick = function () {
            var newState = !_this.state.visible;
            _this.setState({ visible: newState });
            if (typeof _this.props.track !== 'undefined') {
                _this.props.track({
                    target: {
                        eventName: 'Click to expand',
                        action: _this.props.item.title,
                        label: newState ? 'Opened' : 'Closed'
                    }
                });
            }
        };
        _this.state = {
            visible: false,
        };
        return _this;
    }
    ClickToExpand.prototype.render = function () {
        var wrapperClass = this.props.wrapperClass ? this.props.wrapperClass : '';
        wrapperClass += ' expandable-container';
        return (React.createElement("div", { className: wrapperClass },
            React.createElement(deko_common_components_1.Button, { className: this.props.buttonClass + " " + (this.state.visible ? 'opened' : ''), handleClick: this.handleItemClick }, this.props.item.title),
            this.state.visible ? this.props.item.content : ''));
    };
    return ClickToExpand;
}(React.PureComponent));
exports.default = ClickToExpand;
