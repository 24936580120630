"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var creditCardType = require("credit-card-type");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var deko_utilities_1 = require("deko-utilities");
var deko_common_components_1 = require("deko-common-components");
var IApplication_1 = require("../../../../common/api/CreditApplication/IApplication");
var MonthYearInput_1 = require("../../../../common/components/form/MonthYearInput");
var depositActions_1 = require("../../../../common/actions/depositActions");
var Loader_1 = require("../../../../common/components/Loader");
var FieldValidator_1 = require("../../../../common/validation/FieldValidator");
var Row_1 = require("../../../../common/components/template/Row");
var Col_1 = require("../../../../common/components/template/Col");
var ConnectedError_1 = require("../../../../common/components/form/ConnectedError");
var CreditCardNumberInput_1 = require("../../../../common/components/form/CreditCardNumberInput");
var StickyClicky_1 = require("../../../../common/components/StickyClicky");
var deposit_1 = require("../../../../common/utilities/deposit");
require("../../../styles/pages/OnlineDepositForm.scss");
var cardBack = require('../../../../common/assets/card-back.svg');
var card = React.createElement(deko_common_components_1.Image, { className: 'card', src: cardBack });
var TextInput = FieldValidator_1.default()(deko_common_components_1.TextInput);
var MonthYearInput = FieldValidator_1.default()(MonthYearInput_1.default);
var CreditCardNumberInput = FieldValidator_1.default()(CreditCardNumberInput_1.default);
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
var OnlineDepositForm = /** @class */ (function (_super) {
    __extends(OnlineDepositForm, _super);
    function OnlineDepositForm(props) {
        var _this = _super.call(this, props) || this;
        _this.onRef = _this.onRef.bind(_this);
        return _this;
    }
    OnlineDepositForm.prototype.render = function () {
        var _a;
        var application = this.props.application;
        if (!application || !application.finance || !application.consumer) {
            return null;
        }
        return (React.createElement("div", { className: 'deposit-form' }, this.renderForCurrentState((_a = {},
            _a[IApplication_1.DepositStateEnum.PAYING] = this.renderProcessing,
            _a[IApplication_1.DepositStateEnum.ERROR] = this.renderForm,
            _a[IApplication_1.DepositStateEnum.NOT_PAID] = this.renderForm,
            _a))));
    };
    OnlineDepositForm.prototype.renderForCurrentState = function (states) {
        return this.props.deposit.status &&
            states[this.props.deposit.status] &&
            states[this.props.deposit.status].apply(this);
    };
    OnlineDepositForm.prototype.renderForm = function () {
        var _a = this.props, entity = _a.entity, application = _a.application;
        if (application.finance == null) {
            throw new TypeError('Finance must be set.');
        }
        if (application.finance.deposit.amount === application.finance.deposit.paid) {
            return (React.createElement("div", null,
                React.createElement("h1", null, "Deposit has already been paid"),
                React.createElement("h2", null, "Don't pay it again")));
        }
        var typeInfo = creditCardType.getTypeInfo(entity.card_type);
        return (React.createElement("div", { className: 'online-deposit-form' },
            React.createElement("h1", null, "Pay your deposit"),
            React.createElement("h2", null,
                "Deposit amount due: \u00A3",
                deko_utilities_1.currency.formatCurrency(deposit_1.getDepositPayable(application.finance))),
            React.createElement("p", { className: 'payment-info' }, "Please complete the fields below with your card details. The name and registered address on your card must match your application as this is used to verify your identity. The deposit will form part of your repayments."),
            React.createElement(TextInput, { name: 'cardholder_name', label: 'Cardholder name', value: entity.cardholder_name || '', handleChange: this.props.handleChange }),
            React.createElement(CreditCardNumberInput, { name: 'card_number', label: 'Card number', value: entity.card_number || '', handleChange: this.props.handleChange, format: '#### #### #### ####', mask: '' }),
            React.createElement(Row_1.default, { className: 'card-details-container' },
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-6', 'md-4'] },
                    React.createElement(MonthYearInput, { name: 'end_date', label: 'Expiry date', value: entity.end_date, handleChange: this.props.handleChange, twoDigitInput: true })),
                React.createElement(Col_1.default, { className: 'security-container', layout: ['xs-12', 'sm-6'] },
                    React.createElement(TextInput, { name: 'security_code', label: typeInfo ? "Security code (" + typeInfo.code.name + ")" : 'Security code', value: entity.security_code || '', type: 'numeric-text', handleChange: this.props.handleChange, prefix: card, htmlProps: {
                            pattern: '[0-9]*'
                        } }))),
            React.createElement(ConnectedError_1.default, { htmlAttributes: { ref: this.onRef } }),
            React.createElement(CTAButton, { className: 'submit large', disabled: this.props.asyncRunning, submit: true, timeout: 300 }, this.props.asyncRunning ?
                React.createElement(Loader_1.default, { title: 'Processing...', loaderType: Loader_1.LoaderTypeEnum.BUTTON }) : 'Pay deposit')));
    };
    OnlineDepositForm.prototype.onRef = function (error) {
        if (error !== null && this.props.deposit.status === IApplication_1.DepositStateEnum.ERROR) {
            if (!deko_utilities_1.viewport.isElementInViewport(error)) {
                window.scroll(error.offsetLeft, error.offsetTop);
            }
        }
    };
    OnlineDepositForm.prototype.renderProcessing = function () {
        return (React.createElement(Loader_1.default, { title: React.createElement("h1", null, "Processing payment...") }));
    };
    return OnlineDepositForm;
}(React.Component));
var mapStateToProps = function (state) { return ({
    asyncRunning: state.async.asyncRunning,
    application: state.application,
    deposit: state.deposit,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        payDeposit: depositActions_1.payDeposit
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(OnlineDepositForm);
