"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var NewAddressButton_1 = require("./NewAddressButton");
var AddButton = /** @class */ (function (_super) {
    __extends(AddButton, _super);
    function AddButton(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isVisible: false
        };
        _this.makeVisible = _this.makeVisible.bind(_this);
        return _this;
    }
    AddButton.prototype.render = function () {
        return (React.createElement("div", null, (this.props.opened || this.state.isVisible) ?
            this.renderChild() :
            this.renderButton()));
    };
    AddButton.prototype.renderButton = function () {
        var className = (this.props.errors.addresses) ? 'has-errors' : '';
        return React.createElement(NewAddressButton_1.default, { className: className, handleClick: this.makeVisible });
    };
    AddButton.prototype.makeVisible = function () {
        this.setState({ isVisible: true });
    };
    AddButton.prototype.renderChild = function () {
        return this.props.children;
    };
    return AddButton;
}(React.Component));
exports.default = AddButton;
