"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
require("../../magi/styles/components/ProductPromotion.scss");
var PromotionWarningProvider = /** @class */ (function (_super) {
    __extends(PromotionWarningProvider, _super);
    function PromotionWarningProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PromotionWarningProvider.prototype.render = function () {
        var application = this.props.application;
        var finance = this.props.application.finance;
        if (!application || !finance || !finance.offer) {
            return null;
        }
        var offer = finance.offer;
        if (!offer.promotional_offer_type || !offer.promotional_offer) {
            return null;
        }
        switch (offer.promotional_offer_type) {
            case 'cpo':
                return (React.createElement("p", null,
                    "If you do not repay your loan within the ",
                    offer.promotional_offer.cpo_weeks,
                    " week interest free pay-off period then the total amount payable will be higher than the cash price of the product you applied for"));
            default: return null;
        }
    };
    return PromotionWarningProvider;
}(React.Component));
var mapStateToProps = function (state) {
    return {
        application: state.application
    };
};
exports.default = react_redux_1.connect(mapStateToProps)(PromotionWarningProvider);
