"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateToken = exports.updateTokenAction = void 0;
var types = require("./actionTypes");
function updateTokenAction(token) {
    return { type: types.UPDATE_TOKEN, token: token };
}
exports.updateTokenAction = updateTokenAction;
function updateToken(token) {
    return function (dispatch) {
        dispatch(updateTokenAction(token));
    };
}
exports.updateToken = updateToken;
