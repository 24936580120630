"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_redux_1 = require("react-redux");
var React = require("react");
var redux_1 = require("redux");
var react_router_redux_1 = require("react-router-redux");
var react_helmet_1 = require("react-helmet");
var lodash_1 = require("lodash");
var IApplication_1 = require("../api/CreditApplication/IApplication");
var creditApplicationActions_1 = require("../actions/creditApplicationActions");
var tokenActions_1 = require("../actions/tokenActions");
var Loader_1 = require("../components/Loader");
var react_router_1 = require("react-router");
var ApplicationLoader = /** @class */ (function (_super) {
    __extends(ApplicationLoader, _super);
    function ApplicationLoader(props) {
        var _this = _super.call(this, props) || this;
        _this.finishedLoading = function () {
            if (_this.mounted) {
                _this.setState({ finishedLoading: true });
            }
        };
        _this.updateToken = function (token) {
            if (token !== 'form') {
                sessionStorage.setItem('token', token);
                _this.props.actions.updateToken(token);
                _this.props.actions.push(_this.props.history.location.pathname.replace(token, 'form') + _this.props.history.location.search);
            }
        };
        var finishedLoading = false;
        if (props.status === IApplication_1.AppStatusEnum.INIT && props.token) {
            _this.updateToken(props.token);
            _this.props.actions.loadApplication(props.token).then(function (app) {
                if (typeof props.branding_key !== 'undefined' &&
                    props.branding_key === 'magi_app_form') {
                    _this.props.actions.loadBranding(app.application_id, props.branding_key).then(_this.finishedLoading);
                }
                else {
                    if (_this.mounted) {
                        _this.finishedLoading();
                    }
                }
            }).catch(_this.finishedLoading);
        }
        else {
            finishedLoading = true;
        }
        _this.mounted = false;
        _this.state = {
            finishedLoading: finishedLoading
        };
        return _this;
    }
    ApplicationLoader.prototype.componentDidMount = function () {
        this.mounted = true;
    };
    ApplicationLoader.prototype.componentWillUnmount = function () {
        this.mounted = false;
    };
    ApplicationLoader.prototype.componentWillReceiveProps = function (nextProps, nextContext) {
        if (this.props.lender.id
            && this.props.lender.id !== nextProps.lender.id
            && this.props.branding_key
            && this.props.application_id) {
            this.props.actions.loadBranding(this.props.application_id, this.props.branding_key);
        }
    };
    ApplicationLoader.prototype.render = function () {
        var _a = this.props, token = _a.token, branding = _a.branding;
        if (!branding || lodash_1.isEmpty(branding)) {
            if (!this.state.finishedLoading) {
                return this.props.loader ? this.props.loader : React.createElement(Loader_1.default, null);
            }
        }
        // noinspection TypeScriptUnresolvedFunction
        var body = React.cloneElement(this.props.children, {
            token: token,
        });
        var favicon = branding && branding.favicon ?
            branding.favicon : 'https://assets.dekopay.com/Logo/Deko/favicon-32x32.png';
        return (React.createElement(React.Fragment, null,
            body,
            React.createElement(react_helmet_1.Helmet, null,
                React.createElement("link", { href: favicon, rel: 'shortcut icon', type: 'image/png' }))));
    };
    return ApplicationLoader;
}(React.PureComponent));
var mapStateToProps = function (state, props) {
    var tokenProps = {};
    if (props.token === 'form' && typeof sessionStorage.getItem('token') === 'string') {
        tokenProps = { token: sessionStorage.getItem('token') };
    }
    return __assign({ status: state.application.status, lender: state.application.lender, branding: state.branding, application_id: state.application.application_id }, tokenProps);
};
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        loadApplication: creditApplicationActions_1.loadApplication,
        updateToken: tokenActions_1.updateToken,
        loadBranding: creditApplicationActions_1.loadBranding,
        push: react_router_redux_1.push,
    }, dispatch)
}); };
exports.default = react_router_1.withRouter(react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ApplicationLoader));
