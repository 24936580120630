"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var IApplication_1 = require("../../common/api/CreditApplication/IApplication");
var navigationActions_1 = require("../../common/actions/navigationActions");
var navigationActions_2 = require("../actions/navigationActions");
var Page_1 = require("../components/Page");
var RequiredStatus_1 = require("../../common/components/RequiredStatus");
var Summary_1 = require("../components/Summary");
var SummaryProvider_1 = require("../../common/components/SummaryProvider");
require("../styles/pages/PrequalificationPage.scss");
var react_router_1 = require("react-router");
var LenderComponentProvider_1 = require("../components/LenderComponentProvider");
var ConnectedPromotionProvider_1 = require("../../common/components/ConnectedPromotionProvider");
var PrequalificationPage = /** @class */ (function (_super) {
    __extends(PrequalificationPage, _super);
    function PrequalificationPage(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = _this.handleClick.bind(_this);
        return _this;
    }
    PrequalificationPage.prototype.renderSidebarContent = function () {
        var renderSummary = function (summaryProps) { return (React.createElement(Summary_1.default, __assign({}, summaryProps))); };
        return (React.createElement("div", null,
            React.createElement(SummaryProvider_1.default, { render: renderSummary }),
            React.createElement(ConnectedPromotionProvider_1.default, null),
            React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                var CreditSearchSection = _a.CreditSearchSection;
                return (React.createElement(CreditSearchSection, null));
            })));
    };
    PrequalificationPage.prototype.render = function () {
        var _this = this;
        var application = this.props.application;
        if (application.telephone_application) {
            return React.createElement(react_router_1.Redirect, { to: "telephone" });
        }
        if (!application.finance) {
            throw new Error('Finance must be set');
        }
        return (React.createElement("div", null,
            React.createElement(Page_1.default, { mainCols: 7, className: 'prequal-page', sidebarContent: this.renderSidebarContent() },
                React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                    var PrequalContent = _a.PrequalContent;
                    return (React.createElement(PrequalContent, { application: application, continueCallback: _this.handleClick }));
                }))));
    };
    PrequalificationPage.prototype.handleClick = function () {
        this.props.actions.dispatchNavigation(navigationActions_1.APPLY, this.props.token);
    };
    return PrequalificationPage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_2.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(PrequalificationPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.NO_DECISION, IApplication_1.AppStatusEnum.INIT])(connected);
