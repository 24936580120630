"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var NumberFormat = require("react-number-format");
var deko_utilities_1 = require("deko-utilities");
var deko_common_components_1 = require("deko-common-components");
function CreditCardNumberInput(_a) {
    var name = _a.name, label = _a.label, value = _a.value, handleChange = _a.handleChange, validateStandard = _a.validateStandard, handleKeyDown = _a.handleKeyDown, errors = _a.errors, className = _a.className, disabled = _a.disabled, thousandSeparator = _a.thousandSeparator, format = _a.format, mask = _a.mask;
    var wrapperClass = ['text-input focus-on-child-label'];
    if (className) {
        wrapperClass.push(className);
    }
    var disabledStr = (disabled === true) ? 'disabled' : undefined;
    function onValueChange(event) {
        handleChange({
            target: {
                value: event.value,
                name: name
            }
        });
    }
    return (React.createElement(deko_common_components_1.CommonInput, { name: name, label: label, className: wrapperClass.join(' '), errors: errors, disabled: disabled },
        React.createElement(NumberFormat, { className: 'number-input', id: name, name: name, type: 'tel', value: deko_utilities_1.maybe(value, ''), disabled: disabledStr, onValueChange: onValueChange, onKeyDown: handleKeyDown, onBlur: validateStandard, thousandSeparator: thousandSeparator, format: format, mask: mask, "aria-label": label, "aria-invalid": typeof errors === 'string', "aria-describedby": name + "_error" })));
}
exports.default = CreditCardNumberInput;
