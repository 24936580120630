"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var completeImg = require('../../assets/complete.svg');
var DekoCompleteContent = function (_a) {
    var lender = _a.lender, branding = _a.branding, application = _a.application;
    var lenderName = lender.name;
    var lenderEmail = lender.contact_information.email;
    var lenderPhone = lender.contact_information.phone;
    var lenderOpeningHours = branding.lender_opening_hours;
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Your purchase is complete!"),
        React.createElement("p", null,
            "Our meeting was short but sweet. We've finalised your purchase with",
            React.createElement("strong", null,
                " ",
                branding.name),
            " who will send you an order confirmation by email. If you have any questions regarding your goods or service please contact ",
            React.createElement("strong", null, branding.name),
            " directly."),
        React.createElement("div", { className: 'complete-image' },
            React.createElement(deko_common_components_1.Image, { src: completeImg })),
        React.createElement("p", null,
            "For questions about your Credit Agreement please contact the lender,",
            React.createElement("strong", null,
                "\u00A0",
                React.createElement("span", { className: 'lender-name' }, lenderName)),
            ". Your first repayment will be taken approximately ",
            React.createElement("strong", null, "15-30 days after receipt of your purchase "),
            "and will show on your bank statement as ",
            lenderName,
            "."),
        React.createElement("p", null,
            "To change your direct debit date please contact the lender,",
            React.createElement("strong", null,
                "\u00A0",
                React.createElement("span", { className: 'lender-name' }, lenderName),
                "\u00A0"),
            " after you've received your goods or services. To get in touch with them, either email them at \u00A0",
            React.createElement("a", { href: "mailto: " + lenderEmail }, lenderEmail),
            " or call\u00A0",
            React.createElement("a", { href: "tel: " + lenderPhone }, lenderPhone),
            " (",
            lenderOpeningHours,
            "). All of your documents have been emailed to you at ",
            React.createElement("strong", { "data-hj-suppress": true },
                application.consumer.email,
                ".")),
        React.createElement("p", null, "Thanks for choosing to checkout with Deko, enjoy your purchase!")));
};
exports.default = DekoCompleteContent;
