"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        key: 'precontract_explanation',
        title: 'Pre-contract',
        button: 'Pre-contract',
        checkboxText: "I confirm that I have had an opportunity to consider the information above.",
        extraStatements: undefined
    },
    {
        key: 'precontract_information',
        title: 'Credit information',
        button: 'Credit information',
        checkboxText: 'I confirm that I have had an opportunity to consider the information above.',
        extraStatements: undefined
    },
    {
        key: 'precontract',
        title: 'Credit agreement',
        button: 'Credit agreement',
        checkboxText: 'I agree to be legally bound by the terms of this credit agreement.',
        extraStatements: undefined
    },
    {
        key: 'direct_debit',
        title: 'Direct Debit authorisation',
        button: 'Direct Debit',
        renderPrefixMessage: function (lender) { return ("Now review your Direct Debit details. The payment will appear on your bank\n    statement as " + lender + ". No changes to the amount, date or frequency of payments can be made without notifying you at\n    least 10 working days before your account is debited. Again, before you can continue, you'll need to sign to confirm\n    you understand everything."); },
        checkboxText: "I am authorised to make payments from the above bank account as a sole signatory and I authorise the\n    creation of the above Direct Debit mandate.",
        extraStatements: undefined
    },
];
