"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var creditApplicationActions_1 = require("../../../common/actions/creditApplicationActions");
var alternativeFinanceActions_1 = require("../../../common/actions/alternativeFinanceActions");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var navigationActions_1 = require("../../../common/actions/navigationActions");
var Page_1 = require("../../components/Page");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var EntityForm_1 = require("../../../common/components/form/EntityForm");
var AlternativeFinanceForm_1 = require("./form/AlternativeFinanceForm");
var ValidationManager_1 = require("../../../common/validation/ValidationManager");
var AlternativeFinanceValidator_1 = require("../../validators/AlternativeFinanceValidator");
var PropTypes = require("prop-types");
var FormSection_1 = require("../../../common/components/form/FormSection");
require("../../styles/pages/AlternativeFinancePage.scss");
var LenderComponentProvider_1 = require("../../components/LenderComponentProvider");
var AlternativeFinancePage = /** @class */ (function (_super) {
    __extends(AlternativeFinancePage, _super);
    function AlternativeFinancePage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.accept = _this.accept.bind(_this);
        _this.decline = _this.decline.bind(_this);
        return _this;
    }
    AlternativeFinancePage.prototype.getChildContext = function () {
        return { validationManager: new ValidationManager_1.default(AlternativeFinanceValidator_1.default), entityName: 'alternativeFinance' };
    };
    AlternativeFinancePage.prototype.render = function () {
        var _this = this;
        if (!this.props.application.finance) {
            throw new TypeError('Finance details must be set');
        }
        return (React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: false, fallbackAction: navigationActions_1.PAY_DEPOSIT },
            React.createElement(Page_1.default, { className: 'alternative-finance-page', showSummaryContent: false },
                React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                    var AlternativeFinanceContent = _a.AlternativeFinanceContent;
                    return (React.createElement(AlternativeFinanceContent, { finance: _this.props.application.finance, status: _this.props.application.status }));
                }),
                React.createElement(FormSection_1.default, null,
                    React.createElement(EntityForm_1.default, { className: 'alternative-finance', entityName: 'alternativeFinance', updateEntity: alternativeFinanceActions_1.updateAlternativeFinance, handleSubmit: this.accept }, function (_a) {
                        var entity = _a.entity, handleChange = _a.handleChange;
                        return React.createElement(AlternativeFinanceForm_1.default, { entity: entity, acceptAlternativeFinance: _this.accept, declineAlternativeFinance: _this.decline, application: _this.props.application, renderConsent: !_this.props.branding.lender_is_panel, handleChange: handleChange, errors: _this.props.errors });
                    })))));
    };
    AlternativeFinancePage.prototype.accept = function () {
        return this.props.actions.acceptAlternativeFinance();
    };
    AlternativeFinancePage.prototype.decline = function () {
        this.props.actions.cancelApplication(this.props.application, 'Customer declined the alternative finance offer', IApplication_1.CancellationCodeEnum.CANCELLED_BY_USER);
    };
    AlternativeFinancePage.childContextTypes = {
        validationManager: PropTypes.instanceOf(ValidationManager_1.default),
        entityName: PropTypes.string
    };
    return AlternativeFinancePage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    token: state.token,
    application: state.application,
    branding: state.branding,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        acceptAlternativeFinance: alternativeFinanceActions_1.acceptAlternativeFinance,
        cancelApplication: creditApplicationActions_1.cancelApplication,
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(AlternativeFinancePage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT, IApplication_1.AppStatusEnum.REFER])(connected);
