"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
/* The StickyClicky Component
As the name clearly defines this component will help you to stick
an active class to your child component. When the timeout runs out
we'll remove the active class if the component is still mounted.
This is useful when you want to add an animation to a component.
*/
var StickyClicky = function (WrappedComponent) {
    var StickyClickyHOC = /** @class */ (function (_super) {
        __extends(StickyClickyHOC, _super);
        function StickyClickyHOC(props) {
            var _this = _super.call(this, props) || this;
            _this.state = { active: false };
            _this.clickHandler = _this.clickHandler.bind(_this);
            return _this;
        }
        StickyClickyHOC.prototype.componentDidMount = function () {
            this._isMounted = true;
        };
        StickyClickyHOC.prototype.componentWillUnmount = function () {
            this.setState({ active: false });
            this._isMounted = false;
        };
        StickyClickyHOC.prototype.clickHandler = function (e) {
            var _this = this;
            this.setState({ active: true });
            if (this.props.handleClick) {
                this.props.handleClick(e);
            }
            setTimeout(function () {
                if (_this._isMounted) {
                    _this.setState({ active: false });
                }
            }, this.props.timeout);
        };
        StickyClickyHOC.prototype.render = function () {
            var newProps = {
                handleClick: this.clickHandler,
                active: this.state.active
            };
            return React.createElement(WrappedComponent, __assign({}, this.props, newProps));
        };
        return StickyClickyHOC;
    }(React.Component));
    return StickyClickyHOC;
};
exports.default = StickyClicky;
