"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DekoAgreementDisclaimer_1 = require("./DekoAgreementDisclaimer");
var LenderLinks_1 = require("../../../common/config/LenderLinks");
var href = LenderLinks_1.whatIsACreditSearch[LenderLinks_1.lenderEnum.DEKO];
var DekoCreditSearchSection = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'criteria' },
            React.createElement("p", { className: 'font-small' },
                React.createElement("strong", null, "Check your eligibility without affecting your credit score")),
            React.createElement("p", { className: 'font-small' },
                "On submission of your application, the lender(s) (or Deko on their behalf) will perform a soft credit check to assess your eligibility, this does not affect your credit score. You need to pass a credit score as part of your application. Please click for more information on",
                React.createElement("a", { href: href, target: '_blank' }, " What is a credit check."),
                "If your application is successful with the lender and you decide to proceed, a full credit check will be performed and will leave a record on your credit report. If you do not meet the lender(s) criteria you may be offered an alternative finance product. Meeting the criteria does not guarantee acceptance."),
            React.createElement("p", { className: 'font-small' }, "Goods can only be delivered to your home address. If your application is accepted, the deposit must be paid by credit or debit card in your name. We may request a proof of identity after your application has been approved and your deposit has been paid."),
            React.createElement("p", { className: 'font-small show-xs' },
                React.createElement("strong", null, "By clicking the button above you are confirming that you have read and agree to our "),
                React.createElement("a", { href: LenderLinks_1.privacyLink, target: '_blank' }, "Privacy Policy"),
                React.createElement("strong", null, "and"),
                " ",
                React.createElement("a", { href: LenderLinks_1.termsOfServiceLinks[LenderLinks_1.lenderEnum.DEKO], target: '_blank' }, " Terms of Service"),
                "."),
            React.createElement("p", { className: 'font-small show-xs' }, DekoAgreementDisclaimer_1.default))));
};
exports.default = DekoCreditSearchSection;
