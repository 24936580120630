"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoaderTypeEnum = void 0;
var React = require("react");
var DottedCircleLoader_1 = require("./DottedCircleLoader");
var react_redux_1 = require("react-redux");
var Spinner_1 = require("./Spinner");
var LoaderTypeEnum;
(function (LoaderTypeEnum) {
    LoaderTypeEnum["FULL_PAGE"] = "FULL_PAGE";
    LoaderTypeEnum["BUTTON"] = "BUTTON";
})(LoaderTypeEnum = exports.LoaderTypeEnum || (exports.LoaderTypeEnum = {}));
var Loader = /** @class */ (function (_super) {
    __extends(Loader, _super);
    function Loader(props) {
        var _this = _super.call(this, props) || this;
        _this.title = props.title ? props.title : React.createElement("h1", null, "Loading...");
        _this.loaderType = props.loaderType ? props.loaderType : LoaderTypeEnum.FULL_PAGE;
        _this.className = props.className ? props.className : '';
        return _this;
    }
    Loader.prototype.render = function () {
        var styles = ['loader-wrapper'];
        switch (this.loaderType) {
            case LoaderTypeEnum.FULL_PAGE:
                styles.push('full-page');
                break;
            case LoaderTypeEnum.BUTTON:
                styles.push('button-loader');
                break;
        }
        var loader = React.createElement(DottedCircleLoader_1.default, null);
        if (this.props.branding && this.props.branding.lender_is_panel && this.loaderType === LoaderTypeEnum.FULL_PAGE) {
            loader = React.createElement(Spinner_1.default, null);
        }
        return React.createElement("div", { className: styles.join(' ').concat(' ').concat(this.className) },
            React.createElement("div", { className: 'graphic-wrapper' }, loader),
            React.createElement("div", { className: 'text-wrapper' },
                React.createElement("div", { className: 'loader-title' }, this.title)));
    };
    return Loader;
}(React.Component));
var mapStateToProps = function (state) { return ({
    branding: state.branding,
}); };
exports.default = react_redux_1.connect(mapStateToProps, {})(Loader);
