"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var FormValidationSummary = function (_a) {
    var _b = _a.errorText, errorText = _b === void 0 ? '' : _b, _c = _a.errors, errors = _c === void 0 ? {} : _c;
    if (Object.keys(errors).length === 0) {
        return null;
    }
    return (React.createElement("div", { className: 'accessible-error-summary', role: 'alert', "aria-labelledby": 'error-summary-heading errors-list', tabIndex: -1 },
        React.createElement("h2", { id: 'error-summary-heading' }, errorText ? errorText : 'Invalid entries:'),
        React.createElement("ul", { id: 'errors-list' }, Object.keys(errors).map(function (key) {
            if (errors[key] !== true) {
                return React.createElement("li", { key: key + "_" + errors[key] },
                    React.createElement("a", { href: "#" + key }, errors[key]));
            }
        }))));
};
exports.default = FormValidationSummary;
