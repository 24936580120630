"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ClickToExpand_1 = require("../components/template/ClickToExpand");
require("../styles/deko-styles/components/PrivacyPolicyList.scss");
var PrivacyPolicyList = function (_a) {
    var privacyPolicies = _a.privacyPolicies;
    var privacyPolicyLinksList = Object.getOwnPropertyNames(privacyPolicies).map(function (lender) {
        return React.createElement("li", { key: privacyPolicies[lender] },
            React.createElement("a", { href: privacyPolicies[lender], target: '_info' }, lender));
    });
    var lendersPrivacyPolicyInformation = {
        title: "Read more information about Deko's lenders and their privacy policies",
        content: React.createElement("div", null,
            React.createElement("p", null, "In accordance with regulatory requirements please find below details of our lending partners and links to their privacy policies. Please note that by submitting an application your details may be shared with any of the below lenders:"),
            React.createElement("ul", null, privacyPolicyLinksList),
            Object.entries(privacyPolicies).find(function (_a) {
                var key = _a[0], value = _a[1];
                return value.toUpperCase().includes('OMNI');
            }) &&
                React.createElement("p", null, "Personal information we collect from you for these lenders will be shared with fraud prevention agencies who will use it to prevent fraud and money-laundering and to verify your identity. If fraud is detected, you could be refused certain services, finance, or employment. Further details of how your information will be used by our lenders and these fraud prevention agencies, and your data protection rights, can be found in the relevant privacy policy linked above."))
    };
    return (React.createElement(ClickToExpand_1.default, { item: lendersPrivacyPolicyInformation, wrapperClass: 'lender-privacy-section spaced-above', buttonClass: 'unstyled expandable-read-more-title' }));
};
exports.default = PrivacyPolicyList;
