"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Col_1 = require("../../common/components/template/Col");
var Row_1 = require("../../common/components/template/Row");
var deko_utilities_1 = require("deko-utilities");
require("../styles/components/ProductSummary.scss");
var SummaryLine = function (_a) {
    var className = _a.className, label = _a.label, testid = _a.testid, children = _a.children;
    return React.createElement(Col_1.default, { className: (className ? className + " " : '') + "line" },
        React.createElement("strong", null,
            label,
            ":\u00A0"),
        React.createElement("span", { "data-testid": testid, className: 'value' }, children));
};
var Summary = function (_a) {
    var isOffer = _a.isOffer, cash_price = _a.cash_price, deposit = _a.deposit, description = _a.description, interest_rate = _a.interest_rate, loan_amount = _a.loan_amount, loan_term = _a.loan_term, monthly_instalment = _a.monthly_instalment, total_payable = _a.total_payable, apr = _a.apr;
    return React.createElement(Row_1.default, { className: 'product-summary' },
        isOffer && (React.createElement(React.Fragment, null,
            React.createElement(Col_1.default, { className: 'line' },
                React.createElement("strong", null, "Representative Example")),
            React.createElement(Col_1.default, { className: 'line' }))),
        React.createElement(SummaryLine, { className: 'goods-description', label: 'Description' }, description),
        React.createElement(SummaryLine, { label: 'Cash Price' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(cash_price)),
        React.createElement(SummaryLine, { label: 'Deposit' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(deposit)),
        React.createElement(SummaryLine, { label: 'Loan amount' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(loan_amount)),
        React.createElement(SummaryLine, { label: 'Repayment term' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(monthly_instalment),
            "/month for ",
            loan_term,
            " months"),
        interest_rate !== null && React.createElement(SummaryLine, { label: 'Interest rate (fixed)' },
            interest_rate,
            "%"),
        React.createElement(SummaryLine, { label: 'Total amount payable (inc deposit)' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(total_payable)),
        React.createElement(SummaryLine, { testid: 'apr', label: "APR" + (isOffer ? ' (representative)' : '') },
            apr,
            "%"));
};
exports.default = Summary;
