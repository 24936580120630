"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var textContent = require("react-addons-text-content");
var DATA_LAYER_CONTAINER = 'dataLayer';
var dataLayer = window[DATA_LAYER_CONTAINER] || [];
var pageLoadQueue = [];
var loaded = false;
var creditApplication, previousPage;
exports.default = (function () { return function (next) { return function (action) {
    switch (action.type) {
        case 'ADD_ERROR':
            handleFormErrors(action.errors);
            break;
        case 'LOAD_APPLICATION_SUCCESS':
            creditApplication = action.creditApplication;
            break;
        case 'LOAD_BRANDING_SUCCESS':
            handleBrandingLoaded(creditApplication, action.branding.name);
            break;
        case 'MORPHEUS_ADD_EXPERIMENT':
            var next_variant = action.experiment.next_variant;
            handleMorpheusVariantTracking(next_variant);
            break;
        case '@@router/LOCATION_CHANGE':
            handlePageChange(action.payload.pathname);
            break;
    }
    next(action);
}; }; });
var handlePageChange = function (path) {
    if (!loaded) {
        pageLoadQueue.push(path);
        return;
    }
    sendPageView(path);
};
var sendPageView = function (path) {
    var splitPath = path.split('/');
    var pageName = splitPath.pop();
    if (splitPath.pop() === 'deposit' && pageName === 'process') {
        return;
    }
    var dataLayerObject = {
        event: 'VirtualPageView',
        virtualPageURL: pageName,
        sessionControl: ''
    };
    if (previousPage !== pageName) {
        var pages = ['prequalification', 'telephone'];
        if (pages.indexOf(pageName) > -1) {
            dataLayerObject.sessionControl = 'start';
        }
        dataLayer.push(dataLayerObject);
        previousPage = pageName;
    }
};
var handleFormErrors = function (errors) {
    Object.keys(errors).map(function (key) {
        var category = (errors[key] === true) ? 'Form Interactions' : 'Form Errors';
        var label = (errors[key] === true) ? 'Verified' : textContent(errors[key]);
        dataLayer.push({
            event: 'Form Events',
            category: category,
            action: previousPage + "." + key,
            label: label,
            nonInteraction: true,
        });
    });
};
var submitQueuedPageViews = function () {
    pageLoadQueue.splice(0, pageLoadQueue.length).forEach(function (path) {
        sendPageView(path);
    });
};
var handleBrandingLoaded = function (application, retailerName) {
    var applicationType = application.telephone_application ? 'TEL' : application.finance.product.loan_type;
    dataLayer.push({
        event: 'setDimensionVariables',
        applicationType: applicationType,
        productCode: application.finance.product.product_code,
        lenderName: application.lender.name,
        brand: 'Deko',
        applicationVersion: '3',
        deposit: application.finance.deposit.amount,
        term: application.finance.product.loan_term,
        apr: application.finance.product.apr,
        retailer: retailerName,
        retailerId: application.retailer.id,
    });
    loaded = true;
    submitQueuedPageViews();
};
var handleMorpheusVariantTracking = function (variant) {
    if (!variant) {
        return;
    }
    dataLayer.push({
        event: 'morpheusVariant',
        variant: variant
    });
};
