"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var OnlineDepositPage_1 = require("./OnlineDepositPage");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var isApplicationOfType_1 = require("../../../common/utilities/isApplicationOfType");
var react_redux_1 = require("react-redux");
var InstoreDepositPage_1 = require("./InstoreDepositPage");
var DepositSwitchPage = /** @class */ (function (_super) {
    __extends(DepositSwitchPage, _super);
    function DepositSwitchPage(props) {
        return _super.call(this, props) || this;
    }
    DepositSwitchPage.prototype.render = function () {
        return isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, this.props.application)
            ? React.createElement(InstoreDepositPage_1.default, null) : React.createElement(OnlineDepositPage_1.default, null);
    };
    return DepositSwitchPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
exports.default = react_redux_1.connect(mapStateToProps)(DepositSwitchPage);
