"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var faqContent_1 = require("./faq-content/faqContent");
var PageTitle_1 = require("../../common/components/form/PageTitle");
var Footer_1 = require("../components/template/Footer");
require("../styles/pages/LegalPages.scss");
var logo = require('../../common/assets/deko-logo-one-colour.svg');
var FaqPage = /** @class */ (function (_super) {
    __extends(FaqPage, _super);
    function FaqPage(props) {
        var _this = _super.call(this, props) || this;
        _this.renderSection = _this.renderSection.bind(_this);
        _this.renderContentItem = _this.renderContentItem.bind(_this);
        return _this;
    }
    FaqPage.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'container' },
                React.createElement("div", { className: 'legal-page' },
                    React.createElement("div", { className: 'faq-page container' },
                        React.createElement(PageTitle_1.default, { title: 'Deko | FAQ' }),
                        React.createElement("div", { className: 'header' },
                            React.createElement(deko_common_components_1.Image, { className: 'logo', src: logo })),
                        React.createElement("h1", null, "Frequently Asked Questions"),
                        faqContent_1.default.map(this.renderSection)))),
            React.createElement(Footer_1.default, null)));
    };
    FaqPage.prototype.renderSection = function (section, i) {
        var sectionTitle = section.title ? React.createElement("h2", null, section.title) : '';
        return (React.createElement("div", { key: i },
            sectionTitle,
            React.createElement("ol", null, section.content.map(this.renderContentItem))));
    };
    FaqPage.prototype.renderContentItem = function (item, i) {
        var href = item.question.toLowerCase().replace(/ /g, '-').replace(/[^0-9a-zA-Z_\-]/g, '');
        var answer = React.createElement("div", { id: "answer-" + href, role: 'region', "aria-live": 'polite' }, item.answer);
        return (React.createElement("li", { key: i },
            React.createElement("a", { id: href, className: 'question', href: "#" + href, "aria-controls": "answer-" + href }, item.question),
            (window.location.hash === "#" + href) ? answer : null));
    };
    return FaqPage;
}(React.Component));
exports.default = FaqPage;
