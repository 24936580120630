"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var formattedErrorMessages = new Map([
    [
        '0000',
        'An error has occurred with your payment, please try again. If this happens again please contact Deko for further assistance.'
    ],
    [
        '2000',
        '2000 : Please contact your bank to enquire as to why the payment is failing.'
    ],
    [
        '2001',
        "2001 : Your payment is failing due the address on your application not matching that of your bank account.\n    Please contact your bank to ensure it is up to date, then contact the {lender} underwriting team to ensure\n    that these details match."
    ],
    [
        '4026',
        '4026 : Please contact your bank to set up 3D Authentication on your card and then try making the payment again.'
    ]
]);
var sagepayErrorMapper = function (errorMessage, lenderName) {
    var errorCodeMatch = /\d{4}/g.exec(errorMessage);
    if (!errorCodeMatch) {
        return errorMessage;
    }
    var errorCode = errorCodeMatch.toString();
    if (!formattedErrorMessages.has(errorCode)) {
        return errorMessage;
    }
    return formattedErrorMessages.get(errorCode).replace(/{lender}/g, lenderName);
};
exports.default = sagepayErrorMapper;
