"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Modal
 *
 * First child must have a class of main in order for scrolling to be both sane and hookable by things like
 * Scroll4Progress
 *
 */
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
require("../styles/components/BaseModal.scss");
var Modal = function (_a) {
    var className = _a.className, children = _a.children, onClose = _a.onClose;
    var wrapperClass = [className, 'modal-container'];
    var showCloseButton = typeof onClose === 'function';
    return (React.createElement("div", { className: wrapperClass.join(' ') },
        React.createElement("div", { className: 'modal-background-overlay', onClick: onClose }),
        React.createElement("div", { className: 'modal-content' },
            showCloseButton &&
                React.createElement(deko_common_components_1.CloseButton, { handleClick: onClose, ariaAttributes: { 'aria-label': 'Close modal' } }),
            children)));
};
exports.default = Modal;
