"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.whatIsACreditSearch = exports.securityStatementLinks = exports.faqLinks = exports.termsOfServiceLinks = exports.privacyLink = exports.lenderEnum = void 0;
var lenderEnum;
(function (lenderEnum) {
    lenderEnum["DEKO"] = "deko";
    lenderEnum["HONEYCOMB"] = "honeycomb";
    lenderEnum["OMNI"] = "omni";
    lenderEnum["LLOYDS"] = "lloyds";
    lenderEnum["KLARNA"] = "klarna";
    lenderEnum["BRAEMAR"] = "braemar";
    lenderEnum["SNAP"] = "snap";
    lenderEnum["DUOLOGI"] = "duologi";
})(lenderEnum = exports.lenderEnum || (exports.lenderEnum = {}));
var baseUrl = 'https://secure.dekopay.com';
exports.privacyLink = 'https://www.dekopay.com/privacy/';
exports.termsOfServiceLinks = {
    deko: "/terms-of-service",
    klarna: "/klarna-terms-of-service",
    braemar: baseUrl + "/credit-application/braemar/terms.html"
};
exports.faqLinks = {
    deko: "https://www.dekopay.com/support/shoppers/",
    klarna: "https://www.klarna.com/uk/cbrf/consumer/#section-3",
    braemar: baseUrl + "/credit-application/braemar/faq.html"
};
exports.securityStatementLinks = {
    deko: "/security-statement",
    braemar: baseUrl + "/credit-application/braemar/security.html"
};
exports.whatIsACreditSearch = {
    klarna: '/what-is-a-credit-search-klarna',
    braemar: '/what-is-a-credit-search-braemar',
    deko: '/what-is-a-credit-search',
};
