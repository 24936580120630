"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DekoReferSection = function (_a) {
    var lender = _a.lender, branding = _a.branding, application = _a.application, inStore = _a.inStore;
    if (inStore === true) {
        return (React.createElement(React.Fragment, null,
            "We have everything we need from you at this stage. Once the lender,",
            ' ',
            lender.name,
            " has completed their checks,",
            ' ',
            "you'll be notified regarding the outcome of your application by email at",
            ' ',
            React.createElement("strong", { "data-hj-suppress": true }, application.consumer.email),
            " as well as here on this webpage. If you wish to return later, the representative from ",
            React.createElement("strong", null, branding.name),
            " can return to this page from their Back Office account."));
    }
    return (React.createElement(React.Fragment, null,
        "We have everything we need from you at this stage. Once the lender,",
        ' ',
        lender.name,
        " has completed their checks,",
        ' ',
        "you'll be notified regarding the outcome of your application by email at",
        ' ',
        React.createElement("strong", { "data-hj-suppress": true }, application.consumer.email),
        "."));
};
exports.default = DekoReferSection;
