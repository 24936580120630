"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./inversify.config");
var React = require("react");
var react_dom_1 = require("react-dom");
var react_redux_1 = require("react-redux");
var react_router_redux_1 = require("react-router-redux");
var configureStore_1 = require("./store/configureStore");
var initialState_1 = require("./store/initialState");
var routes_1 = require("./routes");
require("./styles/styles.scss");
require("./styles/_bootstrap-custom.scss");
var sharedHistory_1 = require("../common/sharedHistory");
var ScrollRestoration_1 = require("../common/components/ScrollRestoration");
require("url-search-params-polyfill");
var state = initialState_1.default;
var store = configureStore_1.default(state, sharedHistory_1.default);
/**
 * For future reference, BrowserRouter doesn't seem to work,see:
 * https://github.com/ReactTraining/react-router/issues/4924
 */
react_dom_1.render(React.createElement(react_redux_1.Provider, { store: store },
    React.createElement(react_router_redux_1.ConnectedRouter, { history: sharedHistory_1.default },
        React.createElement(ScrollRestoration_1.default, null, routes_1.default))), document.getElementById('app'));
