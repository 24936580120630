"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_pdf_1 = require("react-pdf");
var deko_common_components_1 = require("deko-common-components");
var Loader_1 = require("../Loader");
var DropzoneContent = function (_a) {
    var placeHolderImage = _a.placeHolderImage, previewImage = _a.previewImage, openModal = _a.openModal, visible = _a.visible, fileType = _a.fileType, _b = _a.pdfLoader, pdfLoader = _b === void 0 ? React.createElement(Loader_1.default, null) : _b;
    var notPdfPreview = (React.createElement("img", { id: 'image-preview', className: 'image-preview', alt: 'Preview of your uploaded document', src: previewImage, onClick: openModal }));
    var pdfPreview = (React.createElement(react_pdf_1.Document, { file: previewImage, onItemClick: openModal, loading: pdfLoader },
        React.createElement(react_pdf_1.Page, { className: 'pdf-page', pageNumber: 1, renderAnnotations: false })));
    var preview = fileType === 'application/pdf' ? pdfPreview : notPdfPreview;
    var placeholder = (React.createElement("div", { className: 'upload-placeholder-content' },
        React.createElement(deko_common_components_1.Image, { src: placeHolderImage }),
        React.createElement("span", { className: 'link' }, "Add your image here")));
    return (React.createElement("div", { className: 'upload-placeholder', "data-hj-suppress": true }, (!visible) ? placeholder : preview));
};
exports.default = DropzoneContent;
