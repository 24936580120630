"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
require("../styles/pages/ProblemPages.scss");
var BackToRetailerButton_1 = require("../../common/components/BackToRetailerButton");
var ApplicationOutcomes_1 = require("../../common/components/ApplicationOutcomes");
var Page_1 = require("../components/Page");
var problemImg = require('../assets/problem.svg');
var ErrorPage = /** @class */ (function (_super) {
    __extends(ErrorPage, _super);
    function ErrorPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ErrorPage.prototype.render = function () {
        return (React.createElement(Page_1.default, { className: 'problem-pages error-page', showSummaryContent: false, pageTitle: 'Deko | Error' },
            React.createElement("div", { className: 'bottom' },
                React.createElement("p", { className: 'standfirst' },
                    "Unfortunately, an error has occurred resulting in you being unable to continue your application. Please contact our Client Services team for them to assist you in completing your application. Please find their contact details below:",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Mon - Fri: 9am-6pm",
                    React.createElement("br", null),
                    "Phone: ",
                    React.createElement("a", { href: 'tel:08002945891' }, "0800 294 5891"),
                    React.createElement("br", null),
                    "Email: ",
                    React.createElement("a", { href: 'mailto:support@dekopay.com' }, "support@dekopay.com"),
                    React.createElement("br", null)),
                React.createElement("div", { className: 'problem-image' },
                    React.createElement(deko_common_components_1.Image, { src: problemImg, altText: 'Problem image' })),
                React.createElement(BackToRetailerButton_1.default, { status: ApplicationOutcomes_1.AppOutcomeEnum.REFER }))));
    };
    return ErrorPage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
exports.default = react_redux_1.connect(mapStateToProps, {})(ErrorPage);
