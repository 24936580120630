"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var getNextStep_1 = require("../utilities/getNextStep");
var inversify_types_1 = require("../inversify.types");
var inversify_container_1 = require("../inversify.container");
var navigationActions_1 = require("../actions/navigationActions");
var Loader_1 = require("../../common/components/Loader");
function getDispatchNavigation() {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
}
var ResumeApplicationPage = /** @class */ (function (_super) {
    __extends(ResumeApplicationPage, _super);
    function ResumeApplicationPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getNextPage = function (application) {
            var nextStep = {
                action: navigationActions_1.ERROR_PAGE
            };
            try {
                nextStep = getNextStep_1.getNextStep(application);
            }
            catch (e) {
                return nextStep.action;
            }
            return nextStep.action;
        };
        return _this;
    }
    ResumeApplicationPage.prototype.componentDidMount = function () {
        this.props.actions.dispatchNavigation(this.getNextPage(this.props.application));
    };
    ResumeApplicationPage.prototype.componentDidUpdate = function (nextProps) {
        this.props.actions.dispatchNavigation(this.getNextPage(nextProps.application));
    };
    ResumeApplicationPage.prototype.render = function () {
        return (React.createElement(Loader_1.default, null));
    };
    return ResumeApplicationPage;
}(React.Component));
var mapStateToProps = function (state) {
    return {
        application: state.application
    };
};
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: getDispatchNavigation()
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ResumeApplicationPage);
