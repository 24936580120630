"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var PromotionNoInterest_1 = require("../../magi/components/PromotionNoInterest");
require("../../magi/styles/components/ProductPromotion.scss");
var ConnectedPromotionProvider = /** @class */ (function (_super) {
    __extends(ConnectedPromotionProvider, _super);
    function ConnectedPromotionProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConnectedPromotionProvider.prototype.render = function () {
        var application = this.props.application;
        var finance = this.props.application.finance;
        if (!application || !finance || !finance.product) {
            return null;
        }
        var product = finance.product;
        if (finance.offer) {
            product = finance.offer;
        }
        if (!product.promotional_offer_type || !product.promotional_offer) {
            return null;
        }
        var component;
        switch (product.promotional_offer_type) {
            case 'cpo':
                component = React.createElement(PromotionNoInterest_1.default, { cpo_weeks: product.promotional_offer.cpo_weeks });
                break;
            default: return null;
        }
        return (React.createElement("div", { className: 'promotion-provider' }, component));
    };
    return ConnectedPromotionProvider;
}(React.Component));
var mapStateToProps = function (state) {
    return {
        application: state.application
    };
};
exports.default = react_redux_1.connect(mapStateToProps)(ConnectedPromotionProvider);
