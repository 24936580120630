"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var Page_1 = require("../../components/Page");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
require("../../styles/pages/IdUploadPage.scss");
var IdUploadSuccessPage = /** @class */ (function (_super) {
    __extends(IdUploadSuccessPage, _super);
    function IdUploadSuccessPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdUploadSuccessPage.prototype.render = function () {
        var application = this.props.application;
        return (React.createElement(Page_1.default, { className: 'id-upload-success' },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    React.createElement("h1", null, "Your proof of ID has been uploaded"),
                    React.createElement("p", null,
                        "Thanks for uploading your identity document - it is now being checked over. As soon as checks are done we'll send you a confirmation email to ",
                        React.createElement("strong", { "data-hj-suppress": true }, application.consumer.email),
                        ".")))));
    };
    return IdUploadSuccessPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var connected = react_redux_1.connect(mapStateToProps, {})(IdUploadSuccessPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACTION_CUSTOMER])(connected);
