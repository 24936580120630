"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countNumberOfAddresses = exports.addressEmpty = void 0;
function addressEmpty(address) {
    return Object.keys(address).reduce(function (carry, value) {
        return carry && address[value] === undefined;
    }, true);
}
exports.addressEmpty = addressEmpty;
function countNumberOfAddresses(addresses) {
    var numberOfAddresses = 0;
    Object.keys(addresses).map(function (key) {
        if (!addressEmpty(addresses[key])) {
            numberOfAddresses++;
        }
    });
    return numberOfAddresses;
}
exports.countNumberOfAddresses = countNumberOfAddresses;
