"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var LenderLinks_1 = require("../../../common/config/LenderLinks");
var DekoQuestionsAboutApplication = function (_a) {
    var lender = _a.lender;
    return (React.createElement("p", { className: 'criteria font-small' },
        "If you have any questions or require further information concerning your application or the application form, please refer to Deko\u2019s ",
        React.createElement("a", { target: '_blank', href: LenderLinks_1.faqLinks[LenderLinks_1.lenderEnum.DEKO] }, "Frequently Asked Questions"),
        ". If there is anything you do not understand, please call 0800\u00A0294\u00A05891 or seek free,",
        ' ',
        "independent advice from organisations such as the",
        ' ',
        React.createElement("a", { target: '_blank', href: 'https://www.citizensadvice.org.uk/' }, "Citizens Advice Bureau"),
        " or",
        ' ',
        React.createElement("a", { target: '_blank', href: 'https://www.moneyadviceservice.org.uk/en' }, "Money Advice Service"),
        "."));
};
exports.default = DekoQuestionsAboutApplication;
