"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_types_1 = require("../common/inversify.types");
var CreditApplicationApi_1 = require("../common/api/CreditApplication/CreditApplicationApi");
var MorpheusApi_1 = require("../common/api/Morpheus/MorpheusApi");
var CapturePlusApi_1 = require("../common/api/CapturePlus/CapturePlusApi");
var navigationActions_1 = require("./actions/navigationActions");
var inversify_container_1 = require("../common/inversify.container");
var FormScriptsApi_1 = require("../common/api/FormScripts/FormScriptsApi");
var DynamicFormScripts_1 = require("../common/components/form/DynamicFormScripts");
inversify_container_1.default.bind(inversify_types_1.default.CreditApplicationApi).toConstantValue(new CreditApplicationApi_1.default());
inversify_container_1.default.bind(inversify_types_1.default.CapturePlusApi).to(CapturePlusApi_1.default);
inversify_container_1.default.bind(inversify_types_1.default.dispatchNavigation).toConstantValue(navigationActions_1.dispatchNavigation);
inversify_container_1.default.bind(inversify_types_1.default.dispatchExternalNavigation).toConstantValue(navigationActions_1.dispatchExternalNavigation);
inversify_container_1.default.bind(inversify_types_1.default.FormScriptsApi).toConstantValue(new FormScriptsApi_1.default());
inversify_container_1.default.bind(inversify_types_1.default.DynamicFormScripts).toConstantValue(DynamicFormScripts_1.default);
inversify_container_1.default.bind(inversify_types_1.default.MorpheusApi).toConstantValue(new MorpheusApi_1.default());
