"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
var Loader_1 = require("../../../common/components/Loader");
var AnimatedButton = StickyClicky_1.default(deko_common_components_1.Button);
var DocumentNavigationButtons = /** @class */ (function (_super) {
    __extends(DocumentNavigationButtons, _super);
    function DocumentNavigationButtons(props) {
        var _this = _super.call(this, props) || this;
        _this.loader = React.createElement(Loader_1.default, { title: 'Processing...', loaderType: Loader_1.LoaderTypeEnum.BUTTON });
        _this.state = { nextDocument: null };
        _this.selectDocument = _this.selectDocument.bind(_this);
        _this.closeAction = _this.closeAction.bind(_this);
        return _this;
    }
    DocumentNavigationButtons.prototype.render = function () {
        var _this = this;
        var i = this.props.documents.findIndex(function (doc) {
            return doc.key === _this.props.currentDocument.key;
        });
        var next = this.props.documents[i + 1];
        var previous = this.props.documents[i - 1] ? this.props.documents[i - 1] : null;
        var prevDocText = React.createElement(React.Fragment, null,
            "Previous",
            React.createElement("span", { className: 'hide-xs' }, " document"));
        var nextDocText = React.createElement(React.Fragment, null,
            "Next",
            React.createElement("span", { className: 'hide-xs' }, " document"));
        if (previous === null) {
            return (React.createElement(React.Fragment, null,
                React.createElement(deko_common_components_1.Button, { className: 'prev-doc secondary', disabled: true }, prevDocText),
                React.createElement(deko_common_components_1.Button, { className: 'next-doc', handleClick: this.selectDocument(next.key) }, nextDocText)));
        }
        if ((this.props.documents.length - 1) <= i) {
            var buttonText = React.createElement(React.Fragment, null,
                "Submit",
                React.createElement("span", { className: 'hide-xs' }, " my documents"));
            return (React.createElement(React.Fragment, null,
                React.createElement(deko_common_components_1.Button, { className: 'prev-doc secondary', handleClick: this.selectDocument(previous.key) }, prevDocText),
                React.createElement(AnimatedButton, { className: 'next-doc', disabled: this.props.asyncRunning || !this.props.allDocsSigned, handleClick: this.props.submit, timeout: 300 }, this.props.asyncRunning ? this.loader : buttonText)));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(deko_common_components_1.Button, { className: 'prev-doc secondary', handleClick: this.selectDocument(previous.key) }, prevDocText),
            React.createElement(deko_common_components_1.Button, { className: 'next-doc', handleClick: this.selectDocument(next.key) }, nextDocText)));
    };
    DocumentNavigationButtons.prototype.closeAction = function (event) {
        event.preventDefault();
        this.props.closeModal();
    };
    DocumentNavigationButtons.prototype.selectDocument = function (name) {
        var _this = this;
        return function (event) {
            event.preventDefault();
            _this.props.handleSetCurrentDocument(name);
        };
    };
    return DocumentNavigationButtons;
}(React.Component));
var mapStateToProps = function (state) { return ({
    asyncRunning: state.async.asyncRunning,
}); };
exports.default = react_redux_1.connect(mapStateToProps, {})(DocumentNavigationButtons);
