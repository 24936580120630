"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNextStep = exports.NextStepMessageEnum = void 0;
var IApplication_1 = require("../api/CreditApplication/IApplication");
var navigationActions_1 = require("../actions/navigationActions");
var alternative_1 = require("./alternative");
var NextStepMessageEnum;
(function (NextStepMessageEnum) {
    NextStepMessageEnum["SIGN_DOCUMENTS"] = "Sign your documents";
    NextStepMessageEnum["PAY_DEPOSIT"] = "Pay your deposit";
    NextStepMessageEnum["COMPLETE_APPLICATION"] = "Complete your application";
})(NextStepMessageEnum = exports.NextStepMessageEnum || (exports.NextStepMessageEnum = {}));
var areDocsSigned = function (application) {
    return application.documents_signed;
};
var getNextStep = function (application) {
    var finance = application.finance, telephone_application = application.telephone_application, status = application.status;
    if (finance == null) {
        return undefined;
    }
    if (telephone_application && status === IApplication_1.AppStatusEnum.NO_DECISION) {
        return {
            message: '',
            action: navigationActions_1.TELEPHONE_SCRIPT_PAGE
        };
    }
    switch (status) {
        case IApplication_1.AppStatusEnum.NO_DECISION:
            return {
                message: '',
                action: navigationActions_1.PREQUALIFICATION_PAGE
            };
        case IApplication_1.AppStatusEnum.ACCEPT:
            if (!areDocsSigned(application)) {
                if (alternative_1.shouldOfferAlternativeFinance(application.finance)) {
                    return {
                        message: 'Continue',
                        action: navigationActions_1.ALTERNATIVE_FINANCE
                    };
                }
                return {
                    message: NextStepMessageEnum.SIGN_DOCUMENTS,
                    action: navigationActions_1.DOCUMENTS_PAGE
                };
            }
            if (finance.deposit.amount > finance.deposit.paid) {
                return {
                    message: NextStepMessageEnum.PAY_DEPOSIT,
                    action: navigationActions_1.PAY_DEPOSIT
                };
            }
            else {
                return {
                    message: NextStepMessageEnum.COMPLETE_APPLICATION,
                    action: navigationActions_1.COMPLETE_PAGE
                };
            }
        case IApplication_1.AppStatusEnum.REFER:
            if (alternative_1.shouldOfferAlternativeFinance(application.finance)) {
                return {
                    message: 'Continue',
                    action: navigationActions_1.ALTERNATIVE_FINANCE
                };
            }
            if (!areDocsSigned(application)) {
                return {
                    message: NextStepMessageEnum.SIGN_DOCUMENTS,
                    action: navigationActions_1.DOCUMENTS_PAGE
                };
            }
            return {
                message: NextStepMessageEnum.COMPLETE_APPLICATION,
                action: navigationActions_1.REFER_PAGE
            };
        case IApplication_1.AppStatusEnum.ACTION_LENDER:
            return {
                message: '',
                action: navigationActions_1.REFER_PAGE
            };
        case IApplication_1.AppStatusEnum.VERIFIED:
            return {
                message: NextStepMessageEnum.COMPLETE_APPLICATION,
                action: navigationActions_1.COMPLETE_PAGE
            };
        case IApplication_1.AppStatusEnum.FULFILLED:
            return {
                message: NextStepMessageEnum.COMPLETE_APPLICATION,
                action: navigationActions_1.COMPLETE_PAGE
            };
        case IApplication_1.AppStatusEnum.AMENDED:
            return {
                message: '',
                action: navigationActions_1.AMENDMENT_CONFIRMATION
            };
        case IApplication_1.AppStatusEnum.ACTION_CUSTOMER:
            return {
                message: '',
                action: navigationActions_1.ID_UPLOAD_PAGE
            };
        case IApplication_1.AppStatusEnum.CANCEL:
            return {
                message: '',
                action: navigationActions_1.CANCEL_PAGE
            };
        case IApplication_1.AppStatusEnum.DECLINE:
            return {
                message: '',
                action: navigationActions_1.DECLINE_PAGE
            };
        default:
            throw new Error('No next step');
    }
};
exports.getNextStep = getNextStep;
