"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var jumioActions_1 = require("../../common/actions/jumioActions");
var Loader_1 = require("../../common/components/Loader");
var config_1 = require("../../common/config/config");
var JumioErrorInfo_1 = require("./JumioErrorInfo");
var Logging_1 = require("../../common/utilities/Logging");
var Jumio = /** @class */ (function (_super) {
    __extends(Jumio, _super);
    function Jumio(props) {
        var _this = _super.call(this, props) || this;
        _this.messageDataSchema = {
            customerInternalReference: function (value) { return !value || /^\s*$/.test(value); },
            transactionReference: function (value) { return !value || /^\s*$/.test(value); },
            payload: function (value) { return !value || !value.value || /^\s*$/.test(value.value); },
        };
        _this._isMounted = false;
        _this.validateMessage = function (messageData) {
            return !Object.keys(_this.messageDataSchema)
                .map(function (property) {
                return _this.messageDataSchema[property](messageData[property]);
            })
                .reduce(function (sum, next) { return sum || next; }, false);
        };
        _this.processMessage = function (messageData) {
            switch (messageData.payload.value) {
                case 'success':
                    _this.handleUploadSuccess(messageData.transactionReference, messageData.customerInternalReference);
                    return;
                case 'error':
                    _this.handleUploadFailure(messageData.payload.metainfo.code);
                    return;
                case 'loaded':
                    return;
                default:
                    Logging_1.logErrorToNewrelic("Unknown message type received from jumio: " + messageData.payload.value);
                    _this.handleUploadFailure(0);
            }
        };
        _this.handleRetry = function () {
            var credit_application_id = _this.props.application.credit_application_id;
            _this.props.actions.jumioInit(credit_application_id, config_1.default.baseUrl).then(function (response) {
                _this.setState(__assign(__assign({}, _this.state), { url: response.redirect_url, errorCode: 0 }));
            });
        };
        _this.state = {
            url: '',
            errorCode: 0
        };
        return _this;
    }
    Jumio.prototype.componentDidMount = function () {
        var _this = this;
        this._isMounted = true;
        var credit_application_id = this.props.application.credit_application_id;
        this.props.actions.jumioInit(credit_application_id, config_1.default.baseUrl).then(function (response) {
            if (_this._isMounted) {
                _this.setState(__assign(__assign({}, _this.state), { url: response.redirect_url }));
            }
        });
    };
    Jumio.prototype.componentWillMount = function () {
        this.listener = this.messageHandler.bind(this);
        window.addEventListener('message', this.listener);
    };
    Jumio.prototype.componentWillUnmount = function () {
        window.removeEventListener('message', this.listener);
        this._isMounted = false;
    };
    Jumio.prototype.render = function () {
        var asyncRunning = this.props.asyncRunning;
        if (asyncRunning) {
            return React.createElement(Loader_1.default, null);
        }
        var _a = this.state, url = _a.url, errorCode = _a.errorCode;
        if (errorCode > 0) {
            return React.createElement(JumioErrorInfo_1.default, { dismiss: this.handleRetry, code: this.state.errorCode });
        }
        return React.createElement("iframe", { src: url, title: 'ID Upload', className: 'jumio', width: '100%', allow: 'camera', frameBorder: '0' });
    };
    Jumio.prototype.messageHandler = function (message) {
        var messageData = JSON.parse(message.data);
        if (this.validateMessage(messageData)) {
            this.processMessage(messageData);
        }
        else {
            Logging_1.logErrorToNewrelic("Invalid message data received from jumio: " + message.data);
            this.handleUploadFailure(0);
        }
    };
    Jumio.prototype.handleUploadSuccess = function (transactionReference, customerInternalReference) {
        this.props.actions.jumioSuccess(transactionReference, customerInternalReference, this.props.application.credit_application_id);
    };
    Jumio.prototype.handleUploadFailure = function (errorCode) {
        this.setState(__assign(__assign({}, this.state), { errorCode: errorCode }));
    };
    return Jumio;
}(React.Component));
var mapStateToProps = function (state) { return ({
    asyncRunning: state.async.asyncRunning,
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        jumioInit: jumioActions_1.jumioInit,
        jumioSuccess: jumioActions_1.jumioSuccess
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Jumio);
