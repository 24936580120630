"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatchExternalNavigation = exports.dispatchNavigation = exports.exitAction = void 0;
var react_router_redux_1 = require("react-router-redux");
var types = require("../../common/actions/actionTypes");
var navigationActions_1 = require("../../common/actions/navigationActions");
var deko_utilities_1 = require("deko-utilities");
function exitAction() {
    return { type: types.EXIT };
}
exports.exitAction = exitAction;
function dispatchNavigation(action) {
    return function (dispatch) {
        switch (action) {
            case navigationActions_1.EXPIRED_APP_PAGE:
                var referrer = document.referrer ? document.referrer : 'NO REFERRER';
                deko_utilities_1.debugLog({ message: "Redirecting to expired page, referrer: " + referrer });
                dispatch(react_router_redux_1.replace("/expired"));
                break;
            case navigationActions_1.ERROR_PAGE:
                deko_utilities_1.debugLog({ message: "Redirecting to problem page" });
                dispatch(react_router_redux_1.push("/problem"));
                break;
            case navigationActions_1.PREQUALIFICATION_PAGE:
                dispatch(react_router_redux_1.push("/form/prequalification"));
                break;
            case navigationActions_1.COMPLETE_PAGE:
                dispatch(react_router_redux_1.push("/form/complete"));
                break;
            case navigationActions_1.DECLINE_PAGE:
                dispatch(react_router_redux_1.push("/form/decline"));
                break;
            case navigationActions_1.REFER_PAGE:
                dispatch(react_router_redux_1.push("/form/refer"));
                break;
            case navigationActions_1.NO_DECISION_PAGE:
                console.trace();
                dispatch(react_router_redux_1.push("/form/awaiting-decision"));
                break;
            case navigationActions_1.ID_UPLOAD_PAGE:
                dispatch(react_router_redux_1.push("/form/id-upload"));
                break;
            case navigationActions_1.ID_SUCCESS:
                dispatch(react_router_redux_1.push("/form/id-upload/success"));
                break;
            case navigationActions_1.ID_FAILURE:
                dispatch(react_router_redux_1.push("/form/id-upload/failure"));
                break;
            case navigationActions_1.INSTORE_ID_UPLOAD:
                dispatch(react_router_redux_1.push("/form/instore-id-upload"));
                break;
            case navigationActions_1.EXHAUSTED_ID_UPLOAD_ATTEMPTS_PAGE:
                dispatch(react_router_redux_1.push("/exhausted-id-upload-attempts"));
                break;
            case navigationActions_1.APPLY:
                dispatch(react_router_redux_1.push("/form/apply"));
                break;
            case navigationActions_1.PAY_DEPOSIT:
                dispatch(react_router_redux_1.push("/form/deposit"));
                break;
            case navigationActions_1.AMENDMENT_CONFIRMATION:
                dispatch(react_router_redux_1.push("/form/amendment/confirmation"));
                break;
            case navigationActions_1.AMENDMENT_DECLINED:
                dispatch(react_router_redux_1.push("/form/amendment/declined"));
                break;
            case navigationActions_1.DOCUMENTS_PAGE:
                dispatch(react_router_redux_1.push("/form/documents"));
                break;
            case navigationActions_1.DOCUMENTS_SIGN_PAGE:
                dispatch(react_router_redux_1.push("/form/documents/sign"));
                break;
            case navigationActions_1.DOCUMENTS_INSTRUCTION_PAGE:
                dispatch(react_router_redux_1.push("/form/documents/instructions"));
                break;
            case navigationActions_1.CANCEL_PAGE:
                dispatch(react_router_redux_1.push("/form/cancel"));
                break;
            case navigationActions_1.ALTERNATIVE_FINANCE:
                dispatch(react_router_redux_1.push("/form/alternative-finance"));
                break;
            case navigationActions_1.ALTERNATIVE_FINANCE_DECLINE:
                dispatch(react_router_redux_1.push("/form/cancel"));
                break;
            case navigationActions_1.INSTORE_FULFIL:
                dispatch(react_router_redux_1.push("/form/fulfil"));
                break;
            case navigationActions_1.COMPLETE_FULFILLED_PAGE:
                dispatch(react_router_redux_1.push("/form/complete-fulfilled"));
                break;
            case navigationActions_1.COMPLETE_VERIFIED_PAGE:
                dispatch(react_router_redux_1.push("/form/complete-verified"));
                break;
            case navigationActions_1.TELEPHONE_SCRIPT_PAGE:
                dispatch(react_router_redux_1.push("/form/telephone"));
                break;
            case navigationActions_1.TELEPHONE_ALTERNATIVE_FINANCE:
                dispatch(react_router_redux_1.push("/form/telephone-alternate-finance"));
                break;
            case navigationActions_1.TELEPHONE_END_PAGE:
                dispatch(react_router_redux_1.push("/form/telephone-decision"));
                break;
            default:
                dispatch(react_router_redux_1.push("/problem"));
                break;
        }
    };
}
exports.dispatchNavigation = dispatchNavigation;
function dispatchExternalNavigation(url) {
    return function (dispatch) {
        window.location.href = url;
        dispatch(exitAction());
    };
}
exports.dispatchExternalNavigation = dispatchExternalNavigation;
