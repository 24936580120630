"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes = require("../actions/actionTypes");
var handleCurrentDocument = function (state, action) { return ({
    currentDocument: action.documentKey
}); };
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes.UPDATE_CURRENT_DOCUMENT] = handleCurrentDocument,
    _a), {});
