"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var featureFlagActions_1 = require("../../common/actions/featureFlagActions");
var Loader_1 = require("../../common/components/Loader");
var FeatureSwitch = /** @class */ (function (_super) {
    __extends(FeatureSwitch, _super);
    function FeatureSwitch(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            enabled: false
        };
        return _this;
    }
    FeatureSwitch.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, name = _a.name, identifier = _a.identifier, context = _a.context;
        this.props.actions.featureFlagConfig(name, identifier, context).then(function (featureFlag) {
            _this.setState({
                enabled: featureFlag[name]
            });
        });
    };
    FeatureSwitch.prototype.render = function () {
        var asyncRunning = this.props.asyncRunning;
        if (asyncRunning) {
            return React.createElement(Loader_1.default, null);
        }
        return this.renderContent();
    };
    FeatureSwitch.prototype.renderContent = function () {
        if (!this.state.enabled) {
            return null;
        }
        var children = this.props.children;
        return (React.createElement("div", null, children));
    };
    return FeatureSwitch;
}(React.Component));
var mapStateToProps = function (state) { return ({
    asyncRunning: state.async.asyncRunning
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        featureFlagConfig: featureFlagActions_1.featureFlagConfig
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(FeatureSwitch);
