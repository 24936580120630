"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var PropTypes = require("prop-types");
var react_redux_1 = require("react-redux");
var deko_utilities_1 = require("deko-utilities");
var errorsActions_1 = require("../actions/errorsActions");
var redux_1 = require("redux");
var ValidationManager_1 = require("./ValidationManager");
var lodash_1 = require("lodash");
var linkedFields = {
    'addresses.current_address.house_name': ['addresses.current_address.house_number'],
    'addresses.current_address.house_number': ['addresses.current_address.house_name'],
    'addresses.second_address.house_name': ['addresses.second_address.house_number'],
    'addresses.second_address.house_number': ['addresses.second_address.house_name'],
    'addresses.third_address.house_name': ['addresses.third_address.house_number'],
    'addresses.third_address.house_number': ['addresses.third_address.house_name'],
};
/**
 * Validator for the Address Fields.
 * This HOC is needed, as we are using common components and they want to get passed runValidation,
 * which has a different signature.
 * If we change the common components to use the new validation, we can remove this class and use FieldValidator
 * for all the fields. I decided to create a new HOC instead of inserting the methods in FieldValidator because I felt
 * it was better to keep things separated.
 * Don't judge me.
 *
 * @param WrappedComponent
 * @returns {ComponentClass<any>}
 * @constructor
 */
var AddressFieldValidator = function (WrappedComponent) {
    var ValidatorHOC = /** @class */ (function (_super) {
        __extends(ValidatorHOC, _super);
        function ValidatorHOC(props, context) {
            var _this = _super.call(this, props, context) || this;
            _this.validateStandard = function (event) {
                _this.runValidation(event.target.name, event.target.value);
            };
            _this.validateField = function (e, updateErrors) {
                if (updateErrors === void 0) { updateErrors = true; }
                return _this.runValidation(e.target.name, e.target.value, updateErrors);
            };
            _this.validateList = function (whitelist, updateErrors) {
                if (whitelist === void 0) { whitelist = []; }
                if (updateErrors === void 0) { updateErrors = true; }
                var errors = lodash_1.cloneDeep(_this.props.errors);
                var isValid = true;
                whitelist.forEach(function (field) {
                    var value = lodash_1.get(_this.props.consumer, field);
                    var validityObj = _this.context.validationManager.isFieldValid(field, value, _this.props.consumer);
                    var validity = validityObj[field];
                    isValid = isValid && (validity === true);
                    if (validity === true) {
                        delete errors[field];
                    }
                    else {
                        errors[field] = validity;
                    }
                });
                if (updateErrors) {
                    _this.props.actions.replaceErrors(errors);
                }
                return isValid;
            };
            _this.removeErrors = function (fields) {
                var errors = lodash_1.cloneDeep(_this.props.errors);
                fields.forEach(function (field) {
                    delete errors[field];
                });
                _this.props.actions.replaceErrors(errors);
            };
            _this.runValidation = function (name, val, updateErrors) {
                if (updateErrors === void 0) { updateErrors = false; }
                var validationErrors = _this.context.validationManager.isFieldValid(name, val, _this.props.consumer);
                if (updateErrors) {
                    if (linkedFields[name]) {
                        linkedFields[name].forEach(function (linked) {
                            var updatedEntity = deko_utilities_1.objectAccess.setKey(name, lodash_1.cloneDeep(_this.props.consumer), val);
                            var linkedValidityObj = _this.context.validationManager.isFieldValid(linked, lodash_1.get(_this.props.consumer, linked), updatedEntity);
                            validationErrors[linked] = linkedValidityObj[linked];
                        });
                    }
                    _this.props.actions.updateErrors(validationErrors);
                }
                return validationErrors;
            };
            return _this;
        }
        ValidatorHOC.prototype.render = function () {
            var newProps = {
                validateField: this.validateField,
                validateList: this.validateList,
                removeErrors: this.removeErrors,
                errors: this.props.errors
            };
            return React.createElement(WrappedComponent, __assign({}, this.props, newProps));
        };
        ValidatorHOC.contextTypes = {
            validationManager: PropTypes.instanceOf(ValidationManager_1.default)
        };
        return ValidatorHOC;
    }(React.Component));
    return react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ValidatorHOC);
};
var mapStateToProps = function (state) { return ({
    errors: state.errors,
    consumer: state.application.consumer,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        updateErrors: errorsActions_1.addError,
        replaceErrors: errorsActions_1.replaceErrors
    }, dispatch)
}); };
exports.default = AddressFieldValidator;
