"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MORPHEUS_ADD_EXPERIMENT = exports.ASYNC_FEATURE_REQUEST_FAILURE = exports.ASYNC_FEATURE_REQUEST_SUCCESS = exports.ASYNC_FEATURE_REQUEST_BEGIN = exports.FULFIL_APPLICATION = exports.UPDATE_INSTORE_VERIFICATION = exports.ALTERNATIVE_FINANCE_UPDATE = exports.ALTERNATIVE_FINANCE_SUBMIT = exports.TRACK_ENTITY = exports.RESET_CHECKBOXES = exports.SELECT_ID_TYPE = exports.UPDATE_ID_UPLOAD = exports.TOGGLE_TOOLTIP = exports.ASYNC_FAILURE = exports.ASYNC_SUCCESS = exports.ASYNC_BEGIN = exports.THREATMETRIX_UPDATE_TOKEN = exports.UPDATE_CURRENT_DOCUMENT = exports.DEPOSIT_ERROR = exports.DEPOSIT_3DSECURE = exports.DEPOSIT_OK = exports.SUBMIT_DEPOSIT = exports.UPDATE_DEPOSIT_STATUS = exports.REDEMPTION_ERROR = exports.REDEMPTION_VERIFIED = exports.UPDATE_REDEMPTION_NUMBER = exports.UPDATE_REDEMPTION_STATUS = exports.EXIT = exports.LOAD_BRANDING_SUCCESS = exports.SIGN_DOCUMENT = exports.DISMISS_MODAL = exports.DISPLAY_MODAL = exports.DISMISS_SIDEBAR = exports.DISPLAY_SIDEBAR = exports.UPDATE_MEMORABLE_WORD = exports.REPLACE_ERRORS = exports.ADD_ERROR = exports.UPDATE_TOKEN = exports.UPDATE_STATUS = exports.UPDATE_CARD_DETAILS = exports.UPDATE_CONSUMER = exports.LOAD_APPLICATION_SUCCESS = void 0;
exports.LOAD_APPLICATION_SUCCESS = 'LOAD_APPLICATION_SUCCESS';
exports.UPDATE_CONSUMER = 'UPDATE_CREDIT_APPLICATION_SUCCESS';
exports.UPDATE_CARD_DETAILS = 'UPDATE_CARD_DETAILS';
exports.UPDATE_STATUS = 'UPDATE_STATUS';
exports.UPDATE_TOKEN = 'UPDATE_TOKEN';
exports.ADD_ERROR = 'ADD_ERROR';
exports.REPLACE_ERRORS = 'REPLACE_ERRORS';
exports.UPDATE_MEMORABLE_WORD = 'UPDATE_MEMORABLE_WORD';
exports.DISPLAY_SIDEBAR = 'DISPLAY_SIDEBAR';
exports.DISMISS_SIDEBAR = 'DISMISS_SIDEBAR';
exports.DISPLAY_MODAL = 'DISPLAY_MODAL';
exports.DISMISS_MODAL = 'DISMISS_MODAL';
exports.SIGN_DOCUMENT = 'SIGN_DOCUMENT';
exports.LOAD_BRANDING_SUCCESS = 'LOAD_BRANDING_SUCCESS';
exports.EXIT = 'EXIT';
exports.UPDATE_REDEMPTION_STATUS = 'UPDATE_REDEMPTION_STATUS';
exports.UPDATE_REDEMPTION_NUMBER = 'UPDATE_REDEMPTION_NUMBER';
exports.REDEMPTION_VERIFIED = 'REDEMPTION_VERIFIED';
exports.REDEMPTION_ERROR = 'REDEMPTION_ERROR';
exports.UPDATE_DEPOSIT_STATUS = 'UPDATE_DEPOSIT_STATUS';
exports.SUBMIT_DEPOSIT = 'SUBMIT_DEPOSIT';
exports.DEPOSIT_OK = 'DEPOSIT_OK';
exports.DEPOSIT_3DSECURE = 'DEPOSIT_3DSECURE';
exports.DEPOSIT_ERROR = 'DEPOSIT_ERROR';
exports.UPDATE_CURRENT_DOCUMENT = 'UPDATE_CURRENT_DOCUMENT';
exports.THREATMETRIX_UPDATE_TOKEN = 'THREATMETRIX_UPDATE_TOKEN';
exports.ASYNC_BEGIN = 'ASYNC_BEGIN';
exports.ASYNC_SUCCESS = 'ASYNC_SUCCESS';
exports.ASYNC_FAILURE = 'ASYNC_FAILURE';
exports.TOGGLE_TOOLTIP = 'TOGGLE_TOOLTIP';
exports.UPDATE_ID_UPLOAD = 'UPDATE_ID_UPLOAD';
exports.SELECT_ID_TYPE = 'SELECT_ID_TYPE';
exports.RESET_CHECKBOXES = 'RESET_CHECKBOXES';
exports.TRACK_ENTITY = 'TRACK_ENTITY';
exports.ALTERNATIVE_FINANCE_SUBMIT = 'ALTERNATIVE_FINANCE_SUBMIT';
exports.ALTERNATIVE_FINANCE_UPDATE = 'ALTERNATIVE_FINANCE_UPDATE';
exports.UPDATE_INSTORE_VERIFICATION = 'UPDATE_INSTORE_VERIFICATION';
exports.FULFIL_APPLICATION = 'FULFIL_APPLICATION';
exports.ASYNC_FEATURE_REQUEST_BEGIN = 'ASYNC_FEATURE_REQUEST_BEGIN';
exports.ASYNC_FEATURE_REQUEST_SUCCESS = 'ASYNC_FEATURE_REQUEST_SUCCESS';
exports.ASYNC_FEATURE_REQUEST_FAILURE = 'ASYNC_FEATURE_REQUEST_FAILURE';
exports.MORPHEUS_ADD_EXPERIMENT = 'MORPHEUS_ADD_EXPERIMENT';
