"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_utilities_1 = require("deko-utilities");
function CheckboxInput(_a) {
    var name = _a.name, label = _a.label, value = _a.value, handleChange = _a.handleChange, validateCheckbox = _a.validateCheckbox, errors = _a.errors, className = _a.className, tooltip = _a.tooltip, disabled = _a.disabled;
    var _handleChange = function (event) {
        var _event = {
            target: {
                name: event.target.name,
                value: event.target.checked === true
            }
        };
        handleChange(_event);
    };
    var classNames = [];
    classNames.push('form-group', 'checkbox-input');
    if (typeof className === 'string') {
        classNames.push(className);
    }
    if (errors && (errors.length > 0 || React.isValidElement(errors))) {
        classNames.push('has-error');
    }
    if (tooltip) {
        if (typeof tooltip !== 'string') {
            classNames.push(" " + tooltip.props.name);
        }
        classNames.push('has-tooltip');
    }
    if (value === true) {
        classNames.push('checked');
    }
    classNames.push(disabled ? 'disabled' : '');
    return (React.createElement("div", { className: classNames.join(' ') },
        React.createElement("div", { className: 'field focus-on-child-label' },
            React.createElement("input", { id: name, type: 'checkbox', name: name, checked: deko_utilities_1.maybe(value, false), onChange: _handleChange, onClick: validateCheckbox, disabled: disabled, "aria-invalid": typeof errors === 'string', "aria-describedby": name + "_error" }),
            React.createElement("label", { htmlFor: name }, label)),
        tooltip,
        errors && React.createElement("div", { className: 'alert alert-danger error', id: name + "_error" }, errors)));
}
exports.default = CheckboxInput;
