"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes = require("../actions/actionTypes");
var handleIdUpload = function (state, action) { return (__assign(__assign(__assign({}, state), action.idUpload), { filesBase64: __assign(__assign({}, state.filesBase64), action.idUpload.filesBase64), checkboxes: __assign(__assign({}, state.checkboxes), action.idUpload.checkboxes) })); };
var selectIdType = function (state, action) { return ({
    selected_id: action.idType,
    filesBase64: {
        front_image: null,
        rear_image: null
    },
    checkboxes: {}
}); };
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes.UPDATE_ID_UPLOAD] = handleIdUpload,
    _a[actionTypes.SELECT_ID_TYPE] = selectIdType,
    _a), {});
