"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var Page_1 = require("../../components/Page");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
require("../../styles/pages/TheEndPage.scss");
var declineImg = require('../../assets/decline.svg');
var CancelPage = /** @class */ (function (_super) {
    __extends(CancelPage, _super);
    function CancelPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CancelPage.prototype.render = function () {
        return (React.createElement(Page_1.default, { className: 'cancel-page' },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    React.createElement("h1", null, "Your application has been cancelled"),
                    React.createElement("p", null, "Sorry, we could not offer you this finance at this time."),
                    React.createElement("div", { className: 'decline-image' },
                        React.createElement(deko_common_components_1.Image, { src: declineImg }))))));
    };
    return CancelPage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var connected = react_redux_1.connect(mapStateToProps, function () { return ({}); })(CancelPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.CANCEL])(connected);
