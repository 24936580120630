"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_utilities_1 = require("deko-utilities");
var creditApplicationActions_1 = require("../../common/actions/creditApplicationActions");
var navigationActions_1 = require("../../common/actions/navigationActions");
var IApplication_1 = require("../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../common/components/RequiredStatus");
var StickyClicky_1 = require("../../common/components/StickyClicky");
var CheckboxInput_1 = require("../../common/components/form/CheckboxInput");
var EntityForm_1 = require("../../common/components/form/EntityForm");
var FormSection_1 = require("../../common/components/form/FormSection");
var NextButton_1 = require("../../common/components/form/NextButton");
var navigationActions_2 = require("../actions/navigationActions");
var Page_1 = require("../components/Page");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var redux_1 = require("redux");
var DynamicFinanceInfo_1 = require("../components/phone-scripts/DynamicFinanceInfo");
var LenderPhoneScript_1 = require("../components/phone-scripts/LenderPhoneScript");
require("../styles/pages/TelephoneApplicationScriptPage.scss");
var CTAButton = StickyClicky_1.default(NextButton_1.default);
var capitalize = function (s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
};
var TelephoneApplicationScriptPage = /** @class */ (function (_super) {
    __extends(TelephoneApplicationScriptPage, _super);
    function TelephoneApplicationScriptPage(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSubmit = function () {
            return navigationActions_2.dispatchNavigation(navigationActions_1.APPLY);
        };
        _this.state = {
            agreed: false
        };
        return _this;
    }
    TelephoneApplicationScriptPage.prototype.render = function () {
        var application = this.props.application;
        if (!application.telephone_application) {
            return React.createElement(react_router_1.Redirect, { to: "prequalification" });
        }
        return (React.createElement(Page_1.default, { mainCols: 7, className: 'script-page' },
            React.createElement("h1", { className: 'header' }, "Please read out the following to the customer:"),
            React.createElement(LenderPhoneScript_1.LenderPhoneScript, { lenderName: capitalize(application.lender.name) },
                React.createElement(DynamicFinanceInfo_1.DynamicFinanceInfo, __assign({}, application))),
            React.createElement(EntityForm_1.default, { className: 'apply', entityName: 'application.consumer', updateEntity: creditApplicationActions_1.updateConsumer, handleSubmit: this.handleSubmit }, function (_a) {
                var entity = _a.entity, handleChange = _a.handleChange;
                var consumer = entity;
                return (React.createElement(React.Fragment, null,
                    React.createElement(FormSection_1.default, { className: 'last' },
                        React.createElement(CheckboxInput_1.default, { label: 'The customer has been read the script and has agreed to proceed.', name: 'consent.submit', handleChange: handleChange, value: deko_utilities_1.maybe(consumer.consent.submit, false) })),
                    React.createElement(CTAButton, { disabled: consumer.consent.submit !== true, timeout: 300 }, "Start the application")));
            })));
    };
    return TelephoneApplicationScriptPage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_2.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(TelephoneApplicationScriptPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.NO_DECISION])(connected);
