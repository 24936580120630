"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var deko_utilities_1 = require("deko-utilities");
var SelectInput = /** @class */ (function (_super) {
    __extends(SelectInput, _super);
    function SelectInput(props) {
        var _this = _super.call(this, props) || this;
        _this.onChange = _this.onChange.bind(_this);
        return _this;
    }
    SelectInput.prototype.componentDidMount = function () {
        var _a = this.props, name = _a.name, value = _a.value, handleChange = _a.handleChange;
        if (!this.checkIfOptionIsValid(deko_utilities_1.maybe(value, ''))) {
            handleChange({
                target: {
                    name: name,
                    value: undefined
                }
            });
        }
    };
    SelectInput.prototype.render = function () {
        var value = this.props.value;
        var _a = this.props, name = _a.name, label = _a.label, className = _a.className, placeholder = _a.placeholder, validateStandard = _a.validateStandard, errors = _a.errors, options = _a.options;
        value = deko_utilities_1.maybe(value, '');
        var wrapperClass = ['select-input focus-on-child-label'];
        if (className) {
            wrapperClass.push(className);
        }
        return (React.createElement(deko_common_components_1.CommonInput, { name: name, label: label, className: wrapperClass.join(' '), errors: errors },
            React.createElement("select", { id: name, name: name, className: value === '' ? 'options placeholder' : 'options', value: value, onChange: this.onChange, onBlur: validateStandard, "aria-label": label, "aria-invalid": typeof errors === 'string', "aria-describedby": name + "_error" },
                React.createElement("option", { value: '', className: 'options', hidden: true, "aria-label": label }, placeholder),
                options.map(function (option) {
                    return (React.createElement("option", { className: 'options', key: option.value, value: option.value, "aria-label": label + " " + option.text }, option.text));
                }))));
    };
    SelectInput.prototype.checkIfOptionIsValid = function (passedOption) {
        return this.props.options.reduce(function (acc, curr) {
            return acc || (curr.value === passedOption);
        }, false);
    };
    SelectInput.prototype.onChange = function (event) {
        var _a = this.props, handleChange = _a.handleChange, validateStandard = _a.validateStandard;
        if (typeof handleChange === 'function') {
            handleChange(event);
        }
        if (typeof validateStandard === 'function') {
            validateStandard(event);
        }
    };
    return SelectInput;
}(React.Component));
exports.default = SelectInput;
