"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes_1 = require("../actions/actionTypes");
var ApiErrorKeys_1 = require("../api/CreditApplication/ApiErrorKeys");
var handleAddError = function (state, action) {
    if (state === void 0) { state = {}; }
    var newErrors = __assign(__assign({}, state), action.errors);
    delete newErrors[ApiErrorKeys_1.ERROR_SERVER];
    return newErrors;
};
var handleReplaceErrors = function (state, action) {
    if (state === void 0) { state = {}; }
    return (__assign({}, action.errors));
};
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes_1.ADD_ERROR] = handleAddError,
    _a[actionTypes_1.REPLACE_ERRORS] = handleReplaceErrors,
    _a), {});
