"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPromotionTooltip = exports.getTooltip = void 0;
var React = require("react");
var ConnectedTooltip_1 = require("../../../../common/components/form/ConnectedTooltip");
var tooltipIcon = require('../../../assets/tooltip-icon.svg');
function getTooltipMessage(lenderName) {
    return React.createElement("div", null,
        React.createElement("p", null,
            "If you have any questions please contact ",
            lenderName,
            ". You can also check your credit record at any of the credit reference agencies"),
        React.createElement("p", null,
            React.createElement("strong", null,
                lenderName,
                ":"),
            " ",
            tooltips['Snap Finance'].phone,
            ", ",
            React.createElement("a", { href: "mailto: " + tooltips[lenderName].email }, tooltips[lenderName].email)),
        React.createElement("p", null,
            React.createElement("strong", null, "Credit Reference Agencies:"),
            " Experian, TransUnion, Equifax"));
}
var getTooltip = function (lenderName, result) {
    if (!tooltips[lenderName]) {
        return;
    }
    return (React.createElement(ConnectedTooltip_1.default, { name: lenderName + "-" + result, message: getTooltipMessage(lenderName), icon: tooltipIcon, ariaAttributes: tooltips[lenderName].ariaAttributes }));
};
exports.getTooltip = getTooltip;
var getPromotionTooltip = function () {
    return (React.createElement(ConnectedTooltip_1.default, { name: 'promotion-tooltip', message: promotionTooltip.message, icon: tooltipIcon, ariaAttributes: promotionTooltip.ariaAttributes }));
};
exports.getPromotionTooltip = getPromotionTooltip;
var promotionTooltip = {
    message: "If you do not repay your loan within the interest free pay-off period then the total amount payable\n    will be higher than the cash price of the product you applied for",
    ariaAttributes: { 'aria-label': 'Promotional offer tooltip' }
};
var tooltips = {
    'Duologi': {
        phone: '0345 521 1881',
        email: 'customers@duologi.com',
        ariaAttributes: { 'aria-label': 'Duologi declined tooltip' }
    },
    'Omni Capital': {
        phone: '0333 240 8317',
        email: 'customerenquiries@ocrf.co.uk',
        ariaAttributes: { 'aria-label': 'Omni Capital declined tooltip' }
    },
    'Snap Finance': {
        phone: '0330 010 1026',
        email: 'customersupport@snapfinance.co.uk',
        ariaAttributes: { 'aria-label': 'Snap Finance declined tooltip' }
    },
};
