"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTooltip = void 0;
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var ConnectedTooltip_1 = require("../../../common/components/form/ConnectedTooltip");
var tooltipIcon = require('../../assets/tooltip-icon.svg');
var drivingLicenceClearBackgroundImg = require('../../../common/assets/id-upload-tooltips/dl-not-clear-background@2x.png');
var drivingLicenceWholeIdDisplayedImg = require('../../../common/assets/id-upload-tooltips/dl-not-whole-image-displayed@2x.png');
var drivingLicenceClearOfObstructionsImg = require('../../../common/assets/id-upload-tooltips/dl-obstructed@2x.png');
var drivingLicenceSquareOnImg = require('../../../common/assets/id-upload-tooltips/dl-angle@2x.png');
var drivingLicenceOkImg = require('../../../common/assets/id-upload-tooltips/dl-ok@2x.png');
var passportClearBackgroundImg = require('../../../common/assets/id-upload-tooltips/pass-not-clear-background@2x.png');
var passportWholeIdDisplayedImg = require('../../../common/assets/id-upload-tooltips/pass-not-whole-image-displayed@2x.png');
var passportClearOfObstructionsImg = require('../../../common/assets/id-upload-tooltips/pass-obstructed@2x.png');
var passportSquareOnImg = require('../../../common/assets/id-upload-tooltips/pass-angle@2x.png');
var passportOkImg = require('../../../common/assets/id-upload-tooltips/pass-ok@2x.png');
var idClearBackgroundImg = require('../../../common/assets/id-upload-tooltips/id-not-clear-background@2x.png');
var idWholeIdDisplayedImg = require('../../../common/assets/id-upload-tooltips/id-not-whole-image-displayed@2x.png');
var idClearOfObstructionsImg = require('../../../common/assets/id-upload-tooltips/id-obstructed@2x.png');
var idSquareOnImg = require('../../../common/assets/id-upload-tooltips/id-angle@2x.png');
var idOkImg = require('../../../common/assets/id-upload-tooltips/id-front@2x.png');
var getTooltip = function (docType, tooltipName, disabled, label) {
    if (disabled === void 0) { disabled = false; }
    return (React.createElement(ConnectedTooltip_1.default, { name: tooltipName, message: tooltips[docType][tooltipName].message, icon: tooltipIcon, tooltipPosition: tooltips[docType][tooltipName].position, disabled: disabled, ariaAttributes: { 'aria-label': "Example of: " + label } }));
};
exports.getTooltip = getTooltip;
var resemblesCustomerMessage = (React.createElement("div", null, "If the photo on the ID document does not resemble the customer presenting it and the customer is unable to present an alternative ID document, the application should not proceed and can be cancelled from your Back Office account."));
var customerDetailsMessage = (React.createElement("div", null, "If the name or date of birth on the ID document do not match that of the application, and the customer is unable to present an alternative ID document, the application should not proceed and can be cancelled from your Back Office account."));
var idExpiryMessage = (React.createElement("div", null, "The expiry date on the ID provided is either today or any day in the future."));
var drivingLicenceClearBackgroundMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceOkImg, altText: 'Image of a driving licence with a clear background.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceClearBackgroundImg, altText: "Image of a driving licence where the background isn't clear." }))));
var drivingLicenceWholeIdDisplayedMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceOkImg, altText: 'Image of a driving licence where the whole driving licence is displayed.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceWholeIdDisplayedImg, altText: "Image of a driving licence where whole driving licence isn't displayed." }))));
var drivingLicenceClearOfObstructionsMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceOkImg, altText: 'Image of a driving licence where the driving licence is clear of obstructions.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceClearOfObstructionsImg, altText: "Image of a driving licence where the driving licence isn't clear of obstructions." }))));
var drivingLicenceSquareOnMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceOkImg, altText: 'Image of a driving licence where the image of it is taken square on.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: drivingLicenceSquareOnImg, altText: 'Image of a driving licence where the image of it is taken from an angle.' }))));
var passportClearBackgroundMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: passportOkImg, altText: 'Image of a passport with a clear background.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: passportClearBackgroundImg, altText: "Image of a passport where the background isn't clear." }))));
var passportWholeIdDisplayedMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: passportOkImg, altText: 'Image of a passport where the whole passport is displayed.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: passportWholeIdDisplayedImg, altText: "Image of a passport where whole passport isn't displayed." }))));
var passportClearOfObstructionsMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: passportOkImg, altText: 'Image of a passport where the passport is clear of obstructions.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: passportClearOfObstructionsImg, altText: "Image of a passport where the passport isn't clear of obstructions." }))));
var passportSquareOnMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: passportOkImg, altText: 'Image of a passport where the image of it is taken square on.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: passportSquareOnImg, altText: 'Image of a passport where the image of it is taken from an angle.' }))));
var idCardClearBackgroundMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: idOkImg, altText: 'Image of a national ID with a clear background.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: idClearBackgroundImg, altText: "Image of a national ID where the background isn't clear." }))));
var idCardWholeIdDisplayedMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: idOkImg, altText: 'Image of a national ID where the whole ID is displayed.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: idWholeIdDisplayedImg, altText: "Image of a national ID where whole ID isn't displayed." }))));
var idCardClearOfObstructionsMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: idOkImg, altText: 'Image of a national ID where the ID is clear of obstructions.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: idClearOfObstructionsImg, altText: "Image of a national ID where the ID isn't clear of obstructions." }))));
var idCardSquareOnMessage = (React.createElement("div", null,
    "Correct example of uploaded document",
    React.createElement("div", { className: 'example-image example-correct' },
        React.createElement(deko_common_components_1.Image, { src: idOkImg, altText: 'Image of a national ID where the image of it is taken square on.' })),
    "Incorrect example of uploaded document",
    React.createElement("div", { className: 'example-image example-incorrect' },
        React.createElement("span", { className: 'cross' }),
        React.createElement(deko_common_components_1.Image, { src: idSquareOnImg, altText: 'Image of a national ID where the image of it is taken from an angle.' }))));
var tooltips = {
    'driving-licence': {
        resembles_customer: {
            message: resemblesCustomerMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        details_match: {
            message: customerDetailsMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        id_expiry: {
            message: idExpiryMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        clear_background: {
            message: drivingLicenceClearBackgroundMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        whole_id_displayed: {
            message: drivingLicenceWholeIdDisplayedMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        clear_of_obstructions: {
            message: drivingLicenceClearOfObstructionsMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        square_on: {
            message: drivingLicenceSquareOnMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        }
    },
    'passport': {
        resembles_customer: {
            message: resemblesCustomerMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        details_match: {
            message: customerDetailsMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        id_expiry: {
            message: idExpiryMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        clear_background: {
            message: passportClearBackgroundMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        whole_id_displayed: {
            message: passportWholeIdDisplayedMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        clear_of_obstructions: {
            message: passportClearOfObstructionsMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        square_on: {
            message: passportSquareOnMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        }
    },
    'id-card': {
        resembles_customer: {
            message: resemblesCustomerMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        details_match: {
            message: customerDetailsMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        id_expiry: {
            message: idExpiryMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        clear_background: {
            message: idCardClearBackgroundMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        whole_id_displayed: {
            message: idCardWholeIdDisplayedMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        clear_of_obstructions: {
            message: idCardClearOfObstructionsMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        },
        square_on: {
            message: idCardSquareOnMessage,
            position: ConnectedTooltip_1.TooltipPositionEnum.BOTTOM
        }
    }
};
