"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("../../styles/components/form/BaseProgressDiv.scss");
var ProgressDiv = function (_a) {
    var progressPercent = _a.progressPercent, children = _a.children, _b = _a.wrapperClass, wrapperClass = _b === void 0 ? '' : _b, _c = _a.progressWrapperClass, progressWrapperClass = _c === void 0 ? '' : _c;
    var className = ['progress-div'];
    className.push(wrapperClass);
    var progressClassName = ['progress'];
    progressClassName.push(progressWrapperClass);
    if (progressPercent > 0) {
        className.push('scrolled');
    }
    return (React.createElement("div", { className: className.join(' ') },
        React.createElement("div", { className: progressClassName.join(' '), style: { width: progressPercent + "%" } }),
        children));
};
exports.default = ProgressDiv;
