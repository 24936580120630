"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var deko_common_components_1 = require("deko-common-components");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var MagiDocumentsButtonsList_1 = require("../../components/documents/MagiDocumentsButtonsList");
var MagiDocumentView_1 = require("../../components/documents/MagiDocumentView");
var Page_1 = require("../../components/Page");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var navigationActions_1 = require("../../../common/actions/navigationActions");
var SummaryProvider_1 = require("../../../common/components/SummaryProvider");
var Summary_1 = require("../../components/Summary");
var Loader_1 = require("../../../common/components/Loader");
var documentConfig_1 = require("../../components/documents/documentConfig");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var isApplicationOfType_1 = require("../../../common/utilities/isApplicationOfType");
var creditApplicationActions_1 = require("../../../common/actions/creditApplicationActions");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
var Modal_1 = require("../../../common/components/Modal");
var deko_utilities_1 = require("deko-utilities");
require("../../styles/pages/DocumentsSignPage.scss");
var navigationActions_2 = require("../../actions/navigationActions");
var ConnectedPromotionProvider_1 = require("../../../common/components/ConnectedPromotionProvider");
var AnimatedButton = StickyClicky_1.default(deko_common_components_1.Button);
var DocumentsSignPage = /** @class */ (function (_super) {
    __extends(DocumentsSignPage, _super);
    function DocumentsSignPage(props) {
        var _this = _super.call(this, props) || this;
        _this.loader = React.createElement(Loader_1.default, { title: 'Processing...', loaderType: Loader_1.LoaderTypeEnum.BUTTON });
        _this.renderSidebarContent = function (currentDocumentConfig) {
            var renderSummary = function (summaryProps) { return (React.createElement(Summary_1.default, __assign({}, summaryProps))); };
            return (React.createElement("div", { className: 'sidebar-content' },
                React.createElement(SummaryProvider_1.default, { render: renderSummary }),
                React.createElement(ConnectedPromotionProvider_1.default, null),
                React.createElement("div", { className: 'sticky-buttons' },
                    React.createElement(MagiDocumentsButtonsList_1.default, { documents: _this.props.documents, currentDocument: currentDocumentConfig, handleSetCurrentDocument: _this.handleDocumentChange }),
                    React.createElement(AnimatedButton, { className: 'large submit-docs col-sm-12 show-xs', disabled: !_this.state.allDocsSigned || _this.props.asyncRunning, handleClick: _this.signDocuments, timeout: 300 }, _this.props.asyncRunning ? _this.displayedLoader : 'Submit my documents'))));
        };
        _this.signDocuments = function () {
            var application = _this.props.application;
            if (isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, application) && application.status === IApplication_1.AppStatusEnum.ACCEPT) {
                _this.setState({
                    showInstoreModal: true
                });
            }
            else {
                _this.props.actions.submitSignedDocuments(application);
            }
        };
        _this.closeInstoreModal = function () {
            _this.setState({
                showInstoreModal: !_this.state.showInstoreModal
            });
        };
        _this.renderInstoreModal = function () {
            var _a = _this.props, application = _a.application, branding = _a.branding;
            var submitDocuments = function () { return (_this.props.actions.submitSignedDocuments(application)); };
            if (isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, application) && _this.state.showInstoreModal) {
                return (React.createElement(Modal_1.default, { className: 'instore-submit-modal', onClose: _this.closeInstoreModal },
                    React.createElement("h1", null, "Please return the device"),
                    React.createElement("p", null,
                        "Thank you, ",
                        React.createElement("span", { "data-hj-suppress": true }, application.consumer.forename),
                        ". In order to finalise your purchase, please return the device to the retailer."),
                    React.createElement("p", { className: 'employee-confirmation' },
                        React.createElement("strong", null,
                            "Please confirm you are a representative of ",
                            branding.name,
                            " and that you are ready to verify this purchase.")),
                    React.createElement(AnimatedButton, { className: 'large submit-docs col-sm-12', disabled: !_this.state.allDocsSigned || _this.props.asyncRunning, handleClick: submitDocuments, timeout: 300 }, _this.props.asyncRunning ? _this.loader : 'Confirm ')));
            }
            return null;
        };
        _this.onRef = function (element) {
            if (element !== null) {
                if (!deko_utilities_1.viewport.isElementInViewport(element)) {
                    window.scroll(element.offsetLeft, element.offsetTop);
                }
            }
        };
        _this.handleDocumentChange = function (documentKey) {
            _this.setState({
                currentDocument: documentKey
            });
        };
        _this.state = {
            showInstoreModal: false,
            allDocsSigned: false,
            currentDocument: null
        };
        return _this;
    }
    DocumentsSignPage.prototype.componentDidMount = function () {
        var application = this.props.application;
        if (!isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, application)) {
            this.displayedLoader = this.loader;
        }
    };
    DocumentsSignPage.prototype.componentDidUpdate = function (prevProps) {
        var docs = this.props.documents;
        var allDocs = Object.keys(docs);
        var prevDocs = prevProps.documents;
        var prevAllDocs = Object.keys(prevDocs);
        if (prevAllDocs.length > 0 && prevDocs !== docs) {
            var allDocsSigned = false;
            if (allDocs.length > 0) {
                allDocsSigned = allDocs.every(function (doc) { return docs[doc].agreed === true; });
            }
            this.setState({ allDocsSigned: allDocsSigned });
        }
    };
    DocumentsSignPage.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        if (nextProps.asyncRunning === true && nextProps.documents.length === 0) {
            return false;
        }
        return true;
    };
    DocumentsSignPage.prototype.render = function () {
        var _this = this;
        var directDebitDisplayName = this.props.application.lender.direct_debit_display_name;
        if (this.props.application.documents_signed) {
            this.props.actions.dispatchNavigation(navigationActions_1.PAY_DEPOSIT);
            return null;
        }
        var currentDocumentConfig = this.props.documents.find(function (doc) {
            return doc.key === (_this.state.currentDocument ? _this.state.currentDocument : _this.props.documents[0].key);
        });
        if (!currentDocumentConfig) {
            throw Error("No document config found for\n      " + (this.state.currentDocument ? this.state.currentDocument : this.props.documents[0].key));
        }
        var htmlAttributes = {
            ref: this.onRef
        };
        var fixedIfModalOpen = this.state.currentDocument ? 'fixed' : '';
        var explicitlyOpen = (this.state.currentDocument);
        return (React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: false, fallbackAction: navigationActions_1.PAY_DEPOSIT },
            React.createElement(Page_1.default, { mainCols: 7, className: "documents-sign-page " + fixedIfModalOpen, sidebarContent: this.renderSidebarContent(currentDocumentConfig) },
                React.createElement("div", { className: 'show-xs' },
                    React.createElement("p", null, "Click the buttons below to review & sign your documents."),
                    React.createElement(MagiDocumentsButtonsList_1.default, { documents: this.props.documents, currentDocument: currentDocumentConfig, handleSetCurrentDocument: this.handleDocumentChange }),
                    React.createElement(AnimatedButton, { className: 'large submit-docs col-sm-12', disabled: !this.state.allDocsSigned || this.props.asyncRunning, handleClick: this.signDocuments, timeout: 300, htmlAttributes: this.state.allDocsSigned ? htmlAttributes : null }, this.props.asyncRunning ? this.displayedLoader : 'Submit')),
                this.renderInstoreModal(),
                React.createElement("div", { className: (!explicitlyOpen || this.state.showInstoreModal) ? 'hidden-xs' : '' },
                    React.createElement(MagiDocumentView_1.default, { currentDocument: currentDocumentConfig, handleSetCurrentDocument: this.handleDocumentChange, documents: this.props.documents, lender_dd_display_name: directDebitDisplayName, submit: this.signDocuments, allDocsSigned: this.state.allDocsSigned })))));
    };
    return DocumentsSignPage;
}(React.Component));
var mapStateToProps = function (state) {
    var documents = [];
    if (state.application.consumer.documents) {
        documents = documentConfig_1.getDocumentConfig(state.application);
    }
    return {
        branding: state.branding,
        application: state.application,
        documents: documents,
        explicitlyOpen: (state.documents.currentDocument !== undefined),
        asyncRunning: state.async.asyncRunning
    };
};
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        submitSignedDocuments: creditApplicationActions_1.submitSignedDocuments,
        dispatchNavigation: navigationActions_2.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(DocumentsSignPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT, IApplication_1.AppStatusEnum.REFER])(connected);
