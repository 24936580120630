"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var apiBase, baseUrl, apiBypass;
if (process.env.API_BASE_URL) {
    apiBase = process.env.API_BASE_URL + "/api/v1/";
    apiBypass = process.env.API_BYPASS_URL + "/api/v1/";
    baseUrl = "" + process.env.API_BASE_URL;
}
else {
    var port = (window.location.port ? ':10001' : '');
    baseUrl = window.location.protocol + "//" + window.location.hostname + port + "/";
    apiBase = window.location.protocol + "//" + window.location.hostname + port + "/api/v1/";
    apiBypass = window.location.protocol + "//" + window.location.hostname + port + "/api/v1/";
}
exports.default = {
    baseUrl: baseUrl,
    applicationsApi: apiBase + "applications",
    applicationsApiBypass: apiBypass + "applications",
    willIQualifyApi: apiBase + "will-i-qualify",
    redemptionApi: apiBase + "verify",
    threatmetrixApi: apiBase + "threatmetrix",
    depositThreeDSecureCallback: apiBase + "3d-callback",
    formScriptsApi: apiBase + "scripts",
    apiBase: apiBase,
    featureFlagsApi: "" + apiBase,
};
