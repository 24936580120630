"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.optionsForType = void 0;
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var FieldValidator_1 = require("../../../common/validation/FieldValidator");
var ConnectedError_1 = require("../../../common/components/form/ConnectedError");
var RadioInput_1 = require("../../../common/components/form/RadioInput");
var idUploadTooltips_1 = require("./idUploadTooltips");
var CheckboxInput_1 = require("../../../common/components/form/CheckboxInput");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
var IdTypeOptions_1 = require("../../../common/pages/id-upload/IdTypeOptions");
var Loader_1 = require("../../../common/components/Loader");
var IdUploadArea_1 = require("../../../common/pages/id-upload/IdUploadArea");
require("../../styles/components/Loader.scss");
var FormSection_1 = require("../../../common/components/form/FormSection");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
/**
 * These imports can be removed if we move all the components to be classes and we use decorators.
 */
var baseValidator = FieldValidator_1.default();
var RadioInput = baseValidator(RadioInput_1.default);
var CheckboxInput = baseValidator(CheckboxInput_1.default);
exports.optionsForType = [
    {
        text: 'The photo on the ID resembles the customer presenting it',
        name: 'resembles_customer',
        tooltip: true
    },
    {
        text: 'The name and date of birth on the ID match the application',
        name: 'details_match',
        tooltip: true
    }, {
        text: 'The ID has not expired',
        name: 'id_expiry',
        tooltip: true
    }, {
        text: 'The whole ID is displayed',
        name: 'whole_id_displayed',
        tooltip: true
    }, {
        text: 'The ID is clear of obstructions',
        name: 'clear_of_obstructions',
        tooltip: true
    }, {
        text: 'The ID is not at an angle',
        name: 'square_on',
        tooltip: true
    },
];
var IdUploadForm = /** @class */ (function (_super) {
    __extends(IdUploadForm, _super);
    function IdUploadForm(props) {
        var _this = _super.call(this, props) || this;
        _this.updateImage = _this.updateImage.bind(_this);
        _this.shouldSubmitEnable = _this.shouldSubmitEnable.bind(_this);
        _this.patronisationSection = _this.patronisationSection.bind(_this);
        _this.disableCheckboxesOnID = _this.disableCheckboxesOnID.bind(_this);
        // methods exposed so that a image upload can be forged from intern
        // __PRODUCTION__ is a special compile time variable
        if (!__PRODUCTION__) {
            window.IdUploadPage = window.IdUploadPage || {};
            window.IdUploadPage.updateImage = _this.updateImage;
        }
        return _this;
    }
    IdUploadForm.prototype.render = function () {
        var privacyLink = this.props.branding.links.privacy_policy_url[this.props.lender.name];
        var _a = this.props.entity, selected_id = _a.selected_id, filesBase64 = _a.filesBase64;
        var checkboxDisabled = this.disableCheckboxesOnID();
        return (React.createElement("div", { className: 'application-form' },
            React.createElement(RadioInput, { name: 'selected_id', label: 'Please select a document to upload:', options: IdTypeOptions_1.IdTypeOptions, handleChange: this.props.handleChange, value: selected_id || '', layout: 'HORIZONTAL', errors: this.props.errors }),
            React.createElement(FormSection_1.default, { className: selected_id ? '' : 'disabled' },
                React.createElement(IdUploadArea_1.default, { filePrefix: selected_id || '', filesBase64: filesBase64 || {}, updateImage: this.updateImage, uploadDisabled: !selected_id, errors: this.props.errors, pdfLoader: React.createElement(Loader_1.default, { className: 'pdf-upload' }) })),
            React.createElement("div", { className: "form-section last checkbox-group" + (checkboxDisabled ? ' disabled' : '') },
                React.createElement("p", null, "I confirm that:"),
                this.patronisationSection(checkboxDisabled, selected_id)),
            React.createElement(ConnectedError_1.default, null),
            React.createElement(CTAButton, { className: 'button submit-button large', disabled: !this.shouldSubmitEnable(), submit: true, timeout: 300 }, this.props.asyncRunning ?
                React.createElement(Loader_1.default, { title: 'Processing...', loaderType: Loader_1.LoaderTypeEnum.BUTTON }) : 'Verify identity'),
            React.createElement("p", null,
                "We only use this information to verify your identity. For more details on how we use personal information please see our ",
                React.createElement("a", { href: privacyLink, target: '_blank' }, "Privacy Policy"),
                ".")));
    };
    IdUploadForm.prototype.shouldSubmitEnable = function () {
        var _this = this;
        var _a = this.props.entity, selected_id = _a.selected_id, filesBase64 = _a.filesBase64;
        if (typeof this.props.errors['filesBase64.front_image'] === 'string' ||
            typeof this.props.errors['filesBase64.rear_image'] === 'string') {
            return false;
        }
        var allBoxesChecked = exports.optionsForType.reduce(function (acc, option) {
            return acc && _this.props.entity.checkboxes[option.name] === true;
        }, true);
        var requiredImagesSet = (selected_id !== IdTypeOptions_1.IdTypeEnum.ID_CARD && !!filesBase64.front_image)
            || (selected_id === IdTypeOptions_1.IdTypeEnum.ID_CARD &&
                !!filesBase64.front_image && !!filesBase64.rear_image);
        return !this.props.asyncRunning && allBoxesChecked && requiredImagesSet;
    };
    IdUploadForm.prototype.disableCheckboxesOnID = function () {
        var _a = this.props.entity, selected_id = _a.selected_id, filesBase64 = _a.filesBase64;
        if (typeof this.props.errors['filesBase64.front_image'] === 'string' ||
            typeof this.props.errors['filesBase64.rear_image'] === 'string') {
            return true;
        }
        if (selected_id === IdTypeOptions_1.IdTypeEnum.ID_CARD && filesBase64.rear_image === null) {
            return true;
        }
        return (!selected_id || filesBase64.front_image === null);
    };
    IdUploadForm.prototype.resetCheckboxes = function () {
        var _this = this;
        exports.optionsForType.map(function (option) {
            _this.props.handleChange({ target: { name: "checkboxes." + option.name, value: false } });
        });
    };
    IdUploadForm.prototype.updateImage = function (file, view) {
        var _a;
        var filesBase64 = __assign(__assign({}, this.props.entity.filesBase64), (_a = {}, _a[view.split('.')[1]] = file, _a));
        if (filesBase64.front_image === null && filesBase64.rear_image === null) {
            this.resetCheckboxes();
        }
        this.props.handleChange({ target: { name: 'filesBase64', value: filesBase64 } });
    };
    IdUploadForm.prototype.patronisationSection = function (sectionDisabled, selectedId) {
        var _this = this;
        if (selectedId === void 0) { selectedId = 'driving-licence'; }
        return exports.optionsForType.map(function (row, key) {
            var tooltip = (row.tooltip) ? idUploadTooltips_1.getTooltip(selectedId, row.name, sectionDisabled, row.text) : undefined;
            return (React.createElement("div", { key: key },
                React.createElement(CheckboxInput, { className: "checkboxes" + key, name: "checkboxes." + row.name, label: row.text, handleChange: _this.props.handleChange, value: _this.props.entity.checkboxes[row.name], disabled: sectionDisabled, tooltip: tooltip })));
        });
    };
    return IdUploadForm;
}(React.Component));
exports.default = IdUploadForm;
