"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerJumioSuccess = exports.jumioRedirectUrl = exports.featureFlagService = exports.makeOpayoDeposit = exports.getOpayoMerchantSessionKey = exports.signDocumentsService = exports.idUploadService = exports.cancelApplicationService = exports.fulfilApplicationService = exports.acceptAmendmentService = exports.loadBrandingService = exports.submitApplicationService = exports.updateBranding = exports.loadApplicationService = void 0;
var inversify_types_1 = require("../inversify.types");
var inversify_container_1 = require("../inversify.container");
var IApplication_1 = require("../api/CreditApplication/IApplication");
var navigationActions_1 = require("../actions/navigationActions");
var alternative_1 = require("../utilities/alternative");
var normalize_1 = require("../utilities/normalize");
var getApi = function () {
    return inversify_container_1.default.get(inversify_types_1.default.CreditApplicationApi);
};
var processOutcome = function (error, override) {
    var navigateTo = override || navigationActions_1.ERROR_PAGE;
    if (error.status === 401 || retailerReferenceIsNotUnique(error)) {
        navigateTo = navigationActions_1.EXPIRED_APP_PAGE;
    }
    return { outcome: 'failure', navigateTo: navigateTo, error: error };
};
var retailerReferenceIsNotUnique = function (error) {
    try {
        return error.error.message.toLowerCase().includes('retailer unique reference');
    }
    catch (e) {
        return false;
    }
};
var getDocuments = function (creditApplication) { return __awaiter(void 0, void 0, void 0, function () {
    var response, _a, _b, _c, e_1;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                response = { outcome: 'success', payload: creditApplication };
                _a = creditApplication.status;
                switch (_a) {
                    case IApplication_1.AppStatusEnum.ACCEPT: return [3 /*break*/, 1];
                    case IApplication_1.AppStatusEnum.PRE_APPROVE: return [3 /*break*/, 1];
                    case IApplication_1.AppStatusEnum.VERIFIED: return [3 /*break*/, 1];
                    case IApplication_1.AppStatusEnum.REFER: return [3 /*break*/, 1];
                }
                return [3 /*break*/, 4];
            case 1:
                _d.trys.push([1, 3, , 4]);
                _b = response.payload.consumer;
                _c = addKeyToEachDocumentAndCache;
                return [4 /*yield*/, getApi().getDocuments(creditApplication.application_id)];
            case 2:
                _b.documents = _c.apply(void 0, [_d.sent()]);
                return [3 /*break*/, 4];
            case 3:
                e_1 = _d.sent();
                /* shit hack. if the app is a phone app and we're forbidden,
                   then we're a phone agent and not allowed to see documents,
                   so don't go to the error page */
                if (creditApplication.telephone_application && e_1.status === 403) {
                    return [2 /*return*/, response];
                }
                throw e_1;
            case 4: return [2 /*return*/, response];
        }
    });
}); };
var addKeyToEachDocumentAndCache = function (documents) {
    // add keys to the entities, this makes life way easier
    var documentsWithKeys = {};
    Object.keys(documents).forEach(function (key) {
        documentsWithKeys[key] = __assign(__assign({}, documents[key]), { key: key });
        // So that the document is cached
        fetch(documents[key].html_link);
    });
    return documentsWithKeys;
};
var getCancelNavigateTo = function (creditApplication) {
    if (creditApplication.cancellation_code === IApplication_1.CancellationCodeEnum.CANCELLED_BY_USER) {
        return navigationActions_1.ALTERNATIVE_FINANCE_DECLINE;
    }
    return navigationActions_1.CANCEL_PAGE;
};
var loadApplicationService = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var creditApplications, creditApplication_1, navigateTo, normalizedCreditApplication, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, getApi().discoverApplications(token)];
            case 1:
                creditApplications = _a.sent();
                creditApplication_1 = creditApplications[0];
                return [4 /*yield*/, getDocuments(creditApplication_1)];
            case 2:
                _a.sent();
                navigateTo = (function (status) {
                    switch (status) {
                        case IApplication_1.AppStatusEnum.DECLINE: return navigationActions_1.DECLINE_PAGE;
                        case IApplication_1.AppStatusEnum.CANCEL: return getCancelNavigateTo(creditApplication_1);
                    }
                })(creditApplication_1.status);
                normalizedCreditApplication = normalize_1.normalizeCreditApplication(creditApplication_1);
                return [2 /*return*/, { outcome: 'success', payload: normalizedCreditApplication, navigateTo: navigateTo }];
            case 3:
                e_2 = _a.sent();
                return [2 /*return*/, processOutcome(e_2)];
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.loadApplicationService = loadApplicationService;
var updateBranding = function (appId, key) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, exports.loadBrandingService(appId, key)];
}); }); };
exports.updateBranding = updateBranding;
var submitApplicationService = function (application, brandingKey) { return __awaiter(void 0, void 0, void 0, function () {
    var denormalizedApplication, threatmetrixSessionToken, updatedApplication, submittedApp, alternativeFinance_1, navigateTo, normalizedCreditApplication, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                denormalizedApplication = normalize_1.denormalizeCreditApplication(application);
                threatmetrixSessionToken = denormalizedApplication.threatmetrix_session_token;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                return [4 /*yield*/, getApi().updateApplication(denormalizedApplication)];
            case 2:
                updatedApplication = _a.sent();
                return [4 /*yield*/, getApi().submitApplication(__assign(__assign({}, updatedApplication), { threatmetrix_session_token: threatmetrixSessionToken }))];
            case 3:
                submittedApp = _a.sent();
                return [4 /*yield*/, getDocuments(submittedApp)];
            case 4:
                _a.sent();
                alternativeFinance_1 = alternative_1.shouldOfferAlternativeFinance(submittedApp.finance);
                navigateTo = (function (_submittedApp) {
                    if (_submittedApp.telephone_application) {
                        return alternativeFinance_1 ? navigationActions_1.TELEPHONE_ALTERNATIVE_FINANCE : navigationActions_1.TELEPHONE_END_PAGE;
                    }
                    if (_submittedApp.status === IApplication_1.AppStatusEnum.DECLINE) {
                        return navigationActions_1.DECLINE_PAGE;
                    }
                    if (alternativeFinance_1) {
                        return navigationActions_1.ALTERNATIVE_FINANCE;
                    }
                    if (_submittedApp.status === IApplication_1.AppStatusEnum.REFER) {
                        return navigationActions_1.REFER_PAGE;
                    }
                    return navigationActions_1.DOCUMENTS_PAGE;
                })(submittedApp);
                normalizedCreditApplication = normalize_1.normalizeCreditApplication(submittedApp);
                return [2 /*return*/, { outcome: 'success', payload: normalizedCreditApplication, navigateTo: navigateTo }];
            case 5:
                e_3 = _a.sent();
                return [2 /*return*/, processOutcome(e_3, navigationActions_1.NO_DECISION_PAGE)];
            case 6: return [2 /*return*/];
        }
    });
}); };
exports.submitApplicationService = submitApplicationService;
var loadBrandingService = function (appId, type) { return __awaiter(void 0, void 0, void 0, function () {
    var branding, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getApi().getBranding(appId, type)];
            case 1:
                branding = _a.sent();
                branding.branding_key = type;
                return [2 /*return*/, { outcome: 'success', payload: branding }];
            case 2:
                e_4 = _a.sent();
                return [2 /*return*/, processOutcome(e_4)];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.loadBrandingService = loadBrandingService;
var acceptAmendmentService = function (application) { return __awaiter(void 0, void 0, void 0, function () {
    var amendmentId, amendedmentRes, loadedApplication, navigateTo, normalizedCreditApplication, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                amendmentId = application.finance.amendment.amendment_id;
                return [4 /*yield*/, getApi().acceptAmendment(application.application_id, amendmentId)];
            case 1:
                amendedmentRes = _a.sent();
                return [4 /*yield*/, getApi().getApplication(application.application_id)];
            case 2:
                loadedApplication = _a.sent();
                return [4 /*yield*/, getDocuments(loadedApplication)];
            case 3:
                _a.sent();
                navigateTo = amendedmentRes.success ? navigationActions_1.DOCUMENTS_PAGE : navigationActions_1.AMENDMENT_DECLINED;
                normalizedCreditApplication = normalize_1.normalizeCreditApplication(loadedApplication);
                return [2 /*return*/, { outcome: 'success', payload: normalizedCreditApplication, navigateTo: navigateTo }];
            case 4:
                e_5 = _a.sent();
                return [2 /*return*/, processOutcome(e_5)];
            case 5: return [2 /*return*/];
        }
    });
}); };
exports.acceptAmendmentService = acceptAmendmentService;
var fulfilApplicationService = function (applicationId) { return __awaiter(void 0, void 0, void 0, function () {
    var fulfilledApplication, normalizedCreditApplication, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getApi().fulfilApplication(applicationId)];
            case 1:
                fulfilledApplication = _a.sent();
                normalizedCreditApplication = normalize_1.normalizeCreditApplication(fulfilledApplication);
                return [2 /*return*/, { outcome: 'success', payload: normalizedCreditApplication, navigateTo: navigationActions_1.COMPLETE_FULFILLED_PAGE }];
            case 2:
                e_6 = _a.sent();
                return [2 /*return*/, processOutcome(e_6)];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.fulfilApplicationService = fulfilApplicationService;
var cancelApplicationService = function (applicationId, reason, code) { return __awaiter(void 0, void 0, void 0, function () {
    var cancelledApplication, normalizedCreditApplication, navigateTo, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getApi().cancelApplication(applicationId, reason, code)];
            case 1:
                cancelledApplication = _a.sent();
                normalizedCreditApplication = normalize_1.normalizeCreditApplication(cancelledApplication);
                navigateTo = getCancelNavigateTo(cancelledApplication);
                return [2 /*return*/, { outcome: 'success', payload: normalizedCreditApplication, navigateTo: navigateTo }];
            case 2:
                e_7 = _a.sent();
                return [2 /*return*/, processOutcome(e_7)];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.cancelApplicationService = cancelApplicationService;
var idUploadService = function (applicationId, images) { return __awaiter(void 0, void 0, void 0, function () {
    var uploadedIdApplication, normalizedCreditApplication, scope, redirectPage, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, getApi().submitInstoreIdUpload(applicationId, images)];
            case 1:
                uploadedIdApplication = _a.sent();
                normalizedCreditApplication = normalize_1.normalizeCreditApplication(uploadedIdApplication);
                return [4 /*yield*/, getApi().getScope(applicationId)];
            case 2:
                scope = _a.sent();
                redirectPage = navigationActions_1.INSTORE_FULFIL;
                if (scope.indexOf('credit_application_fulfilment') === -1) {
                    redirectPage = navigationActions_1.COMPLETE_VERIFIED_PAGE;
                }
                return [2 /*return*/, { outcome: 'success', payload: normalizedCreditApplication, navigateTo: redirectPage }];
            case 3:
                e_8 = _a.sent();
                return [2 /*return*/, processOutcome(e_8)];
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.idUploadService = idUploadService;
var signDocumentsService = function (applicationId) { return __awaiter(void 0, void 0, void 0, function () {
    var documentSignedApplication, application, navigateTo, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getApi().signDocuments(applicationId)];
            case 1:
                documentSignedApplication = _a.sent();
                application = normalize_1.normalizeCreditApplication(documentSignedApplication);
                navigateTo = (function (status) {
                    switch (status) {
                        case IApplication_1.AppStatusEnum.ACCEPT: return navigationActions_1.PAY_DEPOSIT;
                        case IApplication_1.AppStatusEnum.REFER: return navigationActions_1.REFER_PAGE;
                        default: return navigationActions_1.COMPLETE_PAGE;
                    }
                })(application.status);
                if (application.status === IApplication_1.AppStatusEnum.VERIFIED && application.finance.is_id_required) {
                    navigateTo = navigationActions_1.INSTORE_ID_UPLOAD;
                }
                return [2 /*return*/, { outcome: 'success', payload: application, navigateTo: navigateTo }];
            case 2:
                e_9 = _a.sent();
                return [2 /*return*/, processOutcome(e_9)];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.signDocumentsService = signDocumentsService;
var getOpayoMerchantSessionKey = function (applicationId) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, getApi().getOpayoMerchantSessionKey(applicationId)];
    });
}); };
exports.getOpayoMerchantSessionKey = getOpayoMerchantSessionKey;
var makeOpayoDeposit = function (applicationId, cardIdentifier, merchantSessionKey) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, getApi().makeOpayoDeposit(applicationId, cardIdentifier, merchantSessionKey)];
    });
}); };
exports.makeOpayoDeposit = makeOpayoDeposit;
var featureFlagService = function (featureFlagName, id, context) { return __awaiter(void 0, void 0, void 0, function () {
    var config, e_10, obj;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getApi().getFeatureFlagConfig(featureFlagName, id, context)];
            case 1:
                config = _a.sent();
                return [2 /*return*/, config];
            case 2:
                e_10 = _a.sent();
                obj = {};
                obj[featureFlagName] = false;
                return [2 /*return*/, obj];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.featureFlagService = featureFlagService;
var jumioRedirectUrl = function (creditRequestId, baseUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var jumioResponse, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getApi().getJumioRedirectUrl(creditRequestId, baseUrl)];
            case 1:
                jumioResponse = _a.sent();
                return [2 /*return*/, jumioResponse];
            case 2:
                e_11 = _a.sent();
                if (e_11.status === 409) {
                    return [2 /*return*/, { outcome: 'failure', navigateTo: navigationActions_1.EXHAUSTED_ID_UPLOAD_ATTEMPTS_PAGE }];
                }
                return [2 /*return*/, processOutcome(e_11)];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.jumioRedirectUrl = jumioRedirectUrl;
var registerJumioSuccess = function (transactionReference, customerInternalReference, creditApplicationId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getApi().registerJumioSuccess(transactionReference, customerInternalReference, creditApplicationId)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_12 = _a.sent();
                return [2 /*return*/, processOutcome(e_12)];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.registerJumioSuccess = registerJumioSuccess;
