"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var inversify_container_1 = require("../inversify.container");
var inversify_types_1 = require("../inversify.types");
var getApi = function () { return inversify_container_1.default.get(inversify_types_1.default.MorpheusApi); };
var MorpheusRewardingButton = function (WrappedComponent) {
    var MorpheusRewardingButtonHOC = /** @class */ (function (_super) {
        __extends(MorpheusRewardingButtonHOC, _super);
        function MorpheusRewardingButtonHOC(props) {
            var _this = _super.call(this, props) || this;
            _this.clickHandler = function (e) {
                var _a = _this.props, experiment = _a.experiment, experimentId = _a.experimentId, token = _a.token, handleClick = _a.handleClick;
                if (experiment != null && experiment.next_variant != null) {
                    getApi().rewardExperiment(experimentId, token);
                }
                if (handleClick) {
                    handleClick(e);
                }
            };
            return _this;
        }
        MorpheusRewardingButtonHOC.prototype.render = function () {
            return React.createElement(WrappedComponent, __assign({}, this.props, { handleClick: this.clickHandler }));
        };
        return MorpheusRewardingButtonHOC;
    }(React.Component));
    var mapStateToProps = function (state, ownProps) { return ({
        token: state.token,
        experiment: state.morpheusExperiments[ownProps.experimentId],
    }); };
    return react_redux_1.connect(mapStateToProps, {})(MorpheusRewardingButtonHOC);
};
exports.default = MorpheusRewardingButton;
