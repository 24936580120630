"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
/* tslint:disable:no-eval */
var React = require("react");
var inversify_container_1 = require("../../inversify.container");
var inversify_types_1 = require("../../inversify.types");
var react_redux_1 = require("react-redux");
var DynamicFormScripts = /** @class */ (function (_super) {
    __extends(DynamicFormScripts, _super);
    function DynamicFormScripts(props) {
        var _this = _super.call(this, props) || this;
        _this.applicationId = props.application.application_id;
        _this.formScriptsApi = _this.getFormScriptsApi();
        _this.formScriptsDiv = React.createRef();
        return _this;
    }
    DynamicFormScripts.prototype.componentDidMount = function () {
        var formScriptsDiv = this.formScriptsDiv.current;
        this.formScriptsApi.createSession(this.applicationId).then(function (json) {
            json.scripts.forEach(function (script) {
                var newScript = document.createElement('div');
                newScript.innerHTML = script;
                formScriptsDiv.insertBefore(newScript, formScriptsDiv.childNodes[0]);
                /**
                 * We need to eval the scripts as HTML5 specifies that a <script> tag
                 * inserted with innerHTML should not execute.
                 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML
                 */
                newScript.childNodes.forEach(function (child) { eval(child.innerHTML); });
            });
        }).catch(function (e) {
            console.error(e);
        });
    };
    DynamicFormScripts.prototype.render = function () {
        return (React.createElement("div", { ref: this.formScriptsDiv }));
    };
    DynamicFormScripts.prototype.getFormScriptsApi = function () {
        return inversify_container_1.default.get(inversify_types_1.default.FormScriptsApi);
    };
    return DynamicFormScripts;
}(React.Component));
var mapStateToProps = function (state) {
    return {
        application: state.application,
        sessionToken: state.sessionToken
    };
};
var ConnectedDynamicFormScripts = react_redux_1.connect(mapStateToProps);
exports.default = ConnectedDynamicFormScripts(DynamicFormScripts);
