"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var RadioInput_1 = require("./RadioInput");
var SelectInput_1 = require("./SelectInput");
var createHandler = function (callback, suffix) {
    return function (e) {
        var targetName = e.target.name.replace(suffix, '');
        callback({
            target: {
                name: targetName,
                value: e.target.value,
            }
        });
    };
};
var DesktopSelectInput = function (props) {
    var wrapperClass = ['desktop-select-input'];
    var className = props.className ? props.className : '';
    return (React.createElement("div", { className: wrapperClass.join(' ') },
        React.createElement("div", { id: "" + props.name + (typeof props.errors === 'object' ? '_error' : '') },
            React.createElement(SelectInput_1.default, __assign({}, props, { handleChange: createHandler(props.handleChange, '_desktop'), validateStandard: createHandler(props.validateStandard, '_desktop'), name: props.name + "_desktop", className: className + " hidden-xs", errors: props.errors })),
            React.createElement(RadioInput_1.default, __assign({}, props, { handleChange: createHandler(props.handleChange, '_mobile'), validateStandard: createHandler(props.validateStandard, '_mobile'), name: props.name + "_mobile", className: className + " show-xs", errors: props.errors })))));
};
exports.default = DesktopSelectInput;
