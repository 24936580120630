"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipPositionEnum = void 0;
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var deko_common_components_1 = require("deko-common-components");
var tooltipActions_1 = require("../../actions/tooltipActions");
require("../../styles/components/form/BaseConnectedTooltip.scss");
var TooltipPositionEnum;
(function (TooltipPositionEnum) {
    TooltipPositionEnum["TOP"] = "top-tooltip";
    TooltipPositionEnum["BOTTOM"] = "bottom-tooltip";
})(TooltipPositionEnum = exports.TooltipPositionEnum || (exports.TooltipPositionEnum = {}));
var ConnectedTooltip = /** @class */ (function (_super) {
    __extends(ConnectedTooltip, _super);
    function ConnectedTooltip(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = _this.handleClick.bind(_this);
        return _this;
    }
    ConnectedTooltip.prototype.render = function () {
        var _a = this.props, message = _a.message, icon = _a.icon, ariaAttributes = _a.ariaAttributes, name = _a.name, _b = _a.tooltipPosition, tooltipPosition = _b === void 0 ? TooltipPositionEnum.TOP : _b;
        var wrapperClass = ['tooltip'];
        wrapperClass.push(tooltipPosition);
        if (this.props.isVisible) {
            wrapperClass.push('visible');
        }
        var allAriaAttr = __assign(__assign({}, ariaAttributes), { 'aria-controls': name + "Tooltip" });
        return (React.createElement("div", { className: wrapperClass.join(' '), onClick: this.handleClick },
            React.createElement(deko_common_components_1.Button, { icon: icon, disabled: this.props.disabled, ariaAttributes: allAriaAttr }),
            React.createElement("div", { className: 'message', id: name + "Tooltip", role: 'region', "aria-live": 'polite', "aria-hidden": !this.props.isVisible }, message)));
    };
    ConnectedTooltip.prototype.handleClick = function () {
        if (this.props.disabled) {
            return;
        }
        if (this.props.isVisible) {
            this.props.actions.setDisplayedTooltip(undefined);
        }
        else {
            this.props.actions.setDisplayedTooltip(this.props.name);
        }
    };
    return ConnectedTooltip;
}(React.Component));
var mapStateToProps = function (state, ownProps) { return ({
    isVisible: (state.tooltip && state.tooltip.visibleTooltip === ownProps.name)
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        setDisplayedTooltip: tooltipActions_1.setDisplayedTooltip
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ConnectedTooltip);
