"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var IdTypeOptions_1 = require("./IdTypeOptions");
var FileUpload_1 = require("../../components/form/FileUpload");
var FieldValidator_1 = require("../../validation/FieldValidator");
var Loader_1 = require("../../components/Loader");
var baseValidator = FieldValidator_1.default();
var FileUpload = baseValidator(FileUpload_1.default);
var UploadArea = function (_a) {
    var uploadDisabled = _a.uploadDisabled, filePrefix = _a.filePrefix, updateImage = _a.updateImage, filesBase64 = _a.filesBase64, errors = _a.errors, _b = _a.pdfLoader, pdfLoader = _b === void 0 ? React.createElement(Loader_1.default, null) : _b;
    var disabled = (!filePrefix);
    var anIDCardInput = (React.createElement("div", null,
        React.createElement(FileUpload, { className: 'id-upload-modal', filePrefix: filePrefix, onDropFile: updateImage, imgSrc: filesBase64.front_image ? filesBase64.front_image.content : '', name: 'filesBase64.front_image', label: 'Upload the front of your document (JPEG, PNG or PDF)', errors: errors['filesBase64.front_image'] || '', pdfLoader: pdfLoader }),
        React.createElement(FileUpload, { className: 'id-upload-modal', filePrefix: filePrefix, onDropFile: updateImage, imgSrc: filesBase64.rear_image ? filesBase64.rear_image.content : '', name: 'filesBase64.rear_image', label: 'Upload the back of your document (JPEG, PNG or PDF)', errors: errors['filesBase64.rear_image'] || '', pdfLoader: pdfLoader })));
    var passportOrLicenceInput = (React.createElement(FileUpload, { filePrefix: filePrefix, className: filePrefix + " id-upload-modal", onDropFile: updateImage, disabled: disabled, imgSrc: filesBase64.front_image ? filesBase64.front_image.content : '', name: 'filesBase64.front_image', label: 'Upload the front of your document (JPEG, PNG or PDF)', errors: errors['filesBase64.front_image'] || '', pdfLoader: pdfLoader }));
    var cardInput = (filePrefix === IdTypeOptions_1.IdTypeEnum.ID_CARD) ? anIDCardInput : passportOrLicenceInput;
    return (React.createElement(React.Fragment, null, cardInput));
};
exports.default = UploadArea;
