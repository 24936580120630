"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDepositPayable = void 0;
var getDepositPayable = function (finance) {
    var deposit = finance.deposit.amount;
    if (finance.deposit.paid) {
        deposit -= finance.deposit.paid;
    }
    return deposit;
};
exports.getDepositPayable = getDepositPayable;
