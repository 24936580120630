"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.denormalizeCreditApplication = exports.normalizeCreditApplication = void 0;
var lodash_1 = require("lodash");
var deko_utilities_1 = require("deko-utilities");
var stringToObject = deko_utilities_1.date.stringToObject, objectToString = deko_utilities_1.date.objectToString;
var deepMap = deko_utilities_1.objectAccess.deepMap, deepClean = deko_utilities_1.objectAccess.deepClean;
var sortCodeStringToObject = deko_utilities_1.sortCode.sortCodeStringToObject, sortCodeObjectToString = deko_utilities_1.sortCode.sortCodeObjectToString;
/**
 * Convert api response into internal representation
 * @param {IApplicationProps} creditApplication
 * @returns {any}
 */
var normalizeCreditApplication = function (creditApplication) {
    var consumer = creditApplication.consumer;
    var addresses = consumer.addresses;
    var conditions = {};
    creditApplication.conditions.map(function (c) {
        conditions[c.identifier] = c.value;
    });
    var expenditure = {};
    if (consumer.expenditure) {
        consumer.expenditure.forEach(function (element) {
            expenditure[element.type] = (element.amount !== undefined) ? element.amount / 100 : undefined;
        });
    }
    var sortCodeValue;
    if (consumer.bank_details) {
        sortCodeValue = consumer.bank_details.sort_code;
    }
    var employment = consumer.employment || {};
    var affordability = consumer.affordability || {};
    var noChange = employment.change_in_circumstances != null ? !employment.change_in_circumstances : null;
    var occupation = employment.occupation;
    if (occupation === 'N/A') {
        occupation = null;
    }
    var yearsWithEmployer = employment.years_with_employer;
    if (yearsWithEmployer === 0) {
        yearsWithEmployer = null;
    }
    var salary = employment.salary;
    if (salary === 0) {
        salary = null;
    }
    var marketing = {};
    if (creditApplication.marketing) {
        marketing = __assign({}, creditApplication.marketing);
    }
    var res = __assign(__assign({ asyncRunning: false }, creditApplication), { consumer: __assign(__assign({}, consumer), { middle_names: consumer.middle_name, employment: __assign(__assign({}, employment), { occupation: occupation, years_with_employer: yearsWithEmployer, no_change_in_circumstances: noChange, salary: {
                    amount: salary,
                    type: employment.salary_type,
                } }), date_of_birth: consumer.date_of_birth && stringToObject(consumer.date_of_birth), mobile_number: consumer.mobile_number, consent: {}, marketing: marketing, bank_details: __assign(__assign({}, consumer.bank_details), { sort_code: sortCodeStringToObject(sortCodeValue) }), expenditure: expenditure, affordability: affordability, nationality: {
                nationality: consumer.nationality,
                entry_date: consumer.entry_date,
                visa_expiry: consumer.visa_expiry,
            }, addresses: {
                current_address: addresses[0] || {
                    county: undefined,
                    flat: undefined,
                    house_name: undefined,
                    house_number: undefined,
                    postcode: undefined,
                    street: undefined,
                    town: undefined,
                    years_at_address: undefined,
                },
                second_address: addresses[1] || {
                    county: undefined,
                    flat: undefined,
                    house_name: undefined,
                    house_number: undefined,
                    postcode: undefined,
                    street: undefined,
                    town: undefined,
                    years_at_address: undefined,
                },
                third_address: addresses[3] || {
                    county: undefined,
                    flat: undefined,
                    house_name: undefined,
                    house_number: undefined,
                    postcode: undefined,
                    street: undefined,
                    town: undefined,
                    years_at_address: undefined,
                }
            }, conditions: conditions }) });
    delete res.consumer.employment.salary_type;
    delete res.conditions;
    delete res.marketing;
    return res;
};
exports.normalizeCreditApplication = normalizeCreditApplication;
/**
 * Convert internal representation into API compatible version
 * @param {IApplicationProps} creditApplication
 * @returns {any}
 */
var denormalizeCreditApplication = function (creditApplication) {
    var consumer = creditApplication.consumer;
    var addresses = [];
    if (consumer.addresses.current_address) {
        addresses.push(consumer.addresses.current_address);
        if (consumer.addresses.second_address) {
            addresses.push(consumer.addresses.second_address);
        }
        if (consumer.addresses.third_address) {
            addresses.push(consumer.addresses.third_address);
        }
    }
    var conditions = [];
    var consumerConditions = consumer.expenditure;
    if (consumerConditions != null) {
        Object.keys(consumerConditions).map(function (key) {
            conditions.push({
                identifier: key,
                value: "" + consumerConditions[key]
            });
        });
    }
    var expenditure = [];
    var consumerExpenditure = consumer.expenditure;
    if (consumerExpenditure != null) {
        Object.keys(consumerExpenditure).map(function (key) {
            var value = consumerExpenditure[key];
            if (value !== undefined) {
                value = value * 100;
            }
            expenditure.push({
                type: key,
                amount: value
            });
        });
    }
    var affordability = consumer.affordability || {};
    var visaExpiry;
    if (consumer.nationality && consumer.nationality.permission_to_remain && consumer.nationality.visa_expiry) {
        visaExpiry = objectToString(consumer.nationality.visa_expiry);
    }
    var entryDate;
    if (consumer.nationality && consumer.nationality.entry_date) {
        entryDate = objectToString(consumer.nationality.entry_date);
    }
    var sortCodeValue;
    if (consumer.bank_details) {
        sortCodeValue = consumer.bank_details.sort_code;
        sortCodeValue = sortCodeObjectToString(sortCodeValue);
        if (sortCodeValue === '') {
            sortCodeValue = undefined;
        }
    }
    var application = {
        application_id: creditApplication.application_id,
        finance: creditApplication.finance,
        lender: creditApplication.lender,
        conditions: conditions,
        memorable_word: creditApplication.memorable_word,
        consumer: {
            title: consumer.title,
            forename: consumer.forename,
            middle_name: consumer.middle_names,
            surname: consumer.surname,
            date_of_birth: consumer.date_of_birth && objectToString(consumer.date_of_birth),
            email: consumer.email,
            mobile_number: consumer.mobile_number,
            marital_status: consumer.marital_status,
            addresses: addresses,
            employment: {
                status: consumer.employment.status,
                occupation: consumer.employment.occupation,
                employer: consumer.employment.employer,
                years_with_employer: consumer.employment.years_with_employer,
                salary: consumer.employment.salary && consumer.employment.salary.amount,
                salary_type: consumer.employment.salary && consumer.employment.salary.type,
                change_in_circumstances: consumer.employment.no_change_in_circumstances != null ?
                    !consumer.employment.no_change_in_circumstances :
                    consumer.employment.change_in_circumstances,
            },
            expenditure: expenditure,
            affordability: affordability,
            number_dependants: consumer.number_dependants,
            bank_details: __assign(__assign({}, consumer.bank_details), { sort_code: sortCodeValue }),
            residential_status: consumer.residential_status,
            nationality: consumer.nationality && consumer.nationality.nationality,
            entry_date: entryDate,
            visa_expiry: visaExpiry,
        },
        marketing: consumer.marketing,
        retailer: __assign({}, creditApplication.retailer),
        threatmetrix_session_token: creditApplication.threatmetrix_session_token,
    };
    // We must clone application as some properties are by reference and should not be cleaned
    application = deepClean(lodash_1.cloneDeep(application));
    application.consumer = deepMap(application.consumer, function (value) {
        return (typeof value === 'string') ? value.trim() : value;
    });
    return application;
};
exports.denormalizeCreditApplication = denormalizeCreditApplication;
