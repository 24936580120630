"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var PromotionNoInterest_1 = require("../../magi/components/PromotionNoInterest");
require("../../magi/styles/components/ProductPromotion.scss");
var PromotionProvider = function (_a) {
    var product = _a.product;
    if (!product.promotional_offer_type || !product.promotional_offer) {
        return null;
    }
    var component;
    switch (product.promotional_offer_type) {
        case 'cpo':
            component = React.createElement(PromotionNoInterest_1.default, { cpo_weeks: product.promotional_offer.cpo_weeks });
            break;
        default: return null;
    }
    return (React.createElement("div", { className: 'promotion-provider' }, component));
};
exports.default = PromotionProvider;
