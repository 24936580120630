"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
function SortCodeInput(_a) {
    var name = _a.name, label = _a.label, value = _a.value, disabled = _a.disabled, handleChange = _a.handleChange, validateMultiInput = _a.validateMultiInput, errors = _a.errors, className = _a.className, tooltip = _a.tooltip;
    var getKey = function (key) { return name + "_" + key; };
    var keyFirst = getKey('first');
    var keySecond = getKey('second');
    var keyThird = getKey('third');
    var sortCodeObj = __assign({}, value);
    var validityInputs = {
        validRegex: /^[0-9]{1,2}$/,
        length: 2,
    };
    var validityConfig = new Map();
    validityConfig.set(keyFirst, validityInputs);
    validityConfig.set(keySecond, validityInputs);
    validityConfig.set(keyThird, validityInputs);
    var multiInputConfig = {
        inputAnyOrder: true,
        validityConfig: validityConfig,
    };
    var handleSortCodeChange = function (event) {
        var _a = event.target, key = _a.name, val = _a.value;
        var keyOrDefault = function (test, defaultVal) { return (key === test) ? val : defaultVal; };
        var objValue = {
            first: keyOrDefault(keyFirst, sortCodeObj.first),
            second: keyOrDefault(keySecond, sortCodeObj.second),
            third: keyOrDefault(keyThird, sortCodeObj.third),
        };
        handleChange({
            target: {
                name: name,
                value: objValue
            }
        });
    };
    var handleBlur = function (e) {
        // if new focus target is another of our fields
        if (e.relatedTarget && [keyFirst, keySecond, keyThird].indexOf(e.relatedTarget.name) !== -1) {
            return;
        }
        if (typeof validateMultiInput === 'function') {
            validateMultiInput(e);
        }
    };
    var wrapperClass = ['text-input', 'multi-input-wrapper', name.split('.').join('-')];
    if (className) {
        wrapperClass.push(className);
    }
    if (tooltip) {
        wrapperClass.push('has-tooltip');
    }
    var ariaInvalid = typeof errors === 'string';
    return (React.createElement(deko_common_components_1.CommonInput, { name: name, label: label, className: wrapperClass.join(' '), errors: errors, disabled: disabled, showLabel: false },
        React.createElement(deko_common_components_1.MultiInput, { config: multiInputConfig },
            React.createElement("input", { type: 'text', pattern: '[0-9]*', id: keyFirst, name: keyFirst, placeholder: '', disabled: disabled, value: sortCodeObj.first || '', onChange: handleSortCodeChange, onBlur: handleBlur, "aria-label": 'sort code, first and second digit', "aria-invalid": ariaInvalid, "aria-describedby": name + "_error" }),
            React.createElement("input", { type: 'text', pattern: '[0-9]*', id: keySecond, name: keySecond, placeholder: '', disabled: disabled, value: sortCodeObj.second || '', onChange: handleSortCodeChange, onBlur: handleBlur, "aria-label": 'sort code, third and forth digit', "aria-invalid": ariaInvalid, "aria-describedby": name + "_error" }),
            React.createElement("input", { type: 'text', pattern: '[0-9]*', id: keyThird, name: keyThird, placeholder: '', disabled: disabled, value: sortCodeObj.third || '', onChange: handleSortCodeChange, onBlur: handleBlur, "aria-label": 'sort code, fifth and sixth digit', "aria-invalid": ariaInvalid, "aria-describedby": name + "_error" })),
        tooltip));
}
exports.default = SortCodeInput;
