"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PrivacyPolicyList_1 = require("../../../../../common/components/PrivacyPolicyList");
var React = require("react");
var DekoAppFormPrefix = function (_a) {
    var branding = _a.branding;
    return (React.createElement("div", { className: 'privacy-policy-list' },
        React.createElement("p", null, "Your information will be used strictly in accordance with the relevant lender's privacy policy and is encrypted before transmission."),
        React.createElement(PrivacyPolicyList_1.default, { privacyPolicies: branding.links.privacy_policy_url })));
};
exports.default = DekoAppFormPrefix;
