"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var PropTypes = require("prop-types");
var react_redux_1 = require("react-redux");
var errorsActions_1 = require("../actions/errorsActions");
var lodash_1 = require("lodash");
var redux_1 = require("redux");
var ValidationManager_1 = require("./ValidationManager");
var FieldValidator = function (linkedFields) {
    if (linkedFields === void 0) { linkedFields = null; }
    return function (WrappedComponent) {
        var ValidatorHOC = /** @class */ (function (_super) {
            __extends(ValidatorHOC, _super);
            function ValidatorHOC(props, context) {
                var _this = _super.call(this, props, context) || this;
                _this.validateStandard = function (event) {
                    _this.runValidation(event.target.name, event.target.value);
                };
                _this.validateCheckbox = function (event) {
                    _this.runValidation(event.target.name, event.target.checked);
                };
                _this.validateMultiInput = function () {
                    _this.runValidation(_this.props.name, lodash_1.get(_this.state.entity, _this.props.name));
                };
                _this.state = {
                    entity: lodash_1.get(_this.props.fullState, _this.context.entityName)
                };
                return _this;
            }
            /**
             * Each component is responsible for triggering its own validation if it notices any of the fields it is linked
             * to has changed state in the store
             * @param prevProps
             */
            ValidatorHOC.prototype.componentDidUpdate = function (prevProps) {
                var _this = this;
                if (prevProps !== this.props) {
                    this.setState({
                        entity: lodash_1.get(this.props.fullState, this.context.entityName)
                    });
                }
                if (!linkedFields) {
                    return;
                }
                var name = this.props.name;
                var errors = this.props.errors;
                var value = lodash_1.get(this.state.entity, this.props.name);
                var hasValue = (value !== undefined && value !== null);
                var hasError = (errors[name] !== undefined && errors[name] !== true);
                // Prevent us making fields the user hasn't yet interacted with from becoming invalid
                // i.e. the moment I enter my email, confirm_email validation runs
                if (!hasValue && !hasError) {
                    return;
                }
                var entityName = this.context.entityName;
                var prevState = lodash_1.get(prevProps.fullState, entityName);
                var newState = lodash_1.get(this.props.fullState, entityName);
                var hasChanged = false;
                linkedFields.forEach(function (key) {
                    if (key === _this.props.name) {
                        return;
                    }
                    var newValue = lodash_1.get(newState, key);
                    var oldValue = lodash_1.get(prevState, key);
                    if (typeof newValue === 'object' && typeof oldValue === 'object') {
                        hasChanged = hasChanged || !lodash_1.isMatch(newValue, oldValue);
                    }
                    else {
                        hasChanged = hasChanged || newValue !== oldValue;
                    }
                });
                if (hasChanged) {
                    this.validateMultiInput();
                }
            };
            ValidatorHOC.prototype.render = function () {
                var newProps = {
                    validateStandard: this.validateStandard,
                    validateCheckbox: this.validateCheckbox,
                    validateMultiInput: this.validateMultiInput,
                    errors: this.props.errors[this.props.name]
                };
                return React.createElement(WrappedComponent, __assign({}, this.props, newProps));
            };
            ValidatorHOC.prototype.runValidation = function (name, val) {
                var state = lodash_1.get(this.props.fullState, this.context.entityName);
                var validationErrors = this.context.validationManager.isFieldValid(name, val, state);
                if (validationErrors[this.props.name] !== this.props.errors[this.props.name]) {
                    this.props.actions.addError(validationErrors);
                }
            };
            ValidatorHOC.contextTypes = {
                validationManager: PropTypes.instanceOf(ValidationManager_1.default),
                entityName: PropTypes.string
            };
            return ValidatorHOC;
        }(React.Component));
        return react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ValidatorHOC);
    };
};
var mapStateToProps = function (state) { return ({
    errors: state.errors,
    fullState: state
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        addError: errorsActions_1.addError,
    }, dispatch)
}); };
exports.default = FieldValidator;
