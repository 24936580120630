"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes = require("../actions/actionTypes");
var IApplication_1 = require("../api/CreditApplication/IApplication");
var handleConsumerLoad = function (state, action) { return (__assign(__assign(__assign({}, state), { card_details: __assign(__assign({}, state.card_details), { cardholder_name: action.creditApplication.consumer.forename + " " + action.creditApplication.consumer.surname }) }), state.instore_verification)); };
var handleUpdateCardDetails = function (state, action) { return (__assign(__assign({}, state), { card_details: action.cardDetails })); };
var handleUpdateStatus = function (state, action) { return (__assign(__assign({}, state), { status: action.status })); };
var handle3DSecure = function (state, action) { return (__assign(__assign({}, state), { status: IApplication_1.DepositStateEnum.THREE_D_SECURE, three_d_secure: action.threeDSecure })); };
var handleUpdateInstoreVerification = function (state, action) { return (__assign(__assign({}, state), { instore_verification: __assign({}, action.instoreVerification) })); };
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes.LOAD_APPLICATION_SUCCESS] = handleConsumerLoad,
    _a[actionTypes.UPDATE_CARD_DETAILS] = handleUpdateCardDetails,
    _a[actionTypes.UPDATE_DEPOSIT_STATUS] = handleUpdateStatus,
    _a[actionTypes.DEPOSIT_3DSECURE] = handle3DSecure,
    _a[actionTypes.UPDATE_INSTORE_VERIFICATION] = handleUpdateInstoreVerification,
    _a), {});
