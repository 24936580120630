"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes = require("../actions/actionTypes");
var handleUpdateToken = function (state, action) {
    return action.token || state;
};
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes.UPDATE_TOKEN] = handleUpdateToken,
    _a), {});
