"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
function FormSection(_a) {
    var label = _a.label, children = _a.children, className = _a.className;
    return (React.createElement("div", { className: "form-section " + (className ? className : '') },
        label && (React.createElement("h2", { className: 'section-heading' }, label)),
        React.createElement("div", { className: 'section' }, children)));
}
exports.default = FormSection;
