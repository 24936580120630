"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_utilities_1 = require("deko-utilities");
var redux_1 = require("redux");
var depositActions_1 = require("../../../common/actions/depositActions");
var react_redux_1 = require("react-redux");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var Page_1 = require("../../components/Page");
var navigationActions_1 = require("../../actions/navigationActions");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var navigationActions_2 = require("../../../common/actions/navigationActions");
var errorsActions_1 = require("../../../common/actions/errorsActions");
var deposit_1 = require("../../../common/utilities/deposit");
require("../../styles/pages/InstoreDepositPage.scss");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
var deko_common_components_1 = require("deko-common-components");
var FormSection_1 = require("../../../common/components/form/FormSection");
var RadioInput_1 = require("../../../common/components/form/RadioInput");
var Loader_1 = require("../../../common/components/Loader");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
var verificationOptions = [
    { text: 'Chip and PIN authenticated credit/debit card payment. Proof of ID may be required', value: 'chip_pin' },
    { text: 'Other (non chip and PIN deposit payment. Proof of ID will be required)', value: 'other' }
];
var InstoreDepositPage = /** @class */ (function (_super) {
    __extends(InstoreDepositPage, _super);
    function InstoreDepositPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this._handleChange = function (event) {
            if (event.target.name === 'verification_method') {
                _this.setState({
                    verificationType: event.target.value
                });
            }
        };
        _this._handleSubmit = function (event) {
            _this.props.actions.submitInstoreDepositPayment(_this.state.verificationType);
        };
        _this.state = {
            verificationType: null
        };
        return _this;
    }
    InstoreDepositPage.prototype.render = function () {
        var application = this.props.application;
        var finance = application.finance;
        var shouldSubmitBeDisabled = !this.state.verificationType;
        if (!application || !finance || !application.consumer) {
            return null;
        }
        return (React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: true, fallbackAction: navigationActions_2.DOCUMENTS_PAGE },
            React.createElement(Page_1.default, { mainCols: 5, mainOffset: 2, className: 'instore-deposit-page' },
                React.createElement("h1", null, "Deposit"),
                React.createElement("p", null,
                    "I confirm that the amount of \u00A3",
                    deko_utilities_1.currency.formatCurrency(deposit_1.getDepositPayable(finance)),
                    " has been taken successfully by the following method:"),
                React.createElement("div", { className: 'instore-deposit-form' },
                    React.createElement(RadioInput_1.default, { name: 'verification_method', handleChange: this._handleChange, options: verificationOptions, type: 'string', style: 'TRADITIONAL', value: this.state.verificationType }),
                    React.createElement(FormSection_1.default, null,
                        React.createElement("hr", null),
                        React.createElement("p", null, "Subject to ID verification if necessary, the following information is true:"),
                        React.createElement("ul", { className: 'check-list' },
                            React.createElement("li", null, "I have given the customer a verbal explanation of the cost of the credit"),
                            React.createElement("li", null, "The customer has confirmed that they have enough information to make an informed decision to accept this agreement"),
                            React.createElement("li", null, "I certify that the name on the credit/debit card provided matches that of the application"),
                            React.createElement("li", null, "I have no reason to believe that the customer does not have the mental capacity to enter into this credit agreement"))),
                    React.createElement(CTAButton, { className: 'submit large', disabled: shouldSubmitBeDisabled, timeout: 300, handleClick: this._handleSubmit }, this.props.asyncRunning ?
                        React.createElement(Loader_1.default, { title: 'Processing...', loaderType: Loader_1.LoaderTypeEnum.BUTTON }) : 'Verify Payment')))));
    };
    return InstoreDepositPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    token: state.token,
    application: state.application,
    errors: state.errors,
    asyncRunning: state.async.asyncRunning,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_1.dispatchNavigation,
        submitInstoreDepositPayment: depositActions_1.submitInstoreDepositPayment,
        replaceErrors: errorsActions_1.replaceErrors,
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(InstoreDepositPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT])(connected);
