"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkedFields = void 0;
var React = require("react");
var lodash_1 = require("lodash");
var insufficientHistoryConstraint = function (currentState) {
    var currentYearsState = lodash_1.get(currentState, 'addresses.current_address.years_at_address');
    // if the first address is not specified, this constraint is not required
    if (typeof currentYearsState === 'undefined') {
        return false;
    }
    var current = Number(currentYearsState) || 0;
    var second = Number(lodash_1.get(currentState, 'addresses.second_address.years_at_address')) || 0;
    if (currentState.addresses.third_address.years_at_address !== undefined) {
        // we have 3 addresses, good enough
        return false;
    }
    return (current + second) < 3;
};
var requiresAddressTwo = function (currentState) {
    var currentYearsState = lodash_1.get(currentState, 'addresses.second_address.years_at_address');
    return currentYearsState !== undefined || insufficientHistoryConstraint(currentState);
};
var requiresAddressThree = function (currentState) {
    var currentYearsState = lodash_1.get(currentState, 'addresses.third_address.years_at_address');
    return currentYearsState !== undefined || insufficientHistoryConstraint(currentState);
};
var isRequiredIfEmploymentStatus = function (currentState) {
    if (currentState.employment.status === undefined || currentState.employment.status === '') {
        return true;
    }
    var isRequiredIfStatues = ['FullTime', 'PartTime', 'SelfEmployed'];
    return isRequiredIfStatues.some(function (status) { return currentState.employment.status === status; });
};
var incomeAboveTenMillionConstraint = function (currentState) {
    if (currentState.employment === undefined || currentState.employment.salary === undefined) {
        return false;
    }
    return currentState.employment.salary.amount > 10000000;
};
var basicSpecialChars = 'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ';
var extendedSpecialChars = 'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ';
exports.linkedFields = {
    employment: ['employment.occupation', 'employment.employer', 'employment.years_with_employer'],
    email: ['email', 'confirm_email']
};
exports.default = {
    'title': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please select a ",
                React.createElement("strong", null, "Title")),
        }
    ],
    'forename': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "First name")),
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 2,
                max: 50
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "First name"),
                " should be between 2 and 50 characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: new RegExp("^[a-zA-Z " + extendedSpecialChars + "^_`'.-]+$"),
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "First name"),
                " contains invalid characters"),
        },
    ],
    'surname': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Last name")),
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 2,
                max: 50
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Last name"),
                " should be between 2 and 50 characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: new RegExp("^[a-zA-Z " + extendedSpecialChars + "^_`'.-]+$"),
            message: React.createElement("span", null,
                "Please enter a valid ",
                React.createElement("strong", null, "Last name")),
        },
    ],
    'date_of_birth': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Date of birth")),
        },
        {
            ruleName: 'isDateOfBirth',
            message: React.createElement("strong", null, "Please enter a valid date (dd/mm/yyyy)"),
        },
        {
            ruleName: 'ageIsOver',
            message: React.createElement("strong", null, "You must be at least 18 years old to apply"),
            constraint: 18
        },
        {
            ruleName: 'isFullDate',
            message: React.createElement("strong", null, "Please enter the date in a valid format (dd/mm/yyyy)"),
        },
    ],
    'marital_status': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please select your ",
                React.createElement("strong", null, "Marital status")),
        },
    ],
    'email': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Email address")),
        },
        {
            ruleName: 'isEmail',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter a valid ",
                React.createElement("strong", null, "Email address")),
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 6,
                max: 80
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Email address"),
                " must be between 6 and 80 characters long"),
        }
    ],
    'confirm_email': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please confirm your ",
                React.createElement("strong", null, "Email address")),
        },
        {
            ruleName: 'shouldMatchField',
            message: React.createElement("span", null, "Please ensure that your email addresses match"),
            constraint: {
                key: 'email'
            },
        }
    ],
    'mobile_number': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Contact number")),
        },
        {
            ruleName: 'validRegex',
            constraint: /^0[1-9]\d{8,9}/,
            message: React.createElement("span", null,
                "Please enter a valid UK ",
                React.createElement("strong", null, "Contact number")),
        },
    ],
    'employment.status': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Employment status")),
        }
    ],
    'employment.occupation': [
        {
            ruleName: 'isRequiredIf',
            constraint: isRequiredIfEmploymentStatus,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Occupation")),
        },
        {
            ruleName: 'validRegex',
            constraint: new RegExp("^[a-zA-Z0-9 " + basicSpecialChars + "^_`'.-]+$"),
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Occupation"),
                " has invalid characters"),
        }
    ],
    'employment.employer': [
        {
            ruleName: 'isRequiredIf',
            constraint: isRequiredIfEmploymentStatus,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Employer name")),
        },
        {
            ruleName: 'validRegex',
            constraint: new RegExp("^[a-zA-Z0-9 " + basicSpecialChars + "^_`'.-]+$"),
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Employer name"),
                " has invalid characters"),
        }
    ],
    'employment.years_with_employer': [
        {
            ruleName: 'isRequiredIf',
            constraint: isRequiredIfEmploymentStatus,
            message: React.createElement("span", null,
                "Please enter how many ",
                React.createElement("strong", null, "Years"),
                " you've worked ",
                React.createElement("strong", null, "with this employer")),
        }
    ],
    'employment.salary.amount': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Annual income")),
        },
        {
            ruleName: 'isAtLeast',
            constraint: 5000,
            message: React.createElement("span", null,
                "If your ",
                React.createElement("strong", null, "Annual income"),
                " is under \u00A35000 you will not be eligible for finance"),
        },
        {
            ruleName: 'passesConstraint',
            constraint: incomeAboveTenMillionConstraint,
            message: (React.createElement("span", null,
                "We use a variety of methods to verify your ",
                React.createElement("strong", null, "Annual income"),
                ", please enter it accurately. If you earn over \u00A310,000,000, please enter \u00A310,000,000"))
        }
    ],
    'affordability.dependants': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Number of dependants"),
                ". If you have no dependants, please enter 0"),
        },
        {
            ruleName: 'passesConstraint',
            constraint: function (currentState) {
                if (currentState.affordability.dependants === undefined) {
                    return false;
                }
                return currentState.affordability.dependants > 100;
            },
            message: (React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Number of dependants"),
                " accurately."))
        }
    ],
    'affordability.monthly_rent_amount.amount': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Monthly mortgage or rent amount."),
                "  If the amount is \u00A30, please enter \u00A30"),
        },
        {
            ruleName: 'passesConstraint',
            constraint: function (currentState) {
                if (currentState.affordability === undefined || currentState.affordability.monthly_rent_amount === undefined) {
                    return false;
                }
                return currentState.affordability.monthly_rent_amount.amount > 1000000;
            },
            message: (React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Monthly mortgage or rent amount."),
                " accurately. If you pay over \u00A31,000,000, please enter \u00A31,000,000"))
        },
        {
            ruleName: 'isAtLeast',
            constraint: 0,
            message: React.createElement("span", null,
                React.createElement("strong", null, "Monthly mortgage or rent amount"),
                " that is paid for your household. If the amount is \u00A30, please enter \u00A30."),
        },
    ],
    'affordability.household_income.amount': [
        {
            ruleName: 'passesConstraint',
            constraint: function (currentState) {
                if (currentState.affordability === undefined || currentState.affordability.household_income === undefined) {
                    return false;
                }
                if (!currentState.affordability.household_income) {
                    return false;
                }
                return currentState.affordability.household_income.amount < 5000;
            },
            message: React.createElement("span", null,
                "If your ",
                React.createElement("strong", null, "Annual household income"),
                " is under \u00A35000 you will not be eligible for finance"),
        },
        {
            ruleName: 'passesConstraint',
            constraint: function (currentState) {
                if (currentState.affordability === undefined || currentState.affordability.household_income === undefined) {
                    return false;
                }
                if (!currentState.affordability.household_income) {
                    return false;
                }
                return currentState.affordability.household_income.amount > 10000000;
            },
            message: (React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Annual household income"),
                " accurately. If your household earns over \u00A310,000,000, please enter \u00A310,000,000"))
        },
        {
            ruleName: 'compareTo',
            constraint: function (currentState) {
                if (currentState.affordability === undefined || currentState.affordability.household_income === undefined) {
                    return false;
                }
                if (currentState.employment === undefined || currentState.employment.salary === undefined) {
                    return false;
                }
                return currentState.affordability.household_income.amount < currentState.employment.salary.amount;
            },
            message: (React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Annual household income"),
                " should include your Annual income."))
        },
    ],
    'residential_status': [
        {
            ruleName: 'isRequired',
            message: React.createElement("span", null,
                "Please select your ",
                React.createElement("strong", null, "Residential status")),
        }
    ],
    'addresses': [
        {
            ruleName: 'passesConstraint',
            constraint: insufficientHistoryConstraint,
            message: React.createElement("span", null,
                "Please provide 3 or more years of your ",
                React.createElement("strong", null, "address history")),
        },
    ],
    'addresses.current_address.house_name': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "House name"),
                "..."),
            constraint: {
                field: 'addresses.current_address.house_number',
                value: '',
            }
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 50
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House name"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z '"\.\/-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House name"),
                " contains invalid characters"),
        },
    ],
    'addresses.current_address.house_number': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "... and/or your ",
                React.createElement("strong", null, "House number")),
            constraint: {
                field: 'addresses.current_address.house_name',
                value: '',
            }
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 10
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House number"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z\.\/-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House number"),
                " has invalid characters"),
        },
    ],
    'addresses.current_address.flat': [
        {
            ruleName: 'hasLength',
            constraint: {
                max: 16
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Flat number"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z \/\.-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Flat number"),
                " has invalid characters"),
        },
    ],
    'addresses.current_address.street': [
        {
            ruleName: 'isRequired',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Street name")),
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 40
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Street name"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z '\.-]+$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Street name"),
                " has invalid characters"),
        },
    ],
    'addresses.current_address.town': [
        {
            ruleName: 'isRequired',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Town/City")),
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 20
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Town/City"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[a-z '\.-]+$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Town/City"),
                " has invalid characters"),
        },
    ],
    'addresses.current_address.county': [
        {
            ruleName: 'hasLength',
            constraint: {
                max: 20
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "County"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[a-z ]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "County"),
                " has invalid characters"),
        },
    ],
    'addresses.current_address.postcode': [
        {
            ruleName: 'isRequired',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Postcode")),
        },
        {
            ruleName: 'isPostcode',
            message: React.createElement("span", null,
                "Please enter a valid UK ",
                React.createElement("strong", null, "Postcode")),
        },
    ],
    'addresses.current_address.years_at_address': [
        {
            ruleName: 'isRequired',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Years at this address")),
        },
    ],
    'addresses.second_address.house_name': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "House name"),
                "..."),
            constraint: function (currentState) {
                var houseNumber = lodash_1.get(currentState, 'addresses.second_address.house_number');
                return requiresAddressTwo(currentState) && (houseNumber === '' || houseNumber === undefined);
            }
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 50
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House name"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z '\.\/-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House name"),
                " has invalid characters"),
        },
    ],
    'addresses.second_address.house_number': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "... and/or your ",
                React.createElement("strong", null, "House number")),
            constraint: function (currentState) {
                var houseName = lodash_1.get(currentState, 'addresses.second_address.house_name');
                return requiresAddressTwo(currentState) && (houseName === '' || houseName === undefined);
            }
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 10
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House number"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z\.\/-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House number"),
                " contains invalid characters"),
        },
    ],
    'addresses.second_address.flat': [
        {
            ruleName: 'hasLength',
            constraint: {
                max: 16
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Flat number"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z \/\.-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Flat number"),
                " has invalid characters"),
        },
    ],
    'addresses.second_address.street': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Street name")),
            constraint: requiresAddressTwo
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 40
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Street name"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z '\.-]+$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Street name"),
                " has invalid characters"),
        },
    ],
    'addresses.second_address.town': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Town/City")),
            constraint: requiresAddressTwo
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 20
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Town/City"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[a-z '\.-]+$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Town/City"),
                " has invalid characters"),
        },
    ],
    'addresses.second_address.county': [
        {
            ruleName: 'hasLength',
            constraint: {
                max: 20
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "County"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[a-z ]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "County"),
                " has invalid characters"),
        },
    ],
    'addresses.second_address.postcode': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Postcode")),
            constraint: requiresAddressTwo
        },
        {
            ruleName: 'isPostcode',
            message: React.createElement("span", null,
                "Please enter a valid UK ",
                React.createElement("strong", null, "Postcode")),
        }
    ],
    'addresses.second_address.years_at_address': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Years at this address")),
            constraint: requiresAddressTwo,
        },
    ],
    'addresses.second_address': [
        {
            ruleName: 'notTheSameAs',
            message: React.createElement("span", null,
                React.createElement("strong", null, "Current"),
                " and ",
                React.createElement("strong", null, "previous addresses"),
                " cannot be the same"),
            constraint: {
                key: 'addresses.current_address',
                fields: ['flat', 'house_number', 'house_name', 'street', 'district', 'county', 'town', 'postcode']
            },
        }
    ],
    'addresses.third_address.house_name': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "House name"),
                "..."),
            constraint: function (currentState) {
                var houseNumber = lodash_1.get(currentState, 'addresses.third_address.house_number');
                return requiresAddressThree(currentState) && (houseNumber === '' || houseNumber === undefined);
            }
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 50
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House name"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z '\.\/-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House name"),
                " has invalid characters"),
        },
    ],
    'addresses.third_address.house_number': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "... and/or your ",
                React.createElement("strong", null, "House number")),
            constraint: function (currentState) {
                var houseName = lodash_1.get(currentState, 'addresses.third_address.house_name');
                return requiresAddressThree(currentState) && (houseName === '' || houseName === undefined);
            }
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 10
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House number"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z '"\.\/-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "House number"),
                " contains invalid characters"),
        },
    ],
    'addresses.third_address.flat': [
        {
            ruleName: 'hasLength',
            constraint: {
                max: 16
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Flat number"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z \/\.-]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Flat number"),
                " has invalid characters"),
        },
    ],
    'addresses.third_address.street': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Street name")),
            constraint: requiresAddressThree
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 40
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Street name"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[\da-z '\.-]+$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Street name"),
                " has invalid characters"),
        },
    ],
    'addresses.third_address.town': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Town/City")),
            constraint: requiresAddressThree
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 1,
                max: 20
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Town/City"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[a-z '\.-]+$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Town/City"),
                " has invalid characters"),
        },
    ],
    'addresses.third_address.county': [
        {
            ruleName: 'hasLength',
            constraint: {
                max: 20
            },
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "County"),
                " has too many characters"),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[a-z ]*$/i,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "County"),
                " has invalid characters"),
        },
    ],
    'addresses.third_address.postcode': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Postcode")),
            constraint: requiresAddressThree
        },
        {
            ruleName: 'isPostcode',
            message: React.createElement("span", null,
                "Please enter a valid UK ",
                React.createElement("strong", null, "Postcode")),
        }
    ],
    'addresses.third_address.years_at_address': [
        {
            ruleName: 'isRequiredIf',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Years at this address")),
            constraint: requiresAddressThree
        },
    ],
    'addresses.third_address': [
        {
            ruleName: 'notTheSameAs',
            message: React.createElement("strong", null, "Current and previous addresses cannot be the same"),
            constraint: {
                key: 'addresses.second_address',
                fields: ['flat', 'house_number', 'house_name', 'street', 'district', 'county', 'town', 'postcode']
            },
        }
    ],
    'bank_details.account_number': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Account number")),
        },
        {
            ruleName: 'validRegex',
            constraint: /^[0-9]{8}$/,
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Account number"),
                " should be 8 digits long"),
        }
    ],
    'bank_details.sort_code': [
        {
            ruleName: 'isRequiredSortCode',
            message: React.createElement("span", null,
                "Please enter your ",
                React.createElement("strong", null, "Sort code")),
        },
        {
            ruleName: 'isValidSortCode',
            message: React.createElement("span", null,
                "Your ",
                React.createElement("strong", null, "Sort code"),
                " should be 6 digits long"),
        }
    ],
    'consent.submit': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: React.createElement("strong", null, "Please confirm if you approve"),
        }
    ],
    'consent.klarna_extra': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: React.createElement("strong", null, "Please confirm"),
        }
    ],
};
