"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var PageTitle_1 = require("../../common/components/form/PageTitle");
var Footer_1 = require("../components/template/Footer");
require("../styles/pages/LegalPages.scss");
var logo = require('../../common/assets/deko-logo-one-colour.svg');
var SecurityStatementPage = /** @class */ (function (_super) {
    __extends(SecurityStatementPage, _super);
    function SecurityStatementPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SecurityStatementPage.prototype.render = function () {
        return (React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'legal-page' },
                React.createElement("div", { className: 'security-statement-page container' },
                    React.createElement(PageTitle_1.default, { title: 'Deko | Security Statement' }),
                    React.createElement("div", { className: 'header' },
                        React.createElement(deko_common_components_1.Image, { className: 'logo', src: logo })),
                    React.createElement("h1", null, "Security Statement"),
                    React.createElement("p", null, "Here at Deko we take our responsibilities regarding information security very seriously. Our information security policies, processes and procedures are derived from industry good practice and cover all customer data that comes under our control or ownership. Our strategic objectives for information security correlate directly to industry standards, with adjustments tailored to our business and security environment."),
                    React.createElement("p", null, "It is our policy that our information assets and those of our clients and their customers are protected from internal and external threats; confidentiality and integrity of information is maintained with all backup media and data in transit encrypted and access to information assets is granted only for justified business needs. Internal and external users of information assets shall be identifiable, accountable and may be monitored by us in their use of those assets.")),
                React.createElement(Footer_1.default, null))));
    };
    return SecurityStatementPage;
}(React.Component));
exports.default = SecurityStatementPage;
