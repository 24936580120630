"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var Page_1 = require("../../components/Page");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var PageTitle_1 = require("../../../common/components/form/PageTitle");
require("../../styles/pages/TheEndPage.scss");
var redux_1 = require("redux");
var BackToRetailerButton_1 = require("../../../common/components/BackToRetailerButton");
var react_redux_1 = require("react-redux");
var NoDecision = /** @class */ (function (_super) {
    __extends(NoDecision, _super);
    function NoDecision() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NoDecision.prototype.componentDidMount = function () {
        window.parent.postMessage({
            message: 'APPLICATION_STATUS_UPDATE',
            data: {
                status: this.props.application.status,
                reference: this.props.application.unique_reference
            }
        }, '*');
    };
    NoDecision.prototype.render = function () {
        var application = this.props.application;
        var lender = application.lender;
        var preContent = (this.lenderIsBraemar(lender)) ? this.preContent() : this.defaultPreContent();
        return (React.createElement(Page_1.default, { className: 'no-decision-page' },
            React.createElement(PageTitle_1.default, { title: 'Your decision will be emailed to you' }),
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    React.createElement("h1", null, "Your decision will be emailed to you"),
                    preContent,
                    React.createElement("p", null,
                        "If your credit application is approved, we will send you an email with details of how to complete your purchase. ",
                        React.createElement("strong", null, "Please check your spam folder"),
                        " to make sure it did not end up there by accident."),
                    React.createElement("p", null,
                        "If you have any questions, please visit our ",
                        React.createElement("a", { href: 'https://www.dekopay.com/consumerfaq/' }, "FAQ "),
                        "or contact the retailer for support.")))));
    };
    NoDecision.prototype.defaultPreContent = function () {
        return (React.createElement("p", null, "This normally takes just a few minutes, however applications received after 9pm or during especially busy periods may take longer (next working day response)."));
    };
    NoDecision.prototype.preContent = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "Your application has been referred to one of our experienced underwriters for a review and a decision will be with you shortly"),
            React.createElement("p", null, "Underwriters are available Monday to Friday from 9am-5pm. If your application is outside of this time, a response will follow on the next business day")));
    };
    NoDecision.prototype.lenderIsBraemar = function (lender) {
        return lender.name === 'Braemar Finance';
    };
    return NoDecision;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchExternalNavigation: BackToRetailerButton_1.getDispatchExternalNavigation()
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(NoDecision);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.NO_DECISION, IApplication_1.AppStatusEnum.REFER])(connected);
