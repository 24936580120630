"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnorderedList = exports.BoldParagraph = void 0;
var React = require("react");
var BoldParagraph = function (_a) {
    var children = _a.children;
    return (React.createElement("p", { style: { fontWeight: 600 } }, children));
};
exports.BoldParagraph = BoldParagraph;
var UnorderedList = function (_a) {
    var children = _a.children;
    return (React.createElement("ul", { style: { listStyle: 'inside' } }, React.Children.map(children, function (child) { return (React.createElement("li", { style: { textIndent: '-1em', paddingLeft: '1em' } }, child)); })));
};
exports.UnorderedList = UnorderedList;
