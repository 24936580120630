"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var deko_common_components_1 = require("deko-common-components");
var creditApplicationActions_1 = require("../actions/creditApplicationActions");
var Modal_1 = require("./Modal");
require("../styles/deko-styles/components/FastTrack.scss");
var FastTrack = /** @class */ (function (_super) {
    __extends(FastTrack, _super);
    function FastTrack(props) {
        var _this = _super.call(this, props) || this;
        _this.renderModalContent = function () { return (React.createElement("div", null,
            React.createElement("h2", null, "Fast Track"),
            React.createElement(deko_common_components_1.TextInput, { name: 'email', label: 'Email', type: 'email', value: _this.state.email, handleChange: _this.updateEmail }),
            React.createElement("div", { className: 'button-wrapper' }, _this.props.scenarios.map(function (scenario) {
                return React.createElement(deko_common_components_1.Button, { key: scenario.surname, handleClick: _this.fastTrack({ surname: scenario.surname }) }, scenario.scenario);
            })))); };
        _this.updateEmail = function (event) { return _this.setState({ email: event.target.value }); };
        _this.toggleModal = function () { return _this.setState({ showModal: !_this.state.showModal }); };
        var email = localStorage ? localStorage.getItem('faster-email') || '' : '';
        _this.state = {
            showModal: false,
            email: email,
        };
        return _this;
    }
    FastTrack.prototype.render = function () {
        return (React.createElement("div", { className: 'fast-track' },
            this.state.showModal ? React.createElement(Modal_1.default, { onClose: this.toggleModal }, this.renderModalContent()) : null,
            React.createElement(deko_common_components_1.Button, { handleClick: this.toggleModal }, "Fast Track")));
    };
    FastTrack.prototype.fastTrack = function (scenario) {
        var _this = this;
        return function () {
            if (localStorage) {
                localStorage.setItem('faster-email', _this.state.email);
            }
            var consumer = __assign(__assign(__assign({}, _this.props.completeConsumer), scenario), { email: _this.state.email, confirm_email: _this.state.email });
            _this.props.actions.updateConsumer(consumer);
            _this.toggleModal();
        };
    };
    return FastTrack;
}(React.Component));
var mapStateToProps = function () { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        updateConsumer: creditApplicationActions_1.updateConsumer
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(FastTrack);
