"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var creditApplicationService_1 = require("../../../services/creditApplicationService");
var deposit_1 = require("../../../../common/utilities/deposit");
var deko_utilities_1 = require("deko-utilities");
var deko_common_components_1 = require("deko-common-components");
var Loader_1 = require("../../Loader");
require("../../../styles/components/form/OpayoForm.scss");
var sagepayErrorMapper_1 = require("../../../utilities/sagepayErrorMapper");
var OpayoThreeDSecureForm_1 = require("./OpayoThreeDSecureForm");
var moment = require("moment");
var INVALID_TIMEOUT = 5000;
var OpayoForm = function (_a) {
    var application = _a.application, handler = _a.handler, token = _a.token;
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var _c = React.useState(''), error = _c[0], setError = _c[1];
    var sagepay = React.useRef(null);
    var timer = React.useRef(null);
    var _d = React.useState(null), show3DAuth = _d[0], setShow3DAuth = _d[1];
    function doOpayoDeposit(cardInfo, sessionKey) {
        return __awaiter(this, void 0, void 0, function () {
            var response, lenderName, message, err;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        return [4 /*yield*/, creditApplicationService_1.makeOpayoDeposit(application.application_id, cardInfo.cardIdentifier, sessionKey)];
                    case 1:
                        response = _a.sent();
                        setLoading(false);
                        if (sagepay.current) {
                            sagepay.current.destroy();
                            sagepay.current = null;
                        }
                        switch (response.status) {
                            case '3DAUTH':
                                if (!response.three_d_secure) {
                                    break;
                                }
                                if (response.three_d_secure.pa_req) {
                                    // 3D Secure v1
                                    setShow3DAuth({
                                        acsUrl: response.three_d_secure.acs_url,
                                        paReq: response.three_d_secure.pa_req,
                                        md: response.three_d_secure.md
                                    });
                                }
                                else {
                                    // 3D Secure v2
                                    setShow3DAuth({
                                        acsUrl: response.three_d_secure.acs_url,
                                        creq: response.three_d_secure.c_req,
                                        threeDSessionData: response.three_d_secure.md
                                    });
                                }
                                break;
                            case 'OK':
                                if (handler) {
                                    handler();
                                }
                                break;
                            case 'FAIL':
                            case 'REJECTED':
                            case 'NOTAUTHED':
                            case 'INVALID':
                            default:
                                lenderName = application.lender.name;
                                message = response.message;
                                err = sagepayErrorMapper_1.default(message, lenderName);
                                if (err === message) {
                                    throw new Error(sagepayErrorMapper_1.default('0000', lenderName));
                                }
                                throw new Error(err);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var tokenize = function (cardInfo, sessionKey) {
        doOpayoDeposit(cardInfo, sessionKey)
            .catch(function (e) {
            var _a;
            setShow3DAuth(null);
            refreshSessionKey();
            setError((_a = e.message) !== null && _a !== void 0 ? _a : 'An error has occurred');
        });
    };
    var detectIFrameClick = function () {
        setTimeout(function () {
            var _a;
            if (((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'IFRAME') {
                setError('');
            }
        }, 50);
    };
    var refreshSessionKey = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (sagepay.current) {
                        sagepay.current.destroy();
                        sagepay.current = null;
                    }
                    setLoading(true);
                    setShow3DAuth(null);
                    return [4 /*yield*/, creditApplicationService_1.getOpayoMerchantSessionKey(application.application_id)
                            .then(function (_a) {
                            var key = _a.key, expiry = _a.expiry, time_out = _a.time_out;
                            setError('');
                            setLoading(false);
                            var now = moment();
                            var end = moment(expiry);
                            var duration = moment.duration(end.diff(now));
                            var ms = time_out > 0 ? time_out * 1000 : duration.asMilliseconds();
                            timer.current = setTimeout(function () { return refreshSessionKey(); }, ms);
                            sagepay.current = sagepayCheckout({
                                merchantSessionKey: key,
                                containerSelector: '#sp-container',
                                onTokenise: function (cardInfo) { return tokenize(cardInfo, key); },
                            });
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        refreshSessionKey();
        window.addEventListener('blur', detectIFrameClick, false);
        return function () {
            clearTimeout(timer.current);
            window.removeEventListener('blur', detectIFrameClick);
        };
    }, []);
    var onFormSubmit = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (sagepay.current) {
            sagepay.current.tokenise();
            setTimeout(function () {
                setLoading(false);
            }, INVALID_TIMEOUT);
        }
        return false;
    };
    var handle3DSecure = function (status, err) {
        switch (status) {
            case 'success': {
                if (handler) {
                    handler();
                }
                break;
            }
            case 'fail':
            default: {
                var lenderName = application.lender.name;
                var mappedErr = sagepayErrorMapper_1.default(err, lenderName);
                if (mappedErr === err) {
                    mappedErr = sagepayErrorMapper_1.default('0000', lenderName);
                }
                setShow3DAuth(null);
                refreshSessionKey();
                setError(mappedErr !== null && mappedErr !== void 0 ? mappedErr : 'An error has occurred');
            }
        }
    };
    return (React.createElement("div", { className: 'online-deposit-form' },
        React.createElement("h1", null, "Pay your deposit"),
        React.createElement("h2", null,
            "Deposit amount due: \u00A3",
            deko_utilities_1.currency.formatCurrency(deposit_1.getDepositPayable(application.finance))),
        React.createElement("p", { className: 'payment-info' }, "Please complete the fields below with your card details. The name and registered address on your card must match your application as this is used to verify your identity. The deposit will form part of your repayments."),
        error !== '' && React.createElement("div", { id: 'error-message' }, error),
        show3DAuth !== null && (React.createElement(OpayoThreeDSecureForm_1.default, __assign({}, show3DAuth, { token: token, handleCallback: handle3DSecure }))),
        show3DAuth == null && (React.createElement("div", { id: 'opayo-form' },
            React.createElement("div", { id: 'sp-container' }),
            React.createElement("div", { id: 'submit-container' },
                React.createElement(deko_common_components_1.Button, { className: 'submit large', submit: true, disabled: loading, handleClick: onFormSubmit }, loading ? (React.createElement(Loader_1.default, { title: 'Processing...', loaderType: Loader_1.LoaderTypeEnum.BUTTON })) : ('Pay deposit')))))));
};
exports.default = OpayoForm;
