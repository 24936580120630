"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var PageTitle_1 = require("../../../common/components/form/PageTitle");
var Footer_1 = require("../../components/template/Footer");
require("../../styles/pages/LegalPages.scss");
var logo = require('../../../common/assets/deko_logo.svg');
var WhatIsACreditSearchPageDeko = function () {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'legal-page' },
                React.createElement("div", { className: 'what-is-credit-search-page container' },
                    React.createElement(PageTitle_1.default, { title: 'Deko | What is Credit Search' }),
                    React.createElement("div", { className: 'header' },
                        React.createElement(deko_common_components_1.Image, { className: 'logo', src: logo })),
                    React.createElement("h1", null, "What is a Credit Search?"),
                    React.createElement("p", null, "A credit search occurs when a company investigates your credit file. They\u2019re looking to see how you manage your credit now, and how you\u2019ve managed it in the past. This search will then be recorded on your credit file for other companies, such as future lenders, to see. This is useful to lenders because if you\u2019ve made multiple applications for credit recently, it could suggest to them that you have an urgent need for credit and that you might struggle to pay it back."),
                    React.createElement("p", null, "The credit search will be performed by your lender, using the personal information you provide to us in your application form."),
                    React.createElement("p", null, "To check your eligibility before you submit a full application, your lender will perform a \u2018soft search\u2019. This type of search will only be visible to you and will not be visible to any third party. Once you submit a full application, your lender will need to make a decision about whether to give you the credit you\u2019ve applied for. At this stage, the lender will perform a \u2018full credit check\u2019. This search will be visible to third parties who view your credit file, and will show that you have applied for finance with the lender."),
                    React.createElement("p", null, "These searches will usually remain on your credit file for a period of 6 years.")),
                React.createElement(Footer_1.default, null))));
};
exports.default = WhatIsACreditSearchPageDeko;
