"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logErrorToNewrelic = void 0;
function logErrorToNewrelic(errorMessage) {
    if (typeof window.newrelic !== 'undefined') {
        var error = new Error(errorMessage);
        window.newrelic.noticeError(error);
    }
}
exports.logErrorToNewrelic = logErrorToNewrelic;
