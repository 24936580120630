"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IApplication_1 = require("../../common/api/CreditApplication/IApplication");
var IRedemptionApi_1 = require("../../common/api/Redemption/IRedemptionApi");
exports.default = {
    token: undefined,
    application: {
        asyncRunning: false,
        application_id: 0,
        status: IApplication_1.AppStatusEnum.INIT,
        finance: undefined,
        telephone_application: false,
        credit_application_id: undefined,
        lender: {
            id: '',
            name: '',
            direct_debit_display_name: '',
            contact_information: {
                phone: '',
                email: ''
            },
        },
        cancellation_code: undefined,
        installation_id: undefined,
        unique_reference: undefined,
        flags: undefined,
        documents_signed: false,
        consumer: {
            title: undefined,
            forename: undefined,
            middle_names: undefined,
            surname: undefined,
            sex: 'M',
            accepted_offer: undefined,
            phone_number: undefined,
            mobile_number: undefined,
            email: undefined,
            date_of_birth: undefined,
            addresses: {
                current_address: {
                    flat: undefined,
                    house_number: undefined,
                    house_name: undefined,
                    street: undefined,
                    district: undefined,
                    county: undefined,
                    town: undefined,
                    postcode: undefined,
                    years_at_address: undefined
                },
                second_address: {
                    flat: undefined,
                    house_number: undefined,
                    house_name: undefined,
                    street: undefined,
                    district: undefined,
                    county: undefined,
                    town: undefined,
                    postcode: undefined,
                    years_at_address: undefined
                },
                third_address: {
                    flat: undefined,
                    house_number: undefined,
                    house_name: undefined,
                    street: undefined,
                    district: undefined,
                    county: undefined,
                    town: undefined,
                    postcode: undefined,
                    years_at_address: undefined
                }
            },
            residential_status: undefined,
            employment: {
                status: undefined,
                occupation: undefined,
                employer_name: undefined,
                years: undefined,
                gross_income: undefined,
                salary_type: IApplication_1.EmploymentSalaryEnum.SALARY_NET_MONTHLY,
            },
            expenditure: {
                HousingCosts: undefined,
                DependantsCosts: undefined,
            },
            number_dependants: undefined,
            marital_status: undefined,
            nationality: {
                is_british: undefined,
                nationality: undefined,
                entry_date: undefined,
                permission_to_remain: undefined,
                visa_expiry: undefined,
            },
            bank_details: {
                account_name: undefined,
                account_number: undefined,
                sort_code: undefined,
                years_at_bank: undefined
            },
            affordability: {
                dependents: undefined,
                household_income: undefined,
                monthly_rent_amount: undefined,
            },
            consent: {},
            marketing: {},
            documents: undefined,
        },
        retailer: {
            id: undefined,
            success_url: undefined,
            fail_url: undefined,
            refer_url: undefined,
            auto_redirect: false,
            bar_code: false
        }
    },
    redemption: {
        status: IRedemptionApi_1.MessageState.NOT_SENT,
        phone_number: undefined,
        link: undefined
    },
    deposit: {
        status: IApplication_1.DepositStateEnum.NOT_PAID,
        state: undefined,
        three_d_secure: undefined,
        card_details: {},
        instore_verification: {
            verification_method: undefined,
            uploaded_id: {
                content: null,
                size: null,
                type: null
            }
        }
    },
    idUpload: {
        selected_id: undefined,
        filesBase64: {
            front_image: null,
            rear_image: null
        },
        checkboxes: {
            own_id: undefined,
            clear_background: undefined,
            whole_id_displayed: undefined,
            clear_of_obstructions: undefined,
            square_on: undefined
        }
    },
    documents: {
        currentDocument: undefined
    },
    branding: {},
    alternativeFinance: {
        differentOption: undefined,
        carefullyConsidered: undefined,
        isAccepted: undefined
    },
    morpheusExperiments: {}
};
