"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIdType = exports.selectIdTypeAction = exports.updateId = exports.updateIdUploadAction = void 0;
var types = require("./actionTypes");
function updateIdUploadAction(idUpload) {
    return { type: types.UPDATE_ID_UPLOAD, idUpload: idUpload };
}
exports.updateIdUploadAction = updateIdUploadAction;
function updateId(idUpload) {
    return function (dispatch) {
        dispatch(updateIdUploadAction(idUpload));
    };
}
exports.updateId = updateId;
function selectIdTypeAction(idType) {
    return { type: types.SELECT_ID_TYPE, idType: idType };
}
exports.selectIdTypeAction = selectIdTypeAction;
function selectIdType(idType) {
    return function (dispatch) {
        dispatch(selectIdTypeAction(idType));
    };
}
exports.selectIdType = selectIdType;
