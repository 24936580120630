"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXHAUSTED_ID_UPLOAD_ATTEMPTS_PAGE = exports.TELEPHONE_END_PAGE = exports.TELEPHONE_ALTERNATIVE_FINANCE = exports.TELEPHONE_SCRIPT_PAGE = exports.COMPLETE_VERIFIED_PAGE = exports.COMPLETE_FULFILLED_PAGE = exports.INSTORE_FULFIL = exports.ALTERNATIVE_FINANCE_DECLINE = exports.ALTERNATIVE_FINANCE = exports.AMENDMENT_CONFIRMATION = exports.AMENDMENT_DECLINED = exports.PAY_DEPOSIT = exports.DOCUMENTS_INSTRUCTION_PAGE = exports.DOCUMENTS_SIGN_PAGE = exports.DOCUMENTS_PAGE = exports.PREQUALIFICATION_PAGE = exports.CANCEL_PAGE = exports.APPLY = exports.ID_FAILURE = exports.ID_SUCCESS = exports.ID_UPLOAD_PAGE = exports.INSTORE_ID_UPLOAD = exports.NO_DECISION_PAGE = exports.REFER_PAGE = exports.COMPLETE_PAGE = exports.OFFER_PAGE = exports.FINANCIALS_PAGE = exports.RESIDENTIAL_STATUS_PAGE = exports.PERSONAL_DETAILS_PAGE = exports.USER_EXIT_PAGE = exports.DECLINE_PAGE = exports.EXPIRED_APP_PAGE = exports.ERROR_PAGE = void 0;
exports.ERROR_PAGE = 'ERROR_PAGE';
exports.EXPIRED_APP_PAGE = 'EXPIRED_APP_PAGE';
exports.DECLINE_PAGE = 'DECLINE_PAGE';
exports.USER_EXIT_PAGE = 'USER_EXIT_PAGE';
exports.PERSONAL_DETAILS_PAGE = 'PERSONAL_DETAILS_PAGE';
exports.RESIDENTIAL_STATUS_PAGE = 'RESIDENTIAL_STATUS_PAGE';
exports.FINANCIALS_PAGE = 'FINANCIALS_PAGE';
exports.OFFER_PAGE = 'OFFER_PAGE';
exports.COMPLETE_PAGE = 'COMPLETE_PAGE';
exports.REFER_PAGE = 'REFER_PAGE';
exports.NO_DECISION_PAGE = 'NO_DECISION_PAGE';
exports.INSTORE_ID_UPLOAD = 'INSTORE_ID_UPLOAD';
exports.ID_UPLOAD_PAGE = 'ID_UPLOAD_PAGE';
exports.ID_SUCCESS = 'ID_SUCCESS';
exports.ID_FAILURE = 'ID_FAILURE';
exports.APPLY = 'APPLY';
exports.CANCEL_PAGE = 'CANCEL_PAGE';
exports.PREQUALIFICATION_PAGE = 'PREQUALIFICATION_PAGE';
exports.DOCUMENTS_PAGE = 'DOCUMENTS_PAGE';
exports.DOCUMENTS_SIGN_PAGE = 'DOCUMENTS_SIGN_PAGE';
exports.DOCUMENTS_INSTRUCTION_PAGE = 'DOCUMENTS_INSTRUCTION_PAGE';
exports.PAY_DEPOSIT = 'PAY_DEPOSIT';
exports.AMENDMENT_DECLINED = 'AMENDMENT_DECLINED';
exports.AMENDMENT_CONFIRMATION = 'AMENDMENT_CONFIRMATION';
exports.ALTERNATIVE_FINANCE = 'ALTERNATIVE_FINANCE';
exports.ALTERNATIVE_FINANCE_DECLINE = 'ALTERNATIVE_FINANCE_DECLINE';
exports.INSTORE_FULFIL = 'INSTORE_FULFIL';
exports.COMPLETE_FULFILLED_PAGE = 'COMPLETE_FULFILLED_PAGE';
exports.COMPLETE_VERIFIED_PAGE = 'COMPLETE_VERIFIED_PAGE';
exports.TELEPHONE_SCRIPT_PAGE = 'TELEPHONE_SCRIPT_PAGE';
exports.TELEPHONE_ALTERNATIVE_FINANCE = 'TELEPHONE_ALTERNATIVE_FINANCE';
exports.TELEPHONE_END_PAGE = 'TELEPHONE_END_PAGE';
exports.EXHAUSTED_ID_UPLOAD_ATTEMPTS_PAGE = 'EXHAUSTED_ID_UPLOAD_ATTEMPTS_PAGE';
