"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var deko_utilities_1 = require("deko-utilities");
var formatYear = deko_utilities_1.date.formatYear, leadingZero = deko_utilities_1.date.leadingZero;
function MonthYearInput(_a) {
    var name = _a.name, label = _a.label, value = _a.value, handleChange = _a.handleChange, validateMultiInput = _a.validateMultiInput, errors = _a.errors, className = _a.className, _b = _a.twoDigitInput, twoDigitInput = _b === void 0 ? false : _b;
    var getKey = function (key) { return name + "_" + key; };
    var keyYear = getKey('year');
    var keyMonth = getKey('month');
    var dateValue = __assign({ month: '', year: '' }, value);
    var validityConfig = new Map();
    validityConfig.set(keyMonth, {
        validRegex: /^\d{1,2}$/,
        length: 2,
    });
    validityConfig.set(keyYear, {
        validRegex: /^\d{1,4}$/,
        length: 4,
    });
    if (twoDigitInput) {
        validityConfig.set(keyYear, {
            validRegex: /^\d{1,2}$/,
            length: 2,
        });
    }
    var handleDateChange = function (event, onBlur) {
        if (onBlur === void 0) { onBlur = false; }
        var _a = event.target, key = _a.name, val = _a.value;
        var keyOrDefaultYear = function (test, defaultVal) { return (key === test) ? formatYear(val) : defaultVal; };
        var keyOrDefaultDayMonth = function (test, defaultVal) { return (key === test) ? leadingZero(val) : defaultVal; };
        var keyOrDefault = function (test, defaultVal) { return (key === test) ? val : defaultVal; };
        var newValue = {
            year: keyOrDefault(keyYear, dateValue.year),
            month: keyOrDefault(keyMonth, dateValue.month),
            day: '01',
        };
        if (!twoDigitInput && onBlur) {
            newValue = {
                year: keyOrDefaultYear(keyYear, dateValue.year),
                month: keyOrDefaultDayMonth(keyMonth, dateValue.month),
                day: '01'
            };
        }
        handleChange({
            target: {
                name: name,
                value: newValue
            }
        });
    };
    var wrapperClass = 'text-input multi-input-wrapper';
    if (className) {
        wrapperClass += " " + className;
    }
    var multiInputConfig = {
        inputAnyOrder: true,
        validityConfig: validityConfig,
    };
    var handleBlur = function (e) {
        handleDateChange(e, true);
        // if new focus target is another of our date fields
        if (e.relatedTarget && [keyMonth, keyYear].indexOf(e.relatedTarget.name) !== -1 || validateMultiInput == null) {
            return;
        }
        if (typeof validateMultiInput === 'function') {
            setTimeout(function () { return validateMultiInput({ target: { name: name } }); }, 10);
        }
    };
    return (React.createElement(deko_common_components_1.CommonInput, { name: name, label: label, className: wrapperClass, errors: errors, showLabel: false },
        React.createElement(deko_common_components_1.MultiInput, { config: multiInputConfig },
            React.createElement("input", { type: 'text', pattern: '[0-9]*', id: keyMonth, name: keyMonth, className: 'month', placeholder: 'mm', value: dateValue.month, onChange: handleDateChange, onBlur: handleBlur, "aria-label": label + " month" }),
            React.createElement("input", { type: 'text', pattern: '[0-9]*', id: keyYear, name: keyYear, className: 'year', placeholder: (twoDigitInput) ? 'yy' : 'yyyy', value: dateValue.year, onChange: handleDateChange, onBlur: handleBlur, "aria-label": label + " year" }))));
}
exports.default = MonthYearInput;
