"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicFinanceInfo = void 0;
var React = require("react");
var currencyFormat_1 = require("../../../common/utilities/currencyFormat");
var styles_1 = require("./styles");
var DynamicFinanceInfo = function (_a) {
    var finance = _a.finance;
    return (React.createElement(React.Fragment, null, (finance === null || finance === void 0 ? void 0 : finance.product) && (React.createElement(React.Fragment, null,
        React.createElement("p", null, "The finance option you would like to apply for is:"),
        React.createElement(styles_1.BoldParagraph, null,
            finance.product.apr,
            "% APR Representative over ",
            finance.product.loan_term,
            " months"),
        React.createElement(styles_1.BoldParagraph, null,
            "Your deposit payment today will be ",
            currencyFormat_1.formatPounds(finance.deposit.amount),
            " and the total amount payable will be ",
            currencyFormat_1.formatPounds(finance.product.loan_amount),
            ". You will then need to make ",
            finance.product.loan_term,
            " monthly payments of ",
            currencyFormat_1.formatPounds(BigInt(finance.product.monthly_instalment)),
            ". This means you will pay ",
            currencyFormat_1.formatPounds(finance.product.interest_payable),
            " interest in total. You will be able to pay more if you can afford to, which will mean you repay the loan earlier.")))));
};
exports.DynamicFinanceInfo = DynamicFinanceInfo;
