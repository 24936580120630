"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var PullOutButton_1 = require("./template/PullOutButton");
require("./../styles/deko-styles/components/Drawer.scss");
var Drawer = /** @class */ (function (_super) {
    __extends(Drawer, _super);
    function Drawer(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleVisibility = function () { return _this.setState({ visible: !_this.state.visible }); };
        _this.state = {
            visible: false
        };
        return _this;
    }
    Drawer.prototype.render = function () {
        var containerClass = ['container', 'drawer-container', 'show-xs'];
        if (this.props.children) {
            containerClass.push("children-" + (this.state.visible ? 'visible force-show' : 'not-visible'));
        }
        else {
            containerClass.push('children-none');
        }
        var arrowDirection = this.state.visible ? 'up' : 'down';
        return (React.createElement("div", { className: containerClass.join(' ') },
            this.props.header,
            React.createElement("div", { className: 'children' }, this.state.visible && this.props.children),
            this.props.children && React.createElement(PullOutButton_1.default, { handleClick: this.toggleVisibility, arrowDirection: arrowDirection })));
    };
    return Drawer;
}(React.Component));
exports.default = Drawer;
