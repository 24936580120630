"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var PropTypes = require("prop-types");
var react_redux_1 = require("react-redux");
var ApplicationForm_1 = require("./form/ApplicationForm");
var ApplicationFormValidator_1 = require("../../validators/ApplicationFormValidator");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var EntityForm_1 = require("../../../common/components/form/EntityForm");
var Page_1 = require("../../components/Page");
var creditApplicationActions_1 = require("../../../common/actions/creditApplicationActions");
var ValidationManager_1 = require("../../../common/validation/ValidationManager");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var Summary_1 = require("../../components/Summary");
var SummaryProvider_1 = require("../../../common/components/SummaryProvider");
var FastTrack_1 = require("../../../common/components/FastTrack");
var FastTrackData_1 = require("../../components/FastTrackData");
var navigationActions_1 = require("../../../common/actions/navigationActions");
var redux_1 = require("redux");
var navigationActions_2 = require("../../actions/navigationActions");
var handleSubmit = function () {
    return creditApplicationActions_1.updateThenSubmit();
};
var Application = /** @class */ (function (_super) {
    __extends(Application, _super);
    function Application(props) {
        var _this = _super.call(this, props) || this;
        var consent = _this.props.application.consumer.consent ? _this.props.application.consumer.consent : {};
        if (_this.props.application.telephone_application && !consent.submit) {
            _this.props.actions.dispatchNavigation(navigationActions_1.TELEPHONE_SCRIPT_PAGE);
        }
        return _this;
    }
    Application.prototype.getChildContext = function () {
        var validator = __assign({}, ApplicationFormValidator_1.default);
        var name = this.props.application.lender.name;
        var lenderNames = [
            'Klarna',
            'Braemar Finance',
            'Close Brothers Retail Finance'
        ];
        if (lenderNames.indexOf(name) === -1) {
            validator['consent.klarna_extra'] = {};
        }
        var branding = this.props.branding;
        if (!branding.lender_is_panel) {
            validator['affordability.dependants'] = {};
            validator['affordability.monthly_rent_amount.amount'] = {};
            validator['affordability.household_income.amount'] = {};
        }
        return { validationManager: new ValidationManager_1.default(validator), entityName: 'application.consumer' };
    };
    Application.prototype.render = function () {
        var _this = this;
        return (React.createElement(Page_1.default, { mainCols: 5, mainOffset: 2, sidebarContent: this.renderSidebarContent() },
            React.createElement(EntityForm_1.default, { className: 'apply', entityName: 'application.consumer', updateEntity: creditApplicationActions_1.updateConsumer, handleSubmit: handleSubmit }, function (_a) {
                var entity = _a.entity, handleChange = _a.handleChange, asyncRunning = _a.asyncRunning;
                return React.createElement(ApplicationForm_1.default, { entity: entity, handleChange: handleChange, status: _this.props.status, asyncRunning: asyncRunning, lender: _this.props.application.lender, retailer: _this.props.application.retailer, telephoneApplication: _this.props.application.telephone_application, branding: _this.props.branding });
            })));
    };
    Application.prototype.renderSidebarContent = function () {
        if (__PRODUCTION__) {
            return null;
        }
        var renderSummary = function (summaryProps) { return (React.createElement(Summary_1.default, __assign({}, summaryProps))); };
        var summaryProviderElement = React.createElement(SummaryProvider_1.default, { render: renderSummary });
        return (React.createElement("div", null,
            summaryProviderElement,
            React.createElement(FastTrack_1.default, { completeConsumer: FastTrackData_1.completeConsumer, scenarios: FastTrackData_1.scenarios })));
    };
    Application.childContextTypes = {
        validationManager: PropTypes.instanceOf(ValidationManager_1.default),
        entityName: PropTypes.string
    };
    return Application;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application,
    branding: state.branding,
    morpheusExperiments: state.morpheusExperiments
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_2.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Application);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.NO_DECISION])(connected);
