"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTooltip = void 0;
var React = require("react");
var ConnectedTooltip_1 = require("../../../../common/components/form/ConnectedTooltip");
var tooltipIcon = require('../../../assets/tooltip-icon.svg');
var getTooltip = function (tooltip) {
    return (React.createElement(ConnectedTooltip_1.default, { name: tooltip, message: tooltips[tooltip].message, icon: tooltipIcon, ariaAttributes: tooltips[tooltip].ariaAttributes }));
};
exports.getTooltip = getTooltip;
var tooltips = {
    forename: {
        message: "Enter your first name exactly as it appears on any official documents (e.g. driving\nlicence or passport).",
        ariaAttributes: { 'aria-label': 'First name tooltip' }
    },
    email: {
        message: "We'll send your credit agreement documents and deposit receipt to this email address. Please double check\n    that it's entered correctly.",
        ariaAttributes: { 'aria-label': 'Email address tooltip' }
    },
    salary: {
        message: "This is your annual income before tax and other deductions.",
        ariaAttributes: { 'aria-label': 'Annual pre tax income tooltip' }
    },
    household: {
        message: "This is the annual income for all members of your household before tax and other deductions.\n    This should include your income. Providing optional information can help the lender make a more informed\n    decision on your application.",
        ariaAttributes: { 'aria-label': 'Annual household income tooltip' }
    },
    dependants: {
        message: "Dependants are people who rely on you financially, such as children or other family members.",
        ariaAttributes: { 'aria-label': 'Dependents tooltip' }
    },
    monthly_rent_amount: {
        message: "This is the total household mortgage repayment or rent amount that you pay each month.",
        ariaAttributes: { 'aria-label': 'Mortgage or rent tooltip' }
    },
    sortCode: {
        message: "This six-digit code can be found on your bank card or statements. It's usually grouped\nin pairs, e.g. 00-00-00.",
        ariaAttributes: { 'aria-label': 'Sort code tooltip' }
    },
    accountNumber: {
        message: "This eight-digit code can be found on your bank card or statements.",
        ariaAttributes: { 'aria-label': 'Account number tooltip' }
    },
    quickfill: {
        message: "By uploading your ID now, you can save on uploading it later and save time"
            + " filling out details in the application form.",
        ariaAttributes: { 'aria-label': 'Account number tooltip' }
    }
};
