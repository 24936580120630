"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fulfilApplication = exports.acceptAmendment = exports.submitSignedDocuments = exports.submitInstoreIdUpload = exports.cancelApplication = exports.loadApplication = exports.updateThenSubmit = exports.loadBranding = exports.updateConsumer = exports.signDocument = exports.signDocumentAction = exports.updateConsumerAction = exports.updateStatus = exports.loadApplicationSuccessAction = exports.loadBrandingSuccessAction = void 0;
var deko_utilities_1 = require("deko-utilities");
var types = require("./actionTypes");
var inversify_types_1 = require("../inversify.types");
var navigationActions_1 = require("./navigationActions");
var inversify_container_1 = require("../inversify.container");
var errorsActions_1 = require("./errorsActions");
var asyncActions_1 = require("./asyncActions");
var ApiErrorKeys_1 = require("../api/CreditApplication/ApiErrorKeys");
var alternativeFinanceActions_1 = require("./alternativeFinanceActions");
var creditApplicationService_1 = require("../services/creditApplicationService");
var getDispatchNavigation = function () {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
};
var loadBrandingSuccessAction = function (branding) {
    return { type: types.LOAD_BRANDING_SUCCESS, branding: branding };
};
exports.loadBrandingSuccessAction = loadBrandingSuccessAction;
var loadApplicationSuccessAction = function (creditApplication) {
    return { type: types.LOAD_APPLICATION_SUCCESS, creditApplication: creditApplication };
};
exports.loadApplicationSuccessAction = loadApplicationSuccessAction;
var updateStatus = function (status) {
    return { type: types.UPDATE_STATUS, status: status };
};
exports.updateStatus = updateStatus;
var updateConsumerAction = function (consumer) {
    return { type: types.UPDATE_CONSUMER, consumer: consumer };
};
exports.updateConsumerAction = updateConsumerAction;
var signDocumentAction = function (type) {
    return { type: types.SIGN_DOCUMENT, documentType: type };
};
exports.signDocumentAction = signDocumentAction;
var signDocument = function (type) { return exports.signDocumentAction(type); };
exports.signDocument = signDocument;
var updateConsumer = function (consumer) { return function (dispatch) { return dispatch(exports.updateConsumerAction(consumer)); }; };
exports.updateConsumer = updateConsumer;
var handleErrorResult = function (result, dispatch) {
    var _a;
    // Form pages that encounter 400 errors should display these errors in form and give the customer chance to fix
    if (result.error && result.error.status === 400 && result.error.error) {
        dispatch(errorsActions_1.replaceErrors((_a = {}, _a[ApiErrorKeys_1.ERROR_SERVER] = result.error.error.message, _a)));
        dispatch(asyncActions_1.asyncFailure());
        deko_utilities_1.debugLog({ message: 'Warning: Server side errors displayed in form.', error: result.error.error });
    }
    else {
        var e = result ? result.error : 'No result';
        dispatch(asyncActions_1.asyncFailure());
        dispatch(getDispatchNavigation()(result.navigateTo ? result.navigateTo : navigationActions_1.ERROR_PAGE));
        deko_utilities_1.debugLog({ message: 'Request failed', error: e });
        throw e;
    }
};
var loadBranding = function (applicationId, type) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, creditApplicationService_1.loadBrandingService(applicationId, type)];
                case 1:
                    result = _a.sent();
                    if (result && result.outcome === 'success') {
                        dispatch(exports.loadBrandingSuccessAction(result.payload));
                        return [2 /*return*/, result.payload];
                    }
                    else {
                        handleErrorResult(result, dispatch);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.loadBranding = loadBranding;
var callWithResultHandling = function (fn, dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(asyncActions_1.asyncBegin());
                return [4 /*yield*/, fn()];
            case 1:
                result = _a.sent();
                if (result && result.outcome === 'success') {
                    dispatch(exports.loadApplicationSuccessAction(result.payload));
                    if (result.navigateTo) {
                        dispatch(getDispatchNavigation()(result.navigateTo));
                    }
                    dispatch(asyncActions_1.asyncSuccess());
                    return [2 /*return*/, result.payload];
                }
                else {
                    handleErrorResult(result, dispatch);
                }
                return [2 /*return*/];
        }
    });
}); };
var updateThenSubmit = function () {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var application, brandingKey;
        return __generator(this, function (_a) {
            application = getState().application;
            brandingKey = getState().branding.branding_key;
            return [2 /*return*/, callWithResultHandling(function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, creditApplicationService_1.submitApplicationService(application, brandingKey)];
                    });
                }); }, dispatch)];
        });
    }); };
};
exports.updateThenSubmit = updateThenSubmit;
var loadApplication = function (token) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, callWithResultHandling(function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, creditApplicationService_1.loadApplicationService(token)];
                    });
                }); }, dispatch)];
        });
    }); };
};
exports.loadApplication = loadApplication;
var cancelApplication = function (application, reason, code) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, callWithResultHandling(function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, creditApplicationService_1.cancelApplicationService(application.application_id, reason, code)];
                    });
                }); }, dispatch)];
        });
    }); };
};
exports.cancelApplication = cancelApplication;
var submitInstoreIdUpload = function (application, images) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, callWithResultHandling(function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, creditApplicationService_1.idUploadService(application.application_id, images)];
                    });
                }); }, dispatch)];
        });
    }); };
};
exports.submitInstoreIdUpload = submitInstoreIdUpload;
var submitSignedDocuments = function (application) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, callWithResultHandling(function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, creditApplicationService_1.signDocumentsService(application.application_id)];
                    });
                }); }, dispatch)];
        });
    }); };
};
exports.submitSignedDocuments = submitSignedDocuments;
var acceptAmendment = function (application) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, callWithResultHandling(function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        dispatch(alternativeFinanceActions_1.acceptAlternativeFinanceAction());
                        return [2 /*return*/, creditApplicationService_1.acceptAmendmentService(application)];
                    });
                }); }, dispatch)];
        });
    }); };
};
exports.acceptAmendment = acceptAmendment;
var fulfilApplication = function (application) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, callWithResultHandling(function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, creditApplicationService_1.fulfilApplicationService(application.application_id)];
                    });
                }); }, dispatch)];
        });
    }); };
};
exports.fulfilApplication = fulfilApplication;
