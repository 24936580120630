"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPounds = void 0;
var formatPounds = function (amount, inputUnits) {
    if (amount === void 0) { amount = 0; }
    if (inputUnits === void 0) { inputUnits = 'pence'; }
    var amountInPounds = inputUnits === 'pence' ? BigInt(amount) / BigInt(100) : amount;
    return amountInPounds.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
};
exports.formatPounds = formatPounds;
