"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Tooltips = require("../../pages/application/form/applicationFormTooltips");
var deko_common_components_1 = require("deko-common-components");
require("../../styles/components/QuickFill.scss");
var QuickFillButton = function (_a) {
    var clickHandler = _a.clickHandler;
    return React.createElement("div", { className: 'quick-fill has-tooltip' },
        React.createElement("p", null,
            React.createElement("strong", null, "Quickfill (optional)"),
            " - Use your ID to save time filling out your application"),
        React.createElement("div", { className: 'field' },
            React.createElement(deko_common_components_1.Button, { handleClick: clickHandler, className: 'secondary large no-margin-top quick-fill' }, "QUICKFILL WITH ID"),
            Tooltips.getTooltip('quickfill')));
};
var QuickFillBanner = function (_a) {
    var clickHandler = _a.clickHandler;
    return React.createElement("div", { className: 'quick-fill-banner', onClick: clickHandler },
        React.createElement("span", null, "Thank you for your interest in QuickFill, this feature is coming soon! Please, continue with your application."),
        React.createElement("svg", { viewBox: '0 0 12 16', version: '1.1', "aria-hidden": 'true' },
            React.createElement("path", { d: 'M12 5l-8 8-4-4 1.5-1.5L4 10l6.5-6.5L12 5z' })));
};
var QuickFill = function () {
    var _a = React.useState(false), isClicked = _a[0], setIsClicked = _a[1];
    var _b = React.useState(false), isDismissed = _b[0], setIsDismissed = _b[1];
    var clickHandle = function (event) { return setIsClicked(true); };
    var dismissHandle = function (event) { return setIsDismissed(true); };
    return isDismissed ? React.createElement(React.Fragment, null)
        :
            React.createElement(React.Fragment, null,
                !isClicked ? React.createElement(QuickFillButton, { clickHandler: clickHandle }) : React.createElement(React.Fragment, null),
                isClicked ? React.createElement(QuickFillBanner, { clickHandler: dismissHandle }) : React.createElement(React.Fragment, null));
};
exports.default = QuickFill;
