"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var PageTitle_1 = require("../../common/components/form/PageTitle");
var Footer_1 = require("../components/template/Footer");
require("../styles/pages/LegalPages.scss");
var logo = require('../../common/assets/deko-logo-one-colour.svg');
var TermsOfServicePage = /** @class */ (function (_super) {
    __extends(TermsOfServicePage, _super);
    function TermsOfServicePage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TermsOfServicePage.prototype.render = function () {
        return (React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'legal-page' },
                React.createElement("div", { className: 'tos-page container' },
                    React.createElement(PageTitle_1.default, { title: 'Deko | Terms of Service' }),
                    React.createElement("div", { className: 'header' },
                        React.createElement(deko_common_components_1.Image, { className: 'logo', src: logo })),
                    React.createElement("h1", null, "Terms Of Service"),
                    React.createElement("p", null, "Deko is a trading name of Pay4Later Limited of 100 Liverpool Street, London, EC2M\u00A02AT. The terms \u2018we\u2019, \u2018us\u2019 and \u2018our\u2019 refer to Deko and the terms \u2018you\u2019 and \u2018your\u2019 refer to a user of the website."),
                    React.createElement("p", null,
                        "The credit broking services offered as part of this website is a free service to you. Your use of this website constitutes your agreement to all the Terms of Service contained in this document, our Privacy Policy and Security Statement and other provisions or notices published elsewhere on our site (together, the",
                        React.createElement("strong", null, " \u2018Agreement\u2019"),
                        "). If you do not agree to the terms of the Agreement, you should not use the website or credit broking services."),
                    React.createElement("h2", null, "Use of the website"),
                    React.createElement("ol", null,
                        React.createElement("li", null, "The information that is made available on this website is intended for general information purposes only and is provided on an 'as is' basis without any warranties of any kind. We do not accept any liability for any loss arising as a result of reliance on the information contained on this website."),
                        React.createElement("li", null, "This website is owned by us and is protected by copyright and database rights. You acknowledge that all trademarks (registered or unregistered) used in providing the services shall remain our property at all times."),
                        React.createElement("li", null, "You shall not copy, adapt, exploit or otherwise use the information contained on this website in any way, save for the downloading and temporary storage of one or more of these pages for the purpose of viewing on a personal computer or terminal, provided that you do not delete any copyright and other proprietary notices contained therein."),
                        React.createElement("li", null, "Whilst we retain the right to establish any hypertext links between this website and any third party website at our discretion, you agree that you will not create any hypertext links or deep links between this website and any third party site without our express written consent."),
                        React.createElement("li", null, "If you choose to use such a link as detailed in Clause 4 then you will leave this website. Once you leave this website, whether or not you realise that you are leaving, we are no longer in any way responsible for the material on the other website that you enter. We exclude to the fullest extent permitted by law all liability that may arise in connection with or as a result of such external website material causing any damage, costs, injury of financial loss of any kind."),
                        React.createElement("li", null, "You must not disclose to or allow any other person to use your user name and password."),
                        React.createElement("li", null,
                            "You agree that you will not use this website to:",
                            React.createElement("ul", null,
                                React.createElement("li", null, "Commit or encourage any criminal offence, including but not limited to harming a minor in any way and stalking or harassing any other person;"),
                                React.createElement("li", null, "Send or receive any material which is offensive, or which may be abusive, indecent, obscene or menacing, or in breach of confidence, copyright, privacy or any other rights. In the event we believe any material falls within any or all of these categories we shall be entitled to remove it;"),
                                React.createElement("li", null, "Collect or store personal data about other users;"),
                                React.createElement("li", null, "Insert or knowingly or recklessly transmit or distribute a virus, worm, trojan horse, time bomb, trap door or any other computer code, files or programs or repetitive requests designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or to diminish the quality of, interfere with the performance of or impair the functionality of this website;"),
                                React.createElement("li", null, "Upload, post, e-mail or otherwise transmit or post links to any content that facilitates hacking;"),
                                React.createElement("li", null, "Hack into any aspect of this website;"),
                                React.createElement("li", null, "Upload, post, e-mail or otherwise transmit links to any content that infringes the intellectual property rights of any third party;"),
                                React.createElement("li", null, "Circumvent, or attempt to seek to circumvent, any of the security safeguards of this website;"),
                                React.createElement("li", null, "Cause annoyance to other people accessing this website;"),
                                React.createElement("li", null, "Send any unsolicited advertising or other promotional material, commonly referred to as 'spam', 'junk mail', 'chain letters', 'pyramid schemes' or any other form of solicitation by e-mail or by any other electronic means;"),
                                React.createElement("li", null, "Send e-mail or any other type of electronic message with the intention or result of affecting the performance or functionality of any computer facilities;"),
                                React.createElement("li", null, "Permit any third party to do any of the above."))),
                        React.createElement("li", null, "While we try to ensure that all information on this website is accurate and up to date, errors may occur and we accept no responsibility for such errors. If we discover an error we will amend the website as soon as reasonably possible."),
                        React.createElement("li", null, "We neither warrant nor represent that your use of this website will not infringe the rights of third parties."),
                        React.createElement("li", null, "You have sole responsibility for adequate protection and backup of data and/or equipment used by you in connection with this website and agree not make a claim against us for lost data, re-run time, inaccurate output, work delays or lost profits resulting from the use of such materials."),
                        React.createElement("li", null, "We reserve the right to refuse access to this website or any component part of it. We may at any time, at our sole discretion refuse access to certain areas of this website to any individual we choose - this refusal of access may take whatever form we deem suitable."),
                        React.createElement("li", null, "We make no representations that materials in this website are appropriate or available for use in other locations. Those who choose to access this website from other locations do so at their own risk and are responsible for compliance with any and all local laws, if and to the extent local laws are applicable. No material from this website may be downloaded, exported or re-exported into (or to a national or resident of) any countries that are subject to UK exporting restrictions or legal or socio-economic sanctions.")),
                    React.createElement("h2", null, "Credit Broking"),
                    React.createElement("ol", null,
                        React.createElement("li", null, "We will act as a credit broker by approaching lenders with whom we have a relationship and who offer the type of credit product you are seeking to apply for."),
                        React.createElement("li", null, "We will collect your information and then use it (alongside the results of any credit reference searches) in accordance with our Privacy Policy to our selected primary lender to determine their willingness to provide you with the product you have requested, and (if they are) the terms on which they are willing to provide it. If you do not meet the financial criteria of that primary lender we may, if we have relationships with other lenders, approach a series of other lenders to determine their willingness to provide you with the product you have requested, and (if they are) the terms on which they are willing to provide it."),
                        React.createElement("li", null, "You agree that you will take all reasonable steps to ensure that you provide full and accurate information. If you become aware that any information you have provided is false, inaccurate or misleading, you must inform us as soon as possible."),
                        React.createElement("li", null, "If false or inaccurate information is provided, or if a reasonable suspicion of fraud or any other criminal offence is identified, details may be shared with fraud prevention agencies, law enforcement agencies, regulatory bodies and other interested parties who may access and use this information. We may access and use the information recorded by fraud prevention agencies to prevent fraud and money laundering."),
                        React.createElement("li", null, "A lender\u2019s acceptance criteria may require that they carry out a further credit search to verify your identity, confirm your address, assess your creditworthiness and to prevent and detect financial crime. In doing so details of this search may be left on your credit files. This search can be viewed by the credit reference agency and any other organisation that conducts a credit search, including other lenders. These organisations may use this information in the future to help make credit decisions, prevent financial crime and to trace debtors."),
                        React.createElement("li", null, "If you are accepted by a lender, you will be provided with pre-contract information, a credit agreement, and any further terms and conditions and information as required by legislation."),
                        React.createElement("li", null, "We will not charge you a fee for carrying out the credit broking services. If you enter into a credit agreement with a lender, we will be paid a fee by that lender. If you request, we will tell you, in good time before you enter into the credit agreement, the amount (or if the precise amount cannot be known at the time of request, the likely amount) of any fee payable to us by the lender.")),
                    React.createElement("h2", null, "General"),
                    React.createElement("ol", null,
                        React.createElement("li", null, "We may amend this Agreement and the products, services and prices detailed on this website from time to time as we see fit. If we make any substantial changes we will notify you by posting a prominent announcement on our pages. By continuing to use the website after any changes have been made you agree that you will be deemed to have accepted the changes. Some of the provisions contained in these terms may also be supplemented and/or superseded by provisions or notices published elsewhere on our website."),
                        React.createElement("li", null, "If you have any questions about the Agreement or any other aspect of this website please contact us at Deko, 100 Liverpool Street, London, EC2M\u00A02AT."),
                        React.createElement("li", null, "We are authorised and regulated by the Financial Conduct Authority as a Credit Broker. Our Firm reference number is 728646."),
                        React.createElement("li", null,
                            "We aim to provide an excellent service to all customers. If you feel that we have failed to provide our service or wish to make a formal complaint of any kind, you can write to us at the address listed above. We will handle and respond to your compliant in line with our complaints handling process.",
                            React.createElement("p", null, "If we do not resolve your complaint internally to your satisfaction, you may be able to refer your complaint to the Financial Ombudsman Service:"),
                            React.createElement("div", { className: 'contact-details' },
                                React.createElement("p", null, "Financial Ombudsman Service"),
                                React.createElement("p", null, "Exchange Tower"),
                                React.createElement("p", null, "London"),
                                React.createElement("p", null, "E14 9SR"),
                                React.createElement("p", null,
                                    React.createElement("a", { href: 'http://www.financial-ombudsman.org.uk/', target: '_blank' }, "www.financial-ombudsman.org.uk")))),
                        React.createElement("li", null, "The Agreement shall be governed by and construed in accordance with English law and subject to the exclusive jurisdiction of the English Courts, or by Scots Law and the exclusive jurisdiction of the Scottish Courts if you are resident in Scotland at the time of entering the Agreement with us"))),
                React.createElement(Footer_1.default, null))));
    };
    return TermsOfServicePage;
}(React.Component));
exports.default = TermsOfServicePage;
