"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var deko_utilities_1 = require("deko-utilities");
var ValidationManager = /** @class */ (function () {
    function ValidationManager(validator) {
        this.validator = validator;
    }
    /**
     * Test the Addresses are valid.
     * @param {string[]} list
     * @param state
     * @param {Object} err
     * @returns {valid: boolean, errors {}}
     */
    ValidationManager.prototype.runValidationAddress = function (list, state, err) {
        var _this = this;
        if (!list) {
            return true;
        }
        var valid = true;
        var errors = list.reduce(function (acc, name) {
            var fieldValid = _this.isFieldValid(name, _this.getFieldValue(state, name), state);
            valid = (fieldValid[name] !== true) ? false : valid;
            return __assign(__assign({}, acc), fieldValid);
        }, {});
        return { valid: valid, errors: errors };
    };
    /**
     * Test the form is valid before submit
     * @param state
     * @returns {boolean | {[p: string]: string}}
     */
    ValidationManager.prototype.isFormValid = function (state) {
        var _this = this;
        var list = this.getFieldNames(this.validator);
        if (!list) {
            return true;
        }
        var valid = true;
        var errors = list.reduce(function (acc, name) {
            var fieldValid = _this.isFieldValid(name, _this.getFieldValue(state, name), state);
            valid = (fieldValid[name] !== true) ? false : valid;
            return __assign(__assign({}, acc), fieldValid);
        }, {});
        return (valid) ? valid : errors;
    };
    /**
     * Test the single field is valid.
     * @param {string} fieldName
     * @param {string | Object} value
     * @param state
     * @returns {}
     */
    ValidationManager.prototype.isFieldValid = function (fieldName, value, state) {
        var _a;
        var fieldRules = this.getFieldRules(fieldName);
        var result = this.validateRules(value, fieldRules, state);
        return _a = {}, _a[fieldName] = result, _a;
    };
    ValidationManager.prototype.getFieldRules = function (name) {
        return this.validator.hasOwnProperty(name) ? this.validator[name] : [];
    };
    /**
     * Validates the fields against the rules defined in the validator
     * @param {string | Object} value
     * @param {Object} rulesToCheck
     * @param state
     * @returns {any | string}
     */
    ValidationManager.prototype.validateRules = function (value, rulesToCheck, state) {
        for (var _i = 0, rulesToCheck_1 = rulesToCheck; _i < rulesToCheck_1.length; _i++) {
            var checkableRule = rulesToCheck_1[_i];
            var ruleName = checkableRule.ruleName;
            if (!deko_utilities_1.rules.hasOwnProperty(ruleName)) {
                throw Error("Unrecognised ruleName: " + ruleName);
            }
            var rule = deko_utilities_1.rules[ruleName];
            var isValid = rule(value, checkableRule.constraint, state);
            if (!isValid) {
                return checkableRule.message;
            }
        }
        return true;
    };
    ValidationManager.prototype.getFieldNames = function () {
        var validators = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            validators[_i] = arguments[_i];
        }
        return validators.reduce(function (acc, val) { return acc.concat(Object.getOwnPropertyNames(val)); }, []);
    };
    ValidationManager.prototype.getFieldValue = function (state, name) {
        return lodash_1.get(state, name);
    };
    return ValidationManager;
}());
exports.default = ValidationManager;
