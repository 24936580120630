"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
require("../../styles/components/form/BaseRadioInput.scss");
function RadioInput(_a) {
    var name = _a.name, label = _a.label, prefix = _a.prefix, value = _a.value, className = _a.className, handleChange = _a.handleChange, validateStandard = _a.validateStandard, layout = _a.layout, errors = _a.errors, options = _a.options, disabled = _a.disabled, tooltip = _a.tooltip, type = _a.type, _b = _a.style
    // Traditional style:
    // ( ) Label 1
    // ( ) Label 2
    // (o) Label 3
    // Modern style:
    // | label 1 | label 2 | label 3 |
    , style = _b === void 0 ? 'MODERN' : _b
    // Traditional style:
    // ( ) Label 1
    // ( ) Label 2
    // (o) Label 3
    // Modern style:
    // | label 1 | label 2 | label 3 |
    ;
    var wrapperClass = ['radio-input'];
    if (className) {
        wrapperClass.push(className);
    }
    var groupClass = ['radio-group'];
    if (layout === 'HORIZONTAL') {
        groupClass.push('horizontal');
    }
    else {
        groupClass.push('vertical');
    }
    if (style === 'MODERN') {
        groupClass.push('modern');
    }
    else {
        groupClass.push('traditional');
    }
    if (disabled) {
        groupClass.push('disabled');
    }
    var _handleChange = handleChange;
    if (type === 'number') {
        _handleChange = function (event) {
            var val = event.target.value;
            handleChange({
                target: {
                    name: event.target.name,
                    value: parseInt(val, 10),
                }
            });
        };
    }
    else if (type === 'bool') {
        _handleChange = function (event) {
            var val = event.target.value;
            handleChange({
                target: {
                    name: event.target.name,
                    value: val === null ? null : (val === 'true'),
                }
            });
        };
    }
    if (tooltip) {
        wrapperClass.push('has-tooltip');
    }
    return (React.createElement(deko_common_components_1.CommonInput, { name: name, label: label, className: wrapperClass.join(' '), errors: errors, disabled: disabled, showLabel: false },
        prefix,
        React.createElement("fieldset", { name: name, role: 'controlgroup' },
            React.createElement("legend", null, label ? label : name),
            React.createElement("div", { className: groupClass.join(' ') }, options.map(function (option) {
                var id = (name + "_" + option.value).toLowerCase();
                var checked = (option.value === value);
                var radioClass = ['radio-button focus-on-child-label'];
                if (checked) {
                    radioClass.push('checked');
                }
                if (disabled) {
                    radioClass.push('disabled');
                }
                return React.createElement("div", { className: radioClass.join(' '), key: name + "_" + option.value },
                    React.createElement("input", { id: id, type: 'radio', name: name, value: option.value, checked: checked, disabled: disabled, onClick: validateStandard, onChange: _handleChange, "aria-label": (label ? label : name) + " " + option.text, "aria-invalid": typeof errors === 'string', "aria-describedby": name + "_error" }),
                    React.createElement("label", { htmlFor: id }, option.text));
            }))),
        tooltip));
}
exports.default = RadioInput;
