"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Col = function (_a) {
    var children = _a.children, className = _a.className, layout = _a.layout, onClick = _a.onClick, testid = _a.testid;
    if (className === undefined) {
        className = '';
    }
    if (layout === undefined) {
        layout = ['xs-12'];
    }
    var prefix = 'col-';
    var classes = layout.map(function (bsClass) {
        return prefix + bsClass;
    });
    classes.push(className);
    return (React.createElement("div", { className: classes.join(' '), "data-testid": testid, onClick: onClick }, children));
};
exports.default = Col;
