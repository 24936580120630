"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldOfferAlternativeFinance = void 0;
function shouldOfferAlternativeFinance(finance) {
    if (finance && finance.product && finance.offer) {
        var isSameProduct = finance.product.product_code === finance.offer.product_code;
        if (isSameProduct)
            return false;
        var isRateChange = finance.product.apr !== finance.offer.apr;
        var isTermChange = finance.product.loan_term !== finance.offer.loan_term;
        var isSameLender = finance.offer.lender_uuid === finance.product.lender_uuid;
        if (!isSameLender)
            return true;
        if (isSameLender && isTermChange)
            return true;
        if (isSameLender && isRateChange && !isTermChange)
            return false;
    }
    return false;
}
exports.shouldOfferAlternativeFinance = shouldOfferAlternativeFinance;
