"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var react_redux_1 = require("react-redux");
var deko_utilities_1 = require("deko-utilities");
var Page_1 = require("../../components/Page");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var ContinueApplicationButton_1 = require("../../../common/components/ContinueApplicationButton");
var SummaryProvider_1 = require("../../../common/components/SummaryProvider");
var Summary_1 = require("../../components/Summary");
require("../../styles/pages/AmendmentPage.scss");
var AmendmentDeclinedPage = /** @class */ (function (_super) {
    __extends(AmendmentDeclinedPage, _super);
    function AmendmentDeclinedPage(props) {
        return _super.call(this, props) || this;
    }
    AmendmentDeclinedPage.prototype.render = function () {
        var finance = this.props.application.finance;
        if (finance == null) {
            throw new TypeError('Finance must be set');
        }
        var total = deko_utilities_1.currency.formatCurrency(finance.offer.total_payable - finance.offer.interest_payable);
        var renderSummary = function (summaryProps) { return (React.createElement(Summary_1.default, __assign({}, summaryProps))); };
        return (React.createElement(Page_1.default, { className: 'amendment-declined-page' },
            React.createElement("h1", null, "The amendment to your offer could not be approved"),
            React.createElement("p", { className: 'amendment-page-copy' },
                "However, your original application for ",
                React.createElement("strong", null,
                    "\u00A3",
                    total),
                " still stands. The original offer is as follows:"),
            React.createElement("div", null,
                React.createElement(Col_1.default, null,
                    React.createElement(SummaryProvider_1.default, { render: renderSummary }))),
            React.createElement("p", { className: 'amendment-page-copy' }, "To continue with your original purchase click the button below."),
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, null,
                    React.createElement(ContinueApplicationButton_1.default, null)))));
    };
    return AmendmentDeclinedPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var connected = react_redux_1.connect(mapStateToProps, function () { return ({}); })(AmendmentDeclinedPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT, IApplication_1.AppStatusEnum.REFER, IApplication_1.AppStatusEnum.VERIFIED])(connected);
