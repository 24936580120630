"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var Page_1 = require("../../components/Page");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
require("../../styles/pages/TheEndPage.scss");
var LenderComponentProvider_1 = require("../../components/LenderComponentProvider");
var isApplicationOfType_1 = require("../../../common/utilities/isApplicationOfType");
var DeclinePage = /** @class */ (function (_super) {
    __extends(DeclinePage, _super);
    function DeclinePage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeclinePage.prototype.componentDidMount = function () {
        window.parent.postMessage({
            message: 'APPLICATION_STATUS_UPDATE',
            data: {
                status: this.props.application.status,
                reference: this.props.application.unique_reference
            }
        }, '*');
    };
    DeclinePage.prototype.render = function () {
        var branding = this.props.branding;
        var credit_application_id = this.props.application.credit_application_id;
        var _a = this.props.application, lender = _a.lender, retailer = _a.retailer;
        var isInstore = isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, this.props.application);
        return (React.createElement(Page_1.default, { className: 'decline-page' },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                        var DeclineContent = _a.DeclineContent;
                        return (React.createElement(DeclineContent, { credit_application_id: credit_application_id, lender: lender, retailer: retailer, context: 'RETAILER', branding: branding, instore: isInstore }));
                    })))));
    };
    return DeclinePage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    branding: state.branding,
    application: state.application
}); };
var connected = react_redux_1.connect(mapStateToProps, {})(DeclinePage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.DECLINE])(connected);
