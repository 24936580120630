"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var IApplication_1 = require("../../api/CreditApplication/IApplication");
var Row_1 = require("../../components/template/Row");
var Loader_1 = require("../../components/Loader");
var depositActions_1 = require("../../actions/depositActions");
var navigationActions_1 = require("../../../magi/actions/navigationActions");
var RequiredStatus_1 = require("../../components/RequiredStatus");
var PageTitle_1 = require("../../components/form/PageTitle");
var DepositProcessPage = /** @class */ (function (_super) {
    __extends(DepositProcessPage, _super);
    function DepositProcessPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DepositProcessPage.prototype.searchParams = function (name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
        if (results == null) {
            return null;
        }
        else {
            return decodeURI(results[1]) || 0;
        }
    };
    DepositProcessPage.prototype.componentDidMount = function () {
        if (window.parent.location.href !== window.location.href) {
            var location_1 = window.location;
            var origin_1 = location_1.protocol + "//" + (location_1.hostname + (location_1.port ? ":" + location_1.port : ''));
            var params = {
                status: this.searchParams('status'),
                error: this.searchParams('error')
            };
            if (params.status) {
                window.parent.postMessage({
                    status: params.status,
                    error: params.error
                }, origin_1);
            }
            else {
                console.error('No status returned from sage pay.');
            }
        }
    };
    DepositProcessPage.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement(PageTitle_1.default, { title: 'Deposit Process' }),
            React.createElement(Row_1.default, { className: 'process-deposit-page' },
                React.createElement(Loader_1.default, { title: 'Processing your deposit' }))));
    };
    return DepositProcessPage;
}(React.PureComponent));
var mapStateToProps = function (state, props) {
    return {
        application: state.application,
        asyncRunning: state.async.asyncRunning
    };
};
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        handle3DSecureResponse: depositActions_1.handle3DSecureResponse,
        dispatchNavigation: navigationActions_1.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(DepositProcessPage);
exports.default = RequiredStatus_1.default([
    IApplication_1.AppStatusEnum.ACCEPT,
    IApplication_1.AppStatusEnum.COMPLETE,
    IApplication_1.AppStatusEnum.VERIFIED,
    IApplication_1.AppStatusEnum.ACTION_CUSTOMER,
    IApplication_1.AppStatusEnum.ACTION_LENDER,
    IApplication_1.AppStatusEnum.REFER
])(connected);
