"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTermUrl = void 0;
var React = require("react");
var config_1 = require("../../../config/config");
var endpoint = config_1.default.depositThreeDSecureCallback;
var generateTermUrl = function (token, pi) {
    if (pi === void 0) { pi = false; }
    return ("" + endpoint + (pi ? 'pi' : '') + "?destination=" + encodeURI(window.location.protocol + "//" + window.location.hostname + (window.location.port ?
        ":" + window.location.port :
        '') + "/" + token + "/deposit/process"));
};
exports.generateTermUrl = generateTermUrl;
var ThreeDSecureForm = /** @class */ (function (_super) {
    __extends(ThreeDSecureForm, _super);
    function ThreeDSecureForm(props) {
        var _this = _super.call(this, props) || this;
        _this.myRef = React.createRef();
        return _this;
    }
    ThreeDSecureForm.prototype.componentDidMount = function () {
        this.myRef.current.submit();
    };
    ThreeDSecureForm.prototype.render = function () {
        var acsUrl = this.props.threeDSecure.acs_url;
        var paReq = this.props.threeDSecure.pa_req;
        var md = this.props.threeDSecure.md;
        return (React.createElement("div", { id: 'pa-frame-wrapper' },
            React.createElement("form", { ref: this.myRef, id: 'pa-form', method: 'post', target: 'pa-frame', action: acsUrl },
                React.createElement("input", { type: 'hidden', name: 'PaReq', value: paReq }),
                React.createElement("input", { type: 'hidden', name: 'MD', value: md }),
                React.createElement("input", { type: 'hidden', name: 'TermUrl', value: exports.generateTermUrl(this.props.token) })),
            React.createElement("iframe", { name: 'pa-frame', id: 'pa-frame', width: 500, height: 500, frameBorder: '0' })));
    };
    return ThreeDSecureForm;
}(React.Component));
exports.default = ThreeDSecureForm;
