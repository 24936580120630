"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
/* tslint:disable-next-line:variable-name */
var Scroll4Progress = function (_a) {
    var className = _a.className, children = _a.children, onScrollToEnd = _a.onScrollToEnd, onScrollProgress = _a.onScrollProgress, onContentRef = _a.onContentRef;
    var checkScrollToEnd = function (element) {
        var tolerance = 5;
        if (!onScrollToEnd) {
            return;
        }
        if (element.offsetParent === null) {
            return;
        }
        var childrenScrollHeight = Object.keys(element.children).reduce(function (acc, child) {
            if (element.children[child].scrollHeight) {
                return acc + element.children[child].scrollHeight;
            }
            return acc;
        }, 0);
        if (element.scrollTop + element.clientHeight >= (childrenScrollHeight - tolerance)) {
            onScrollToEnd();
        }
    };
    var documentScroll = function (event) {
        var element = event.target;
        checkScrollToEnd(element);
        if (element.offsetParent === null) {
            return;
        }
        var scrollHeight = element.scrollHeight - element.clientHeight;
        var scrollPercent = (element.scrollTop / scrollHeight) * 100;
        onScrollProgress(scrollPercent, 100);
    };
    var onRef = function (element) {
        if (element) {
            checkScrollToEnd(element);
            if (onContentRef) {
                onContentRef(element);
            }
        }
    };
    return (React.createElement("div", { className: className + " scroll-child", onScroll: documentScroll, ref: onRef }, children));
};
exports.default = Scroll4Progress;
