"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
require("../styles/pages/ProblemPages.scss");
var BackToRetailerButton_1 = require("../../common/components/BackToRetailerButton");
var ApplicationOutcomes_1 = require("../../common/components/ApplicationOutcomes");
var Page_1 = require("../components/Page");
var problemImg = require('../assets/problem.svg');
var ExhaustedIdUploadAttemptsPage = /** @class */ (function (_super) {
    __extends(ExhaustedIdUploadAttemptsPage, _super);
    function ExhaustedIdUploadAttemptsPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExhaustedIdUploadAttemptsPage.prototype.render = function () {
        return (React.createElement(Page_1.default, { className: 'problem-pages error-page', showSummaryContent: false, pageTitle: 'Deko | Error' },
            React.createElement("div", { className: 'heading' },
                React.createElement("h1", null, "There's no need to upload your ID at this time")),
            React.createElement("div", { className: 'bottom' },
                React.createElement("p", { className: 'standfirst' }, "We have everything we need from you right now. There's no need to provide any additional ID. As soon as checks have been completed we'll send you a confirmation email."),
                React.createElement("div", { className: 'problem-image' },
                    React.createElement(deko_common_components_1.Image, { src: problemImg, altText: 'Problem image' })),
                React.createElement(BackToRetailerButton_1.default, { status: ApplicationOutcomes_1.AppOutcomeEnum.DECLINE }))));
    };
    return ExhaustedIdUploadAttemptsPage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
exports.default = react_redux_1.connect(mapStateToProps, {})(ExhaustedIdUploadAttemptsPage);
