"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var Col_1 = require("../template/Col");
var Row_1 = require("../template/Row");
function NextButton(_a) {
    var children = _a.children, disabled = _a.disabled, ariaAttributes = _a.ariaAttributes, active = _a.active, handleClick = _a.handleClick;
    return (React.createElement(Row_1.default, null,
        React.createElement(Col_1.default, null,
            React.createElement(deko_common_components_1.Button, __assign({ className: 'submit', size: 'large', submit: true, disabled: disabled, active: active, handleClick: handleClick }, ariaAttributes), children))));
}
exports.default = NextButton;
