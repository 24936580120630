"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LenderPhoneScript = void 0;
var React = require("react");
var styles_1 = require("./styles");
var LenderPhoneScript = function (_a) {
    var children = _a.children, lenderName = _a.lenderName;
    return (React.createElement("div", { className: 'script' },
        React.createElement("p", null, "During this call, I have taken you through the payment and finance options available to you, and you have selected to apply for finance. I now need to take you through some important information about the finance application process, and some key terms that will apply to the finance agreement."),
        React.createElement("p", null, "In introducing you to finance, we are acting as a credit broker, not a lender and are authorised and regulated by the Financial Conduct Authority."),
        React.createElement("p", null,
            "We\u2019ve partnered with Deko (the trading name of Pay4Later Ltd), who will process your finance application. Deko is a credit broker, not a lender and will pass your application to their panel of lenders. In this case, your application will be passed to ",
            lenderName,
            ", a trading name of Specialist Finance Ltd. Both Deko and ",
            lenderName,
            " are authorised and regulated by the Financial Conduct Authority."),
        children,
        React.createElement(styles_1.BoldParagraph, null, "0% interest repayable in 12 months or less is not currently regulated by the Financial Conduct Authority."),
        React.createElement("p", null, "To be considered for finance, you will need to meet all of the following criteria:"),
        React.createElement(styles_1.UnorderedList, null,
            React.createElement(React.Fragment, null, "You are 18 or over and will be no older than 75 by the end of the loan term "),
            React.createElement(React.Fragment, null, "Are in regular full or part-time employment (minimum 16 hour per week or \u00A310,000 annual income), unless you are retired and receiving a private or company pension, or you are in receipt of disability living allowance"),
            React.createElement(React.Fragment, null, " Are a permanent UK resident and have lived in the UK for at least 3 years"),
            React.createElement(React.Fragment, null, " Have a UK bank account capable of accepting Direct Debits"),
            React.createElement(React.Fragment, null, " Have a good credit history with no late payments, Debt Relief Orders, County Court Judgments or bankruptcies")),
        React.createElement(styles_1.BoldParagraph, null, "Stop to check that the customer meets all of these criteria."),
        React.createElement("p", null, "To apply for finance, you will be asked to provide Deko your personal data. Deko will be the controller of your personal data, and will process your data for the lawful basis of taking steps to assist you in entering into a credit agreement with the lender."),
        React.createElement("p", null, "Deko may also process your personal data to monitor and enhance your customer experience or to provide supporting customer services to you. Deko has a legitimate interest in improving internal processes and their products and/or services."),
        React.createElement("p", null, "You have various legal rights in relation to your personal data. These are set out in Deko's privacy policy and cover your right for access to, rectification, erasure, portability or restriction of, or objections to, the processing of your personal data and include your right to lodge a complaint with the Information Commissioner's Office. If you wish to exercise any of your data rights, you can contact Deko on DPO@dekopay.com."),
        React.createElement("p", null, "Deko will share your personal data with the lender in order for your application to be considered. You will be provided with a copy of the lender\u2019s privacy policy when you receive their decision about your application. You can also find a copy of their privacy policy on their website."),
        React.createElement("p", null, "Your lender will perform a \u2018soft\u2019 credit search on your credit file as part of their assessment, to determine whether the loan is affordable for you and if you are likely to make your repayments on time. If your application is accepted, a \u2018hard\u2019 credit check may be made on your credit file. Only you can see that a \u2018soft\u2019 search has been made on your credit file, but a \u2018hard\u2019 credit check will be visible to others viewing your credit file. For example, if you apply for credit in the future, the lender will see that an application credit search was made."),
        React.createElement("p", null, "If your application is successful, your lender will be the controller of your personal data in the on-going management of your loan.Before signing your credit agreement, you will be given some important documents that include key information about your loan."),
        React.createElement("p", null, "It is important that you read these documents and understand the information and key terms. If you have any questions about your credit agreement before you sign it, Deko\u2019s customer support team can help -you can contact them on support@dekopay.com or by phone on 0800 294 5891. They are open Monday to Friday from 8am to 8pm."),
        React.createElement("p", null, "You will make a deposit payment today and your monthly payments will be taken by Direct Debit by your lender. Please note, missed or late payments may result in late payment fees and additional interest on your loan, and your lender may instruct a Debt Collection Agency or take legal action against you if you fail to repay the loan."),
        React.createElement("p", null, "Depending on the goods or services you receive from us, you may have the legal right to cancel your contract with us within 14 days from receipt of your goods or services. You can find out more about your right to cancel in our cancellation policy and terms and conditions. If you decide to cancel your purchase with us, you will need to arrange to return any goods to us, and we will cease to provide any further services under the contract. If we accept your cancellation request, we will contact your lender to cancel the credit agreement and you will be refunded any repayments already made."),
        React.createElement("p", null, "You have the right under section 66A of the Consumer Credit Act to withdraw from the agreement without giving any reason before the end of 14 days after the agreement is signed by both you and the lender. If you wish to withdraw you must give your lender notice in writing or by telephone or email. The name and contact details of your lender will be clearly shown on your credit agreement."),
        React.createElement("p", null, "If you do give notice of withdrawal, you must repay the whole of the credit without delay and in any event by no later than 30 days after giving notice of withdrawal. You will also have to pay interest accrued from the date the agreement was made until the date you repay it."),
        React.createElement("p", null, "Please note that if you provide any false or inaccurate information, Deko or the lender may pass this on to fraud prevention agencies and your lender may terminate the credit agreement."),
        React.createElement("p", null, "You will receive copies of both Deko\u2019s, and the lender\u2019s Privacy Policy as you proceed with your application. When you receive your pre-contractual documents, you are entitled to take time to read and understand them before continuing to sign your credit agreement."),
        React.createElement("p", null, "If you are unhappy with the way you have been introduced to finance, you have the right to complain to us, Deko or your lender. If your credit agreement is regulated by the Consumer Credit Act, then you will also have the right to refer your complaint to the Financial Ombudsman Service if you remain dissatisfied with any response to your complaint."),
        React.createElement("p", null, "Finally, I need to let you know that the Financial Services compensation scheme does not apply to the credit agreement."),
        React.createElement("p", null, "Before we begin your application, is there anything that you\u2019d like me to explain further for you?")));
};
exports.LenderPhoneScript = LenderPhoneScript;
