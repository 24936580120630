"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_pdf_1 = require("react-pdf");
var deko_common_components_1 = require("deko-common-components");
var DropzoneContent_1 = require("./DropzoneContent");
var DropzoneActionsBar_1 = require("./DropzoneActionsBar");
var Loader_1 = require("../Loader");
var Modal_1 = require("../Modal");
var FormSection_1 = require("./FormSection");
var Dropzone = require('react-dropzone');
var cameraImg = require('../../assets/camera.svg');
var deleteIcon = require('../../assets/bin.svg');
var previewIcon = require('../../assets/lens.svg');
var FileUpload = /** @class */ (function (_super) {
    __extends(FileUpload, _super);
    function FileUpload(props) {
        var _this = _super.call(this, props) || this;
        _this.onDrop = function (files) {
            var file = files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            _this.fileType = file.type;
            var handleReady = function (result, f) {
                var fileUpload = { type: f.type, size: f.size, content: result };
                _this.onImageChange(fileUpload);
            };
            reader.onload = function () {
                handleReady(reader.result, file);
            };
            if (reader.readyState === reader.DONE) {
                handleReady(reader.result, file);
            }
        };
        _this.validateFileType = function (file) {
            if (typeof _this.props.validateStandard === 'function') {
                _this.props.validateStandard({
                    target: {
                        name: _this.props.name,
                        value: file
                    }
                });
            }
        };
        _this.getModalContent = function () {
            var children;
            var modalClass = ['id-upload-modal'];
            var onClose;
            if (!_this.state.displayDelete) {
                onClose = _this.closeModal;
                modalClass.push('hidden-xs');
                var pdfPreview = (React.createElement(react_pdf_1.Document, { className: 'modal-preview-image', file: _this.props.imgSrc, loading: React.createElement(Loader_1.default, null) },
                    React.createElement(react_pdf_1.Page, { className: 'pdf-page', pageNumber: 1, renderAnnotations: false })));
                var notPdfPreview = (React.createElement("img", { src: _this.props.imgSrc, className: 'modal-preview-image', alt: 'Preview of your uploaded document' }));
                children = _this.fileType === 'application/pdf' ? pdfPreview : notPdfPreview;
            }
            else {
                modalClass.push('modal-delete');
                children = (React.createElement("div", null,
                    React.createElement("p", null, "Are you sure you want to delete this image?"),
                    React.createElement("div", { className: 'button-group' },
                        React.createElement(deko_common_components_1.Button, { className: 'secondary back', handleClick: _this.closeModal }, "No"),
                        React.createElement(deko_common_components_1.Button, { className: 'enabled', handleClick: _this.resetState }, "Yes"))));
            }
            var modal = React.createElement(Modal_1.default, { className: modalClass.join(' '), onClose: onClose }, children);
            return _this.state.isModalOpen ? modal : null;
        };
        _this.openModal = function () {
            _this.setState({
                isModalOpen: true
            });
        };
        _this.closeModal = function () {
            _this.setState({
                isModalOpen: false,
                displayDelete: false,
            });
        };
        _this.displayModalDeleteImage = function () {
            _this.setState({ displayDelete: true, isModalOpen: true });
        };
        _this.resetState = function () {
            _this.setState({
                isModalOpen: false,
                displayDelete: false,
            });
            _this.onImageChange();
        };
        _this.fileType = '';
        _this.state = {
            isModalOpen: false,
            displayDelete: false,
        };
        return _this;
    }
    FileUpload.prototype.render = function () {
        var _a = this.props, name = _a.name, label = _a.label, imgSrc = _a.imgSrc, errors = _a.errors, disabled = _a.disabled, _b = _a.pdfLoader, pdfLoader = _b === void 0 ? React.createElement(Loader_1.default, null) : _b;
        var inputProps = {
            id: name,
            title: label,
            name: name
        };
        var hasImage = !!imgSrc;
        var hasErrors = (errors !== undefined && errors !== true);
        var errorClass = hasErrors ? 'has-errors' : null;
        var classes = ['dropzone-override', this.props.className, errorClass];
        if (disabled && hasImage) {
            this.resetState();
        }
        return (React.createElement(FormSection_1.default, { className: disabled ? 'disabled' : '' },
            React.createElement(deko_common_components_1.CommonInput, { name: name, label: label, errors: errors },
                React.createElement(Dropzone, { inputProps: inputProps, className: classes.join(' '), disableClick: (hasImage && !hasErrors) || disabled, onDrop: this.onDrop, disabled: disabled },
                    React.createElement(DropzoneContent_1.default, { placeHolderImage: cameraImg, openModal: this.openModal, visible: hasImage && !hasErrors, previewImage: imgSrc, fileType: this.fileType, pdfLoader: pdfLoader }),
                    React.createElement(DropzoneActionsBar_1.default, { visible: hasImage && !hasErrors, openModal: this.openModal, displayModalDeleteImage: this.displayModalDeleteImage, deleteIcon: deleteIcon, previewIcon: previewIcon })),
                this.getModalContent())));
    };
    FileUpload.prototype.onImageChange = function (image) {
        if (image === void 0) { image = null; }
        this.props.onDropFile(image, this.props.name);
        if (!this.props.disabled) {
            this.validateFileType(image);
        }
    };
    return FileUpload;
}(React.Component));
exports.default = FileUpload;
