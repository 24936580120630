"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var documentImage = require('../../../common/assets/document-icon.svg');
var tickImage = require('../../../common/assets/tick-icon.svg');
var DocumentsButtonsList = /** @class */ (function (_super) {
    __extends(DocumentsButtonsList, _super);
    function DocumentsButtonsList(props) {
        var _this = _super.call(this, props) || this;
        _this.selectCurrentDocument = _this.selectCurrentDocument.bind(_this);
        return _this;
    }
    DocumentsButtonsList.prototype.render = function () {
        var _this = this;
        var listItems = [];
        this.props.documents.forEach(function (doc) {
            var icon = doc.agreed ? tickImage : documentImage;
            var className = [
                'secondary',
                'large',
                "document-" + doc.key,
                "" + (doc.agreed ? 'agreed' : 'not-agreed')
            ].join(' ');
            var activeElement = (_this.props.currentDocument.key === doc.key) ? React.createElement("span", { className: 'active' }) : undefined;
            listItems.push(React.createElement(deko_common_components_1.Button, { className: className, icon: icon, key: doc.key, handleClick: _this.selectCurrentDocument(doc.key), disabled: _this.props.asyncRunning },
                activeElement,
                React.createElement("span", { className: 'document-title' }, doc.button ? doc.button : doc.title)));
        });
        return (React.createElement("div", null, listItems));
    };
    DocumentsButtonsList.prototype.selectCurrentDocument = function (key) {
        var _this = this;
        return function () {
            _this.props.handleSetCurrentDocument(key);
        };
    };
    return DocumentsButtonsList;
}(React.Component));
var mapStateToProps = function (state) {
    return {
        asyncRunning: state.async.asyncRunning
    };
};
exports.default = react_redux_1.connect(mapStateToProps, {})(DocumentsButtonsList);
