"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDisplayedTooltipAction = exports.setDisplayedTooltip = void 0;
var types = require("./actionTypes");
function setDisplayedTooltip(visibleTooltip) {
    return function (dispatch) {
        dispatch(setDisplayedTooltipAction(visibleTooltip));
    };
}
exports.setDisplayedTooltip = setDisplayedTooltip;
function setDisplayedTooltipAction(visibleTooltip) {
    return { type: types.TOGGLE_TOOLTIP, visibleTooltip: visibleTooltip };
}
exports.setDisplayedTooltipAction = setDisplayedTooltipAction;
