"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var PropTypes = require("prop-types");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var errorsActions_1 = require("../actions/errorsActions");
var ValidationManager_1 = require("./ValidationManager");
var inversify_types_1 = require("../inversify.types");
var inversify_container_1 = require("../inversify.container");
function getDispatchNavigation() {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
}
var FormValidator = function (WrappedComponent) {
    var FormValidatorHOC = /** @class */ (function (_super) {
        __extends(FormValidatorHOC, _super);
        function FormValidatorHOC(props, context) {
            var _this = _super.call(this, props, context) || this;
            _this.submit = _this.submit.bind(_this);
            return _this;
        }
        FormValidatorHOC.prototype.submit = function () {
            if (this.context.validationManager) {
                var isValid = this.context.validationManager.isFormValid(this.props.entity);
                this.props.actions.replaceErrors(isValid);
                if (isValid === true) {
                    this.props.actions.handleSubmit();
                }
            }
            else {
                this.props.actions.handleSubmit();
            }
        };
        FormValidatorHOC.prototype.render = function () {
            var newProps = {
                handleSubmit: this.submit
            };
            return React.createElement(WrappedComponent, __assign({}, this.props, newProps));
        };
        FormValidatorHOC.contextTypes = {
            validationManager: PropTypes.instanceOf(ValidationManager_1.default)
        };
        return FormValidatorHOC;
    }(React.Component));
    return react_redux_1.connect(mapStateToProps, mapDispatchToProps)(FormValidatorHOC);
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch, props) { return ({
    actions: redux_1.bindActionCreators(__assign(__assign({}, props), { dispatchNavigation: getDispatchNavigation(), replaceErrors: errorsActions_1.replaceErrors }), dispatch)
}); };
exports.default = FormValidator;
