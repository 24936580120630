"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
require("reflect-metadata");
var config_1 = require("../../config/config");
var endpoint = config_1.default.formScriptsApi;
var FormScriptsApi = /** @class */ (function () {
    function FormScriptsApi() {
    }
    FormScriptsApi.prototype.createSession = function (applicationId) {
        var request = new Request(endpoint, this.createSessionInit(applicationId));
        return fetch(request).then(function (response) {
            if (response.ok) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
    };
    FormScriptsApi.prototype.createSessionInit = function (applicationId) {
        return {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json;charset=UTF-8',
            }),
            mode: 'cors',
            body: JSON.stringify({
                application_id: applicationId,
            })
        };
    };
    FormScriptsApi = __decorate([
        inversify_1.injectable()
    ], FormScriptsApi);
    return FormScriptsApi;
}());
exports.default = FormScriptsApi;
