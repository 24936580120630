"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_utilities_1 = require("deko-utilities");
var FormValidationSummary_1 = require("./FormValidationSummary");
var ApiErrorKeys_1 = require("../../api/CreditApplication/ApiErrorKeys");
var createClickHandler = function (key) {
    return function (event) {
        var el = document.getElementById("" + key.replace(/\./g, '\.'));
        if (!el || !el.parentElement) {
            return;
        }
        // converting the nodelist from the querySelector to an array
        var focusCandidates = el.parentElement.querySelectorAll('.focus-on-child-label label');
        // special case for multiInput and also a fallback for other components
        if (focusCandidates.length === 0) {
            focusCandidates = el.parentElement.querySelectorAll('input');
        }
        // special case for addressInput
        if (key === 'address-input-group') {
            focusCandidates = el.querySelectorAll('.focus-on-child-label label');
        }
        var elementToFocus = Array.prototype.slice.call(focusCandidates).find(function (focusEl) { return focusEl.offsetHeight > 0; });
        var destination = Math.max(deko_utilities_1.offset(elementToFocus).top - 200, 0);
        window.scrollTo({
            top: destination,
            behavior: 'smooth'
        });
        // focus on first visible element
        elementToFocus.focus();
        event.preventDefault();
        return false;
    };
};
var renderErrorHref = function (error) {
    return React.createElement("a", { href: "#" + error.key, onClick: createClickHandler(error.key) }, error.value);
};
var ConnectedError = function (_a) {
    var _b = _a.errorKey, errorKey = _b === void 0 ? ApiErrorKeys_1.ERROR_SERVER : _b, errors = _a.errors, htmlAttributes = _a.htmlAttributes, _c = _a.errorMessage, errorMessage = _c === void 0 ? 'You have some invalid entries, please review: ' : _c;
    var errorText = '', errorLink, validErrors;
    var className = 'has-errors-message-empty';
    if (errorsToBeDisplayed(errors)) {
        validErrors = getListOfValidErrors(errors);
        var firstError = getFirstError(validErrors);
        if (firstError == null) {
            errorText = '';
            errorLink = undefined;
        }
        else {
            errorText = errorMessage;
            errorLink = renderErrorHref(firstError);
            className = 'has-errors-message';
            if (errors.hasOwnProperty(errorKey)) {
                errorText = errors[errorKey];
                errorLink = undefined;
            }
        }
    }
    return (React.createElement("div", null,
        React.createElement(FormValidationSummary_1.default, { errors: validErrors }),
        React.createElement("div", __assign({ className: className }, htmlAttributes),
            errorText,
            errorLink)));
};
var errorsToBeDisplayed = function (errors) {
    if (errors === void 0) { errors = {}; }
    for (var name_1 in errors) {
        if (errors[name_1] !== true) {
            return true;
        }
    }
    return false;
};
var getListOfValidErrors = function (errors) {
    var _a, _b;
    var validErrors = {};
    var hasAddressAlready = false;
    for (var name_2 in errors) {
        if (errors[name_2] !== true) {
            var errorKey = name_2 + "_error", errorValue = errors[name_2];
            if (name_2.indexOf('addresses') !== -1) {
                if (!hasAddressAlready) {
                    errorKey = 'address-input-group';
                    errorValue = 'Please review your addresses';
                    hasAddressAlready = true;
                    Object.assign(validErrors, (_a = {}, _a[errorKey] = errorValue, _a));
                }
            }
            else {
                Object.assign(validErrors, (_b = {}, _b[errorKey] = errorValue, _b));
            }
        }
    }
    return validErrors;
};
var getFirstError = function (errors) {
    var count = 0;
    for (var name_3 in errors) {
        if (count === 0 && errors[name_3] !== true) {
            count++;
            return { key: name_3, value: errors[name_3] };
        }
    }
};
var mapStateToProps = function (state) { return ({
    errors: state.errors
}); };
exports.default = react_redux_1.connect(mapStateToProps)(ConnectedError);
