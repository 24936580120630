"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var navigationActions_1 = require("../../magi/actions/navigationActions");
var RequireDocumentsSigned = /** @class */ (function (_super) {
    __extends(RequireDocumentsSigned, _super);
    function RequireDocumentsSigned(props) {
        var _this = _super.call(this, props) || this;
        _this.validateAccess = function (currentState) {
            if (currentState.asyncRunning) {
                return;
            }
            if (_this.props.documentShouldBeSigned !== currentState.application.documents_signed) {
                _this.props.actions.dispatchNavigation(_this.props.fallbackAction);
                return;
            }
            _this.setState({ hasValidated: true });
            return true;
        };
        _this.state = { hasValidated: false };
        return _this;
    }
    RequireDocumentsSigned.prototype.render = function () {
        if (!this.state.hasValidated) {
            return null;
        }
        return (React.createElement(React.Fragment, null, this.props.children));
    };
    RequireDocumentsSigned.prototype.componentDidMount = function () {
        this.validateAccess(this.props);
    };
    RequireDocumentsSigned.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps !== this.props) {
            this.validateAccess(this.props);
        }
    };
    return RequireDocumentsSigned;
}(React.Component));
var mapStateToProps = function (state) {
    return {
        asyncRunning: state.async.asyncRunning,
        application: state.application
    };
};
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_1.dispatchNavigation
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(RequireDocumentsSigned);
