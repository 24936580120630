"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ThreeDSecureForm_1 = require("../../../pages/deposit/form/ThreeDSecureForm");
function useOpayoAutoSubmittingForm() {
    var opayoForm = React.useRef(null);
    React.useEffect(function () {
        if (opayoForm.current) {
            opayoForm.current.submit();
        }
    }, [opayoForm]);
    return opayoForm;
}
function OpayoThreeDSecureBaseForm(_a) {
    var acsUrl = _a.acsUrl, children = _a.children, handleCallback = _a.handleCallback;
    var opayoForm = useOpayoAutoSubmittingForm();
    var frame = React.useRef(null);
    var handleMessage = function (message) {
        var location = window.location;
        var origin = location.protocol + "//" + (location.hostname + (location.port ? ":" + location.port : ''));
        if (message.origin !== origin) {
            return;
        }
        if (['success', 'initialize', 'fail', 'cancel', 'refund', 'processing'].indexOf(message.data.status) === -1) {
            return;
        }
        handleCallback(message.data.status, message.data.error);
    };
    React.useEffect(function () {
        window.addEventListener('message', handleMessage, false);
        return function () {
            window.removeEventListener('message', handleMessage, false);
        };
    }, [frame]);
    return (React.createElement("div", { id: 'opayo-frame-wrapper' },
        React.createElement("form", { ref: opayoForm, method: 'post', target: 'pa-frame', action: acsUrl }, children),
        React.createElement("iframe", { ref: frame, name: 'pa-frame', id: 'pa-frame', width: '100%', height: 500, frameBorder: '0' })));
}
function OpayoThreeDSecureV2Form(_a) {
    var acsUrl = _a.acsUrl, threeDSessionData = _a.threeDSessionData, creq = _a.creq, handleCallback = _a.handleCallback;
    return React.createElement(OpayoThreeDSecureBaseForm, { handleCallback: handleCallback, acsUrl: acsUrl },
        React.createElement("input", { type: 'hidden', name: 'creq', value: creq }),
        React.createElement("input", { type: 'hidden', name: 'threeDSSessionData', value: threeDSessionData }));
}
function OpayoThreeDSecureV1Form(_a) {
    var acsUrl = _a.acsUrl, md = _a.md, paReq = _a.paReq, token = _a.token, handleCallback = _a.handleCallback;
    var termUrl = ThreeDSecureForm_1.generateTermUrl(token, true);
    return React.createElement(OpayoThreeDSecureBaseForm, { handleCallback: handleCallback, acsUrl: acsUrl },
        React.createElement("input", { type: 'hidden', name: 'PaReq', value: paReq }),
        React.createElement("input", { type: 'hidden', name: 'TermUrl', value: termUrl }),
        React.createElement("input", { type: 'hidden', name: 'MD', value: md }));
}
function OpayoThreeDSecureForm(props) {
    return props.paReq ?
        (React.createElement(OpayoThreeDSecureV1Form, __assign({}, props))) :
        (React.createElement(OpayoThreeDSecureV2Form, __assign({}, props)));
}
exports.default = OpayoThreeDSecureForm;
