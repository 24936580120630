"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.completeConsumer = exports.scenarios = void 0;
var IApplication_1 = require("../../common/api/CreditApplication/IApplication");
exports.scenarios = [
    { scenario: 'Accept', surname: 'Accept' },
    { scenario: 'Decline', surname: 'JDecline' },
    { scenario: 'Alternative Finance', surname: 'Asecond' },
    { scenario: 'Refer', surname: 'QRefer' },
    { scenario: 'Action Customer', surname: 'Aidcheck' },
];
exports.completeConsumer = {
    title: IApplication_1.TitleEnum.MR,
    forename: 'Test',
    surname: 'Testington',
    date_of_birth: {
        day: '01',
        month: '01',
        year: '1980',
    },
    email: '',
    confirm_email: '',
    mobile_number: '07777777777',
    marital_status: IApplication_1.MaritalStatusEnum.SINGLE,
    number_dependants: 0,
    addresses: {
        current_address: {
            flat: '4124',
            house_number: '17',
            house_name: 'Little Paris',
            street: 'The Avenue',
            district: '9',
            county: 'countyplace',
            town: 'London',
            postcode: 'SE15EA',
            years_at_address: 3
        },
        second_address: {
            flat: undefined,
            house_number: undefined,
            house_name: undefined,
            street: undefined,
            district: undefined,
            county: undefined,
            town: undefined,
            postcode: undefined,
        },
        third_address: {
            flat: undefined,
            house_number: undefined,
            house_name: undefined,
            street: undefined,
            district: undefined,
            county: undefined,
            town: undefined,
            postcode: undefined,
        }
    },
    employment: {
        status: IApplication_1.EmploymentStatusEnum.FULL_TIME,
        occupation: 'Developer',
        employer: 'Deko',
        years_with_employer: 10,
        salary: {
            type: IApplication_1.EmploymentSalaryEnum.SALARY_GROSS_ANNUAL,
            amount: 12000,
        },
        no_change_in_circumstances: true
    },
    residential_status: IApplication_1.ResidentialStatusEnum.RESIDENTIAL_TENANT,
    expenditure: {
        HousingCosts: '100',
        DependantsCosts: '100',
    },
    nationality: {
        is_british: 'yes',
    },
    bank_details: {
        account_number: '70872490',
        sort_code: {
            first: '40',
            second: '47',
            third: '84',
        }
    },
    marketing: {},
    consent: {
        submit: true,
    },
    affordability: {
        dependants: '0',
        household_income: {
            amount: 100000,
            type: IApplication_1.AffordabilityExpenditureEnum.HOUSEHOLD_INCOME,
        },
        monthly_rent_amount: {
            amount: 400,
            type: IApplication_1.AffordabilityExpenditureEnum.RENT_OR_MORTGAGE,
        }
    },
    documents: undefined
};
