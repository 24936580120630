"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var documentConfig_1 = require("../../components/documents/documentConfig");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var navigationActions_1 = require("../../actions/navigationActions");
var react_redux_1 = require("react-redux");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var redux_1 = require("redux");
var Page_1 = require("../../components/Page");
var navigationActions_2 = require("../../../common/actions/navigationActions");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var DocumentsInstructionPage = /** @class */ (function (_super) {
    __extends(DocumentsInstructionPage, _super);
    function DocumentsInstructionPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.goToDocumentsSigningPage = function () {
            _this.props.actions.dispatchNavigation(navigationActions_2.DOCUMENTS_SIGN_PAGE);
        };
        return _this;
    }
    DocumentsInstructionPage.prototype.render = function () {
        var branding = this.props.branding;
        if (!branding.instore_information) {
            throw new Error('Retailer not configured for in-store script');
        }
        if (branding.instore_information.should_show_instore_video) {
            throw new Error('Retailer should show in-store video');
        }
        var script = String(branding.instore_information.script);
        return React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: false, fallbackAction: navigationActions_2.PAY_DEPOSIT },
            React.createElement(Page_1.default, { mainCols: 7, className: 'documents-page' },
                React.createElement("h1", null, "Please follow the instructions"),
                React.createElement("div", { className: 'instore-script', dangerouslySetInnerHTML: { __html: script } }),
                React.createElement("p", { className: 'return-to-customer' },
                    React.createElement("strong", null, "Please press the button below and return the device to the customer.")),
                React.createElement(deko_common_components_1.Button, { className: 'continue-to-docs', handleClick: this.goToDocumentsSigningPage }, "Go to Documents")));
    };
    return DocumentsInstructionPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application,
    branding: state.branding,
    documents: documentConfig_1.getDocumentConfig(state.application),
    lender: state.application.lender,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_1.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(DocumentsInstructionPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT, IApplication_1.AppStatusEnum.REFER])(connected);
