"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asyncFeatureFailure = exports.asyncFeatureSuccess = exports.asyncFeatureBegin = exports.asyncFailure = exports.asyncSuccess = exports.asyncBegin = void 0;
var types = require("../actions/actionTypes");
function asyncBegin() {
    return { type: types.ASYNC_BEGIN };
}
exports.asyncBegin = asyncBegin;
function asyncSuccess() {
    return { type: types.ASYNC_SUCCESS };
}
exports.asyncSuccess = asyncSuccess;
function asyncFailure() {
    return { type: types.ASYNC_FAILURE };
}
exports.asyncFailure = asyncFailure;
function asyncFeatureBegin() {
    return { type: types.ASYNC_FEATURE_REQUEST_BEGIN };
}
exports.asyncFeatureBegin = asyncFeatureBegin;
function asyncFeatureSuccess() {
    return { type: types.ASYNC_FEATURE_REQUEST_SUCCESS };
}
exports.asyncFeatureSuccess = asyncFeatureSuccess;
function asyncFeatureFailure() {
    return { type: types.ASYNC_FEATURE_REQUEST_FAILURE };
}
exports.asyncFeatureFailure = asyncFeatureFailure;
