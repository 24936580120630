"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_utilities_1 = require("deko-utilities");
var IApplication_1 = require("../api/CreditApplication/IApplication");
var representativeStatuses = [IApplication_1.AppStatusEnum.NO_DECISION, IApplication_1.AppStatusEnum.INIT];
var goodsDesc = function (goods) { return deko_utilities_1.truncate(goods.map(function (good) { return good.description; }).join(', '), 180); };
var createSummary = function (application, finance) {
    var isOffer = representativeStatuses.indexOf(application.status) === -1;
    var summaryProduct = isOffer ?
        (finance.offer === undefined ? finance.product : finance.offer) : finance.product;
    return {
        isOffer: !isOffer,
        description: goodsDesc(finance.goods),
        apr: summaryProduct.apr,
        interest_payable: summaryProduct.interest_payable,
        loan_term: summaryProduct.loan_term,
        monthly_instalment: summaryProduct.monthly_instalment,
        first_instalment: summaryProduct.first_instalment,
        last_instalment: summaryProduct.last_instalment,
        total_payable: summaryProduct.total_payable,
        deposit: finance.deposit.amount,
        loan_amount: finance.credit,
        cash_price: finance.goods.reduce(function (p, c) { return p += c.price; }, 0),
        interest_rate: summaryProduct.annual_interest_rate,
    };
};
var SummaryProvider = /** @class */ (function (_super) {
    __extends(SummaryProvider, _super);
    function SummaryProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SummaryProvider.prototype.render = function () {
        var application = this.props.application;
        var finance = this.props.application.finance;
        if (!application || !finance || !finance.goods) {
            return null;
        }
        if (finance.product.loan_type === 'CC') {
            return null;
        }
        return (React.createElement("div", { className: 'current-summary-wrapper' }, this.props.render(createSummary(application, finance))));
    };
    return SummaryProvider;
}(React.Component));
var mapStateToProps = function (state) {
    return {
        application: state.application
    };
};
exports.default = react_redux_1.connect(mapStateToProps)(SummaryProvider);
