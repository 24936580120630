"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var LenderLinks_1 = require("../../../../common/config/LenderLinks");
require("../../../../common/styles/deko-styles/components/Footer.scss");
var logo = require('../../../../common/assets/logo-deko-form.svg');
var DekoFooterContent = function () {
    var securityLink = LenderLinks_1.securityStatementLinks[LenderLinks_1.lenderEnum.DEKO];
    var termsLink = LenderLinks_1.termsOfServiceLinks[LenderLinks_1.lenderEnum.DEKO];
    var faqLink = LenderLinks_1.faqLinks[LenderLinks_1.lenderEnum.DEKO];
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Deko is a trading name of Pay4Later Limited. We are registered in England and Wales (company number: 06447333) and authorised and regulated by the Financial Conduct Authority (register number: 728646)."),
        React.createElement("ul", null,
            React.createElement("li", null,
                React.createElement("a", { href: termsLink, target: '_blank' }, "Terms of Service"),
                React.createElement("span", { className: 'hidden-xs delimiter' }, "|")),
            React.createElement("li", null,
                React.createElement("a", { href: LenderLinks_1.privacyLink, target: '_blank' }, "Privacy Policy"),
                React.createElement("span", { className: 'hidden-xs delimiter' }, "|")),
            React.createElement("li", null,
                React.createElement("a", { href: faqLink, target: '_blank' }, "FAQ"),
                React.createElement("span", { className: 'hidden-xs delimiter' }, "|")),
            React.createElement("li", null,
                React.createElement("a", { href: securityLink, target: '_blank' }, "Security Statement"))),
        React.createElement(deko_common_components_1.Image, { src: logo })));
};
exports.default = DekoFooterContent;
