"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* tslint:disable:max-line-length */
var React = require("react");
var styled_components_1 = require("styled-components");
var dAnimation = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%   { opacity: 0; transform: translateX(-75px);}\n  7.5%{ opacity: 1; transform: translateX(0);}\n  50%  { opacity: 1; }\n  67.5%{ opacity: 0; }\n  100% { opacity: 0; }\n"], ["\n  0%   { opacity: 0; transform: translateX(-75px);}\n  7.5%{ opacity: 1; transform: translateX(0);}\n  50%  { opacity: 1; }\n  67.5%{ opacity: 0; }\n  100% { opacity: 0; }\n"])));
var e1Animation = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0%   { opacity: 0; }\n  10%  { opacity: 0; }\n  17.5% { opacity: 1; }\n  60% { opacity: 1; }\n  65% { opacity: 0; }\n  100% { opacity: 0; }\n"], ["\n  0%   { opacity: 0; }\n  10%  { opacity: 0; }\n  17.5% { opacity: 1; }\n  60% { opacity: 1; }\n  65% { opacity: 0; }\n  100% { opacity: 0; }\n"])));
var e2Animation = styled_components_1.keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0%   { opacity: 0; }\n  15%  { opacity: 0; }\n  22.5% { opacity: 1; }\n  65% { opacity: 1; }\n  70% { opacity: 0; }\n  100% { opacity: 0; }\n"], ["\n  0%   { opacity: 0; }\n  15%  { opacity: 0; }\n  22.5% { opacity: 1; }\n  65% { opacity: 1; }\n  70% { opacity: 0; }\n  100% { opacity: 0; }\n"])));
var e3Animation = styled_components_1.keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  0%   { opacity: 0; }\n  20%  { opacity: 0; }\n  27.5% { opacity: 1; }\n  70% { opacity: 1; }\n  75% { opacity: 0; }\n  100% { opacity: 0; }\n"], ["\n  0%   { opacity: 0; }\n  20%  { opacity: 0; }\n  27.5% { opacity: 1; }\n  70% { opacity: 1; }\n  75% { opacity: 0; }\n  100% { opacity: 0; }\n"])));
var k1Animation = styled_components_1.keyframes(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  0%    { opacity: 0;}\n  25%   { opacity: 0; transform: scaleY(0);}\n  37.5% { opacity: 1; transform: scaleY(1);}\n  80%   { opacity: 1;}\n  87.5% { opacity: 0;}\n  100%  { opacity: 0;}\n"], ["\n  0%    { opacity: 0;}\n  25%   { opacity: 0; transform: scaleY(0);}\n  37.5% { opacity: 1; transform: scaleY(1);}\n  80%   { opacity: 1;}\n  87.5% { opacity: 0;}\n  100%  { opacity: 0;}\n"])));
var k2Animation = styled_components_1.keyframes(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  0%   { opacity: 0;}\n  30%  { opacity: 0; transform: translateX(50px);}\n  37.5%{ opacity: 1; transform: translateX(0);}\n  80%   { opacity: 1;}\n  87.5% { opacity: 0;}\n  100%  { opacity: 0;}\n"], ["\n  0%   { opacity: 0;}\n  30%  { opacity: 0; transform: translateX(50px);}\n  37.5%{ opacity: 1; transform: translateX(0);}\n  80%   { opacity: 1;}\n  87.5% { opacity: 0;}\n  100%  { opacity: 0;}\n"])));
var oAnimation = styled_components_1.keyframes(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  0%   { opacity: 0;}\n  40%  { opacity: 0; transform: translateX(75px);}\n  50% { opacity: 1; transform: translateX(0);}\n  87.5%{opacity: 1}\n  95% {opacity: 0;}\n  100% {opacity: 0;}\n"], ["\n  0%   { opacity: 0;}\n  40%  { opacity: 0; transform: translateX(75px);}\n  50% { opacity: 1; transform: translateX(0);}\n  87.5%{opacity: 1}\n  95% {opacity: 0;}\n  100% {opacity: 0;}\n"])));
var SpinnerWrapper = styled_components_1.default('div')(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #f1f1f1;\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  width: 100%;\n  z-index: 500000;\n  pointer-events: none;\n  opacity: 1;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #f1f1f1;\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  width: 100%;\n  z-index: 500000;\n  pointer-events: none;\n  opacity: 1;\n"])));
var SpinnerContainer = styled_components_1.default('div')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  opacity: 1;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100%;\n  pointer-events: none;\n"], ["\n  opacity: 1;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100%;\n  pointer-events: none;\n"])));
var D = styled_components_1.default('path')(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  animation: ", " 2s infinite;\n"], ["\n  animation: ", " 2s infinite;\n"])), dAnimation);
var E1 = styled_components_1.default('path')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  animation: ", " 2s infinite ease-in;\n"], ["\n  animation: ", " 2s infinite ease-in;\n"])), e1Animation);
var E2 = styled_components_1.default('path')(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  animation: ", " 2s infinite ease-in;\n"], ["\n  animation: ", " 2s infinite ease-in;\n"])), e2Animation);
var E3 = styled_components_1.default('path')(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  animation: ", " 2s infinite ease-in;\n"], ["\n  animation: ", " 2s infinite ease-in;\n"])), e3Animation);
var K1 = styled_components_1.default('path')(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  transform-origin: 10% 70%;\n  animation: ", " 2s infinite ease-out;\n"], ["\n  transform-origin: 10% 70%;\n  animation: ", " 2s infinite ease-out;\n"])), k1Animation);
var K2 = styled_components_1.default('path')(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  transform-origin: 10% 70%;\n  animation: ", " 2s infinite ease-out;\n"], ["\n  transform-origin: 10% 70%;\n  animation: ", " 2s infinite ease-out;\n"])), k2Animation);
var O = styled_components_1.default('path')(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  animation: ", " 2s infinite;\n"], ["\n  animation: ", " 2s infinite;\n"])), oAnimation);
var Spinner = function (_a) {
    var children = _a.children;
    return (React.createElement(SpinnerWrapper, null,
        React.createElement(SpinnerContainer, null,
            React.createElement("svg", { width: '200px', height: '75px', viewBox: '0 0 200 200', version: '1.1' },
                React.createElement("g", { id: 'logos', stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' },
                    React.createElement("g", { id: 'Deko_square_colour_whiteBG' },
                        React.createElement("polygon", { id: 'Fill-1', fill: '#fff', fillOpacity: '0', points: '-2.2759572e-15 200 200 200 200 0 -2.2759572e-15 0' }),
                        React.createElement(D, { d: 'M51.772,80.144 L34.4535,80.144 C33.741,80.144 33.163,79.566 33.163,78.853 L33.163,34.454 C33.163,33.741 33.741,33.1635 34.4535,33.1635 L52.1285,33.1635 C65.203,33.1635 75.816,43.899 75.616,57.018 C75.4195,69.907 64.663,80.144 51.772,80.144 Z M52.1285,22.109 L23.8295,22.109 C22.879,22.109 22.1085,22.8795 22.1085,23.83 L22.1085,89.477 C22.1085,90.4275 22.879,91.198 23.8295,91.198 L51.695,91.198 C70.7425,91.198 86.531,75.9625 86.6725,56.9155 C86.8145,37.717 71.2945,22.109 52.1285,22.109 L52.1285,22.109 Z', id: 'Fill-9', fill: '#133742' }),
                        React.createElement(E1, { d: 'M174.9255,31.4425 L174.9255,23.83 C174.9255,22.8795 174.1555,22.109 173.2045,22.109 L112.0525,22.109 C111.102,22.109 110.3315,22.8795 110.3315,23.83 L110.3315,31.4425 C110.3315,32.393 111.102,33.163 112.0525,33.163 L173.2045,33.163 C174.1555,33.163 174.9255,32.393 174.9255,31.4425', id: 'Fill-4', fill: '#E3636F' }),
                        React.createElement(E2, { d: 'M174.9255,60.4595 L174.9255,52.847 C174.9255,51.8965 174.1555,51.126 173.2045,51.126 L112.0525,51.126 C111.102,51.126 110.3315,51.8965 110.3315,52.847 L110.3315,60.4595 C110.3315,61.41 111.102,62.1805 112.0525,62.1805 L173.2045,62.1805 C174.1555,62.1805 174.9255,61.41 174.9255,60.4595', id: 'Fill-6', fill: '#24B095' }),
                        React.createElement(E3, { d: 'M110.3315,81.8645 L110.3315,89.477 C110.3315,90.4275 111.102,91.198 112.0525,91.198 L173.2045,91.198 C174.1555,91.198 174.9255,90.4275 174.9255,89.477 L174.9255,81.8645 C174.9255,80.914 174.1555,80.144 173.2045,80.144 L112.0525,80.144 C111.102,80.144 110.3315,80.914 110.3315,81.8645', id: 'Fill-5', fill: '#EB9D3F' }),
                        React.createElement(K1, { d: 'M31.4415,177.891 L23.8295,177.891 C22.879,177.891 22.1085,177.1205 22.1085,176.1705 L22.1085,110.523 C22.1085,109.573 22.879,108.802 23.8295,108.802 L31.4415,108.802 C32.392,108.802 33.1625,109.573 33.1625,110.523 L33.1625,176.1705 C33.1625,177.1205 32.392,177.891 31.4415,177.891', id: 'Fill-7', fill: '#133742' }),
                        React.createElement(K2, { d: 'M54.771,142.7385 L87.2385,110.271 C87.7805,109.729 87.3965,108.802 86.63,108.802 L73.9655,108.802 C73.3955,108.802 72.848,109.029 72.4445,109.432 L40.9635,140.913 C39.6195,142.257 39.6195,144.4365 40.9635,145.7805 L72.444,177.261 C72.8475,177.6645 73.395,177.891 73.9655,177.891 L86.63,177.891 C87.3965,177.891 87.7805,176.9645 87.2385,176.4225 L54.771,143.955 C54.435,143.619 54.435,143.074 54.771,142.7385', id: 'Fill-8', fill: '#133742' }),
                        React.createElement(O, { d: 'M142.6285,108.063 C123.142,108.063 107.345,123.86 107.345,143.3465 C107.345,162.833 123.142,178.63 142.6285,178.63 C162.115,178.63 177.912,162.833 177.912,143.3465 C177.912,123.86 162.115,108.063 142.6285,108.063 Z M142.6285,119.1175 C155.989,119.1175 166.8575,129.9865 166.8575,143.3465 C166.8575,156.7065 155.989,167.5755 142.6285,167.5755 C129.2685,167.5755 118.3995,156.7065 118.3995,143.3465 C118.3995,129.9865 129.2685,119.1175 142.6285,119.1175 L142.6285,119.1175 Z', id: 'Fill-3', fill: '#133742' })))),
            children)));
};
exports.default = Spinner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
