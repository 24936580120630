"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var navigationActions_1 = require("../actions/navigationActions");
var inversify_container_1 = require("../inversify.container");
var inversify_types_1 = require("../inversify.types");
var deko_utilities_1 = require("deko-utilities");
var getNextStep_1 = require("../utilities/getNextStep");
function getDispatchNavigation() {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
}
var RequiredStatus = function (validStatuses) {
    if (validStatuses === void 0) { validStatuses = []; }
    return function (WrappedComponent) {
        var RequiredStatusHOC = /** @class */ (function (_super) {
            __extends(RequiredStatusHOC, _super);
            function RequiredStatusHOC(props) {
                var _this = _super.call(this, props) || this;
                _this.hasAccess = function (currentState) {
                    return validStatuses.indexOf(currentState.status) !== -1;
                };
                _this.validateAccess = function (currentState) {
                    if (currentState.asyncRunning) {
                        return;
                    }
                    if (!_this.hasAccess(currentState)) {
                        var correctPage = void 0;
                        try {
                            var nextStep = getNextStep_1.getNextStep(currentState.application);
                            correctPage = (nextStep) ? nextStep.action : navigationActions_1.ERROR_PAGE;
                        }
                        catch (e) {
                            correctPage = navigationActions_1.ERROR_PAGE;
                        }
                        _this.logError(currentState, correctPage);
                        _this.props.actions.dispatchNavigation(correctPage);
                        return;
                    }
                    _this.setState({ hasValidated: true });
                    return true;
                };
                _this.state = { hasValidated: false };
                return _this;
            }
            RequiredStatusHOC.prototype.componentDidMount = function () {
                this.validateAccess(this.props);
            };
            RequiredStatusHOC.prototype.componentDidUpdate = function (prevProps) {
                if (prevProps !== this.props) {
                    this.validateAccess(this.props);
                }
            };
            RequiredStatusHOC.prototype.render = function () {
                if (!this.state.hasValidated) {
                    return null;
                }
                return React.createElement(WrappedComponent, __assign({}, this.props));
            };
            RequiredStatusHOC.prototype.logError = function (currentState, correctPage) {
                var referrer = document.referrer ? document.referrer : 'NO REFERRER';
                var msg = "RequiredStatus fail, " + currentState.status + " did not match one of " + validStatuses.join(', ') + "\n                    Application ID: " + currentState.application.application_id + "\n                    Referer: " + referrer + "\n                    Redirecting to correct page: " + correctPage;
                deko_utilities_1.debugLog({
                    message: msg
                });
                console.error(msg);
            };
            return RequiredStatusHOC;
        }(React.Component));
        return react_redux_1.connect(mapStateToProps, mapDispatchToProps)(RequiredStatusHOC);
    };
};
var mapStateToProps = function (state) { return ({
    status: state.application.status,
    asyncRunning: state.async.asyncRunning,
    application: state.application,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: getDispatchNavigation()
    }, dispatch)
}); };
exports.default = RequiredStatus;
