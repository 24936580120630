"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.payOpayoDeposit = exports.payDeposit = exports.handle3DSecureResponse = exports.handleDepositPaid = exports.submitInstoreDepositPayment = exports.updateInstoreVerification = exports.updateInstoreVerificationAction = exports.updateDepositStatusAction = exports.updateCardDetails = exports.updateThreeDSecure = exports.updateCardDetailsAction = void 0;
var deko_utilities_1 = require("deko-utilities");
var types = require("./actionTypes");
var creditCardType = require("credit-card-type");
var IApplication_1 = require("../api/CreditApplication/IApplication");
var creditApplicationActions_1 = require("./creditApplicationActions");
var navigationActions_1 = require("./navigationActions");
var inversify_container_1 = require("../inversify.container");
var inversify_types_1 = require("../inversify.types");
var errorsActions_1 = require("./errorsActions");
var asyncActions_1 = require("./asyncActions");
var normalize_1 = require("../utilities/normalize");
var ApiErrorKeys_1 = require("../api/CreditApplication/ApiErrorKeys");
var sagepayErrorMapper_1 = require("../utilities/sagepayErrorMapper");
function getApi() {
    return inversify_container_1.default.get(inversify_types_1.default.CreditApplicationApi);
}
function getDispatchNavigation() {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
}
function updateCardDetailsAction(cardDetails) {
    return { type: types.UPDATE_CARD_DETAILS, cardDetails: cardDetails };
}
exports.updateCardDetailsAction = updateCardDetailsAction;
function updateThreeDSecure(threeDSecure) {
    return { type: types.DEPOSIT_3DSECURE, threeDSecure: threeDSecure };
}
exports.updateThreeDSecure = updateThreeDSecure;
function updateCardDetails(cardDetails) {
    return function (dispatch) {
        if (cardDetails.card_number) {
            var cardNumber = deko_utilities_1.cardNumberParse(cardDetails.card_number);
            var cardTypes = creditCardType(cardNumber);
            if (cardTypes.length === 1) {
                cardDetails.card_type = cardTypes[0].type;
            }
            else {
                cardDetails.card_type = undefined;
            }
        }
        dispatch(updateCardDetailsAction(cardDetails));
    };
}
exports.updateCardDetails = updateCardDetails;
function updateDepositStatusAction(status) {
    return { type: types.UPDATE_DEPOSIT_STATUS, status: status };
}
exports.updateDepositStatusAction = updateDepositStatusAction;
function updateInstoreVerificationAction(instoreVerification) {
    return { type: types.UPDATE_INSTORE_VERIFICATION, instoreVerification: instoreVerification };
}
exports.updateInstoreVerificationAction = updateInstoreVerificationAction;
function updateInstoreVerification(instoreVerification) {
    return function (dispatch) {
        dispatch(updateInstoreVerificationAction(instoreVerification));
    };
}
exports.updateInstoreVerification = updateInstoreVerification;
function submitInstoreDepositPayment(verificationMethod) {
    return function (dispatch, getState) {
        dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.PAYING));
        var state = getState();
        var request = state.application;
        var verification = { verification_method: verificationMethod };
        dispatch(asyncActions_1.asyncBegin());
        return new Promise(function (resolve, reject) {
            return getApi().submitInstoreDepositPayment(request, verification).then(function (result) {
                dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.PAID));
                if (result.status === IApplication_1.AppStatusEnum.ACTION_CUSTOMER) {
                    resolve();
                    dispatch(creditApplicationActions_1.updateStatus(IApplication_1.AppStatusEnum.ACTION_CUSTOMER));
                    dispatch(getDispatchNavigation()(navigationActions_1.ID_UPLOAD_PAGE));
                    dispatch(asyncActions_1.asyncSuccess());
                }
                else {
                    return getApi().getScope(request.application_id).then(function (scope) {
                        var redirectPage = navigationActions_1.INSTORE_FULFIL;
                        if (scope.indexOf('credit_application_fulfilment') === -1) {
                            redirectPage = navigationActions_1.COMPLETE_VERIFIED_PAGE;
                        }
                        resolve();
                        dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.PAID));
                        dispatch(creditApplicationActions_1.updateStatus(IApplication_1.AppStatusEnum.VERIFIED));
                        dispatch(getDispatchNavigation()(redirectPage));
                        dispatch(asyncActions_1.asyncSuccess());
                    });
                }
            }).catch(function (res) {
                var _a;
                reject(res.error.message);
                dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.ERROR));
                dispatch(asyncActions_1.asyncFailure());
                deko_utilities_1.debugLog({ message: 'Failed to verify application' });
                dispatch(errorsActions_1.replaceErrorsAction((_a = {}, _a[ApiErrorKeys_1.ERROR_SERVER] = res.error.message, _a)));
                throw (res.error);
            });
        });
    };
}
exports.submitInstoreDepositPayment = submitInstoreDepositPayment;
var handleDepositPaid = function (dispatch, getState) {
    return new Promise(function (resolve) {
        var id = getState().application.application_id;
        dispatch(asyncActions_1.asyncBegin());
        return getApi().getApplication(id).then(function (application) {
            dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.PAID));
            dispatch(creditApplicationActions_1.loadApplicationSuccessAction(normalize_1.normalizeCreditApplication(application)));
            if (application.status === IApplication_1.AppStatusEnum.ACTION_CUSTOMER) {
                dispatch(getDispatchNavigation()(navigationActions_1.ID_UPLOAD_PAGE));
            }
            else {
                var nav = getDispatchNavigation();
                dispatch(nav(navigationActions_1.COMPLETE_PAGE));
            }
            dispatch(asyncActions_1.asyncSuccess());
            resolve(application);
        });
    });
};
exports.handleDepositPaid = handleDepositPaid;
var handlePayDepositResponse = function (res, dispatch, getState) {
    return new Promise(function (resolve) {
        var _a;
        switch (res.status) {
            case '3DAUTH':
                dispatch(updateThreeDSecure(res.three_d_secure));
                resolve();
                break;
            case 'OK':
                exports.handleDepositPaid(dispatch, getState).then(function () {
                    resolve();
                });
                break;
            case 'INVALID':
            case 'NOTAUTHED':
            default:
                var errorMessage = res.message;
                var lenderName = getState().application.lender.name;
                dispatch(errorsActions_1.replaceErrorsAction((_a = {}, _a[ApiErrorKeys_1.ERROR_SERVER] = sagepayErrorMapper_1.default(errorMessage, lenderName), _a)));
                dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.ERROR));
                resolve();
                break;
        }
    });
};
function handle3DSecureResponse(status, error) {
    return function (dispatch, getState) {
        switch (status) {
            case 'success':
                return new Promise(function (resolve) {
                    exports.handleDepositPaid(dispatch, getState);
                    resolve();
                });
                break;
            case 'fail':
            default:
                return new Promise(function (resolve) {
                    var _a;
                    dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.ERROR));
                    var errorMessage = deko_utilities_1.maybe(error, '3D-Authentication failed');
                    var lenderName = getState().application.lender.name;
                    dispatch(errorsActions_1.replaceErrorsAction((_a = {}, _a[ApiErrorKeys_1.ERROR_SERVER] = sagepayErrorMapper_1.default(errorMessage, lenderName), _a)));
                    dispatch(getDispatchNavigation()(navigationActions_1.PAY_DEPOSIT));
                    resolve();
                });
                break;
        }
    };
}
exports.handle3DSecureResponse = handle3DSecureResponse;
function payDeposit() {
    return function (dispatch, getState) {
        dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.PAYING));
        var request = getState().application;
        var cardDetails = getState().deposit.card_details;
        var year = cardDetails.end_date.year;
        dispatch(asyncActions_1.asyncBegin());
        return getApi().payDeposit(request, __assign(__assign({}, cardDetails), { end_date: __assign(__assign({}, cardDetails.end_date), { year: ((year) ? ("20" + year) : year) }) })).then(function (res) {
            dispatch(asyncActions_1.asyncSuccess());
            return handlePayDepositResponse(res, dispatch, getState);
        }).catch(function (res) {
            var _a;
            dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.ERROR));
            dispatch(asyncActions_1.asyncFailure());
            dispatch(errorsActions_1.replaceErrorsAction((_a = {}, _a[ApiErrorKeys_1.ERROR_SERVER] = res.error.message, _a)));
        });
    };
}
exports.payDeposit = payDeposit;
function payOpayoDeposit() {
    return function (dispatch, getState) {
        var id = getState().application.application_id;
        dispatch(asyncActions_1.asyncBegin());
        getApi().getApplication(id).then(function (application) {
            dispatch(updateDepositStatusAction(IApplication_1.DepositStateEnum.PAID));
            dispatch(creditApplicationActions_1.loadApplicationSuccessAction(normalize_1.normalizeCreditApplication(application)));
            if (application.status === IApplication_1.AppStatusEnum.ACTION_CUSTOMER) {
                dispatch(getDispatchNavigation()(navigationActions_1.ID_UPLOAD_PAGE));
            }
            else {
                var nav = getDispatchNavigation();
                dispatch(nav(navigationActions_1.COMPLETE_PAGE));
            }
            dispatch(asyncActions_1.asyncSuccess());
        });
    };
}
exports.payOpayoDeposit = payOpayoDeposit;
