"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var deko_utilities_1 = require("deko-utilities");
var creditApplicationActions_1 = require("../../../common/actions/creditApplicationActions");
var CheckboxInput_1 = require("../../../common/components/form/CheckboxInput");
var Col_1 = require("../../../common/components/template/Col");
var ConnectedError_1 = require("../../../common/components/form/ConnectedError");
var Page_1 = require("../../components/Page");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var Row_1 = require("../../../common/components/template/Row");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
require("../../styles/pages/AmendmentPage.scss");
require("../../styles/components/InstoreSubmitModal.scss");
var Loader_1 = require("../../../common/components/Loader");
var isApplicationOfType_1 = require("../../../common/utilities/isApplicationOfType");
var Modal_1 = require("../../../common/components/Modal");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
var agreeOption = function (amendment, offer) {
    return "I understand that I am borrowing \u00A3" + deko_utilities_1.currency.formatCurrency(amendment.credit) + " at " + offer.apr + "% APR and that\n  my monthly repayment will be \u00A3" + deko_utilities_1.currency.formatCurrency(amendment.monthly_instalment) + " for " + offer.loan_term + "\n  months.";
};
var goodsDesc = function (goods) { return deko_utilities_1.truncate(goods.map(function (good) { return good.description; }).join(', '), 180); };
var AmendmentConfirmationPage = /** @class */ (function (_super) {
    __extends(AmendmentConfirmationPage, _super);
    function AmendmentConfirmationPage(props) {
        var _this = _super.call(this, props) || this;
        _this.renderInstoreModal = function () {
            var application = _this.props.application;
            var showInstoreModal = _this.state.showInstoreModal;
            if (isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, application) && showInstoreModal) {
                return (React.createElement(Modal_1.default, { className: 'instore-submit-modal' },
                    React.createElement("h1", null, "Please return the device to the customer"),
                    React.createElement("p", null,
                        React.createElement("strong", null, "The application has been amended. The customer can now complete the order.")),
                    React.createElement(CTAButton, { className: 'btn btn-default confirm col-xs-12', handleClick: _this.onCloseModal, timeout: 300 }, "Continue")));
            }
            return null;
        };
        _this.renderInfoSection = function () {
            var _a = _this.props, branding = _a.branding, application = _a.application;
            if (isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, application)) {
                return (React.createElement("div", null,
                    React.createElement("h1", null, "Your Amended Order"),
                    React.createElement("p", { className: 'amendment-page-copy' }, "Please make sure the details below are correct for your new order.")));
            }
            return (React.createElement("div", null,
                React.createElement("h1", null,
                    branding.name,
                    " has made changes to your order"),
                React.createElement("p", { className: 'amendment-page-copy' },
                    "If you have any questions regarding any of the changes below, please contact ",
                    React.createElement("strong", null, branding.name),
                    " directly.")));
        };
        _this.agreeToBorrow = function () {
            var agree = _this.state.agree;
            _this.setState({
                agree: !agree
            });
        };
        _this.acceptChanges = function () {
            if (_this.state.agree) {
                _this.props.actions.acceptAmendment(_this.props.application);
            }
        };
        _this.onCloseModal = function () {
            var showInstoreModal = _this.state.showInstoreModal;
            _this.setState({
                showInstoreModal: !showInstoreModal
            });
        };
        _this.state = {
            agree: false,
            showInstoreModal: true
        };
        return _this;
    }
    AmendmentConfirmationPage.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var amendment = nextProps.application.finance.amendment;
        if (nextProps.asyncRunning === true &&
            (typeof amendment === 'undefined' || amendment === null)) {
            return false;
        }
        return true;
    };
    AmendmentConfirmationPage.prototype.render = function () {
        var agree = this.state.agree;
        var _a = this.props, application = _a.application, asyncRunning = _a.asyncRunning;
        if (application.finance == null) {
            throw new TypeError('Finance must be set');
        }
        if (application.finance.amendment == null) {
            throw new TypeError('Amendment must be set');
        }
        var amendment = application.finance.amendment;
        var totalPrice = amendment.goods.reduce(function (acc, good) { return acc + good.price; }, 0);
        return (React.createElement(Page_1.default, { className: 'amendment-confirmation-page', sidebarContent: React.createElement("div", null), showSummaryContent: false },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'lg-10'] },
                    this.renderInfoSection(),
                    React.createElement(Col_1.default, null,
                        React.createElement(Row_1.default, { className: 'product-summary' },
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'goods-description line' },
                                React.createElement("strong", null, "Description: "),
                                React.createElement("span", { className: 'value' }, goodsDesc(amendment.goods))),
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'goods-description line' },
                                React.createElement("strong", null, "Goods price: "),
                                React.createElement("span", { className: 'value' },
                                    "\u00A3",
                                    deko_utilities_1.currency.formatCurrency(totalPrice))),
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'line' },
                                React.createElement("strong", null, "Deposit: "),
                                React.createElement("span", { className: 'value' },
                                    "\u00A3",
                                    deko_utilities_1.currency.formatCurrency(amendment.deposit))),
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'line' },
                                React.createElement("strong", null, "Loan amount: "),
                                React.createElement("span", { className: 'value' },
                                    "\u00A3",
                                    deko_utilities_1.currency.formatCurrency(amendment.credit))),
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'line' },
                                React.createElement("strong", null, "Interest: "),
                                React.createElement("span", { className: 'value' },
                                    application.finance.offer.apr,
                                    "% APR")),
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'line' },
                                React.createElement("strong", null, "Total Interest: "),
                                React.createElement("span", { className: 'value' },
                                    "\u00A3",
                                    deko_utilities_1.currency.formatCurrency(amendment.interest_payable))),
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'line' },
                                React.createElement("strong", null, "Total Payable: "),
                                React.createElement("span", { className: 'value' },
                                    "\u00A3",
                                    deko_utilities_1.currency.formatCurrency(amendment.total_payable))),
                            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'line' },
                                React.createElement("strong", null, "Repayment: "),
                                React.createElement("span", { className: 'value' },
                                    "\u00A3",
                                    deko_utilities_1.currency.formatCurrency(amendment.monthly_instalment),
                                    "/month for ",
                                    application.finance.offer.loan_term,
                                    " months")))),
                    React.createElement(Row_1.default, null,
                        React.createElement(Col_1.default, { layout: ['xs-12', 'md-10'] },
                            React.createElement("p", { className: 'consent-section' },
                                React.createElement("strong", null, "Your approval:")),
                            React.createElement(CheckboxInput_1.default, { className: 'confirm', name: 'agree-to-borrow-more', label: agreeOption(amendment, application.finance.offer), value: agree, handleChange: this.agreeToBorrow }))),
                    React.createElement(ConnectedError_1.default, null),
                    this.renderInstoreModal(),
                    React.createElement(CTAButton, { className: 'btn btn-default confirm', disabled: !agree || asyncRunning, handleClick: this.acceptChanges, timeout: 300 }, asyncRunning ? React.createElement(Loader_1.default, { title: 'Processing', loaderType: Loader_1.LoaderTypeEnum.BUTTON }) : 'Confirm my order')))));
    };
    return AmendmentConfirmationPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    branding: state.branding,
    asyncRunning: state.async.asyncRunning,
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        acceptAmendment: creditApplicationActions_1.acceptAmendment
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(AmendmentConfirmationPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.AMENDED])(connected);
