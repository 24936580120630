"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var deko_utilities_1 = require("deko-utilities");
var StickyClicky_1 = require("../../../../common/components/StickyClicky");
var CheckboxInput_1 = require("../../../../common/components/form/CheckboxInput");
var FieldValidator_1 = require("../../../../common/validation/FieldValidator");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
var baseValidator = FieldValidator_1.default();
var CheckboxInput = baseValidator(CheckboxInput_1.default);
var AlternativeFinanceForm = /** @class */ (function (_super) {
    __extends(AlternativeFinanceForm, _super);
    function AlternativeFinanceForm(props) {
        var _this = _super.call(this, props) || this;
        _this.renderConsentCheckboxes = _this.renderConsentCheckboxes.bind(_this);
        _this.shouldContinueButtonBeDisabled = _this.shouldContinueButtonBeDisabled.bind(_this);
        return _this;
    }
    AlternativeFinanceForm.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            this.renderConsentCheckboxes(),
            React.createElement("div", { className: 'buttons' },
                React.createElement(CTAButton, { submit: true, className: 'accept', timeout: 300, disabled: this.shouldContinueButtonBeDisabled(), handleClick: this.props.acceptAlternativeFinance }, "Confirm and continue"),
                React.createElement(CTAButton, { className: 'secondary decline', handleClick: this.props.declineAlternativeFinance, timeout: 0 }, "Exit Finance Application"))));
    };
    AlternativeFinanceForm.prototype.shouldContinueButtonBeDisabled = function () {
        if ((!this.props.entity.differentOption
            || !this.props.entity.carefullyConsidered)
            && this.props.renderConsent) {
            return true;
        }
    };
    AlternativeFinanceForm.prototype.renderConsentCheckboxes = function () {
        var _this = this;
        if (!this.props.application.finance || !this.props.renderConsent) {
            return null;
        }
        var finance = this.props.application.finance;
        var offer = finance.offer;
        var consent = [
            {
                name: 'differentOption',
                text: "I understand that the alternative finance offer I have selected is different from the one I originally\n      applied for and that I am now borrowing \u00A3" + deko_utilities_1.currency.formatCurrency(finance.credit) + " at " + offer.apr + "% APR  and that\n      my first monthly repayment will be \u00A3" + deko_utilities_1.currency.formatCurrency(offer.first_instalment) + " followed by\n      " + (offer.loan_term - 1) + " monthly instalments of \u00A3" + deko_utilities_1.currency.formatCurrency(offer.monthly_instalment) + "."
            },
            {
                name: 'carefullyConsidered',
                text: "I confirm that I have carefully considered the cost of this new loan against my current and expected\n      future financial commitments and that I am able to afford the monthly instalments for the duration of the credit agreement."
            }
        ];
        return consent.map(function (key, i) {
            return (React.createElement(CheckboxInput, { key: i, name: key.name, label: key.text, value: _this.props.entity[key.name], handleChange: _this.props.handleChange }));
        });
    };
    return AlternativeFinanceForm;
}(React.Component));
exports.default = AlternativeFinanceForm;
