"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var config_1 = require("../../config/config");
var inversify_1 = require("inversify");
require("reflect-metadata");
var endpoint = config_1.default.apiBase;
var MorpheusApi = /** @class */ (function () {
    function MorpheusApi() {
        this.checkJson = this.checkJson.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
    }
    MorpheusApi.prototype.rewardExperiment = function (experimentId, token) {
        var request = new Request(endpoint + "morpheus/reward", __assign(__assign({}, this.getInit(token)), { method: 'POST', body: JSON.stringify({
                experiment_id: experimentId,
                session_id: token
            }) }));
        fetch(request);
    };
    MorpheusApi.prototype.getNextVariant = function (experimentId, token, lenderId) {
        var input = endpoint + "morpheus/next-variant?"
            + ("lender_id=" + lenderId + "&experiment_id=" + experimentId + "&session_id=" + token);
        var request = new Request(input, __assign(__assign({}, this.getInit(token)), { method: 'GET' }));
        return Promise.race([
            fetch(request).then(this.checkStatus),
            new Promise(function (resolve) {
                return setTimeout(function () { return resolve({ variant_id: null }); }, 5000);
            })
        ]);
    };
    MorpheusApi.prototype.getInit = function (token, forPost) {
        if (forPost === void 0) { forPost = true; }
        var headers = { Authorization: "Bearer " + token };
        if (forPost) {
            headers['Content-Type'] = 'application/json';
        }
        return {
            headers: new Headers(__assign({}, headers)),
            mode: 'cors'
        };
    };
    MorpheusApi.prototype.checkStatus = function (res) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (res.ok) {
                _this.checkJson(res).then(resolve);
            }
            else {
                _this.checkJson(res).then(function (body) {
                    reject({
                        status: res.status,
                        statusText: res.statusText,
                        error: body.error,
                    });
                });
            }
        });
    };
    MorpheusApi.prototype.checkJson = function (res) {
        return new Promise(function (resolve) {
            if (res.headers.get('Content-Type').indexOf('application/json') === 0) {
                res.json().then(resolve);
            }
            else {
                resolve(res);
            }
        });
    };
    MorpheusApi = __decorate([
        inversify_1.injectable(),
        __metadata("design:paramtypes", [])
    ], MorpheusApi);
    return MorpheusApi;
}());
exports.default = MorpheusApi;
