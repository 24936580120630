"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isApplicationOfType = void 0;
var isApplicationOfType = function (type, application) {
    return application.finance
        && application.finance
        && application.finance.product
        && application.finance.product.loan_type === type;
};
exports.isApplicationOfType = isApplicationOfType;
