"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAlternativeFinance = exports.updateAlternativeFinanceAction = exports.acceptAlternativeFinance = exports.acceptAlternativeFinanceAction = void 0;
var types = require("./actionTypes");
var navigationActions_1 = require("./navigationActions");
var inversify_container_1 = require("../inversify.container");
var inversify_types_1 = require("../inversify.types");
function getDispatchNavigation() {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
}
function acceptAlternativeFinanceAction() {
    return { type: types.ALTERNATIVE_FINANCE_SUBMIT };
}
exports.acceptAlternativeFinanceAction = acceptAlternativeFinanceAction;
function acceptAlternativeFinance() {
    return function (dispatch) {
        dispatch(acceptAlternativeFinanceAction());
        dispatch(getDispatchNavigation()(navigationActions_1.DOCUMENTS_PAGE));
    };
}
exports.acceptAlternativeFinance = acceptAlternativeFinance;
function updateAlternativeFinanceAction(alternativeFinance) {
    return { type: types.ALTERNATIVE_FINANCE_UPDATE, alternativeFinance: alternativeFinance };
}
exports.updateAlternativeFinanceAction = updateAlternativeFinanceAction;
function updateAlternativeFinance(alternativeFinance) {
    return function (dispatch) {
        dispatch(updateAlternativeFinanceAction(alternativeFinance));
    };
}
exports.updateAlternativeFinance = updateAlternativeFinance;
