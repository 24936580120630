"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackInteractions = exports.trackInteractionsAction = void 0;
var actionTypes_1 = require("./actionTypes");
function trackInteractionsAction(eventName, field, label) {
    return {
        type: actionTypes_1.TRACK_ENTITY,
        trackingInfo: {
            event: eventName,
            category: eventName,
            field: field,
            label: label
        }
    };
}
exports.trackInteractionsAction = trackInteractionsAction;
function trackInteractions(eventName, field, label) {
    return function (dispatch) {
        dispatch(trackInteractionsAction(eventName, field, label));
    };
}
exports.trackInteractions = trackInteractions;
