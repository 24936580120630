"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_player_1 = require("react-player");
var Video = function (_a) {
    var videoId = _a.videoId, onVideoEnd = _a.onVideoEnd, className = _a.className;
    var props = {
        width: '100%',
        controls: true,
        className: className,
        url: "https://www.youtube.com/watch?v=" + videoId,
        onEnded: onVideoEnd
    };
    return (React.createElement(react_player_1.default, __assign({}, props)));
};
exports.default = Video;
