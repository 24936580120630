"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var react_redux_1 = require("react-redux");
var Page_1 = require("../../components/Page");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var ApplicationOutcomes_1 = require("../../../common/components/ApplicationOutcomes");
var BackToRetailerButton_1 = require("../../../common/components/BackToRetailerButton");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
require("../../styles/pages/TheEndPage.scss");
var LenderComponentProvider_1 = require("../../components/LenderComponentProvider");
var redux_1 = require("redux");
var isApplicationOfType_1 = require("../../../common/utilities/isApplicationOfType");
var Loader_1 = require("../../../common/components/Loader");
var CompletePage = /** @class */ (function (_super) {
    __extends(CompletePage, _super);
    function CompletePage(props) {
        return _super.call(this, props) || this;
    }
    CompletePage.prototype.componentDidMount = function () {
        var finance = this.props.application.finance;
        window.parent.postMessage({
            message: 'APPLICATION_STATUS_UPDATE',
            contents: {
                status: this.props.application.status,
                url: this.props.application.retailer.success_url ? this.props.application.retailer.success_url : '',
                goods: !finance ? '' : finance.goods ? finance.goods : '',
                reference: this.props.application.unique_reference
            }
        }, '*');
    };
    CompletePage.prototype.render = function () {
        var _a = this.props, application = _a.application, branding = _a.branding, retailer = _a.retailer;
        var lender = application.lender;
        var isInstore = isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, application);
        if (retailer.auto_redirect && retailer.success_url && !isInstore) {
            this.props.actions.dispatchExternalNavigation(retailer.success_url);
            return React.createElement(Loader_1.default, null);
        }
        return (React.createElement(Page_1.default, { className: 'complete-page' },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                        var CompleteContent = _a.CompleteContent;
                        return (React.createElement(CompleteContent, __assign({}, { lender: lender, branding: branding, application: application })));
                    }),
                    React.createElement("div", { className: 'bottom' },
                        React.createElement(BackToRetailerButton_1.default, { status: ApplicationOutcomes_1.AppOutcomeEnum.SUCCESS }))))));
    };
    return CompletePage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application,
    branding: state.branding,
    retailer: state.application.retailer
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchExternalNavigation: BackToRetailerButton_1.getDispatchExternalNavigation()
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(CompletePage);
exports.default = RequiredStatus_1.default([
    IApplication_1.AppStatusEnum.VERIFIED,
    IApplication_1.AppStatusEnum.ACTION_LENDER,
    IApplication_1.AppStatusEnum.FULFILLED,
    IApplication_1.AppStatusEnum.COMPLETE
])(connected);
