"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var defaultDeleteIcon = require('../../assets/bin.svg');
var defaultPreviewIcon = require('../../assets/lens.svg');
var DropzoneActionsBar = function (_a) {
    var _b = _a.previewIcon, previewIcon = _b === void 0 ? defaultPreviewIcon : _b, displayModalDeleteImage = _a.displayModalDeleteImage, openModal = _a.openModal, visible = _a.visible, _c = _a.deleteIcon, deleteIcon = _c === void 0 ? defaultDeleteIcon : _c;
    return (!visible) ? null : (React.createElement("div", { className: 'actions-bar' },
        React.createElement("i", { onClick: openModal, className: 'hidden-xs' },
            React.createElement(deko_common_components_1.Image, { src: previewIcon })),
        React.createElement("i", { onClick: displayModalDeleteImage },
            React.createElement(deko_common_components_1.Image, { src: deleteIcon }))));
};
exports.default = DropzoneActionsBar;
