"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("../../magi/styles/components/Page.scss");
var Row_1 = require("../../common/components/template/Row");
var Col_1 = require("../../common/components/template/Col");
var react_redux_1 = require("react-redux");
var SummaryProvider_1 = require("../../common/components/SummaryProvider");
var Drawer_1 = require("../../common/components/Drawer");
var Main_1 = require("../../common/components/Main");
var Sidebar_1 = require("./template/Sidebar");
var Header_1 = require("./template/Header");
var Footer_1 = require("./template/Footer");
var Summary_1 = require("./Summary");
var IApplication_1 = require("../../common/api/CreditApplication/IApplication");
var PageTitle_1 = require("../../common/components/form/PageTitle");
var LenderComponentProvider_1 = require("./LenderComponentProvider");
var ConnectedPromotionProvider_1 = require("../../common/components/ConnectedPromotionProvider");
var defaultLogo = require('../../common/assets/deko-logo-full-colour.svg');
var Page = /** @class */ (function (_super) {
    __extends(Page, _super);
    function Page(props) {
        return _super.call(this, props) || this;
    }
    Page.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, children = _a.children, _b = _a.mainCols, mainCols = _b === void 0 ? 8 : _b, _c = _a.mainOffset, mainOffset = _c === void 0 ? 0 : _c, _d = _a.showSummaryContent, showSummaryContent = _d === void 0 ? true : _d, _e = _a.tradingName, tradingName = _e === void 0 ? 'Deko' : _e, logo = _a.logo, status = _a.status, lenderIsPanel = _a.lenderIsPanel;
        var renderSummary = function (summaryProps) { return (React.createElement(Summary_1.default, __assign({}, summaryProps))); };
        var summaryProviderElement = [
            React.createElement(SummaryProvider_1.default, { key: 'summary', render: renderSummary }),
            React.createElement(ConnectedPromotionProvider_1.default, { key: 'promotion' })
        ];
        if (status === IApplication_1.AppStatusEnum.DECLINE && lenderIsPanel) {
            summaryProviderElement = [];
        }
        var stickyContent = (showSummaryContent) ? summaryProviderElement : null;
        var wrapperClass = ['page'];
        if (className) {
            wrapperClass.push(className);
        }
        var sidebarContent = this.props.sidebarContent;
        if (!sidebarContent && showSummaryContent) {
            sidebarContent = summaryProviderElement;
        }
        if (!showSummaryContent) {
            wrapperClass.push('no-summary-content');
        }
        if (this.props.asyncRunning) {
            wrapperClass.push('disabled');
        }
        var defaultTitle = tradingName + " | Apply for Credit";
        return (React.createElement(LenderComponentProvider_1.default, null, function (_a) {
            var LenderLogo = _a.LenderLogo;
            return (React.createElement("div", { className: wrapperClass.join(' ') },
                React.createElement(PageTitle_1.default, { title: _this.props.pageTitle ? _this.props.pageTitle : defaultTitle }),
                React.createElement(Drawer_1.default, { headerHeight: 50, header: React.createElement(Header_1.default, { logo: logo ? logo : LenderLogo }) }, stickyContent),
                React.createElement("div", { className: 'container main-container' },
                    React.createElement(Row_1.default, { className: 'equal' },
                        React.createElement(Col_1.default, { layout: ['xs-12', ("sm-" + mainCols), ("sm-offset-" + mainOffset)], className: 'main-content-wrapper' },
                            React.createElement(Main_1.default, null,
                                React.createElement(Col_1.default, null, children))),
                        React.createElement(Col_1.default, { layout: ['xs-12', 'sm-4', "sm-offset-" + Math.max(8 - mainCols - mainOffset, 0)], className: 'sidebar-wrapper' },
                            React.createElement(Sidebar_1.default, { logo: logo ? logo : LenderLogo }, sidebarContent)))),
                React.createElement(Footer_1.default, null)));
        }));
    };
    return Page;
}(React.Component));
var mapStateToProps = function (state) {
    var brandingConfig = (state.branding && state.branding.config) ? state.branding.config : {};
    return {
        tradingName: brandingConfig.retailer_trading_name,
        logo: brandingConfig.logo,
        asyncRunning: state.async.asyncRunning,
        status: state.application.status,
        lenderIsPanel: state.branding.lender_is_panel
    };
};
exports.default = react_redux_1.connect(mapStateToProps)(Page);
