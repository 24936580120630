"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var getNextStep_1 = require("../utilities/getNextStep");
var StickyClicky_1 = require("./StickyClicky");
var inversify_types_1 = require("../inversify.types");
var inversify_container_1 = require("../inversify.container");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
function getDispatchNavigation() {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchNavigation);
}
var ContinueApplicationButton = /** @class */ (function (_super) {
    __extends(ContinueApplicationButton, _super);
    function ContinueApplicationButton(props) {
        var _this = _super.call(this, props) || this;
        _this.onClick = function () {
            if (_this.state.nextStep == null) {
                throw new Error('Next step is undefined');
            }
            _this.props.actions.dispatchNavigation(_this.state.nextStep.action);
        };
        _this.state = {
            nextStep: undefined,
        };
        return _this;
    }
    ContinueApplicationButton.prototype.componentDidUpdate = function () {
        if (this.state.nextStep === undefined) {
            this.setState({
                nextStep: getNextStep_1.getNextStep(this.props.application)
            });
        }
        return;
    };
    ContinueApplicationButton.prototype.componentDidMount = function () {
        this.setState({
            nextStep: getNextStep_1.getNextStep(this.props.application)
        });
    };
    ContinueApplicationButton.prototype.render = function () {
        if (this.state.nextStep === undefined) {
            return null;
        }
        return (React.createElement(CTAButton, { className: 'submit', handleClick: this.onClick, timeout: 300 }, this.state.nextStep.message));
    };
    return ContinueApplicationButton;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: getDispatchNavigation()
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ContinueApplicationButton);
