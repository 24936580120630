"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes = require("../actions/actionTypes");
var handleAsync = function (state, action) {
    var asyncRunning = action.type === actionTypes.ASYNC_BEGIN;
    return __assign(__assign({}, state), { asyncRunning: asyncRunning });
};
var handleFeatureAsync = function (state, action) {
    var featureRequestInFlight = action.type === actionTypes.ASYNC_FEATURE_REQUEST_BEGIN;
    return __assign(__assign({}, state), { featureRequestInFlight: featureRequestInFlight });
};
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes.ASYNC_BEGIN] = handleAsync,
    _a[actionTypes.ASYNC_SUCCESS] = handleAsync,
    _a[actionTypes.ASYNC_FAILURE] = handleAsync,
    _a[actionTypes.ASYNC_FEATURE_REQUEST_BEGIN] = handleFeatureAsync,
    _a[actionTypes.ASYNC_FEATURE_REQUEST_SUCCESS] = handleFeatureAsync,
    _a[actionTypes.ASYNC_FEATURE_REQUEST_FAILURE] = handleFeatureAsync,
    _a), {});
