"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDocumentConfig = void 0;
var dekoDocumentConfig_1 = require("./config/dekoDocumentConfig");
var map = (_a = {},
    _a['default'] = dekoDocumentConfig_1.default,
    _a);
/**
 * Takes consumer documents and returns the sorted config
 * @param {IDocumentCollection} application
 * @returns {{}[]}
 */
var getDocumentConfig = function (application) {
    var docs = map[application.lender.name] ? map[application.lender.name] : map.default;
    var customerDocuments = application.consumer.documents;
    if (!customerDocuments) {
        throw Error('Customer has no documents');
    }
    var validKeys = [];
    docs.forEach(function (doc) {
        validKeys[doc.key] = doc.key;
    });
    var unsupported = Object.keys(customerDocuments).filter(function (doc) { return validKeys[doc] === undefined; });
    if (unsupported.length > 0) {
        throw Error("Unsupported documents: " + unsupported.join(' '));
    }
    return docs.map(function (doc) {
        if (customerDocuments[doc.key]) {
            return __assign(__assign({}, doc), customerDocuments[doc.key]);
        }
    }).filter(function (doc) { return doc; });
};
exports.getDocumentConfig = getDocumentConfig;
