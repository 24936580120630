"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var NextButton_1 = require("../../common/components/form/NextButton");
var spaced = { marginLeft: '1.5em' };
var JumioErrorInfo = /** @class */ (function (_super) {
    __extends(JumioErrorInfo, _super);
    function JumioErrorInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JumioErrorInfo.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement("h2", null, "Your ID upload was not successful"),
            this.messageFromCode(this.props.code),
            React.createElement(NextButton_1.default, { handleClick: this.props.dismiss }, "Upload your ID"));
    };
    JumioErrorInfo.prototype.messageFromCode = function (errorCode) {
        switch (errorCode) {
            case (9820):
            case (9822):
                return React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        errorCode,
                        " - It appears there has been an issue with your ID upload."),
                    React.createElement("p", null, "Here are some reasons this may have happened:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Your device does not have a camera"),
                        React.createElement("li", null, "Your web browser does not support your camera"),
                        React.createElement("li", null, "Your camera has been disabled within your web browser")),
                    React.createElement("p", null, "If you continue to see this issue, please check the camera settings on your device or try again with an alternative device or web browser."));
            case (9100):
            case (9200):
            case (9300):
            case (9400):
            case (9800):
            case (9801):
            case (9810):
                return React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        errorCode,
                        " - It appears there has been an issue with your ID upload. Please click the button below to try again."),
                    React.createElement("p", null, "If you continue to see this issue, please try the following steps:"),
                    React.createElement("ol", { style: spaced },
                        React.createElement("li", { style: spaced }, "Try using a different web browser"),
                        React.createElement("li", { style: spaced }, "Attempt to clear your cached data"),
                        React.createElement("li", { style: spaced }, "Try using a different device")));
            case (9210):
                return React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        errorCode,
                        " - Your session has expired. Please click the button below to try again."));
            default:
                return React.createElement(React.Fragment, null,
                    React.createElement("p", null, "It seems there is an issue with your uploaded image. This happens sometimes. Please click below to try again, or come back later."),
                    React.createElement("p", null, "Here are some reasons this may have happened:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "The image quality was too low"),
                        React.createElement("li", null, "The image was uploaded in black and white"),
                        React.createElement("li", null, "The ID is oriented incorrectly or was uploaded at an obscure angle"),
                        React.createElement("li", null, "The ID has expired"),
                        React.createElement("li", null, "The ID service is experiencing temporary problems")));
        }
    };
    return JumioErrorInfo;
}(React.Component));
exports.default = JumioErrorInfo;
