"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var IApplication_1 = require("../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../common/components/RequiredStatus");
var Page_1 = require("../components/Page");
var Row_1 = require("../../common/components/template/Row");
var Col_1 = require("../../common/components/template/Col");
var TelephoneEndPage = /** @class */ (function (_super) {
    __extends(TelephoneEndPage, _super);
    function TelephoneEndPage(props) {
        var _this = _super.call(this, props) || this;
        _this.acceptContent = function (lender, term) {
            return (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "Application Approved"),
                React.createElement("p", { className: 'readout' },
                    React.createElement("strong", null, "Please read out the following to the customer:")),
                React.createElement("p", null,
                    "Great news! You've been approved to spread the cost of your purchase over ",
                    React.createElement("strong", null, term),
                    " months by the lender, ",
                    React.createElement("strong", null, lender.name),
                    "."),
                React.createElement("p", null, "Please follow the instructions that have been emailed to you to finalise your purchase. Once this has been done, we'll get the order processed for you.")));
        };
        _this.referContent = function (lender) {
            return (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "Awaiting Decision"),
                React.createElement("p", { className: 'readout' },
                    React.createElement("strong", null, "Please read out the following to the customer:")),
                React.createElement("p", null,
                    "Your application to spread the cost of your purchase needs a few more checks by the lender, ",
                    React.createElement("strong", null, lender.name),
                    "."),
                React.createElement("p", null,
                    "This happens sometimes and ",
                    React.createElement("strong", null, lender.name),
                    " usually takes around one hour to get back to you."),
                React.createElement("p", null, "Once the checks have been completed, you'll be notified regarding the outcome of your application by email. Please follow the instructions in the email to finalise your purchase.")));
        };
        _this.declineContent = function (lender) {
            return (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "Application Declined"),
                React.createElement("p", { className: 'readout' },
                    React.createElement("strong", null, "Please read out the following to the customer:")),
                React.createElement("p", null,
                    "Unfortunately the Lender, ",
                    React.createElement("strong", null, lender.name),
                    ", was unable to approve your application for credit on this occasion."),
                React.createElement("p", null,
                    "I'm afraid that at this time I'm unable to give you any more details, but if you would like to find out more you can contact ",
                    React.createElement("strong", null, lender.name),
                    " directly. Would you like me to give you their details?"),
                React.createElement("p", null,
                    React.createElement("strong", null, "If yes: ")),
                React.createElement("p", null,
                    "You can contact ",
                    React.createElement("strong", null, lender.name),
                    " by phone on ",
                    React.createElement("strong", null, lender.contact_information.phone),
                    " or by email at ",
                    React.createElement("strong", null, lender.contact_information.email),
                    ".")));
        };
        _this.renderContentByDecision = function (application, finance) {
            var status = application.status;
            var lender = application.lender;
            var term = (status === 'ACCEPT') ? finance.offer.loan_term.toString() : '';
            switch (status) {
                case 'ACCEPT':
                    return _this.acceptContent(lender, term);
                case 'DECLINE':
                    return _this.declineContent(lender);
                default:
                    return _this.referContent(lender);
            }
        };
        return _this;
    }
    TelephoneEndPage.prototype.render = function () {
        return (React.createElement(Page_1.default, { className: 'telephone-end-page' },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    this.renderContentByDecision(this.props.application, this.props.finance),
                    React.createElement("div", { className: 'bottom' },
                        React.createElement(deko_common_components_1.Button, { handleClick: window.close }, "Close this window"))))));
    };
    return TelephoneEndPage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    application: state.application,
    finance: state.application.finance
}); };
var connected = react_redux_1.connect(mapStateToProps, {})(TelephoneEndPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.DECLINE, IApplication_1.AppStatusEnum.ACCEPT, IApplication_1.AppStatusEnum.REFER])(connected);
