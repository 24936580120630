"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceErrors = exports.replaceErrorsAction = exports.addError = exports.addErrorAction = void 0;
var types = require("../actions/actionTypes");
function addErrorAction(errors) {
    return { type: types.ADD_ERROR, errors: errors };
}
exports.addErrorAction = addErrorAction;
function addError(errors) {
    return function (dispatch) {
        dispatch(addErrorAction(errors));
    };
}
exports.addError = addError;
function replaceErrorsAction(errors) {
    return { type: types.REPLACE_ERRORS, errors: errors };
}
exports.replaceErrorsAction = replaceErrorsAction;
function replaceErrors(errors) {
    return function (dispatch) {
        dispatch(replaceErrorsAction(errors));
    };
}
exports.replaceErrors = replaceErrors;
