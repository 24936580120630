"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var PropTypes = require("prop-types");
var OnlineDepositForm_1 = require("./form/OnlineDepositForm");
var EntityForm_1 = require("../../../common/components/form/EntityForm");
var redux_1 = require("redux");
var depositActions_1 = require("../../../common/actions/depositActions");
var react_redux_1 = require("react-redux");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var ThreeDSecureForm_1 = require("../../../common/pages/deposit/form/ThreeDSecureForm");
var OnlineDepositValidator_1 = require("../../validators/OnlineDepositValidator");
var ValidationManager_1 = require("../../../common/validation/ValidationManager");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var Page_1 = require("../../components/Page");
var navigationActions_1 = require("../../actions/navigationActions");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var navigationActions_2 = require("../../../common/actions/navigationActions");
var lodash_1 = require("lodash");
var OpayoForm_1 = require("../../../common/components/form/opayo/OpayoForm");
var FeatureToggle_1 = require("../../components/FeatureToggle");
var featureFlagActions_1 = require("../../../common/actions/featureFlagActions");
var Context;
(function (Context) {
    Context["RETAILER"] = "RETAILER";
    Context["LENDER"] = "LENDER";
})(Context || (Context = {}));
var OnlineDepositPage = /** @class */ (function (_super) {
    __extends(OnlineDepositPage, _super);
    function OnlineDepositPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = { hasValidated: false };
        _this.props.actions.featureFlagConfig('use-opayo', _this.props.application.lender.id, Context.LENDER).then(function (featureFlag) {
            if (!featureFlag['use-opayo']) {
                window.addEventListener('message', function (message) {
                    var location = window.location;
                    var origin = location.protocol + "//" + (location.hostname + (location.port ? ":" + location.port : ''));
                    if (message.origin !== origin) {
                        return;
                    }
                    if (!lodash_1.includes(['success', 'initialize', 'fail', 'cancel', 'refund', 'processing'], message.data.status)) {
                        return;
                    }
                    _this.props.actions.handle3DSecureResponse(message.data.status, message.data.error);
                }, false);
            }
        });
        return _this;
    }
    OnlineDepositPage.prototype.getChildContext = function () {
        return { validationManager: new ValidationManager_1.default(OnlineDepositValidator_1.default), entityName: 'deposit.card_details' };
    };
    OnlineDepositPage.prototype.handleSuccess = function () {
        this.props.actions.payOpayoDeposit();
    };
    OnlineDepositPage.prototype.render = function () {
        var identifier = this.props.application.lender.id;
        if (this.props.deposit.status === IApplication_1.DepositStateEnum.THREE_D_SECURE) {
            return (React.createElement(ThreeDSecureForm_1.default, { token: this.props.token, threeDSecure: this.props.deposit.three_d_secure }));
        }
        return (React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: true, fallbackAction: navigationActions_2.DOCUMENTS_PAGE },
            React.createElement(Page_1.default, { mainCols: 5, mainOffset: 2 },
                React.createElement(FeatureToggle_1.default, { name: 'use-opayo', context: Context.LENDER, identifier: identifier, off_content: React.createElement(EntityForm_1.default, { className: 'apply', entityName: 'deposit.card_details', updateEntity: depositActions_1.updateCardDetails, handleSubmit: depositActions_1.payDeposit }, function (props) { return React.createElement(OnlineDepositForm_1.default, __assign({}, props)); }), on_content: React.createElement(OpayoForm_1.default, { application: this.props.application, handler: this.handleSuccess.bind(this), token: this.props.token }) }))));
    };
    OnlineDepositPage.childContextTypes = {
        validationManager: PropTypes.instanceOf(ValidationManager_1.default),
        entityName: PropTypes.string
    };
    return OnlineDepositPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    token: state.token,
    deposit: state.deposit,
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_1.dispatchNavigation,
        payDeposit: depositActions_1.payDeposit,
        handle3DSecureResponse: depositActions_1.handle3DSecureResponse,
        payOpayoDeposit: depositActions_1.payOpayoDeposit,
        featureFlagConfig: featureFlagActions_1.featureFlagConfig,
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(OnlineDepositPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT])(connected);
