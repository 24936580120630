"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InstoreVerificationTypeEnum = exports.CancellationCodeEnum = exports.ResidentialStatusEnum = exports.AffordabilityExpenditureEnum = exports.EmploymentSalaryEnum = exports.EmploymentStatusEnum = exports.MaritalStatusEnum = exports.LoanTypeEnum = exports.DepositStateEnum = exports.AppStatusEnum = exports.TitleEnum = void 0;
var TitleEnum;
(function (TitleEnum) {
    TitleEnum["MR"] = "Mr";
    TitleEnum["MRS"] = "Mrs";
    TitleEnum["MISS"] = "Miss";
    TitleEnum["MS"] = "Ms";
})(TitleEnum = exports.TitleEnum || (exports.TitleEnum = {}));
var AppStatusEnum;
(function (AppStatusEnum) {
    AppStatusEnum["INIT"] = "INIT";
    AppStatusEnum["NO_DECISION"] = "NO-DECISION";
    AppStatusEnum["ACCEPT"] = "ACCEPT";
    AppStatusEnum["PRE_APPROVE"] = "PRE-APPROVE";
    AppStatusEnum["FULFILLED"] = "FULFILLED";
    AppStatusEnum["DECLINE"] = "DECLINE";
    AppStatusEnum["REFER"] = "REFER";
    AppStatusEnum["VERIFIED"] = "VERIFIED";
    AppStatusEnum["CANCEL"] = "CANCEL";
    AppStatusEnum["ACTION_CUSTOMER"] = "ACTION-CUSTOMER";
    AppStatusEnum["ACTION_LENDER"] = "ACTION-LENDER";
    AppStatusEnum["AMENDED"] = "AMENDED";
    AppStatusEnum["COMPLETE"] = "COMPLETE";
})(AppStatusEnum = exports.AppStatusEnum || (exports.AppStatusEnum = {}));
var DepositStateEnum;
(function (DepositStateEnum) {
    DepositStateEnum["ERROR"] = "ERROR";
    DepositStateEnum["NOT_PAID"] = "NOT_PAID";
    DepositStateEnum["PAYING"] = "PAYING";
    DepositStateEnum["PAID"] = "PAID";
    DepositStateEnum["THREE_D_SECURE"] = "3DAUTH";
    DepositStateEnum["PROCESSING_3D_SECURE"] = "PROCESSING_3D_SECURE";
})(DepositStateEnum = exports.DepositStateEnum || (exports.DepositStateEnum = {}));
var LoanTypeEnum;
(function (LoanTypeEnum) {
    LoanTypeEnum["IS"] = "IS";
    LoanTypeEnum["ON"] = "ON";
    LoanTypeEnum["BL"] = "BL";
    LoanTypeEnum["PL"] = "PL";
    LoanTypeEnum["IH"] = "IH";
    LoanTypeEnum["CC"] = "CC";
})(LoanTypeEnum = exports.LoanTypeEnum || (exports.LoanTypeEnum = {}));
var MaritalStatusEnum;
(function (MaritalStatusEnum) {
    MaritalStatusEnum["MARRIED"] = "married";
    MaritalStatusEnum["SINGLE"] = "single";
    MaritalStatusEnum["COHABITING"] = "cohabiting";
    MaritalStatusEnum["DIVORCED"] = "divorced";
    MaritalStatusEnum["SEPERATED"] = "separated";
    MaritalStatusEnum["WIDOWED"] = "widowed";
})(MaritalStatusEnum = exports.MaritalStatusEnum || (exports.MaritalStatusEnum = {}));
var EmploymentStatusEnum;
(function (EmploymentStatusEnum) {
    EmploymentStatusEnum["FULL_TIME"] = "FullTime";
    EmploymentStatusEnum["PART_TIME"] = "PartTime";
    EmploymentStatusEnum["SELF_EMPLOYED"] = "SelfEmployed";
    EmploymentStatusEnum["STUDENT"] = "Student";
    EmploymentStatusEnum["MILITARY"] = "Military";
    EmploymentStatusEnum["RETIRED"] = "Retired";
    EmploymentStatusEnum["HOMEMAKER"] = "HomeMaker";
    EmploymentStatusEnum["NOT_EMPLOYED"] = "NotEmployed";
})(EmploymentStatusEnum = exports.EmploymentStatusEnum || (exports.EmploymentStatusEnum = {}));
var EmploymentSalaryEnum;
(function (EmploymentSalaryEnum) {
    EmploymentSalaryEnum["SALARY_GROSS_ANNUAL"] = "GROSS_ANNUAL";
    EmploymentSalaryEnum["SALARY_NET_MONTHLY"] = "NET_MONTHLY";
})(EmploymentSalaryEnum = exports.EmploymentSalaryEnum || (exports.EmploymentSalaryEnum = {}));
var AffordabilityExpenditureEnum;
(function (AffordabilityExpenditureEnum) {
    AffordabilityExpenditureEnum["HOUSEHOLD_INCOME"] = "HOUSEHOLD_INCOME";
    AffordabilityExpenditureEnum["RENT_OR_MORTGAGE"] = "MONTHLY_RENT_OR_MORTGAGE";
})(AffordabilityExpenditureEnum = exports.AffordabilityExpenditureEnum || (exports.AffordabilityExpenditureEnum = {}));
var ResidentialStatusEnum;
(function (ResidentialStatusEnum) {
    ResidentialStatusEnum["RESIDENTIAL_HOMEOWNER"] = "owner";
    ResidentialStatusEnum["RESIDENTIAL_TENANT"] = "tenant";
    ResidentialStatusEnum["RESIDENTIAL_LIVING_WITH_PARENTS"] = "living_with_parents";
    ResidentialStatusEnum["RESIDENTIAL_COUNCIL_RENTING"] = "tenant_council";
})(ResidentialStatusEnum = exports.ResidentialStatusEnum || (exports.ResidentialStatusEnum = {}));
var CancellationCodeEnum;
(function (CancellationCodeEnum) {
    CancellationCodeEnum["AUTOCANCEL"] = "autocancel";
    CancellationCodeEnum["CANCELLED_BY_MERCHANT"] = "cancelled-by-merchant";
    CancellationCodeEnum["NO_CODE"] = "no-code";
    CancellationCodeEnum["MASS_CANCELLATION"] = "mass-cancellation";
    CancellationCodeEnum["CANCELLED_BY_LENDER"] = "cancelled-by-lender";
    CancellationCodeEnum["CANCELLED_BY_SYSTEM"] = "cancelled-by-system";
    CancellationCodeEnum["CANCELLED_BY_USER"] = "cancelled-by-user";
})(CancellationCodeEnum = exports.CancellationCodeEnum || (exports.CancellationCodeEnum = {}));
var InstoreVerificationTypeEnum;
(function (InstoreVerificationTypeEnum) {
    InstoreVerificationTypeEnum["CHIP_PIN"] = "chip_pin";
    InstoreVerificationTypeEnum["CHIP_PIN_ID"] = "chip_pin_id";
    InstoreVerificationTypeEnum["OTHER"] = "other";
})(InstoreVerificationTypeEnum = exports.InstoreVerificationTypeEnum || (exports.InstoreVerificationTypeEnum = {}));
