"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
function Form(_a) {
    var _b = _a.children, children = _b === void 0 ? null : _b, handleSubmit = _a.handleSubmit;
    var onSubmit = function (e) {
        e.preventDefault();
        handleSubmit();
    };
    return (React.createElement("form", { onSubmit: onSubmit }, children));
}
exports.default = Form;
