"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var PropTypes = require("prop-types");
var deko_utilities_1 = require("deko-utilities");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var Page_1 = require("../../components/Page");
var ValidationManager_1 = require("../../../common/validation/ValidationManager");
var IdUploadFormValidator_1 = require("../../validators/IdUploadFormValidator");
var idUploadActions_1 = require("../../../common/actions/idUploadActions");
var gaActions_1 = require("../../../common/actions/gaActions");
var errorsActions_1 = require("../../../common/actions/errorsActions");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var creditApplicationActions_1 = require("../../../common/actions/creditApplicationActions");
require("../../styles/pages/IdUploadPage.scss");
require("../../styles/components/form/MagiIdModal.scss");
var Jumio_1 = require("../../components/Jumio");
// TODO: Verify application has not already got a pending ID upload. This requires API work
var IdUpload = /** @class */ (function (_super) {
    __extends(IdUpload, _super);
    function IdUpload(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        _this.handleEntityChange = _this.handleEntityChange.bind(_this);
        return _this;
    }
    IdUpload.prototype.getChildContext = function () {
        return { validationManager: new ValidationManager_1.default(IdUploadFormValidator_1.default), entityName: 'idUpload' };
    };
    IdUpload.prototype.handleEntityChange = function (event) {
        var field = event.target.name;
        var value = event.target.value;
        if (field === 'selected_id') {
            this.props.actions.selectIdType(value);
            this.props.actions.replaceErrors({});
        }
        else {
            var entity = {};
            deko_utilities_1.objectAccess.setKey(field, entity, value);
            this.props.actions.updateId(entity);
        }
    };
    IdUpload.prototype.render = function () {
        return (React.createElement(Page_1.default, { className: 'id-upload', mainCols: 5, mainOffset: 2 },
            React.createElement("h1", null, "Upload your proof of ID"),
            React.createElement("p", { className: 'preamble' }, "Protecting your identity is our number one priority. That's why we need to verify you're you."),
            React.createElement(Jumio_1.default, null)));
    };
    IdUpload.prototype.handleSubmit = function () {
        var _this = this;
        return function () {
            _this.props.actions.trackInteractions('ID Upload', 'id-submitted', _this.props.idUpload.selected_id);
            var files = _this.props.idUpload.filesBase64;
            var filesBase64 = {
                front_image: files.front_image ? files.front_image.content : null,
                rear_image: files.rear_image ? files.rear_image.content : null
            };
            _this.props.actions.submitInstoreIdUpload(_this.props.application, filesBase64);
        };
    };
    IdUpload.childContextTypes = {
        validationManager: PropTypes.instanceOf(ValidationManager_1.default),
        entityName: PropTypes.string
    };
    return IdUpload;
}(React.Component));
var mapStateToProps = function (state) { return ({
    lenderName: state.application.lender.name,
    application: state.application,
    idUpload: state.idUpload,
    asyncRunning: state.async.asyncRunning,
    errors: state.errors,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        updateId: idUploadActions_1.updateId,
        submitInstoreIdUpload: creditApplicationActions_1.submitInstoreIdUpload,
        selectIdType: idUploadActions_1.selectIdType,
        trackInteractions: gaActions_1.trackInteractions,
        replaceErrors: errorsActions_1.replaceErrors,
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(IdUpload);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACTION_CUSTOMER])(connected);
