"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageState = void 0;
var MessageState;
(function (MessageState) {
    MessageState["NOT_SENT"] = "NOT_SENT";
    MessageState["SENDING"] = "SENDING";
    MessageState["SENT"] = "SENT";
    MessageState["VERIFYING"] = "VERIFYING";
    MessageState["VERIFIED"] = "VERIFIED";
})(MessageState = exports.MessageState || (exports.MessageState = {}));
