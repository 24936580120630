"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("redux");
var reducers_1 = require("../reducers");
var redux_thunk_1 = require("redux-thunk");
var immutableStateInvariantMiddleware = require("redux-immutable-state-invariant");
var react_router_redux_1 = require("react-router-redux");
var magi_analytics_1 = require("../magi-analytics");
function configureStore(initialState, history) {
    var devToolsKey = '__REDUX_DEVTOOLS_EXTENSION__';
    var enhancer = window[devToolsKey] ? window[devToolsKey]() : function (f) { return f; };
    var middleware = react_router_redux_1.routerMiddleware(history);
    return redux_1.createStore(reducers_1.default, initialState, redux_1.compose(redux_1.applyMiddleware(redux_thunk_1.default, middleware, immutableStateInvariantMiddleware.default(), magi_analytics_1.default), enhancer));
}
exports.default = configureStore;
