"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdTypeOptions = exports.IdTypeEnum = void 0;
var IdTypeEnum;
(function (IdTypeEnum) {
    IdTypeEnum["DRIVING_LICENCE"] = "driving-licence";
    IdTypeEnum["PASSPORT"] = "passport";
    IdTypeEnum["ID_CARD"] = "id-card";
})(IdTypeEnum || (IdTypeEnum = {}));
exports.IdTypeEnum = IdTypeEnum;
var IdTypeOptions = [
    { text: 'Driving licence', value: IdTypeEnum.DRIVING_LICENCE },
    { text: 'Passport', value: IdTypeEnum.PASSPORT },
    { text: 'National ID', value: IdTypeEnum.ID_CARD },
];
exports.IdTypeOptions = IdTypeOptions;
