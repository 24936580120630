"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var ClickToExpand_1 = require("../../../common/components/template/ClickToExpand");
var BackToRetailerButton_1 = require("../../../common/components/BackToRetailerButton");
var ApplicationOutcomes_1 = require("../../../common/components/ApplicationOutcomes");
require("../../styles/pages/DekoDeclineContent.scss");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
var FeatureSwitch_1 = require("../FeatureSwitch");
var Context;
(function (Context) {
    Context["RETAILER"] = "RETAILER";
    Context["LENDER"] = "LENDER";
})(Context || (Context = {}));
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
var bureauInformation = {
    experian: {
        title: 'Experian Ltd',
        content: React.createElement("div", { className: 'bureau-info-section' },
            React.createElement("p", null, "Customer Support Centre"),
            React.createElement("p", null, "PO Box 9000"),
            React.createElement("p", null, "Nottingham"),
            React.createElement("p", null, "NG80 7WF"),
            React.createElement("p", null, "Tel: 0344 481 0800 / 0800 313 8888"),
            React.createElement("p", null,
                "Email: ",
                React.createElement("a", { href: 'mailto:consumer.helpservice@uk.experian.com' }, "consumer.helpservice@uk.experian.com")),
            React.createElement("p", null,
                React.createElement("a", { href: 'https://www.experian.co.uk/', target: '_blank' }, "www.experian.co.uk")))
    },
    callCredit: {
        title: 'TransUnion (Formerly Callcredit Ltd)',
        content: React.createElement("div", { className: 'bureau-info-section' },
            React.createElement("p", null, "Consumer Services Team"),
            React.createElement("p", null, "TransUnion Information Group"),
            React.createElement("p", null, "One Park Lane"),
            React.createElement("p", null, "Leeds"),
            React.createElement("p", null, "West Yorkshire"),
            React.createElement("p", null, "LS3 1EP"),
            React.createElement("p", null, "Tel: 0330 024 7574"),
            React.createElement("p", null,
                "Email: ",
                React.createElement("a", { href: 'mailto:consumer@callcreditgroup.com' }, "consumer@callcreditgroup.com")),
            React.createElement("p", null,
                React.createElement("a", { href: 'https://www.transunion.co.uk/', target: '_blank' }, "https://www.transunion.co.uk/")))
    },
    equifax: {
        title: 'Equifax Ltd',
        content: React.createElement("div", { className: 'bureau-info-section' },
            React.createElement("p", null, "Credit File Advice Centre"),
            React.createElement("p", null, "PO Box 1140"),
            React.createElement("p", null, "Bradford"),
            React.createElement("p", null, "BD1 5US"),
            React.createElement("p", null, "Tel: 0844 335 0550"),
            React.createElement("p", null,
                React.createElement("a", { href: 'http://www.equifax.co.uk/', target: '_blank' }, "www.equifax.co.uk")))
    },
};
var renderBureauData = Object.keys(bureauInformation).map(function (bureau) {
    return (React.createElement(ClickToExpand_1.default, { item: bureauInformation[bureau], key: bureau, track: undefined, wrapperClass: 'bureau-section-item', buttonClass: 'unstyled' }));
});
var DekoDeclineContent = function (_a) {
    var credit_application_id = _a.credit_application_id, lender = _a.lender, retailer = _a.retailer, context = _a.context, branding = _a.branding, _b = _a.instore, instore = _b === void 0 ? false : _b;
    var handleClick = function () {
        var personalLoansUrl = "\n      https://deko.monevo.co.uk/?v1=" + retailer.id + "&v2=" + credit_application_id + "&v3=declined\n    ";
        window.open(personalLoansUrl, '_blank');
    };
    var identifier = retailer.id;
    if (context === Context.LENDER) {
        identifier = lender.id;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Application update"),
        React.createElement("p", null,
            "Unfortunately your application to finance goods from ",
            branding.name,
            " could not be approved."),
        React.createElement(FeatureSwitch_1.default, { name: 'personal-loans-decline-online-v1', identifier: identifier, context: context },
            React.createElement("p", null, "Not to worry, one of our partners may still be able to help. Their platform offers access to personal loans which you can use to purchase the goods you'd like to buy amongst other things. The rates are competitive, acceptance is more likely and you can get a quote without impacting your credit score."),
            React.createElement("p", null,
                "Please note that should your personal loan application be successful and should you wish to use your loan to fund this purchase from ",
                branding.name,
                ", you will have to pay the retailer once the funds become available in your bank account."),
            React.createElement(CTAButton, { className: 'secondary', handleClick: handleClick, timeout: 300 }, "GET YOUR QUOTE"),
            React.createElement("p", { className: 'personal-loans-tac' }, "By clicking this button you will be taken to our partner's page. Our partner's page has its own T&C's and Privacy Policy.")),
        React.createElement("h2", null, "Declined Finance Application details"),
        React.createElement("p", null,
            "The lender ",
            lender.name,
            ", was unable to approve your application for credit on this occasion."),
        React.createElement("p", null, "The Lender takes into account any information provided by you as well as additional information held on you by the credit reference agencies listed below. The lender then assesses this information against their credit scoring system."),
        React.createElement("p", null,
            "We appreciate that customers may be concerned when their application for credit is unsuccessful. If you have any questions related to how or why this decision was made please contact ",
            lender.name,
            " directly."),
        React.createElement(Row_1.default, null,
            React.createElement(Col_1.default, { className: 'lending-decisions-info' },
                React.createElement("div", { className: 'lending-decisions-section lending-decisions' },
                    React.createElement("p", null,
                        React.createElement("strong", null, lender.name)),
                    React.createElement("p", null,
                        "Tel: ",
                        lender.contact_information.phone),
                    React.createElement("p", null,
                        "Email: ",
                        React.createElement("a", { href: "mailto:" + lender.contact_information.email }, lender.contact_information.email))))),
        React.createElement("p", null, "To check the details held on your credit record, get in touch with one of the following credit reference agencies."),
        React.createElement(Row_1.default, null,
            React.createElement(Col_1.default, { layout: ['xs-12'], className: 'pull-left bureau-info' },
                React.createElement("div", null, renderBureauData))),
        !instore &&
            React.createElement(React.Fragment, null,
                React.createElement("p", null, "You can return to the retailers website to make your purchase with an alternative payment method, if you wish."),
                React.createElement("div", { className: 'bottom' },
                    React.createElement(BackToRetailerButton_1.default, { status: ApplicationOutcomes_1.AppOutcomeEnum.DECLINE })))));
};
exports.default = DekoDeclineContent;
