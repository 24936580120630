"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var react_redux_1 = require("react-redux");
var Page_1 = require("../../components/Page");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
require("../../styles/pages/TheEndPage.scss");
var completeImg = require('../../assets/complete.svg');
var closeButton = function () { return window.opener ? React.createElement(deko_common_components_1.Button, { handleClick: window.close }, "Close this window") : null; };
var CompleteFulfilledPage = /** @class */ (function (_super) {
    __extends(CompleteFulfilledPage, _super);
    function CompleteFulfilledPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CompleteFulfilledPage.prototype.render = function () {
        return (React.createElement(Page_1.default, { className: 'complete-fulfilled-page' },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    React.createElement("h1", null, "The purchase has been fulfilled"),
                    React.createElement("p", null,
                        "You have confirmed that the ",
                        React.createElement("strong", null, "customer has received"),
                        " the purchase and that ",
                        React.createElement("strong", null, "this order has been fulfilled"),
                        "."),
                    React.createElement("p", null, "The credit application process is now complete. You may close this window."),
                    React.createElement("div", { className: 'complete-image' },
                        React.createElement(deko_common_components_1.Image, { src: completeImg })),
                    closeButton()))));
    };
    return CompleteFulfilledPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application,
    branding: state.branding
}); };
var connected = react_redux_1.connect(mapStateToProps)(CompleteFulfilledPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.FULFILLED])(connected);
