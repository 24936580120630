"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var PageTitle_1 = require("../../../common/components/form/PageTitle");
var Footer_1 = require("../../components/template/Footer");
require("../../styles/pages/LegalPages.scss");
var logo = require('../../assets/klarna/klarna-221x50.png');
var WhatIsACreditSearchPageKlarna = function () {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'legal-page' },
                React.createElement("div", { className: 'what-is-credit-search-page container' },
                    React.createElement(PageTitle_1.default, { title: 'Deko | What is Credit Search' }),
                    React.createElement("div", { className: 'header' },
                        React.createElement(deko_common_components_1.Image, { className: 'logo', src: logo })),
                    React.createElement("h1", null, "What is a Credit Search?"),
                    React.createElement("p", null, "A credit check occurs when a third party investigates your credit report. They\u2019re looking to see how you manage your credit now, and how you\u2019ve managed it in the past. This check may then be recorded on your credit report for other third parties to see. This is useful to lenders because if you\u2019ve made multiple applications for credit recently, it could suggest to them that you have an urgent need for credit which you may struggle to pay back.")),
                React.createElement(Footer_1.default, null))));
};
exports.default = WhatIsACreditSearchPageKlarna;
