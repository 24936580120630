"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("../styles/components/BaseDottedCircleLoader.scss");
var DottedCircleLoader = function (_a) {
    var className = _a.className;
    return (React.createElement("div", { className: "circle-container" + (className ? " " + className : '') },
        React.createElement("div", { className: 'circle-dot1 circle-dot' }),
        React.createElement("div", { className: 'circle-dot2 circle-dot' }),
        React.createElement("div", { className: 'circle-dot3 circle-dot' }),
        React.createElement("div", { className: 'circle-dot4 circle-dot' }),
        React.createElement("div", { className: 'circle-dot5 circle-dot' }),
        React.createElement("div", { className: 'circle-dot6 circle-dot' }),
        React.createElement("div", { className: 'circle-dot7 circle-dot' }),
        React.createElement("div", { className: 'circle-dot8 circle-dot' })));
};
exports.default = DottedCircleLoader;
