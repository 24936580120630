"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var redux_1 = require("redux");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var Page_1 = require("../../components/Page");
var FormSection_1 = require("../../../common/components/form/FormSection");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
var navigationActions_1 = require("../../actions/navigationActions");
var creditApplicationActions_1 = require("../../../common/actions/creditApplicationActions");
var navigationActions_2 = require("../../../common/actions/navigationActions");
var Modal_1 = require("../../../common/components/Modal");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
require("../../styles/pages/FulfilPage.scss");
var Loader_1 = require("../../../common/components/Loader");
var FulFilPage = /** @class */ (function (_super) {
    __extends(FulFilPage, _super);
    function FulFilPage(props) {
        var _this = _super.call(this, props) || this;
        _this.onFulfilLaterClick = function () {
            _this.props.actions.dispatchNavigation(navigationActions_2.COMPLETE_VERIFIED_PAGE);
        };
        _this.onFulfilNowClick = function () {
            _this.setState({ showConfirmationModal: true });
        };
        _this.closeModal = function () {
            _this.setState({ showConfirmationModal: false });
        };
        _this.onConfirmFulfil = function () {
            _this.props.actions.fulfilApplication(_this.props.application);
        };
        _this.renderModal = function () {
            return _this.state.showConfirmationModal ? (React.createElement(Modal_1.default, { onClose: _this.closeModal },
                React.createElement("p", null, "Are you sure you want to confirm that the customer has received their purchase and mark this order as fulfilled?"),
                React.createElement("p", null,
                    React.createElement("strong", null, "This action cannot be reverted.")),
                React.createElement("div", { className: 'button-group' },
                    React.createElement(deko_common_components_1.Button, { className: 'secondary back', handleClick: _this.closeModal }, "Back"),
                    React.createElement(CTAButton, { className: 'confirm-fulfil-now wide-content', ariaAttributes: { 'aria-label': 'Fulfil now' }, handleClick: _this.onConfirmFulfil, timeout: 300, disabled: _this.props.asyncRunning }, _this.props.asyncRunning ? React.createElement(Loader_1.default, { title: 'Processing', loaderType: Loader_1.LoaderTypeEnum.BUTTON }) : 'Confirm')))) : null;
        };
        _this.state = {
            showConfirmationModal: false
        };
        return _this;
    }
    FulFilPage.prototype.render = function () {
        return (React.createElement(Page_1.default, { mainCols: 5, mainOffset: 2, className: 'fulfil-page' },
            React.createElement("h1", null, "The Lender has verified the purchase"),
            React.createElement("p", null, "You have confirmed that where necessary the customer\u2019s deposit has been received and ID has been provided."),
            React.createElement("p", null,
                "Once you have fulfilled the order, the Credit Agreement will be in effect and you are authorised to release the purchase. The Credit Agreement and Direct Debit documents have been emailed to your customer\u2019s email address at\u00A0",
                React.createElement("strong", { "data-hj-suppress": true }, this.props.application.consumer.email),
                "."),
            React.createElement(FormSection_1.default, { label: 'If the customer is to receive their purchase right away, please fulfil the order now:' },
                React.createElement("div", { className: 'button-group' },
                    React.createElement(CTAButton, { className: 'secondary', ariaAttributes: { 'aria-label': 'Fulfil now' }, handleClick: this.onFulfilNowClick, timeout: 300 }, "Fulfil now"),
                    React.createElement(CTAButton, { className: 'fulfil-later', ariaAttributes: { 'aria-label': 'Fulfil later' }, handleClick: this.onFulfilLaterClick, timeout: 300 }, "Fulfil Later")),
                React.createElement("p", null, "You can also fulfil the order later from your Back Office account.")),
            this.renderModal()));
    };
    return FulFilPage;
}(React.PureComponent));
var mapStateToProps = function (state) { return ({
    application: state.application,
    consumer: state.application.consumer,
    asyncRunning: state.async.asyncRunning,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_1.dispatchNavigation,
        fulfilApplication: creditApplicationActions_1.fulfilApplication
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(FulFilPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.VERIFIED])(connected);
