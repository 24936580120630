"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DekoAcceptContent = function (_a) {
    var term = _a.term, lenderName = _a.lenderName, lenderDescription = _a.lenderDescription, lenderContactNumber = _a.lenderContactNumber;
    var contact;
    if (lenderContactNumber) {
        contact = React.createElement("p", null,
            "If you have any questions or require further information concerning any of your contractual documentation",
            ' ',
            "or the example credit agreement please refer to lender FAQs or call the lender on ",
            lenderContactNumber,
            ".");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "You've been approved to spread the cost of your purchase over ",
            term,
            " months by the lender, ",
            lenderName,
            ". ",
            lenderName,
            " is the finance company and is authorised and regulated by the Financial Conduct Authority."),
        contact));
};
exports.default = DekoAcceptContent;
