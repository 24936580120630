"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes = require("../actions/actionTypes");
var IRedemptionApi_1 = require("../api/Redemption/IRedemptionApi");
var handleUpdateStatus = function (state, action) { return (__assign(__assign({}, state), { status: action.status })); };
var handleUpdateNumber = function (state, action) { return (__assign(__assign({}, state), { mobile_number: action.number })); };
var handleRedemptionVerified = function (state, action) { return ({
    status: IRedemptionApi_1.MessageState.VERIFIED,
    link: action.link
}); };
var handleRedemptionError = function (state, action) { return (__assign(__assign({}, state), { error: action.message })); };
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes.UPDATE_REDEMPTION_STATUS] = handleUpdateStatus,
    _a[actionTypes.UPDATE_REDEMPTION_NUMBER] = handleUpdateNumber,
    _a[actionTypes.REDEMPTION_VERIFIED] = handleRedemptionVerified,
    _a[actionTypes.REDEMPTION_ERROR] = handleRedemptionError,
    _a), {});
