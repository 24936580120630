"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var DekoConsentSection_1 = require("../pages/application/form/consent/DekoConsentSection");
var DekoPrequalContent_1 = require("./prequal/DekoPrequalContent");
var DekoDeclineContent_1 = require("./decline/DekoDeclineContent");
var DekoCreditSearchSection_1 = require("./prequal/DekoCreditSearchSection");
var DekoCompleteContent_1 = require("./complete/DekoCompleteContent");
var DekoQuestionsAboutApplication_1 = require("./form/DekoQuestionsAboutApplication");
var DekoFooterContent_1 = require("./template/lender/DekoFooterContent");
var DekoAlternativeFinanceContent_1 = require("./alternative-finance/DekoAlternativeFinanceContent");
var DekoTelephoneAlternativeFinanceContent_1 = require("./alternative-finance/DekoTelephoneAlternativeFinanceContent");
var DekoAppFormPrefix_1 = require("../pages/application/form/prefix/DekoAppFormPrefix");
var DekoAcceptContent_1 = require("./pre-documents/DekoAcceptContent");
var DekoPreDocumentsContentWrapper_1 = require("./documents/DekoPreDocumentsContentWrapper");
var DekoReferContent_1 = require("./pre-documents/DekoReferContent");
var DekoReferSection_1 = require("./refer/DekoReferSection");
var DekoDirectDebitInfo_1 = require("./form/DekoDirectDebitInfo");
var dekoLogo = require('../../common/assets/deko-logo-full-colour.svg');
var deko = {
    AcceptContent: DekoAcceptContent_1.default,
    AppFormPrefix: DekoAppFormPrefix_1.default,
    DirectDebitInfo: DekoDirectDebitInfo_1.default,
    AlternativeFinanceContent: DekoAlternativeFinanceContent_1.default,
    TelephoneAlternativeFinanceContent: DekoTelephoneAlternativeFinanceContent_1.default,
    ConsentSection: DekoConsentSection_1.default,
    PrequalContent: DekoPrequalContent_1.default,
    CreditSearchSection: DekoCreditSearchSection_1.default,
    QuestionsAboutApplicationSection: DekoQuestionsAboutApplication_1.default,
    CompleteContent: DekoCompleteContent_1.default,
    DeclineContent: DekoDeclineContent_1.default,
    FooterContent: DekoFooterContent_1.default,
    LenderLogo: dekoLogo,
    PreDocumentsContentWrapper: DekoPreDocumentsContentWrapper_1.default,
    ReferContent: DekoReferContent_1.default,
    ReferSection: DekoReferSection_1.default,
};
var map = (_a = {},
    _a['default'] = deko,
    _a);
var LenderComponentProvider = /** @class */ (function (_super) {
    __extends(LenderComponentProvider, _super);
    function LenderComponentProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getComponents = function () {
            if (map[_this.props.lender.name]) {
                return map[_this.props.lender.name];
            }
            return map.default;
        };
        return _this;
    }
    LenderComponentProvider.prototype.render = function () {
        return (this.props.children(this.getComponents()));
    };
    return LenderComponentProvider;
}(React.Component));
var mapStateToProps = function (state) { return ({
    lender: state.application.lender
}); };
exports.default = react_redux_1.connect(mapStateToProps)(LenderComponentProvider);
