"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var NewAddressButton = function (props) {
    var wrapperClass = ['address-button-container'];
    if (props.className) {
        wrapperClass.push(props.className);
    }
    return (React.createElement("div", { className: wrapperClass.join(' ') },
        React.createElement("p", null, "We need 3 or more years of address"),
        React.createElement(deko_common_components_1.Button, { handleClick: props.handleClick, className: 'has-icon' },
            React.createElement("div", { className: 'cross-wrapper' },
                React.createElement("span", { className: 'cross' })),
            "Add a new address")));
};
exports.default = NewAddressButton;
