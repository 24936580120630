"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDispatchExternalNavigation = void 0;
var React = require("react");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var ApplicationOutcomes_1 = require("./ApplicationOutcomes");
var StickyClicky_1 = require("./StickyClicky");
var inversify_types_1 = require("../inversify.types");
var inversify_container_1 = require("../inversify.container");
var Loader_1 = require("./Loader");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
function getDispatchExternalNavigation() {
    return inversify_container_1.default.get(inversify_types_1.default.dispatchExternalNavigation);
}
exports.getDispatchExternalNavigation = getDispatchExternalNavigation;
var BackToRetailerButton = /** @class */ (function (_super) {
    __extends(BackToRetailerButton, _super);
    function BackToRetailerButton(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            loading: false
        };
        return _this;
    }
    BackToRetailerButton.prototype.render = function () {
        var _this = this;
        var _a = this.props, status = _a.status, retailer = _a.retailer, application = _a.application;
        if (retailer === undefined) {
            return null;
        }
        if (this.state.loading) {
            return React.createElement(Loader_1.default, null);
        }
        var url;
        switch (status) {
            case ApplicationOutcomes_1.AppOutcomeEnum.SUCCESS:
                url = retailer.success_url;
                break;
            case ApplicationOutcomes_1.AppOutcomeEnum.REFER:
                url = retailer.refer_url;
                break;
            case ApplicationOutcomes_1.AppOutcomeEnum.DECLINE:
                url = retailer.fail_url;
                break;
            default:
                return null;
        }
        if (url === undefined || url === '' || url === null) {
            return null;
        }
        var buttonText = 'Back to the retailer';
        var finance = application.finance;
        var goods = finance ? finance.goods : '';
        var onClick = function () {
            window.parent.postMessage({
                status: application.status,
                url: retailer.success_url,
                goods: goods ? goods : '',
                reference: application.unique_reference,
            }, '*');
            _this.setState({ loading: true });
            _this.props.actions.dispatchExternalNavigation(url);
        };
        return (React.createElement(CTAButton, { handleClick: onClick, className: "submit " + (url ? '' : 'hide'), timeout: 300 }, buttonText));
    };
    return BackToRetailerButton;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application,
    retailer: state.application.retailer,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchExternalNavigation: getDispatchExternalNavigation()
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(BackToRetailerButton);
