"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var lodash_1 = require("lodash");
var deko_utilities_1 = require("deko-utilities");
var Form_1 = require("../../components/form/Form");
var FormValidator_1 = require("../../validation/FormValidator");
var EntityForm = /** @class */ (function (_super) {
    __extends(EntityForm, _super);
    // binding the functions which will update the global state
    function EntityForm(props) {
        var _this = _super.call(this, props) || this;
        _this.fields = [];
        _this.handleEntityChange = _this.handleEntityChange.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    EntityForm.prototype.handleEntityChange = function (event) {
        var field = event.target.name;
        var value = event.target.value;
        var entity = lodash_1.cloneDeep(this.props.entity);
        deko_utilities_1.objectAccess.setKey(field, entity, value);
        this.props.actions.updateEntity(entity);
    };
    EntityForm.prototype.handleSubmit = function () {
        this.props.handleSubmit();
    };
    EntityForm.prototype.render = function () {
        var _a = this.props, className = _a.className, entity = _a.entity, asyncRunning = _a.asyncRunning;
        if (!this.props.children) {
            throw new Error('EntityForm rendered without child component');
        }
        var classNames = ['form-container'];
        if (className) {
            classNames.push(className);
        }
        return (React.createElement("div", { className: classNames.join(' '), key: 'form' },
            React.createElement(Form_1.default, { handleSubmit: this.handleSubmit }, this.props.children({
                entity: entity,
                handleChange: this.handleEntityChange,
                asyncRunning: asyncRunning,
            }))));
    };
    EntityForm = __decorate([
        FormValidator_1.default,
        __metadata("design:paramtypes", [Object])
    ], EntityForm);
    return EntityForm;
}(React.Component));
var mapStateToProps = function (state, ownProps) { return ({
    entity: lodash_1.get(state, ownProps.entityName),
    asyncRunning: state.async.asyncRunning,
}); };
var mapDispatchToProps = function (dispatch, props) { return ({
    actions: redux_1.bindActionCreators(__assign({}, props), dispatch)
}); };
var connectFunc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.default = connectFunc(EntityForm);
