"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_utilities_1 = require("deko-utilities");
var FormSection_1 = require("../../../../../common/components/form/FormSection");
var CheckboxInput_1 = require("../../../../../common/components/form/CheckboxInput");
var FieldValidator_1 = require("../../../../../common/validation/FieldValidator");
var ClickToExpand_1 = require("../../../../../common/components/template/ClickToExpand");
require("../../../../styles/components/Consent.scss");
var baseValidator = FieldValidator_1.default();
var CheckboxInput = baseValidator(CheckboxInput_1.default);
var DekoConsentSection = /** @class */ (function (_super) {
    __extends(DekoConsentSection, _super);
    function DekoConsentSection(props) {
        return _super.call(this, props) || this;
    }
    DekoConsentSection.prototype.render = function () {
        var _a = this.props, handleChange = _a.handleChange, consent = _a.consent, branding = _a.branding, telephoneApplication = _a.telephoneApplication;
        if (telephoneApplication) {
            return React.createElement("div", { className: 'margin-bottom-large' });
        }
        var privacyPolicies = branding.links.privacy_policy_url;
        var privacyPolicyLinksList = Object.getOwnPropertyNames(privacyPolicies).map(function (lender) {
            return React.createElement("li", { key: privacyPolicies[lender] },
                React.createElement("a", { href: privacyPolicies[lender], target: '_info' }, lender));
        });
        var lendersPrivacyPolicyInformation = {
            title: "This is in accordance with the relevant lender's Privacy Policy",
            content: React.createElement("div", null,
                React.createElement("ul", null, privacyPolicyLinksList))
        };
        return (React.createElement(FormSection_1.default, { label: 'Your approval', className: 'last' },
            React.createElement(CheckboxInput, { label: React.createElement("span", null,
                    React.createElement("p", null, "I authorise the lender(s) to make credit reference, identity, fraud and other enquiries and to share information about me and how I manage my accounts."),
                    React.createElement(ClickToExpand_1.default, { item: lendersPrivacyPolicyInformation, wrapperClass: 'lender-privacy-section approval-section-privacy spaced-above', buttonClass: 'unstyled expandable-read-more-title' })), name: 'consent.submit', handleChange: handleChange, value: deko_utilities_1.maybe(consent.submit, false) })));
    };
    return DekoConsentSection;
}(React.Component));
exports.default = DekoConsentSection;
