"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'selected_id': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: 'Please select an ID type'
        }
    ],
    'filesBase64.front_image': [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: 'Please upload the Front of your document'
        },
        {
            ruleName: 'isValidType',
            constraint: { documentType: 'front', validTypes: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'] },
            message: 'Please upload a JPEG, PNG or PDF file',
        },
        {
            ruleName: 'isValidSize',
            // max file size for id uploads is 4mb
            constraint: { maximumFileSize: 4000000 },
            message: 'Please upload an image file smaller than 4mb',
        }
    ],
    'filesBase64.rear_image': [
        {
            ruleName: 'isRequiredIf',
            constraint: function (currentState) { return currentState.selected_id === 'id-card'; },
            message: 'Please upload the Back of your document'
        },
        {
            ruleName: 'isValidType',
            constraint: { documentType: 'rear', validTypes: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'] },
            message: 'Please upload a JPEG, PNG or PDF file',
        },
        {
            ruleName: 'isValidSize',
            // max file size for online id uploads is 4mb
            constraint: { maximumFileSize: 4000000 },
            message: 'Please upload an image file smaller than 4mb',
        }
    ],
    'checkboxes.resembles_customer': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'ID resembles customer'
        }
    ],
    'checkboxes.details_match': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'The name and date of birth match the application'
        }
    ],
    'checkboxes.id_expiry': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'The ID has not expired'
        }
    ],
    'checkboxes.whole_id_displayed': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'The whole ID is displayed'
        }
    ],
    'checkboxes.clear_of_obstructions': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'The ID is clear of obstructions'
        }
    ],
    'checkboxes.square_on': [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'The ID is not at an angle'
        }
    ],
};
