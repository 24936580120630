"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var extendedSpecialChars = 'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ';
var acceptedCreditCardTypes = ['mastercard', 'visa', 'maestro'];
exports.default = {
    cardholder_name: [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please insert a ",
                React.createElement("strong", null, "Cardholder name"))
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 2,
                max: 40
            },
            message: React.createElement("span", null,
                "Please enter a valid ",
                React.createElement("strong", null, "Cardholder name"))
        },
        {
            ruleName: 'validRegex',
            constraint: new RegExp("^[a-zA-Z " + extendedSpecialChars + "^_`'.-]+$"),
            message: React.createElement("span", null,
                "Please enter a valid ",
                React.createElement("strong", null, "Cardholder name"))
        },
    ],
    card_number: [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please insert a ",
                React.createElement("strong", null, "Card number"))
        },
        {
            ruleName: 'isCreditCardNumberValid',
            message: React.createElement("span", null,
                "Please insert a valid payment ",
                React.createElement("strong", null, "Card number"))
        },
        {
            ruleName: 'isCreditAnAcceptedType',
            constraint: acceptedCreditCardTypes,
            message: React.createElement("strong", null, "We accept VISA (inc. Debit and Electron), Mastercard and Maestro")
        }
    ],
    end_date: [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please insert an ",
                React.createElement("strong", null, "End date"))
        },
        {
            ruleName: 'isFullDate',
            constraint: false,
            message: React.createElement("strong", null, "Please enter the date in a valid format (mm/yy)")
        },
        {
            ruleName: 'isDateInTheFuture',
            constraint: 'month',
            message: React.createElement("strong", null, "Please enter a valid date (mm/yy)")
        },
    ],
    security_code: [
        {
            ruleName: 'isRequired',
            constraint: true,
            message: React.createElement("span", null,
                "Please insert a ",
                React.createElement("strong", null, "Security code"))
        },
        {
            ruleName: 'hasLength',
            constraint: {
                min: 3,
                max: 4
            },
            message: React.createElement("span", null,
                "Please insert a valid ",
                React.createElement("strong", null, "Security code"))
        },
    ]
};
