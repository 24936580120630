"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var featureFlagActions_1 = require("../../common/actions/featureFlagActions");
var Loader_1 = require("../../common/components/Loader");
var FeatureToggle = /** @class */ (function (_super) {
    __extends(FeatureToggle, _super);
    function FeatureToggle(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            enabled: false
        };
        return _this;
    }
    FeatureToggle.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, name = _a.name, identifier = _a.identifier, context = _a.context;
        this.props.actions.featureFlagConfig(name, identifier, context).then(function (featureFlag) {
            _this.setState(__assign(__assign({}, _this.state), { enabled: featureFlag[name] }));
        });
    };
    FeatureToggle.prototype.render = function () {
        if (this.props.feature_request_inflight) {
            return React.createElement(Loader_1.default, null);
        }
        return this.renderContent();
    };
    FeatureToggle.prototype.renderContent = function () {
        if (this.state.enabled) {
            return this.props.on_content;
        }
        return this.props.off_content;
    };
    return FeatureToggle;
}(React.Component));
var mapStateToProps = function (state) { return ({
    feature_request_inflight: state.async.featureRequestInFlight
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        featureFlagConfig: featureFlagActions_1.featureFlagConfig
    }, dispatch)
}); };
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(FeatureToggle);
