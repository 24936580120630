"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var RadioInput_1 = require("./RadioInput");
var addresses_1 = require("../../utilities/addresses");
var AddButton_1 = require("./AddButton");
var deko_common_components_1 = require("deko-common-components");
var inversify_container_1 = require("../../inversify.container");
var inversify_types_1 = require("../../inversify.types");
var getCapturePlus = function () {
    return inversify_container_1.default.get(inversify_types_1.default.CapturePlusApi);
};
var binImg = require('../../assets/bin.svg');
var MIN_ADDRESS_HISTORY_LENGTH_DEFAULT = 3;
var yearsAtAddressOptionsDefault = [
    { text: '0–1', value: 0 },
    { text: '1–2', value: 1 },
    { text: '2–3', value: 2 },
    { text: '3+', value: 3 }
];
var mapYearsAtAddress = function (years) {
    if (years > 3) {
        return 3;
    }
    return years;
};
var addressTypesDefault = [
    {
        key: 'current_address',
        title: 'Current address'
    },
    {
        key: 'second_address',
        title: 'Second address'
    },
    {
        key: 'third_address',
        title: 'Third address'
    },
];
var AddressInputGroup = /** @class */ (function (_super) {
    __extends(AddressInputGroup, _super);
    function AddressInputGroup(props) {
        var _this = _super.call(this, props) || this;
        _this.deleteObsoleteAddresses = function () {
            var totalYears = 0;
            var sufficientAddressHistory = false;
            var addresses = _this.props.customer.addresses;
            var addressKeys = Object.keys(addresses);
            addressKeys.some(function (type) {
                if (sufficientAddressHistory) {
                    _this.handleAddressDeletion(type);
                    return true;
                }
                totalYears += addresses[type].years_at_address;
                if (totalYears >= 3) {
                    sufficientAddressHistory = true;
                }
                if (addressKeys[addressKeys.length - 1] === type) {
                    return true;
                }
                return false;
            });
        };
        _this.removeAddressIcon = _this.props.removeAddressIcon || binImg;
        _this.minAddressHistoryLength = _this.props.minAddressHistoryLength || MIN_ADDRESS_HISTORY_LENGTH_DEFAULT;
        _this.yearsAtAddressOptions = _this.props.yearsAtAddressOptions || yearsAtAddressOptionsDefault;
        _this.addressTypes = _this.props.addressTypes || addressTypesDefault;
        _this.handleAddressChange = _this.handleAddressChange.bind(_this);
        _this.hasSufficientYears = _this.hasSufficientYears.bind(_this);
        _this.previousAddressEmpty = _this.previousAddressEmpty.bind(_this);
        _this.addressIsEmpty = _this.addressIsEmpty.bind(_this);
        _this.state = {
            sufficientYears: false
        };
        return _this;
    }
    AddressInputGroup.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevState.sufficientYears !== this.state.sufficientYears) {
            this.deleteObsoleteAddresses();
        }
    };
    AddressInputGroup.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: 'address-input-group', id: 'address-input-group', "data-hj-suppress": true }, this.addressTypes.map(function (type, i) {
            if (_this.hasSufficientYears(i) || _this.previousAddressEmpty(i)) {
                return;
            }
            return _this.renderSingleAddress(type);
        })));
    };
    AddressInputGroup.prototype.renderSingleAddress = function (_a) {
        var key = _a.key, title = _a.title;
        // The address input component doesn't allow validation to be optional. We should change it so it does,
        // as sometimes we might not want it
        if (this.props.validateField === null || this.props.validateField === undefined
            || this.props.validateList === null || this.props.validateList === undefined
            || this.props.removeErrors === null || this.props.removeErrors === undefined) {
            throw new TypeError('Validation must be set');
        }
        var address = this.props.customer.addresses ? this.props.customer.addresses[key] : {};
        var opened = (key === 'current_address' || !addresses_1.addressEmpty(address));
        var yearsAtAddressError = this.props.errors ? this.props.errors["addresses." + key + ".years_at_address"] : "";
        return (React.createElement(AddButton_1.default, { key: key, opened: opened, errors: this.props.errors },
            React.createElement(deko_common_components_1.AddressInput, { api: getCapturePlus(), name: "addresses." + key, label: title, address: address, handleChange: this.handleAddressChange, validateField: this.props.validateField, validateList: this.props.validateList, removeErrors: this.props.removeErrors, errors: this.props.errors || {}, removeAddressImg: this.removeAddressIcon, track: this.props.track }),
            (key === 'current_address') &&
                React.createElement("p", { className: 'current-address-message' }, this.props.currentAddressMessage),
            React.createElement(RadioInput_1.default, { name: "addresses." + key + ".years_at_address", label: 'Years at this address', options: this.yearsAtAddressOptions, value: mapYearsAtAddress(address.years_at_address), errors: yearsAtAddressError, validateStandard: this.props.validateField, handleChange: this.props.handleChange, type: 'number', layout: 'HORIZONTAL' })));
    };
    AddressInputGroup.prototype.handleAddressChange = function (e) {
        if (this.addressIsEmpty(e.target.value)) {
            return this.handleAddressDeletion(e.target.name);
        }
        return this.props.handleChange(e);
    };
    AddressInputGroup.prototype.addressIsEmpty = function (obj) {
        if (obj === undefined || obj === null) {
            return true;
        }
        if (typeof obj === 'object') {
            var hasKeysWithValue_1 = false;
            Object.keys(obj).map(function (key) {
                if (obj[key] !== undefined && obj[key] !== null) {
                    hasKeysWithValue_1 = true;
                }
            });
            return !hasKeysWithValue_1;
        }
        return false;
    };
    AddressInputGroup.prototype.handleAddressDeletion = function (name) {
        var _this = this;
        var emptyAddress = {
            flat: undefined,
            house_number: undefined,
            house_name: undefined,
            street: undefined,
            district: undefined,
            county: undefined,
            town: undefined,
            postcode: undefined,
            years_at_address: undefined,
        };
        var addresses = __assign({}, this.props.customer.addresses);
        this.addressTypes.map(function (type, i) {
            if (type.key === name || "addresses." + type.key === name) {
                _this.addressTypes.map(function (nextType, j) {
                    if (j >= i) {
                        addresses[nextType.key] = __assign({}, emptyAddress);
                    }
                });
            }
        });
        var newEvent = {
            target: {
                name: 'addresses',
                value: addresses
            }
        };
        return this.props.handleChange(newEvent);
    };
    AddressInputGroup.prototype.previousAddressEmpty = function (index) {
        return index > 0 && this.props.customer.addresses[this.addressTypes[index - 1].key].years_at_address === undefined;
    };
    AddressInputGroup.prototype.hasSufficientYears = function (upTo) {
        var totalYears = 0;
        var addresses = this.props.customer.addresses;
        this.addressTypes.map(function (type, i) {
            if (i >= upTo) {
                return false;
            }
            if (addresses[type.key].years_at_address === undefined) {
                return false;
            }
            totalYears += addresses[type.key].years_at_address;
        });
        totalYears >= this.minAddressHistoryLength ?
            this.setState({ sufficientYears: true }) : this.setState({ sufficientYears: false });
        return totalYears >= this.minAddressHistoryLength;
    };
    return AddressInputGroup;
}(React.PureComponent));
exports.default = AddressInputGroup;
