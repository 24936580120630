"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("../../../common/styles/deko-styles/components/Footer.scss");
var LenderComponentProvider_1 = require("../LenderComponentProvider");
var Footer = function () {
    return (React.createElement("footer", null,
        React.createElement("div", { className: 'container footer' },
            React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                var FooterContent = _a.FooterContent;
                return (React.createElement(FooterContent, null));
            }))));
};
exports.default = Footer;
