"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var deko_utilities_1 = require("deko-utilities");
var PromotionProvider_1 = require("../../../common/components/PromotionProvider");
var deko_common_components_1 = require("deko-common-components");
var alternativeFinanceTooltips_1 = require("../../pages/application/form/alternativeFinanceTooltips");
var PromotionWarningProvider_1 = require("../../../common/components/PromotionWarningProvider");
var accepted = require('../../assets/alternative-finance-icons/accepted.svg');
var declined = require('../../assets/alternative-finance-icons/declined.svg');
var DekoAlternativeFinanceContent = function (_a) {
    var finance = _a.finance, status = _a.status;
    var isReferJourney = status === IApplication_1.AppStatusEnum.REFER;
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Great news!"),
        React.createElement("h2", null,
            "We have an alternative finance offer you've been ",
            isReferJourney && 'provisionally',
            " accepted for"),
        React.createElement("p", null,
            "Based on the details you provided and a credit check, our lenders are unable to offer your original finance choice. However, they have an alternative offer below which you have now been ",
            isReferJourney && 'provisionally',
            " accepted for."),
        React.createElement("p", null,
            React.createElement("b", null, "Please review your alternative offer below")),
        React.createElement("p", null, "The loan amount, interest rate or term may be different to your original finance choice. If the loan amount has changed, you will be required to pay a higher deposit."),
        React.createElement("div", { className: 'margin-bottom-large' }, renderTable(finance, isReferJourney)),
        React.createElement(PromotionWarningProvider_1.default, null),
        isReferJourney && React.createElement("p", null, "Your offer for finance has been provisionally accepted by the lender. This means that the lender may get in touch to request further information from you."),
        React.createElement("p", null,
            React.createElement("b", null, "I confirm that I have carefully considered the cost of this new loan against my current and expected future financial commitments and that I am able to afford the monthly instalments for the duration of the credit agreement."))));
};
var renderTable = function (finance, isReferJourney) {
    var isSameLender = finance.product.lender_display_name === finance.offer.lender_display_name;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'columns-container' },
            React.createElement("div", { className: 'offer-container declined' },
                React.createElement("div", { className: 'title' },
                    React.createElement("p", { className: 'heading' },
                        React.createElement(deko_common_components_1.Image, { className: 'offer-icon', src: declined, altText: 'Declined product icon' }),
                        isSameLender ? 'Applied For' : 'Declined'),
                    !isSameLender && alternativeFinanceTooltips_1.getTooltip(finance.product.lender_display_name, IApplication_1.AppStatusEnum.DECLINE)),
                React.createElement("p", null, "The lender was unable to approve your application for this credit product on this occasion"),
                React.createElement(PromotionProvider_1.default, { product: finance.product }),
                renderProduct(finance, finance.product)),
            React.createElement("div", { className: 'offer-container accepted' },
                React.createElement("div", { className: 'title' },
                    React.createElement("p", { className: 'heading' },
                        React.createElement(deko_common_components_1.Image, { className: 'offer-icon', src: accepted, altText: 'Accepted product icon' }),
                        isReferJourney && 'Provisionally',
                        " Accepted"),
                    finance.offer.promotional_offer && alternativeFinanceTooltips_1.getPromotionTooltip()),
                React.createElement(PromotionProvider_1.default, { product: finance.offer }),
                renderProduct(finance, finance.offer)))));
};
/**
 * Reusable product information logic (can be used to print either application or offer productx`x`)
 * @param finance
 * @param product
 * @returns
 */
var renderProduct = function (finance, product) {
    var cashPrice = finance.goods.reduce(function (p, c) { return p + c.price; }, 0);
    return (React.createElement("div", { className: 'finance-details' },
        React.createElement("p", null, "Cash price"),
        React.createElement("p", { className: 'cash-price' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(cashPrice)),
        React.createElement("p", null, "Deposit"),
        React.createElement("p", { className: 'deposit' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(finance.deposit.amount)),
        React.createElement("p", null, "Loan amount"),
        React.createElement("p", { className: 'loan-amount' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(finance.credit)),
        React.createElement("p", null, "Repayment term"),
        React.createElement("p", { className: 'repayment-term' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(product.monthly_instalment),
            "/month for ",
            product.loan_term,
            " months"),
        React.createElement("p", null, "Interest rate (fixed)"),
        React.createElement("p", { className: 'annual-interest-rate' },
            product.annual_interest_rate,
            "%"),
        React.createElement("p", null, "Total amount payable (inc deposit)"),
        React.createElement("p", { className: 'total-amount-payable' },
            "\u00A3",
            deko_utilities_1.currency.formatCurrency(product.total_payable)),
        React.createElement("p", null, "APR"),
        React.createElement("p", { className: 'apr' },
            product.apr,
            "%"),
        React.createElement("span", { className: 'provider' },
            "Provided by ",
            product.lender_display_name)));
};
exports.default = DekoAlternativeFinanceContent;
