"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_utilities_1 = require("deko-utilities");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var navigationActions_1 = require("../../../common/actions/navigationActions");
var Page_1 = require("../../components/Page");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var ValidationManager_1 = require("../../../common/validation/ValidationManager");
var AlternativeFinanceValidator_1 = require("../../validators/AlternativeFinanceValidator");
var PropTypes = require("prop-types");
require("../../styles/pages/AlternativeFinancePage.scss");
var LenderComponentProvider_1 = require("../../components/LenderComponentProvider");
var deko_common_components_1 = require("deko-common-components");
var AlternativeFinancePage = /** @class */ (function (_super) {
    __extends(AlternativeFinancePage, _super);
    function AlternativeFinancePage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.renderTable = function () {
            return (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'table-caption' }, "Your Approved Finance Option and Declined Finance Option are as follow:"),
                React.createElement(Col_1.default, { className: 'table' },
                    React.createElement(Row_1.default, { className: 'row-wrapper first' },
                        React.createElement(Col_1.default, { layout: ['xs-6', 'md-3', 'md-offset-6'], className: 'cell row-title approved' }, "Approved"),
                        React.createElement(Col_1.default, { layout: ['xs-6', 'md-3'], className: 'cell row-title declined' }, "Declined")),
                    _this.renderFinanceOptions())));
        };
        _this.renderFinanceOptions = function () {
            var finance = _this.props.application.finance ? _this.props.application.finance : null;
            if (!finance || !finance.offer || !finance.goods || !finance.deposit) {
                throw new TypeError('Finance details must be set');
            }
            var comparedFinanceOffers = [
                {
                    key: 'purchase-price',
                    title: 'Purchase price',
                    accepted: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.credit + finance.deposit.amount),
                    declined: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.credit + finance.deposit.amount),
                },
                {
                    key: 'deposit',
                    title: 'Deposit',
                    accepted: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.deposit.amount),
                    declined: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.deposit.amount),
                },
                {
                    key: 'amount-of-credit',
                    title: 'Amount of Credit',
                    accepted: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.credit),
                    declined: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.credit),
                },
                {
                    key: 'duration',
                    title: 'Duration of agreement',
                    accepted: finance.offer.loan_term + " months",
                    declined: finance.product.loan_term + " months",
                },
                {
                    key: 'monthly-payments',
                    title: 'Monthly payments',
                    accepted: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.offer.monthly_instalment),
                    declined: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.product.monthly_instalment),
                },
                {
                    key: 'total-repayable',
                    title: 'Total repayable',
                    accepted: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.offer.total_payable),
                    declined: "\u00A3" + deko_utilities_1.currency.formatCurrency(finance.product.total_payable),
                },
                {
                    key: 'rate-of-interest',
                    title: 'Rate of interest (fixed)',
                    accepted: finance.offer.apr + "%",
                    declined: finance.product.apr + "%",
                },
                {
                    key: 'apr',
                    title: 'APR representative',
                    accepted: finance.offer.apr + "%",
                    declined: finance.product.apr + "%",
                }
            ];
            return comparedFinanceOffers.map(function (item, i) {
                return (React.createElement(Row_1.default, { key: i, className: "row-wrapper " + item.key + (item.accepted === item.declined ? '' : ' differ') },
                    React.createElement(Col_1.default, { layout: ['xs-12', 'md-6'], className: 'cell row-title' }, item.title + ":"),
                    React.createElement(Col_1.default, { layout: ['xs-6', 'md-3'], className: 'cell row-value accept left-col' }, item.accepted),
                    React.createElement(Col_1.default, { layout: ['xs-6', 'md-3'], className: 'cell row-value decline' }, item.declined)));
            });
        };
        return _this;
    }
    AlternativeFinancePage.prototype.getChildContext = function () {
        return { validationManager: new ValidationManager_1.default(AlternativeFinanceValidator_1.default), entityName: 'alternativeFinance' };
    };
    AlternativeFinancePage.prototype.render = function () {
        var _this = this;
        if (!this.props.application.finance) {
            throw new TypeError('Finance details must be set');
        }
        return (React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: false, fallbackAction: navigationActions_1.PAY_DEPOSIT },
            React.createElement(Page_1.default, { className: 'alternative-finance-page', showSummaryContent: false },
                React.createElement("h1", null, "Alternative Finance"),
                React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                    var TelephoneAlternativeFinanceContent = _a.TelephoneAlternativeFinanceContent;
                    return (React.createElement(TelephoneAlternativeFinanceContent, { renderTable: _this.renderTable }));
                }),
                React.createElement("div", { className: 'bottom' },
                    React.createElement(deko_common_components_1.Button, { handleClick: window.close }, "Close this window")))));
    };
    AlternativeFinancePage.childContextTypes = {
        validationManager: PropTypes.instanceOf(ValidationManager_1.default),
        entityName: PropTypes.string
    };
    return AlternativeFinancePage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    token: state.token,
    application: state.application
}); };
var connected = react_redux_1.connect(mapStateToProps, {})(AlternativeFinancePage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.ACCEPT])(connected);
