"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Row = function (_a) {
    var children = _a.children, className = _a.className;
    var wrapperClass = 'row';
    if (className) {
        wrapperClass += " " + className;
    }
    return (React.createElement("div", { className: wrapperClass }, children));
};
exports.default = Row;
