"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DekoReferContent = function (_a) {
    var lender = _a.lender;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "The lender, ",
            lender.name,
            " needs to make a few additional checks on your finance application. We have sent you an email providing details about these checks and next steps."),
        React.createElement("p", null,
            lender.name,
            " is authorised and regulated by the Financial Conduct Authority.")));
};
exports.default = DekoReferContent;
