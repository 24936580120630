"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
require("reflect-metadata");
var deko_utilities_1 = require("deko-utilities");
var config_1 = require("../../config/config");
var endpoint = config_1.default.applicationsApi;
var idEndpoint = config_1.default.applicationsApiBypass;
var cardTypeMap = {
    visa: 'VISA',
    mastercard: 'MC',
    maestro: 'MAESTRO'
};
var CreditApplicationApi = /** @class */ (function () {
    function CreditApplicationApi() {
        this.fetch = this.fetch.bind(this);
        this.checkJson = this.checkJson.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
    }
    CreditApplicationApi.prototype.acceptAmendment = function (applicationId, amendmentId) {
        var request = new Request(endpoint + "/" + applicationId + "/amend/" + amendmentId, __assign(__assign({}, this.getInit()), { method: 'PATCH', body: JSON.stringify({
                accept: true
            }) }));
        return this.fetch(request);
    };
    CreditApplicationApi.prototype.discoverApplications = function (token) {
        this.token = token;
        return this.fetch(endpoint);
    };
    CreditApplicationApi.prototype.getApplication = function (id) {
        return this.fetch(endpoint + "/" + id);
    };
    CreditApplicationApi.prototype.updateApplication = function (application) {
        var request = new Request(endpoint + "/" + application.application_id, {
            method: 'PATCH',
            mode: 'cors',
            headers: this.getInit().headers,
            body: JSON.stringify({
                consumer: application.consumer,
                marketing: application.marketing,
            })
        });
        return this.requestThenFetch(request, application.application_id);
    };
    CreditApplicationApi.prototype.updateConsent = function (application) {
        var body = {
            conditions: application.conditions
        };
        var request = new Request(endpoint + "/" + application.application_id + "/consent", __assign(__assign({}, this.getInit()), { method: 'PATCH', body: JSON.stringify(body) }));
        return this.requestThenFetch(request, application.application_id);
    };
    CreditApplicationApi.prototype.submitApplication = function (creditApplication) {
        var blackboxString = {};
        var igloo = window.IGLOO;
        if (igloo != null && igloo.getBlackbox != null) {
            var bbObject = igloo.getBlackbox();
            blackboxString = { iovation_black_box_string: bbObject.blackbox };
        }
        var request = new Request(endpoint + "/" + creditApplication.application_id + "/submit", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify(__assign(__assign({ decision_type: 'QUALIFIED', threatmetrix_session_token: creditApplication.threatmetrix_session_token }, blackboxString), { consent: { hard_search: true } })) }));
        return this.requestThenFetch(request, creditApplication.application_id);
    };
    CreditApplicationApi.prototype.cancelApplication = function (applicationId, reason, code) {
        var request = new Request(endpoint + "/" + applicationId + "/cancel", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify({
                cancellation_reason: reason,
                cancellation_code: code
            }) }));
        return this.requestThenFetch(request, applicationId);
    };
    CreditApplicationApi.prototype.getDocuments = function (applicationId) {
        var url = endpoint + "/" + applicationId + "/documents";
        return this.fetch(url);
    };
    CreditApplicationApi.prototype.signDocuments = function (applicationId) {
        var request = new Request(endpoint + "/" + applicationId + "/documents", __assign(__assign({}, this.getInit()), { method: 'PATCH', body: JSON.stringify({
                documents_signed: true
            }) }));
        return this.requestThenFetch(request, applicationId);
    };
    CreditApplicationApi.prototype.payDeposit = function (creditApplication, cardDetails) {
        var request = new Request(endpoint + "/" + creditApplication.application_id + "/deposits", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify({
                cardholder_name: cardDetails.cardholder_name,
                card_number: this.prepareCreditCardNumber(cardDetails.card_number.toString()),
                security_code: cardDetails.security_code.toString(),
                card_type: this.mapCardType(cardDetails.card_type),
                end_date_month: cardDetails.end_date.month.toString(),
                end_date_year: cardDetails.end_date.year.toString()
            }) }));
        return this.fetch(request);
    };
    CreditApplicationApi.prototype.getBranding = function (applicationId, brandingType) {
        return this.fetch(endpoint + "/" + applicationId + "/branding/" + brandingType);
    };
    CreditApplicationApi.prototype.submitInstoreIdUpload = function (applicationId, images) {
        var request = new Request(idEndpoint + "/" + applicationId + "/instore-identity-verification", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify(images) }));
        return this.requestThenFetch(request, applicationId);
    };
    CreditApplicationApi.prototype.submitInstoreDepositPayment = function (creditApplication, instoreVerification) {
        var request = new Request(idEndpoint + "/" + creditApplication.application_id + "/instore-deposit-payment", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify(instoreVerification) }));
        return this.requestThenFetch(request, creditApplication.application_id);
    };
    CreditApplicationApi.prototype.submitInstoreIdVerification = function (creditApplication, instoreVerification) {
        var request = new Request(idEndpoint + "/" + creditApplication.application_id + "/id-upload-instore", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify(instoreVerification) }));
        return this.requestThenFetch(request, creditApplication.application_id);
    };
    CreditApplicationApi.prototype.fulfilApplication = function (creditApplicationId) {
        var request = new Request(endpoint + "/" + creditApplicationId + "/fulfil", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify({ fulfilment_reference: 'Fulfilled by application form' }) }));
        return this.requestThenFetch(request, creditApplicationId);
    };
    CreditApplicationApi.prototype.getDecisioningInformation = function (id) {
        return this.fetch(new Request(endpoint + "/" + id + "/credit-bureaus", __assign(__assign({}, this.getInit(false)), { method: 'GET' })));
    };
    CreditApplicationApi.prototype.getFeatureFlagConfig = function (featureFlagName, id, context) {
        var request = new Request(config_1.default.apiBase + "feature-flags?context=" + context + "&identifier=" + id + "&features=" + featureFlagName, __assign(__assign({}, this.getInit()), { method: 'GET' }));
        return this.fetch(request);
    };
    CreditApplicationApi.prototype.getOpayoMerchantSessionKey = function (applicationId) {
        var request = new Request(endpoint + "/" + applicationId + "/merchant-session-key", __assign(__assign({}, this.getInit(false)), { method: 'GET' }));
        return this.fetch(request);
    };
    CreditApplicationApi.prototype.makeOpayoDeposit = function (applicationId, cardIdentifier, merchantSessionKey) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                request = new Request(endpoint + "/" + applicationId + "/depositspi", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify({
                        card_identifier: cardIdentifier,
                        merchant_session_key: merchantSessionKey
                    }) }));
                return [2 /*return*/, this.fetch(request)];
            });
        });
    };
    CreditApplicationApi.prototype.getScope = function (applicationId) {
        var request = new Request(endpoint + "/" + applicationId + "/scope/" + this.token, __assign(__assign({}, this.getInit()), { method: 'GET' }));
        return this.fetch(request);
    };
    CreditApplicationApi.prototype.getJumioRedirectUrl = function (creditRequestId, baseUrl) {
        var request = new Request(config_1.default.apiBase + "jumio/initiate", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify({
                credit_request_id: creditRequestId,
                base_url: baseUrl
            }) }));
        return this.fetch(request);
    };
    CreditApplicationApi.prototype.registerJumioSuccess = function (transactionReference, customerInternalReference, creditApplicationId) {
        var request = new Request(config_1.default.apiBase + "jumio/id-upload-success", __assign(__assign({}, this.getInit()), { method: 'POST', body: JSON.stringify({
                vendor_reference: transactionReference,
                internal_reference: customerInternalReference,
                credit_request_id: creditApplicationId
            }) }));
        return this.fetch(request);
    };
    CreditApplicationApi.prototype.getInit = function (forPost) {
        if (forPost === void 0) { forPost = true; }
        var headers = { Authorization: "Bearer " + this.token };
        if (forPost) {
            headers['Content-Type'] = 'application/json';
        }
        return {
            headers: new Headers(__assign({}, headers)),
            mode: 'cors'
        };
    };
    CreditApplicationApi.prototype.fetch = function (request) {
        if (typeof request === 'string') {
            request = new Request(request, this.getInit(false));
        }
        return fetch(request).then(this.checkStatus);
    };
    CreditApplicationApi.prototype.checkStatus = function (res) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (res.ok) {
                _this.checkJson(res).then(resolve);
            }
            else {
                _this.checkJson(res).then(function (body) {
                    reject({
                        status: res.status,
                        statusText: res.statusText,
                        error: body.error,
                    });
                });
            }
        });
    };
    CreditApplicationApi.prototype.checkJson = function (res) {
        return new Promise(function (resolve) {
            if (res.headers.get('Content-Type').indexOf('application/json') === 0) {
                res.json().then(resolve);
            }
            else {
                resolve(res);
            }
        });
    };
    CreditApplicationApi.prototype.requestThenFetch = function (request, applicationId) {
        var _this = this;
        return this.fetch(request).then(function () {
            return _this.getApplication(applicationId);
        });
    };
    CreditApplicationApi.prototype.mapCardType = function (cardType) {
        if (cardTypeMap[cardType]) {
            return cardTypeMap[cardType];
        }
        throw new Error('Unsupported card type');
    };
    CreditApplicationApi.prototype.prepareCreditCardNumber = function (cardNumber) {
        return deko_utilities_1.cardNumberParse(cardNumber);
    };
    CreditApplicationApi = __decorate([
        inversify_1.injectable(),
        __metadata("design:paramtypes", [])
    ], CreditApplicationApi);
    return CreditApplicationApi;
}());
exports.default = CreditApplicationApi;
