"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_1 = require("redux");
var navigationActions_1 = require("../../actions/navigationActions");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var Page_1 = require("../../components/Page");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
require("../../styles/pages/TheEndPage.scss");
var Barcode = require("react-barcode");
var deko_utilities_1 = require("deko-utilities");
var completeImg = require('../../assets/complete.svg');
var closeButton = function () { return window.opener ? React.createElement(deko_common_components_1.Button, { handleClick: window.close }, "Close this window") : null; };
var CompleteVerifiedPage = /** @class */ (function (_super) {
    __extends(CompleteVerifiedPage, _super);
    function CompleteVerifiedPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CompleteVerifiedPage.prototype.render = function () {
        var creditApplication = this.props.application.credit_application_id;
        var barCode = this.props.application.retailer.bar_code && creditApplication ?
            React.createElement(Barcode, { format: 'EAN13', renderer: 'canvas', value: deko_utilities_1.barCodeGenerator.generateBarCode((creditApplication).toString()) }) : null;
        var noCreditApplicationMessage = creditApplication ? null : 'Cannot generate barcode : credit application id ' +
            'not set';
        return (React.createElement(Page_1.default, { className: 'complete-verified-page' },
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] },
                    React.createElement("h1", null, "The purchase has been verified"),
                    React.createElement("p", null, "You have confirmed that the customer\u2019s deposit has been received. The Credit Agreement is now in effect and you are authorised to release the purchase. When you are ready to mark the order as fulfilled, you can do so from your Back Office account."),
                    React.createElement("p", null, "The credit application process is now complete. You may close this window."),
                    React.createElement("div", { className: 'canvas-wrapper' },
                        barCode,
                        noCreditApplicationMessage),
                    React.createElement("div", { className: 'complete-image' },
                        React.createElement(deko_common_components_1.Image, { src: completeImg })),
                    closeButton()))));
    };
    return CompleteVerifiedPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: redux_1.bindActionCreators({
        dispatchNavigation: navigationActions_1.dispatchNavigation
    }, dispatch)
}); };
var connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(CompleteVerifiedPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.VERIFIED])(connected);
