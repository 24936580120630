"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
require("reflect-metadata");
var baseUrl = 'https://services.postcodeanywhere.co.uk/';
var key = 'GU38-XP61-WX19-FN27';
var findEndpoint = baseUrl + "CapturePlus/Interactive/Find/v2.00/json3ex.ws?";
var retrieveEndpoint = baseUrl + "CapturePlus/Interactive/RetrieveFormatted/v2.00/json3ex.ws?";
var CapturePlusApi = /** @class */ (function () {
    function CapturePlusApi() {
        this.MAX_RETRY_COUNT = 3;
    }
    CapturePlusApi.prototype.find = function (query, lastId) {
        var url = findEndpoint;
        url += "&Key=" + key;
        url += "&SearchTerm=" + encodeURIComponent(query);
        url += '&SearchFor=Everything';
        url += '&Country=GBR';
        url += '&$block=true';
        url += '&$cache=true';
        if (lastId) {
            url += "&LastId=" + lastId;
        }
        return this.fetchWithRetry(url);
    };
    CapturePlusApi.prototype.retrieve = function (id) {
        var url = retrieveEndpoint;
        url += "&Key=" + key;
        url += "&Id=" + encodeURIComponent(id);
        url += '&Origin=GBR';
        return this.fetchWithRetry(url);
    };
    CapturePlusApi.prototype.fetchWithRetry = function (url, failCount) {
        var _this = this;
        return fetch(url)
            .then(function (res) { return res.ok ? res.json() : Promise.reject({ status: res.status, statusText: res.statusText }); })
            .then(function (res) {
            if (res && res.Items && res.Items[0] && res.Items[0].Error) {
                failCount = failCount || 0;
                console.warn("Request failed: \"" + url + "\"");
                if (failCount < _this.MAX_RETRY_COUNT) {
                    console.warn('Retrying');
                    return _this.fetchWithRetry(url, failCount + 1);
                }
                else {
                    console.error('Giving up');
                    return Promise.reject(res.Items[0]);
                }
            }
            else {
                return res;
            }
        });
    };
    CapturePlusApi = __decorate([
        inversify_1.injectable()
    ], CapturePlusApi);
    return CapturePlusApi;
}());
exports.default = CapturePlusApi;
