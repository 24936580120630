"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var ScrollRestoration = /** @class */ (function (_super) {
    __extends(ScrollRestoration, _super);
    function ScrollRestoration() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScrollRestoration.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.location === prevProps.location) {
            return;
        }
        var hash = window.location.hash;
        if (hash !== '') {
            setTimeout(function () {
                var id = hash.replace('#', '');
                var element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
        else {
            window.scrollTo(0, 0);
        }
    };
    ScrollRestoration.prototype.render = function () {
        // TODO-16: Remove this wrapping div
        return React.createElement("div", null, this.props.children);
    };
    return ScrollRestoration;
}(React.Component));
exports.default = react_router_1.withRouter(ScrollRestoration);
