"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var deko_common_components_1 = require("deko-common-components");
var NextButton_1 = require("../../../../common/components/form/NextButton");
var RadioInput_1 = require("../../../../common/components/form/RadioInput");
var ConnectedError_1 = require("../../../../common/components/form/ConnectedError");
var IApplication_1 = require("../../../../common/api/CreditApplication/IApplication");
var SortCodeInput_1 = require("../../../../common/components/form/SortCodeInput");
var AddressInputGroup_1 = require("../../../../common/components/form/AddressInputGroup");
var FieldValidator_1 = require("../../../../common/validation/FieldValidator");
var FormSection_1 = require("../../../../common/components/form/FormSection");
var Row_1 = require("../../../../common/components/template/Row");
var Col_1 = require("../../../../common/components/template/Col");
var Tooltips = require("./applicationFormTooltips");
var AddressFieldValidator_1 = require("../../../../common/validation/AddressFieldValidator");
var DesktopSelectInput_1 = require("../../../../common/components/form/DesktopSelectInput");
var Loader_1 = require("../../../../common/components/Loader");
var StickyClicky_1 = require("../../../../common/components/StickyClicky");
var ApplicationFormValidator_1 = require("../../../validators/ApplicationFormValidator");
var LenderComponentProvider_1 = require("../../../components/LenderComponentProvider");
var QuickFill_1 = require("../../../components/QuickFill/QuickFill");
var FeatureSwitch_1 = require("../../../components/FeatureSwitch");
var MorpheusRewardingButton_1 = require("../../../../common/components/MorpheusRewardingButton");
/**
 * These imports can be removed if we move all the components to be classes and we use decorators.
 */
var baseValidator = FieldValidator_1.default();
var TextInput = baseValidator(deko_common_components_1.TextInput);
var RadioInput = baseValidator(RadioInput_1.default);
var DateInput = baseValidator(deko_common_components_1.DateInput);
var SortCodeInput = baseValidator(SortCodeInput_1.default);
var FormattedNumberInput = baseValidator(deko_common_components_1.FormattedNumberInput);
var DesktopSelectInput = baseValidator(DesktopSelectInput_1.default);
var AddressInputGroup = AddressFieldValidator_1.default(AddressInputGroup_1.default);
var employmentFieldValidator = FieldValidator_1.default(ApplicationFormValidator_1.linkedFields.employment);
var EmploymentTextInput = employmentFieldValidator(deko_common_components_1.TextInput);
var EmploymentRadioInput = employmentFieldValidator(RadioInput_1.default);
var confirmEmailFieldValidator = FieldValidator_1.default(ApplicationFormValidator_1.linkedFields.email);
var ConfirmEmailTextInput = confirmEmailFieldValidator(deko_common_components_1.TextInput);
var CTAButton = MorpheusRewardingButton_1.default(StickyClicky_1.default(NextButton_1.default));
var titleOptions = [
    { text: 'Mr', value: IApplication_1.TitleEnum.MR },
    { text: 'Mrs', value: IApplication_1.TitleEnum.MRS },
    { text: 'Ms', value: IApplication_1.TitleEnum.MS },
    { text: 'Miss', value: IApplication_1.TitleEnum.MISS }
];
var maritalOptions = [
    { text: 'Married/Civil partnership', value: IApplication_1.MaritalStatusEnum.MARRIED },
    { text: 'Single', value: IApplication_1.MaritalStatusEnum.SINGLE },
    { text: 'Cohabiting', value: IApplication_1.MaritalStatusEnum.COHABITING },
    { text: 'Divorced', value: IApplication_1.MaritalStatusEnum.DIVORCED },
    { text: 'Separated', value: IApplication_1.MaritalStatusEnum.SEPERATED },
    { text: 'Widowed', value: IApplication_1.MaritalStatusEnum.WIDOWED },
];
var employmentStatusOptions = [
    { text: 'Employed full-time', value: IApplication_1.EmploymentStatusEnum.FULL_TIME },
    { text: 'Employed part-time', value: IApplication_1.EmploymentStatusEnum.PART_TIME },
    { text: 'Self-employed', value: IApplication_1.EmploymentStatusEnum.SELF_EMPLOYED },
    { text: 'Military', value: IApplication_1.EmploymentStatusEnum.MILITARY },
    { text: 'Retired', value: IApplication_1.EmploymentStatusEnum.RETIRED },
    { text: 'Houseperson', value: IApplication_1.EmploymentStatusEnum.HOMEMAKER },
];
var yearsWithEmployerOptions = [
    { text: '0‒1', value: 1 },
    { text: '1‒3', value: 2 },
    { text: '3‒6', value: 4 },
    { text: '6‒9', value: 7 },
    { text: '9+', value: 10 }
];
var mapYearsWithEmployer = function (years) {
    return yearsWithEmployerOptions.reduce(function (carry, current) {
        if (carry < current.value && years >= current.value) {
            return current.value;
        }
        return carry;
    }, 0);
};
var shouldDisableEmployerFields = function (status) {
    return typeof status !== 'undefined' ? (status === IApplication_1.EmploymentStatusEnum.RETIRED ||
        status === IApplication_1.EmploymentStatusEnum.HOMEMAKER) : false;
};
var residentialStatusOptions = [
    { text: 'Homeowner', value: IApplication_1.ResidentialStatusEnum.RESIDENTIAL_HOMEOWNER },
    { text: 'Private tenant', value: IApplication_1.ResidentialStatusEnum.RESIDENTIAL_TENANT },
    { text: 'Council tenant', value: IApplication_1.ResidentialStatusEnum.RESIDENTIAL_COUNCIL_RENTING },
    { text: 'Living with parents', value: IApplication_1.ResidentialStatusEnum.RESIDENTIAL_LIVING_WITH_PARENTS }
];
var addonsYear = React.createElement(React.Fragment, null,
    React.createElement("span", { key: 0, className: 'left-addon' }, "\u00A3"),
    React.createElement("span", { key: 1, className: 'right-addon' }, "/year"));
var addonsMonth = React.createElement(React.Fragment, null,
    React.createElement("span", { key: 0, className: 'left-addon' }, "\u00A3"),
    React.createElement("span", { key: 1, className: 'right-addon' }, "/month"));
var ApplicationForm = function (_a) {
    var handleChange = _a.handleChange, entity = _a.entity, _b = _a.asyncRunning, asyncRunning = _b === void 0 ? false : _b, _c = _a.telephoneApplication, telephoneApplication = _c === void 0 ? false : _c, lender = _a.lender, retailer = _a.retailer, branding = _a.branding;
    var consumer = entity;
    var title = consumer.title, forename = consumer.forename, surname = consumer.surname, date_of_birth = consumer.date_of_birth, email = consumer.email, confirm_email = consumer.confirm_email, mobile_number = consumer.mobile_number, marital_status = consumer.marital_status, employment = consumer.employment, affordability = consumer.affordability, bank_details = consumer.bank_details, consent = consumer.consent, marketing = consumer.marketing;
    if (bank_details == null) {
        throw new TypeError('Bank details is required');
    }
    if (consent == null) {
        throw new TypeError('Consent is required');
    }
    var employmentDisabled = shouldDisableEmployerFields(employment.status);
    var handleEmploymentChange = function (event) {
        var status = event.target.value;
        if (shouldDisableEmployerFields(status)) {
            return handleChange({
                target: {
                    name: 'employment',
                    value: __assign(__assign({}, employment), { status: status, occupation: undefined, employer: undefined, years_with_employer: undefined })
                }
            });
        }
        return handleChange(event);
    };
    var handleSalaryChange = function (event) {
        return handleChange({
            target: {
                name: 'employment.salary',
                value: {
                    amount: event.target.value,
                    type: IApplication_1.EmploymentSalaryEnum.SALARY_GROSS_ANNUAL
                }
            }
        });
    };
    var handleHouseholdIncomeChange = function (event) {
        var value = {};
        if (event.target.value !== undefined) {
            value = {
                amount: event.target.value,
                type: IApplication_1.AffordabilityExpenditureEnum.HOUSEHOLD_INCOME
            };
        }
        return handleChange({
            target: {
                name: 'affordability.household_income',
                value: value
            }
        });
    };
    var handleMortgageIncomeChange = function (event) {
        return handleChange({
            target: {
                name: 'affordability.monthly_rent_amount',
                value: {
                    amount: event.target.value,
                    type: IApplication_1.AffordabilityExpenditureEnum.RENT_OR_MORTGAGE
                }
            }
        });
    };
    var salary = employment.salary || {};
    var householdIncome = affordability.household_income || {};
    var monthlyRent = affordability.monthly_rent_amount || {};
    return (React.createElement("div", { className: 'application-form' },
        React.createElement("h1", null, "Your Details"),
        React.createElement(FormSection_1.default, { label: 'About you' },
            React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                var AppFormPrefix = _a.AppFormPrefix;
                return (React.createElement(AppFormPrefix, { branding: branding }));
            }),
            React.createElement(FeatureSwitch_1.default, { name: 'quick_fill', identifier: retailer.id, context: 'RETAILER' },
                React.createElement(QuickFill_1.default, null)),
            React.createElement("input", { type: 'hidden', name: 'affordability-active', value: 'active', onChange: handleChange }),
            React.createElement(RadioInput, { name: 'title', label: 'Title', options: titleOptions, value: title, handleChange: handleChange, layout: 'HORIZONTAL' }),
            React.createElement(TextInput, { name: 'forename', label: 'First name', value: forename, handleChange: handleChange, tooltip: Tooltips.getTooltip('forename') }),
            React.createElement(TextInput, { name: 'surname', label: 'Last name', value: surname, handleChange: handleChange }),
            React.createElement(Row_1.default, null,
                React.createElement(Col_1.default, { layout: ['xs-10'] },
                    React.createElement(DateInput, { name: 'date_of_birth', label: 'Date of birth', value: date_of_birth, handleChange: handleChange }))),
            React.createElement(TextInput, { name: 'email', label: 'Email address', type: 'email', value: email, handleChange: handleChange, tooltip: Tooltips.getTooltip('email') }),
            React.createElement(ConfirmEmailTextInput, { name: 'confirm_email', label: 'Confirm email address', type: 'email', value: confirm_email, handleChange: handleChange }),
            React.createElement(TextInput, { name: 'mobile_number', label: 'Contact number', type: 'tel', value: mobile_number, handleChange: handleChange }),
            React.createElement(DesktopSelectInput, { name: 'marital_status', label: 'Marital status', options: maritalOptions, value: marital_status, handleChange: handleChange, layout: 'VERTICAL', placeholder: 'Please select' }),
            branding.lender_is_panel &&
                React.createElement(TextInput, { name: 'affordability.dependants', label: 'Number of dependants', type: 'numeric-text', value: affordability.dependants, handleChange: handleChange, tooltip: Tooltips.getTooltip('dependants') })),
        React.createElement(FormSection_1.default, { label: 'Your employment' },
            React.createElement(DesktopSelectInput, { name: 'employment.status', label: 'Employment status', options: employmentStatusOptions, value: employment.status, handleChange: handleEmploymentChange, layout: 'VERTICAL', placeholder: 'Please select' }),
            React.createElement(EmploymentTextInput, { name: 'employment.occupation', label: 'Occupation', value: employment.occupation, handleChange: handleChange, disabled: employmentDisabled }),
            React.createElement(EmploymentTextInput, { name: 'employment.employer', label: 'Employer name', value: employment.employer, handleChange: handleChange, disabled: employmentDisabled }),
            React.createElement(EmploymentRadioInput, { name: 'employment.years_with_employer', label: 'Years with this employer', options: yearsWithEmployerOptions, type: 'number', value: mapYearsWithEmployer(employment.years_with_employer), handleChange: handleChange, layout: 'HORIZONTAL', disabled: employmentDisabled }),
            React.createElement(FormattedNumberInput, { name: 'employment.salary.amount', label: 'Annual income', className: 'salary', value: salary.amount, handleChange: handleSalaryChange, thousandSeparator: true, prefix: addonsYear, tooltip: Tooltips.getTooltip('salary') }),
            branding.lender_is_panel &&
                React.createElement(FormattedNumberInput, { name: 'affordability.household_income.amount', label: 'Annual household income (optional)', className: 'household', value: householdIncome.amount, handleChange: handleHouseholdIncomeChange, thousandSeparator: true, prefix: addonsYear, tooltip: Tooltips.getTooltip('household') })),
        React.createElement(FormSection_1.default, { label: 'Your residential status' },
            React.createElement(DesktopSelectInput, { name: 'residential_status', label: 'Residential status', options: residentialStatusOptions, value: consumer.residential_status, handleChange: handleChange, layout: 'VERTICAL', placeholder: 'Please select' }),
            React.createElement(AddressInputGroup, { customer: consumer, handleChange: handleChange, currentAddressMessage: "Please note that any goods purchased must be delivered to the address used on this\n                                 application." }),
            branding.lender_is_panel &&
                React.createElement(FormattedNumberInput, { name: 'affordability.monthly_rent_amount.amount', label: 'Monthly mortgage or rent amount', className: 'rent', value: monthlyRent.amount, handleChange: handleMortgageIncomeChange, thousandSeparator: true, prefix: addonsMonth, tooltip: Tooltips.getTooltip('monthly_rent_amount') })),
        React.createElement(FormSection_1.default, { label: 'Your bank details' },
            React.createElement("div", { className: 'direct-debit-info' },
                React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                    var DirectDebitInfo = _a.DirectDebitInfo;
                    return (React.createElement(DirectDebitInfo, null));
                })),
            React.createElement(TextInput, { label: 'Account number', name: 'bank_details.account_number', type: 'text', handleChange: handleChange, value: bank_details.account_number, htmlProps: {
                    pattern: '[0-9]*',
                } }),
            React.createElement("div", { className: 'row' },
                React.createElement(Col_1.default, { layout: ['xs-10'] },
                    React.createElement(SortCodeInput, { label: 'Sort code', name: 'bank_details.sort_code', value: bank_details.sort_code, handleChange: handleChange })))),
        React.createElement(LenderComponentProvider_1.default, null, function (_a) {
            var ConsentSection = _a.ConsentSection;
            return (React.createElement(ConsentSection, __assign({}, { consent: consent, marketing: marketing, handleChange: handleChange, lender: lender, branding: branding, telephoneApplication: telephoneApplication })));
        }),
        React.createElement(ConnectedError_1.default, { errorMessage: "You're not quite finished yet. " }),
        React.createElement("div", { className: 'next-button-section' },
            React.createElement(CTAButton, { experimentId: 'experiment', disabled: asyncRunning, ariaAttributes: { 'aria-label': 'submit my application' }, timeout: 300 }, asyncRunning ? React.createElement(Loader_1.default, { title: 'Processing...', loaderType: Loader_1.LoaderTypeEnum.BUTTON }) :
                'Submit my application')),
        React.createElement(LenderComponentProvider_1.default, null, function (_a) {
            var QuestionsAboutApplicationSection = _a.QuestionsAboutApplicationSection;
            return (React.createElement(QuestionsAboutApplicationSection, __assign({}, { lender: lender })));
        })));
};
exports.default = ApplicationForm;
