"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var deko_common_components_1 = require("deko-common-components");
var ApplicationOutcomes_1 = require("../../../common/components/ApplicationOutcomes");
var IApplication_1 = require("../../../common/api/CreditApplication/IApplication");
var RequiredStatus_1 = require("../../../common/components/RequiredStatus");
var navigationActions_1 = require("../../../common/actions/navigationActions");
var Page_1 = require("../../components/Page");
var Row_1 = require("../../../common/components/template/Row");
var Col_1 = require("../../../common/components/template/Col");
var BackToRetailerButton_1 = require("../../../common/components/BackToRetailerButton");
var RequireDocumentsSigned_1 = require("../../../common/components/RequireDocumentsSigned");
var isApplicationOfType_1 = require("../../../common/utilities/isApplicationOfType");
require("../../styles/pages/TheEndPage.scss");
var LenderComponentProvider_1 = require("../../components/LenderComponentProvider");
var referImg = require('../../assets/complete.svg');
var ReferPage = /** @class */ (function (_super) {
    __extends(ReferPage, _super);
    function ReferPage(props) {
        var _this = _super.call(this, props) || this;
        _this.renderInstoreContent = _this.renderInstoreContent.bind(_this);
        _this.renderOnlineContent = _this.renderOnlineContent.bind(_this);
        _this.emailFromText = _this.emailFromText.bind(_this);
        return _this;
    }
    ReferPage.prototype.render = function () {
        var application = this.props.application;
        var content = isApplicationOfType_1.isApplicationOfType(IApplication_1.LoanTypeEnum.IS, application) ?
            this.renderInstoreContent() :
            this.renderOnlineContent();
        return (React.createElement(RequireDocumentsSigned_1.default, { documentShouldBeSigned: true, fallbackAction: navigationActions_1.DOCUMENTS_PAGE },
            React.createElement(Page_1.default, { className: 'refer-page' },
                React.createElement(Row_1.default, null,
                    React.createElement(Col_1.default, { layout: ['xs-12', 'sm-10'] }, content)))));
    };
    ReferPage.prototype.renderInstoreContent = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", null,
                "Thank you ",
                React.createElement("span", { "data-hj-suppress": true }, this.props.application.consumer.forename)),
            React.createElement("p", null,
                React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                    var ReferSection = _a.ReferSection;
                    return (React.createElement(ReferSection, { application: _this.props.application, lender: _this.props.application.lender, branding: _this.props.branding, inStore: true }));
                })),
            React.createElement("p", null, "For the time being, please return the device to the representative."),
            React.createElement("div", { className: 'decline-image' },
                React.createElement(deko_common_components_1.Image, { src: referImg }))));
    };
    ReferPage.prototype.renderOnlineContent = function () {
        var _this = this;
        var appendText = this.lenderIsBraemar(this.props.application.lender) ?
            'One of our expert underwriters will review your application shortly.' :
            'The checks usually take less than one hour, ' +
                'however applications received out of working hours may take longer.';
        var postContent = this.lenderIsBraemar(this.props.application.lender) ? this.postContent() : null;
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", null,
                "Thank you ",
                React.createElement("span", { "data-hj-suppress": true }, this.props.application.consumer.forename)),
            React.createElement("p", null,
                React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                    var ReferSection = _a.ReferSection;
                    return (React.createElement(ReferSection, { application: _this.props.application, lender: _this.props.application.lender, branding: _this.props.branding, inStore: false }));
                })),
            React.createElement("p", null,
                "Please follow the instructions in the email to finalise your purchase. ",
                appendText),
            React.createElement("p", null, postContent),
            React.createElement("div", { className: 'decline-image' },
                React.createElement(deko_common_components_1.Image, { src: referImg })),
            React.createElement("p", null,
                "Put the kettle on and keep an eye out for an email from ",
                React.createElement("strong", null, this.emailFromText()),
                "."),
            React.createElement(BackToRetailerButton_1.default, { status: ApplicationOutcomes_1.AppOutcomeEnum.REFER })));
    };
    ReferPage.prototype.emailFromText = function () {
        if (this.props.application.lender.name === 'Klarna' ||
            this.props.application.lender.name === 'Close Brothers Retail Finance') {
            return 'Klarna Bank Customer Support';
        }
        return 'support@dekopay.com';
    };
    ReferPage.prototype.postContent = function () {
        return (React.createElement("div", null,
            React.createElement("p", null, "Underwriters are available Monday to Friday from 9am-5pm. If your application is outside of this time, a response will follow on the next business day.")));
    };
    ReferPage.prototype.lenderIsBraemar = function (lender) {
        return lender.name === 'Braemar Finance';
    };
    return ReferPage;
}(React.Component));
var mapStateToProps = function (state) { return ({
    application: state.application,
    branding: state.branding,
}); };
var connected = react_redux_1.connect(mapStateToProps)(ReferPage);
exports.default = RequiredStatus_1.default([IApplication_1.AppStatusEnum.REFER, IApplication_1.AppStatusEnum.ACTION_LENDER])(connected);
