"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    differentOption: [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'Please confirm'
        },
    ],
    carefullyConsidered: [
        {
            ruleName: 'shouldBe',
            constraint: true,
            message: 'Please confirm'
        },
    ]
};
