"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var StickyClicky_1 = require("../../../common/components/StickyClicky");
var deko_common_components_1 = require("deko-common-components");
var LenderComponentProvider_1 = require("../LenderComponentProvider");
var DekoAgreementDisclaimer_1 = require("./DekoAgreementDisclaimer");
var LenderLinks_1 = require("../../../common/config/LenderLinks");
var CTAButton = StickyClicky_1.default(deko_common_components_1.Button);
var termsUrl = LenderLinks_1.termsOfServiceLinks[LenderLinks_1.lenderEnum.DEKO];
var trustPilot = require('../../assets/trustpilot.svg');
var DekoPrequalContent = function (_a) {
    var application = _a.application, continueCallback = _a.continueCallback;
    var term = application.finance.product.loan_term;
    var defaultTimeout = 300;
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: 'header' }, "Your Credit Application"),
        React.createElement("p", null,
            "You're applying for credit from our lender partners to spread the cost of your purchase over ",
            term,
            " months. ",
            React.createElement("strong", null, "Deko's technology"),
            " uses the information you provide to match you with a lender."),
        React.createElement("p", null, "There are a few simple steps to complete your application:"),
        React.createElement("ul", { className: 'ticked-list' },
            React.createElement("li", null, "Provide personal information"),
            React.createElement("li", null, "Read and sign your documentation"),
            React.createElement("li", null, "Set up your Direct Debit for repayments")),
        React.createElement("p", { className: 'font-small hide-xs privacy-policy-section' },
            React.createElement("strong", null, "By continuing you are confirming that you have read and agree to Deko's "),
            React.createElement("a", { href: LenderLinks_1.privacyLink, target: '_blank' }, "Privacy Policy"),
            " ",
            React.createElement("strong", null, "and"),
            " ",
            React.createElement("a", { href: termsUrl, target: '_blank' }, " Terms of Service"),
            "."),
        React.createElement(CTAButton, { handleClick: continueCallback, timeout: defaultTimeout }, "Start your application"),
        React.createElement("div", { style: { marginBottom: '20px' } },
            React.createElement(deko_common_components_1.Image, { src: trustPilot, altText: 'Trustpilot' })),
        React.createElement("p", { className: 'font-small hide-xs' }, DekoAgreementDisclaimer_1.default),
        React.createElement("div", { className: 'show-xs' },
            React.createElement(LenderComponentProvider_1.default, null, function (_a) {
                var CreditSearchSection = _a.CreditSearchSection;
                return (React.createElement(CreditSearchSection, null));
            }))));
};
exports.default = DekoPrequalContent;
