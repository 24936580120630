"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("../styles/components/BaseEmbeddedDocument.scss");
var Loader_1 = require("./Loader");
var Row_1 = require("./template/Row");
var Col_1 = require("./template/Col");
var EmbeddedDocument = /** @class */ (function (_super) {
    __extends(EmbeddedDocument, _super);
    function EmbeddedDocument(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { content: '', error: undefined };
        _this.loadContent(props.src);
        _this.loadContent = _this.loadContent.bind(_this);
        return _this;
    }
    EmbeddedDocument.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return (nextProps.src !== this.props.src ||
            nextState.content !== this.state.content ||
            nextState.error !== this.state.error);
    };
    EmbeddedDocument.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.src !== this.props.src) {
            this.setState({ content: '' });
            this.loadContent(this.props.src);
        }
        if (window.innerWidth > 739) {
            window.scrollTo(0, 0);
        }
    };
    EmbeddedDocument.prototype.render = function () {
        var _a = this.props, documentInnerLayout = _a.documentInnerLayout, children = _a.children, _b = _a.loader, loader = _b === void 0 ? React.createElement(Loader_1.default, null) : _b;
        if (this.state.error) {
            throw new Error("Problem fetching document: " + this.state.error);
        }
        if (!this.state.content) {
            return loader;
        }
        return (React.createElement("div", { className: 'embedded-document', "data-hj-suppress": true },
            React.createElement(Row_1.default, { className: 'contents' },
                React.createElement(Col_1.default, { layout: documentInnerLayout },
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: this.state.content } }))),
            children));
    };
    EmbeddedDocument.prototype.loadContent = function (src) {
        var _this = this;
        fetch(src).then(function (res) {
            if (res.ok) {
                res.text().then(function (content) {
                    _this.setState({ content: content });
                    if (_this.props.onFinishedLoading) {
                        _this.props.onFinishedLoading();
                    }
                });
            }
            else {
                _this.setState({ error: res.status + ": " + res.statusText });
            }
        }).catch(function (err) {
            _this.setState({ error: err });
        });
    };
    return EmbeddedDocument;
}(React.Component));
exports.default = EmbeddedDocument;
