"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var redux_actions_1 = require("redux-actions");
var actionTypes = require("../actions/actionTypes");
var handleLoadApplicationSuccess = function (state, action) { return (__assign({}, action.creditApplication)); };
var handleUpdateStatus = function (state, action) { return (__assign(__assign({}, state), { status: action.status })); };
var handleUpdateConsumer = function (state, action) {
    return __assign(__assign({}, state), { consumer: action.consumer });
};
var handleSignDocument = function (state, action) {
    var _a;
    if (state.consumer.documents == null) {
        throw new TypeError('Documents must be set');
    }
    return __assign(__assign({}, state), { consumer: __assign(__assign({}, state.consumer), { documents: __assign(__assign({}, state.consumer.documents), (_a = {}, _a[action.documentType] = __assign(__assign({}, state.consumer.documents[action.documentType]), { agreed: true, signed_time: new Date() }), _a)) }) });
};
var threatmetrixUpdateSessionToken = function (state, action) { return (__assign(__assign({}, state), { threatmetrix_session_token: action.threatmetrix_session_token })); };
exports.default = redux_actions_1.handleActions((_a = {},
    _a[actionTypes.LOAD_APPLICATION_SUCCESS] = handleLoadApplicationSuccess,
    _a[actionTypes.UPDATE_STATUS] = handleUpdateStatus,
    _a[actionTypes.UPDATE_CONSUMER] = handleUpdateConsumer,
    _a[actionTypes.SIGN_DOCUMENT] = handleSignDocument,
    _a[actionTypes.THREATMETRIX_UPDATE_TOKEN] = threatmetrixUpdateSessionToken,
    _a), {});
